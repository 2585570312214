.CategoryPreview {
	display: grid;
	grid-template-rows: auto auto 1fr;
	grid-template-columns: 1fr 1fr;
	grid-template-areas:
		'title products'
		'crumbs products'
		'other products'
	;
}
.CategoryPreview__title {
	font-size: 20px;
	grid-area: title;
}
.CategoryPreview__title a {
	text-decoration: none;
}
.CategoryPreview__title a:hover {
	text-decoration: underline;
}
.CategoryPreview__title a:after {
	content: '';
	display: inline-block;
	--size: 0.8em;
	vertical-align: baseline;
	height: var(--size);
	width: var(--size);
	background-image: url('~/src/icons/external-link.svg');
	background-size: var(--size) var(--size);
	background-position: center center;
	background-repeat: no-repeat;
	margin-left: 8px;
}

.CategoryPreview__crumbs {
	margin-top: 10px;
	grid-area: crumbs;
}
.CategoryPreview__crumb {
	text-decoration: none;
}
.CategoryPreview__crumb:hover {
	text-decoration: underline;
}
.CategoryPreview__crumb:after {
	content: ' / ';
	display: inline-block;
	margin: 0 0.5em;
}
.CategoryPreview__crumb:last-child:after {
	content: none;
}

.CategoryPreview__products {
	grid-area: products;
}

.CategoryPreview__sectionTitle {
	font-size: 16px;
	font-weight: var(--bold);
	margin-bottom: 10px;
}
.CategoryPreview__showMore {
	font-size: 12px;
}