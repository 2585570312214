import React, { useState, useRef, useEffect } from 'react';

export const InfiniteOverflowScroller = ({ items, render, itemHeight=150, itemSpacing=20 }) => {
	const scrollState = useScrollState();
	const [startIndex, visibleItems] = getVisibleItems({ scrollState, items, itemHeight, itemSpacing });
	return (
		<div ref={scrollState.wrapRef} style={getWrapStyle({ items, itemHeight, itemSpacing })}>
			{visibleItems.map((item, index) => render(item, getItemStyle({ item, itemHeight, itemSpacing, index: index + startIndex }), index + startIndex))}
		</div>
	);
};

function getItemStyle({ itemHeight, itemSpacing, index }) {
	return {
		position: 'absolute',
		width: '100%',
		top: (itemHeight + itemSpacing) * index,
	};
}

function getWrapStyle({ items, itemHeight, itemSpacing }) {
	const height = items.length * (itemHeight + itemSpacing);
	return {
		position: 'relative',
		height,
	};
}

function getVisibleItems({ scrollState, items, itemHeight, itemSpacing }) {
	const windowHeight = scrollState.innerHeight - scrollState.topOffset;
	const itemsPerWindow = windowHeight / ( itemHeight + itemSpacing );
	const scrollProgress = scrollState.scrollY / windowHeight;
	const start = Math.max(0, Math.floor(scrollProgress * itemsPerWindow) - 5);
	const end = Math.ceil(scrollProgress * itemsPerWindow + itemsPerWindow) + 5;
	return [start, items.slice(start, end)];
}

function useScrollState() {
	const scrollContainer = window;
	const [topOffset, setTopOffset] = useState(0);
	const [scrollY, setScrollY] = useState(scrollContainer.scrollY);
	const wrapRef = useRef();
	useEffect(() => {
		if (wrapRef.current) {
			setTopOffset(wrapRef.current.offsetTop);
		}
	}, [wrapRef]);
	useEffect(() => {
		const handleScroll = (event) => {
			setScrollY(event.currentTarget.scrollY);
		};
		scrollContainer.addEventListener('scroll', handleScroll);
		return () => {
			scrollContainer.removeEventListener('scroll', handleScroll);
		}
	}, [scrollContainer]);
	return {
		topOffset,
		innerHeight: window.innerHeight,
		wrapRef,
		scrollY,
	};
}