.Code {
	display: block;
	padding: 20px;
	background: rgba(0, 0, 0, 0.1);
}
.Code pre {
	white-space: break-spaces;
	tab-size: 2;
	line-height: 1.5;
	margin: 0;
}
