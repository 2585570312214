import React, { useState } from 'react';
import classnames from 'classnames';

import * as css from './JSONPreview.css';

export const JSONPreview = ({ children, initiallyOpen=false }) => {
	const [isOpen, setIsOpen] = useState(initiallyOpen);
	// Allows having contracted content when not open
	const codeArgs = isOpen ? [children, null, '\t'] : [children];
	const toggleOpen = () => {
		setIsOpen(!isOpen);
	}
	return (
		<div className={classnames(css.JSONPreview, !isOpen && css['JSONPreview--closed'])}>
			<pre onClick={() => setIsOpen(true)}>
				<code>{typeof children === 'string' ? children : JSON.stringify(...codeArgs)}</code>
			</pre>
			<div className={css.JSONPreview__toggle} onClick={toggleOpen}>
				{isOpen ? 'Click to close -' : 'Click to expand +'}
			</div>
		</div>
	)
};