import React, { useState } from 'react';

import { EditorField } from '~/src/lib/Editors';

import * as css from './VideoEmbedEditor.css';

const noop = (value) => console.log('VideoEmbedEditor.onChange', value);

const FIELDS = [{
	name: 'service',
	type: 'text',
	label: 'Video service',
}, {
	name: 'videoId',
	type: 'text',
	label: 'Video ID',
}];

export const VideoEmbedEditor = ({ onChange=noop, defaultValue={} }) => {
	const [data, setData] = useState(defaultValue || {});
	const handleChange = (key, value) => {
		const newData = { ...data, [key]: value };
		setData(newData);
		onChange(newData);
	};
	return (
		<div className={css.VideoEmbedEditor}>
			{FIELDS.map((field) => (
				<EditorField
					key={field.name}
					field={field}
					onChange={handleChange}
					item={data}
				/>
			))}
		</div>
	);
};

