import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import classnames from 'classnames';

import notify from '~/src/util/notify';

import * as css from './Buttons.css';

export const BaseButton = ({ children, onClick, to, target, className, type='button', style={}, ...props }) => {
	const isMounted = useRef(false);
	const [isLoading, setIsLoading] = useState(false);
	const isLink = typeof to === 'string';
	const isExternalLink = isLink && to.startsWith('http');
	const ButtonComponent = isLink ? (isExternalLink ? 'a' : RouterLink) : 'button';
	useEffect(() => {
		isMounted.current = true;
		return () => isMounted.current = false;
	});
	if (typeof style !== 'object') {
		throw new Error('Button only accepts objects for style parameter');
	}
	const handleClick = async (event) => {
		try {
			if (onClick) {
				event.preventDefault();
				setIsLoading(true);
				await onClick(event);
				if (isMounted.current) {
					setIsLoading(false);
				}
			}
		} catch (error) {
			notify.error(error);
			setIsLoading(false);
		}
	};
	return (
		<ButtonComponent
			className={classnames(css.BaseButton, isLoading && css['BaseButton--is-loading'], className)}
			onClick={handleClick}
			to={to}
			href={to}
			target={target || isExternalLink ? '_blank' : target}
			type={type}
			style={style}
			{...props}
		>
			{typeof children === 'string' ? <span>{children}</span> : children}
		</ButtonComponent>
	);
};

export const Button = ({ className, variant='primary', shape='round', size='medium', icon=null, ...props }) => (
	<BaseButton
		className={classnames(
			css.Button,
			css[`Button--${variant}`],
			css[`Button--${shape}`],
			css[`Button--size-${size}`],
			icon && css['Button--has-icon'],
			icon && css[`Icon--${icon}`],
			className
		)}
		{...props}
	/>
);

export const FeedbackButton = ({ onClick, children, ...props }) => {
	const [ message, setMessage ] = useState(null);
	const promiseRef = useRef(null);
	const handleClick = (event) => {
		event.stopPropagation();
		promiseRef.current = Promise.resolve(onClick(event));
		setMessage(' . . . ');
	};
	useEffect(() => {
		let isActive = true;
		if (promiseRef.current) {
			promiseRef.current.then(() => {
				if (isActive) {
					setMessage(null);
				}
			});
		}
		return () => isActive = false;
	}, [message, promiseRef]);
	return (
		<Button {...props} onClick={handleClick}>
			<span style={{
				position: 'absolute',
				width: '100%',
				left: 0,
				textAlign: 'center',
				opacity: message ? 1 : 0,
			}}>{message}</span>
			<span style={{ opacity: message ? 0 : 1 }}>{children}</span>
		</Button>
	);
}

export const FunButton = (props) => (
	<BaseButton className={css.FunButton} {...props} />
);

export const DangerButton = ({ onClick, ...props }) => {
	const [hasClicked, setClicked] = useState(false);
	useEffect(() => {
		if (hasClicked) {
			const timeout = setTimeout(() => {
				setClicked(false);
			}, 3000);
			return () => clearTimeout(timeout);
		}
	}, [hasClicked]);
	const handleSecondClick = (event) => {
		setClicked(false);
		onClick(event);
	};
	if (hasClicked) {
		return (
			<Button className={css.DangerButton} variant="danger" onClick={handleSecondClick} data-popover="Are you sure?" {...props} />
		);
	}
	return (
		<Button className={css.DangerButton} variant="danger" onClick={() => setClicked(true)} {...props} />
	);
};

export const HelpButton = ({ className, ...props }) => (
	<BaseButton className={classnames(css.HelpButton, className)} {...props} />
);

export const ButtonBar = ({ className, variant='default', lozenge=false, noSpread, ...props }) => (
	<div className={classnames(css.ButtonBar, css[`ButtonBar--${lozenge ? 'lozenge' : variant}`], noSpread && css['ButtonBar--no-spread'], className)} {...props} />
);

export const ButtonList = ({ className, ...props }) => (
	<div className={classnames(css.ButtonList, className)} {...props} />
);

export const Link = ({ className, variant='default', expandHitArea=false, ...props }) => (
	<BaseButton className={classnames(css.Link, css[`Link--${variant}`], expandHitArea && css['Link--larger-hit-area'], className)} {...props} />
);

export const SharpButton = ({ className, variant='default', ...props }) => (
	<BaseButton className={classnames(css.SharpButton, css[`SharpButton--${variant}`], className)} {...props} />
);

export const SmallButton = ({ className, variant='default', ...props }) => (
	<BaseButton className={classnames(css.SmallButton, css[`SmallButton--${variant}`], className)} {...props} />
);

export const SlimIconButton = ({ className, variant='default', icon='none', isSelected, children, ...props }) => (
	<BaseButton
		className={classnames(
				css.SlimIconButton,
				css[`SlimIconButton--${variant}`],
				isSelected && css['SlimIconButton--is-selected'],
				!(children && children.length) && css['SlimIconButton--no-text'],
				css[`Icon--${icon}`],
				isSelected && icon && css['Icon--is-selected'],
				className
			)}
		{...props}
	>
		{children}
	</BaseButton>
);
