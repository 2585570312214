.TextEditor {
	display: flex;
	position: relative;
	font-size: inherit;
}
.TextEditor--size-large {
	--line-height: 1.4em;
	font-size: var(--editor-large-text);
}
.TextEditor--size-normal {
	--line-height: 1.4em;
	font-size: var(--editor-normal-text);
}
.TextEditor--size-small {
	--line-height: 1.4em;
	font-size: var(--editor-small-text);
}
.TextEditor__fill,
.TextEditor__input {
	resize: none;
	border: none;
	font: inherit;
	line-height: var(--line-height);
	padding: 0;
	margin: 0;
	white-space: pre-line;
	word-break: break-word;
}
.TextEditor__input {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
}
.TextEditor__input:focus {
	outline: none;
}
.TextEditor__fill {
	visibility: hidden;
	pointer-events: none;
	padding-bottom: var(--line-height);
}