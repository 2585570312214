import React, { Component } from 'react';
import classnames from 'classnames';

import { makeStore } from '~/src/store';
import { Button } from '~/src/lib/Buttons';

import * as css from './notify.css';

/*

Show a notification.

Info:
notify('Something happened')

Errors:
notify.error('Error')
notify.error(new Error('Something happened'))

Both functions accept an input object instead of a message:
notify({
	message: 'Something happened',
	duration: 1000, // How long to show banner, in milliseconds
	id, // read below
	button: {
		label: 'View result',
		to: `/scrape-results/${json.data.scrape_id}`,
	},
})


If the `id` parameter is sent in, the banner with the same ID
will be replaced by this new item.


*/

const MESSAGE_MAX_AGE = 1500;
const ERROR_MAX_AGE = 10000;

const ERROR = 'ERROR';
const INFO = 'INFO';

const notifyStore = makeStore({ load: () => ({ messages: [] }) });


function notify(input) {
	const message = formatMessage(input);
	showNotification(message);
}

function error(input) {
	const message = formatError(input);
	showNotification(message);
}
notify.error = error;

window.notify = notify;

function showNotification(message) {
	const data = notifyStore.all();
	const messages = [...data.messages];
	const messageIndex = messages.findIndex((item) => item.id === message.id);
	const isReplacementMessage = messageIndex !== -1;
	if (isReplacementMessage) {
		messages.splice(messageIndex, 1, message);
	} else {
		messages.push(message);
	}
	notifyStore.update({
		messages,
	});
	setTimeout(removeOldMessages, message.maxAge - (new Date()).getTime() + 100); // +100 so we're never before the timeout
}

function removeOldMessages() {
	const messages = notifyStore.all().messages;
	const now = (new Date()).getTime();
	const prunedMessages = messages.filter((message) => message.maxAge > now);
	notifyStore.update({ messages: prunedMessages });
}

function formatMessage(input) {
	const isString = typeof input === 'string';
	return {
		type: INFO,
		message: isString ? input : input.message,
		button: input.button || null,
		id: input.id || Math.random(),
		maxAge: (new Date()).getTime() + (input.duration || MESSAGE_MAX_AGE),
	};
}

function formatError(input) {
	const isString = typeof input === 'string';
	return {
		type: ERROR,
		message: isString ? input : input.message,
		statusCode: input.statusCode || null,
		code: input.code || null,
		full: input,
		id: input.id || Math.random(),
		button: input.button || null,
		maxAge: (new Date()).getTime() + (input.duration || ERROR_MAX_AGE),
	};
}

const NotifyItem = ({ children, message, onDismiss }) => (
	<li className={classnames(css.item, message.type === ERROR && css.isError, message.type === INFO && css.isInfo)}>
		<div className={css.text}>{children}</div>
		{message.button && (
			<div className={css.button}>
				<Button variant="primary" {...message.button}>{message.button.label}</Button>
			</div>
		)}
		<button onClick={onDismiss} className={css.close}></button>
	</li>
);

export class NotifyBanner extends Component {
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
	}
	componentDidMount() {
		this.subscription = notifyStore.listen(this.handleChange);
	}
	componentWillUnmount() {
		this.subscription.remove();
	}
	handleChange() {
		this.setState({
			random: Math.random(),
		});
	}
	handleDismiss(message) {
		const messages = notifyStore.get('messages');
		notifyStore.update({
			messages: messages.filter((item) => item.id !== message.id),
		});
	}
	renderMessage(message) {
		switch (message.type) {
			case ERROR:
				return (
					<NotifyItem
						onDismiss={() => this.handleDismiss(message)}
						message={message}
						key={message.id}
					>
						{message.message} {(message.statusCode || message.code) && `(${message.statusCode || message.code})`}
					</NotifyItem>
				);
			default:
				return (
					<NotifyItem
						onDismiss={() => this.handleDismiss(message)}
						message={message}
						key={message.id}
					>
						{message.message}
					</NotifyItem>
				);
		}
	}
	render() {
		const messages = notifyStore.get('messages');
		if (!messages.length) {
			return null;
		}
		return (
			<div className={css.NotifyBanner}>
				<ul className={css.messages}>
					{messages.map((message) => this.renderMessage(message))}
				</ul>
			</div>
		);
	}
}

export default notify;