import React from 'react';
import classnames from 'classnames';

import { formatLink, formatTimeAgoStrict } from '~/src/util/format';

import * as css from './Text.css';

export const ExternalLink = ({ href, children }) => (
	<a className={css.ExternalLink} href={href} target="_blank" rel="noopener noreferrer">{children || formatLink(href)}</a>
);

export const OverflowFade = ({ children, title=null }) => (
	<span title={title} className={css.OverflowFade}>{children}</span>
);

export const SectionTitle = ({ children }) => (
	<h2 className={css.SectionTitle}>{children}</h2>
);

export const SubSectionTitle = ({ children }) => (
	<h3 className={css.SubSectionTitle}>{children}</h3>
);

export const Red = ({ children, bold=false }) => (
	<span className={classnames(css.Red, bold && css['Red--bold'])}>{children}</span>
);

export const Label = ({ children, className, subLabel }) => {
	if (!children) {
		return null;
	}
	return (
		<span className={classnames(css.Label, className)}>
			{children}
			{subLabel && (
				<span className={css.Label__subLabel}>{subLabel}</span>
			)}
		</span>
	);
};

export const Line = ({ children, size, variant='default' }) => (
	<span className={classnames(css.Line, css[`Line--${size}`], css[`Line--${variant}`])}>{children}</span>
);

export const TimeAgo = ({ children, className }) => (
	<span className={className} title={children}>{formatTimeAgoStrict(children)} ago</span>
);


export const LabelledText = ({ children, className, label, tight=false }) => (
	<div className={classnames(css.LabelledText, tight && css['LabelledText--tight'], className)}>
		<div className={css.LabelledText__label}>{label || '-'}</div>
		{typeof children === 'string' ? (<p>{children}</p>) : children}
	</div>
);

export const LabelledBlock = ({ children, className, label }) => (
	<div className={classnames(css.LabelledBlock, className)}>
		<div className={css.LabelledBlock__label}>{label || '-'}</div>
		{typeof children === 'string' ? (<p>{children}</p>) : children}
	</div>
);

export const SmallText = ({ children }) => (
	<span className={css.SmallText}>{children}</span>
);
