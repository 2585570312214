import React, { useState } from 'react';

import request from '~/src/util/request';
import { useTemporaryMessage } from '~/src/util/useTemporaryMessage';
import { setClipboard } from '~/src/util/clipboard';
import { SectionTitle } from '~/src/lib/Text';
import { Page } from '~/src/lib/Page';
import { Button } from '~/src/lib/Buttons';
import { URLField } from '~/src/lib/Form';

import * as css from './LinkCreator.css';

export const LinkCreator = () => {
	const [message, setMessage] = useTemporaryMessage(3000);
	const [isLoading, setIsLoading] = useState(false);
	const [inputUrl, setInputUrl] = useState();
	const [linkInfo, setLinkInfo] = useState(null);
	const handleChange = (value) => {
		setInputUrl(value);
		setLinkInfo(null);
	};
	const handleSubmit = async (event) => {
		event.preventDefault();
		setIsLoading(true);
		try {
			const result = await request(
				'POST',
				'/v0/affiliates/create-link',
				{ target_url: inputUrl }
			);
			setLinkInfo(result.data);
		} finally {
			setIsLoading(false);
		}
	};
	const handleCopyUrl = async () => {
		await setClipboard(linkInfo.url);
		setMessage('Copied!');
	};
	const isValid = validateUrl(inputUrl);
	const action = linkInfo?.action;
	return (
		<Page
			title="Link Creator"
			isLoading={isLoading}
		>
			<div className={css.LinkCreator}>
				<section className={css.LinkCreator__input}>
					<form onSubmit={handleSubmit}>
						<SectionTitle>Link creator</SectionTitle>
						<URLField
							label="URL"
							defaultValue={inputUrl}
							onChange={handleChange}
						/>
						<div className={css.LinkCreator__actions}>
							<Button type="submit" disabled={!isValid} variant="neutral">Generate link</Button>
						</div>
					</form>
				</section>
				{action ? (
					<section className={css.LinkCreator__prompt}>
						{action ? (
							<div className={css.Action}>
								<p className={css.Action__message}>{action.message}</p>
								<div className={css.Action__button}>
									{action.button && (
										<Button variant="primary" {...action.button}>{action.button.label}</Button>
									)}
								</div>
							</div>
						) : null}
					</section>
				) : null}
				<section className={css.LinkCreator__result}>
					{linkInfo && linkInfo.url ? (
						<div className={css.Link} onClick={handleCopyUrl}>
							{linkInfo.affiliate_scheme ? (
								<div className={css.Link__scheme}>
									Affiliate scheme: <strong>{linkInfo.affiliate_scheme}</strong>
								</div>
							) : null}
							<div className={css.Link__label}>
								Click to copy
								{message ? (
									<span className={css.Link__message}>
										&nbsp;- {message}
									</span>
								) : null}
							</div>
							<div className={css.Link__url}>
								<span className={css.Link__urlHighlight}>{linkInfo.url}</span>
							</div>
						</div>
					) : null}
				</section>
			</div>
		</Page>
	);
};

function validateUrl(inputUrl) {
	try {
		new URL(inputUrl);
		return true;
	} catch (error) {
		return false;
	}
}