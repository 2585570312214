import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { SectionTitle, SubSectionTitle } from '~/src/lib/Text';
import { TitleBar, Spread } from '~/src/lib/Layout';
import { Button, ButtonBar } from '~/src/lib/Buttons';
import { AutoLoader } from '~/src/lib/AutoLoader';
import request from '~/src/util/request';
import { useResource } from '~/src/util/useResource';
import { Page } from '~/src/lib/Page';
import { JSONPreview } from '~/src/lib/JSONPreview';
import { JSONField } from '~/src/lib/Form';

export const SetupImport = () => {
	const history = useHistory();
	const params = new URLSearchParams(history.location.search);
	const siteScanId = params.get('site_scan_id');
	const [importConfig, setImportConfig] = useState(null);
	const [checkResult, setCheckResult] = useState(null);
	const [error, setError] = useState(siteScanId ? null : new Error('Missing site_scan_id'));
	const scan = useResource(siteScanId ? `/v0/scanner/scans/${siteScanId}` : null);
	const defaultConfigResource = useResource(siteScanId ? `/v0/product-importer/default-import-config/${siteScanId}` : null);
	const configIsOk = checkResult && checkResult.isValid;
	const cancelImport = () => {};
	const checkImport = async () => {
		const response = await request('POST', '/v0/product-importer/imports', { siteScanId, importConfig });
		if (response.ok) {
			setCheckResult(response.data);
		} else {
			setCheckResult(null);
		}
	};
	const createImport = async () => {
		const response = await request('POST', '/v0/product-importer/imports', { siteScanId, importConfig, create: true });
		if (response.ok) {
			// TODO: Ensure we get an importId
			if (response.data.importId) {
				history.push(`/product-imports/imports/${response.data.importId}/products`);
			} else {
				setError(new Error(`API did not return an importId (got "${response.data.importId}")`));
			}
		}
	};
	const handleImportConfigChange = (value) => {
		setImportConfig(value);
	};
	useEffect(() => {
		if (defaultConfigResource.data) {
			setImportConfig(defaultConfigResource.data);
		}
	}, [defaultConfigResource.data]);
	return (
		<Page
			backLink={`/scanner/scans/${siteScanId}`}
			title="Import products"
		>
			<AutoLoader error={error || defaultConfigResource.error || scan.error} isLoading={defaultConfigResource.isLoading || scan.isLoading}>
				<SectionTitle>Scan info</SectionTitle>
				<JSONPreview>{scan.data}</JSONPreview>
				<SectionTitle>Import config</SectionTitle>
				{importConfig ? (
					<JSONField
						isSimple
						onChange={handleImportConfigChange}
						defaultValue={importConfig}
					/>
				) : 'Loading import config...'}
				<ButtonBar>
					<Button variant="secondary" onClick={cancelImport}>Cancel</Button>
					<Spread />
					<Button variant="blue" onClick={checkImport}>Check</Button>
				</ButtonBar>
				{configIsOk ? (
					<Fragment>
						<TitleBar>
							<SectionTitle>Configuration is valid</SectionTitle>
							<Button variant="primary" onClick={createImport}>Create</Button>
						</TitleBar>
						<SubSectionTitle>Product count: {checkResult.matchedProductCount}</SubSectionTitle>
					</Fragment>
				) : null}
			</AutoLoader>
		</Page>
	);
};
