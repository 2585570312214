import React from 'react';

import { Button } from '~/src/lib/Buttons';

import * as css from './Paginate.css';

export const Paginate = ({ nextLink }) => (
	<div className={css.Paginate}>
		<Button to={nextLink}>Next page</Button>
	</div>
);