import React, { Fragment, useState } from 'react';

import { Page } from '~/src/lib/Page';
import { SectionTitle } from '~/src/lib/Text';
import { Button, ButtonBar } from '~/src/lib/Buttons';
import { Spread } from '~/src/lib/Layout';
import { InspectRaw, KeyVals } from '~/src/lib/Debug';
import { Expander } from '~/src/lib/Expander';
import { MatchList } from '~/src/lib/MatchList';
import { ServicesList } from '~/src/lib/ServicesList';
import { ProductList } from '~/src/lib/ProductList';
import { CategoriesList } from '~/src/lib/CategoriesList';
import { TabSwitcher, TabSwitcherTab } from '~/src/lib/TabSwitcher';
import { useResource } from '~/src/util/useResource';

import * as css from './QueryInspector.css';

const Lead = () => (
	<Fragment>
		<p>See what was scanned in the query, and how the system analyses it and extracts meaning.</p>
		<p>Also, see what products were returned in the original run vs the fresh run.</p>
	</Fragment>
);

export const QueryInspector = ({ match }) => {
	const queryId = match.params.id;
	const [resultSelection, setResultSelection] = useState('original');
	const isFresh = resultSelection === 'fresh';
	const originalResult = useResource(`/v0/querylog/${queryId}`);
	const freshResult = useResource(isFresh ? `/v0/querylog/${queryId}/rerun` : null);
	const result = isFresh ? freshResult : originalResult;
	console.info('Result:', result && (result.isLoading ? 'Loading...' : (result.error || result.data)));
	console.info('Timings:', result?.data?.timings);
	return (
		<Page
			title={`Query: ${queryId}`}
			lead={<Lead />}
			hasStickyHeader
			extra={result?.data?.timings ? <span className={css.timings}>Query took {result?.data?.timings?.queryDuration}ms</span> : null}
			actions={(
				<ButtonBar>
					<TabSwitcher onChange={setResultSelection} value={resultSelection}>
						<TabSwitcherTab name="original">Original</TabSwitcherTab>
						<TabSwitcherTab name="fresh">Fresh</TabSwitcherTab>
					</TabSwitcher>
					<Spread />
					{originalResult?.data?.evaluation_id && (
						<Button
							variant="neutral"
							to={`/match-evaluator/${originalResult.data.evaluation_id}?return_to=${encodeURIComponent(`/querylog/${queryId}`)}`}
						>
							View match evaluation
						</Button>
					)}
				</ButtonBar>
			)}
		>
			{result.data && (
				<div className={css.Inspect}>
					<Scan raw={result.data.rawQuery} clean={result.data.query} />
					<Analysis analysis={result.data.analysis} query={result.data.query} />
					<Categories categories={result.data.categories} />
					{result.data.api === '0.0.1' ? (
						<LegacyResults
							products={result.data.products}
							source={result.data.matchSource}
						/>
					) : (
						<Results
							matches={result.data.matches}
							products={result.data.products}
							services={result.data.services}
							resultSelection={resultSelection}
						/>
					)}
				</div>
			)}
			{result.data && (
				<Expander title="Raw result">
					<InspectRaw data={result.data} />
				</Expander>
			)}
			{result.isLoading && (
				<div>Loading</div>
			)}
			{result.error && (
				<div>Error: {result.error.message}</div>
			)}
		</Page>
	);
};

const Image = ({ raw }) => {
	if (!raw?.posterImage?.data) {
		return null;
	}
	return (
		<section className={css.Inspect__section}>
			<div className={css.Inspect__image}>
				<img src={raw.posterImage.data} alt="Image from the match" />
			</div>
		</section>
	);
};

const SCAN_KEY_ORDER = ['url', 'doman', 'title', 'heading', 'cutHeading', 'breadcrumbs', 'language', 'path'];
const Scan = ({ raw, clean }) => {
	const [selection, setSelection] = useState('clean');
	const data = selection === 'raw' ? raw : clean;
	return (
		<section className={css.Inspect__section} data-section="Scan">
			<SectionTitle>
				Scan
				{' '}
				<TabSwitcher onChange={setSelection} value={selection}>
					<TabSwitcherTab name="clean">Clean</TabSwitcherTab>
					<TabSwitcherTab name="raw">Raw</TabSwitcherTab>
				</TabSwitcher>
			</SectionTitle>
			<Image raw={raw} />
			{data && <KeyVals data={data} order={SCAN_KEY_ORDER} />}
		</section>
	)
};

const ANALYSIS_KEY_ORDER = ['productTerm', 'pageType', 'gender', 'ageGroup', 'demography', 'colors', 'materials', 'ecoCredentials', 'quantities', 'topic', 'niches', 'units', 'tokens'];
const Analysis = ({ title='Analysis', analysis }) => {
	const [pickProp, setPickProp] = useState('heading');
	if (!Object.keys(analysis).length) {
		return null;
	}
	return (
		<section className={css.Inspect__section} data-section="Analysis">
			<SectionTitle>
				{title}
				{' '}
				<TabSwitcher onChange={setPickProp} value={pickProp}>
					<TabSwitcherTab name="heading">Heading</TabSwitcherTab>
					<TabSwitcherTab name="title">Title</TabSwitcherTab>
				</TabSwitcher>
			</SectionTitle>
			<KeyVals data={analysis} pickProp={pickProp} order={ANALYSIS_KEY_ORDER} />
		</section>
	);
};

const LegacyResults = ({ products, source }) => (
	<section className={css.Inspect__section} data-section="Results">
		<SectionTitle>
			Legacy results
			{' '}
			<span className={css.Results__source}>Source: <em>{source}</em></span>
		</SectionTitle>
		{products && products.length ? (
			<ProductList products={products} />
		) : (
			<p>No matches</p>
		)}
	</section>
);

const Results = ({ products, matches, services, resultSelection }) => (
	<section className={css.Inspect__section} data-section="Results">
		<SectionTitle>
			Results ({resultSelection})
		</SectionTitle>
		{(matches && matches.length && products && products.length) ? (
			<MatchList matches={matches} products={products} />
		) : (
			services && services.length ? (
				<ServicesList services={services} />
			) : (
				<p>No matches</p>
			)
		)}
	</section>
);

const Categories = ({ categories }) => {
	const [showAll, setShowAll] = useState(false);
	return (
		<section className={css.Inspect__section} data-section="Categories">
			<SectionTitle>
				Categories
				{' '}
				<TabSwitcher onChange={setShowAll} value={showAll}>
					<TabSwitcherTab name={false}>Some</TabSwitcherTab>
					<TabSwitcherTab name={true}>All</TabSwitcherTab>
				</TabSwitcher>
			</SectionTitle>
			<CategoriesList categories={categories} truncate={showAll ? false : 3} />
		</section>
	);
}
