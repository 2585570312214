import React, { Fragment, useState } from 'react';
import { v4 as createUuid } from 'uuid';
import classnames from 'classnames';

import { TextEditor, ImageUploadEditor, TagsAndCertsEditor, QuoteEditor, VideoEmbedEditor } from '~/src/lib/Editors';
import { SlimIconButton, Button } from '~/src/lib/Buttons';

import textIcon from '~/src/icons/field-type-text.svg';
import imageIcon from '~/src/icons/field-type-image.svg';
import tagsAndCertsIcon from '~/src/icons/field-type-tags.svg';
import quoteIcon from '~/src/icons/field-type-quote.svg';

import * as css from './BlocksEditor.css';

const BLOCK_TYPES = {
	heading: {
		getBlank: () => ({ text: '', variant: 'h2' }),
		variants: [{
			label: 'H2',
			value: 'h2',
		}, {
			label: 'H3',
			value: 'h3',
		}, {
			label: 'H4',
			value: 'h4',
		}],
		render: ({ block, onChange, params }) => (
			<TextEditor
				size="large"
				key={block.id}
				onChange={(text) => onChange('update', { ...block.content, text })}
				defaultValue={block.content.text}
				placeholder="Type heading"
				params={params}
			/>
		)
	},
	text: {
		getBlank: () => ({ text: '' }),
		icon: textIcon,
		render: ({ block, onChange, params }) => (
			<TextEditor
				key={block.id}
				onChange={(text) => onChange('update', { ...block.content, text })}
				defaultValue={block.content.text}
				placeholder="Type text"
				params={params}
			/>
		)
	},
	image: {
		getBlank: () => ({ id: '', meta: { alt: '', url: '' } }),
		icon: imageIcon,
		render: ({ block, onChange, params }) => (
			<ImageUploadEditor
				key={block.id}
				onChange={(data) => onChange('update', { ...block.content, ...data })}
				defaultValue={block.content}
				updatedAt={block.updatedAt}
				params={params}
			/>
		)
	},
	quote: {
		getBlank: () => ({ id: '', text: '', attribution: '' }),
		icon: quoteIcon,
		render: ({ block, onChange, params }) => (
			<QuoteEditor
				key={block.id}
				onChange={(data) => onChange('update', { ...block.content, ...data })}
				defaultValue={block.content}
				updatedAt={block.updatedAt}
				params={params}
			/>
		)
	},
	video: {
		getBlank: () => ({ service: '', videoId: '' }),
		icon: quoteIcon,
		render: ({ block, onChange, params }) => (
			<VideoEmbedEditor
				key={block.id}
				onChange={(data) => onChange('update', { ...block.content, ...data })}
				defaultValue={block.content}
				updatedAt={block.updatedAt}
				params={params}
			/>
		)
	},
	tags_and_certifications: {
		getBlank: () => ({ tags: [], certifications: [] }),
		icon: tagsAndCertsIcon,
		render: ({ block, onChange, params }) => (
			<TagsAndCertsEditor
				key={block.id}
				onChange={(data) => onChange('update', { ...block.content, ...data })}
				defaultValue={block.content}
				updatedAt={block.updatedAt}
				params={params}
			/>
		)
	},
};

export const BlocksEditor = ({ content, onChange, params }) => {
	const updateContent = (newContent) => {
		onChange(newContent);
	};
	const handleBlockChange = (id, action, payload) => {
		switch (action) {
			case 'update':
				updateContent(content.map((block) => {
					if (block.id === id) {
						return {
							...block,
							content: payload,
						};
					}
					return block;
				}));
				break;
			case 'delete':
				updateContent(content.filter((block) => block.id !== id));
				break;
			case 'move':
				if (payload === 'up') {
					updateContent(moveItemInArray(content, (block) => block.id === id, -1))
				} else if (payload === 'down') {
					updateContent(moveItemInArray(content, (block) => block.id === id, +1))
				}
				break;
			default:
				console.warn(`Unsure what to do with action "${action}"`, payload);
		}
	};
	const addBlock = (type) => {
		const blockDef = getBlockDef(type);
		const block = {
			id: createUuid(),
			type,
			content: blockDef.getBlank(),
		};
		updateContent([...content, block]);
	};
	return (
		<section className={css.BlocksEditor}>
			<div className={css.BlocksEditor__blocks}>
				{content.map((block) => (
					<Block
						key={block.id}
						onChange={(...args) => handleBlockChange(block.id, ...args)}
						block={block}
						params={params}
					/>
				))}
			</div>
			<div className={css.BlocksEditor__actions}>
				{getAllowedBlockKeys(params).map((type) => (
					<Button key={type} onClick={() => addBlock(type)} variant="neutral">Add {type}</Button>
				))}
			</div>
		</section>
	);
};

function getAllowedBlockKeys(params) {
	if (!params.disableBlocks) {
		return Object.keys(BLOCK_TYPES);
	}
	return Object.keys(BLOCK_TYPES).filter((key) => !params.disableBlocks.includes(key));
}

const Block = ({ block, onChange, params }) => {
	const blockDef = getBlockDef(block.type);
	const handleVariantChange = (variant) => onChange('update', { ...block.content, variant });
	return (
		<div className={css.Block}>
			<div className={css.Block__inner}>
				<div className={css.Block__type}>
					<TypeBox
						block={block}
						blockDef={blockDef}
						onChangeVariant={handleVariantChange}
					/>
				</div>
				<div className={css.Block__content}>
					{blockDef.render({ block, onChange, params })}
				</div>
			</div>
			<div className={css.Block__actions}>
				<SlimIconButton
					className={css.Block__actionButton}
					onClick={() => onChange('move', 'up')}
					icon="up"
				/>
				<SlimIconButton
					className={css.Block__actionButton}
					onClick={() => onChange('move', 'down')}
					icon="down"
				/>
				<SlimIconButton
					className={css.Block__actionButton}
					onClick={() => onChange('delete')}
					icon="trash"
				/>
			</div>
		</div>
	);
};

function getBlockDef(type) {
	const blockDef = BLOCK_TYPES[type];
	if (!blockDef) {
		throw new Error(`Unknown block type "${type}"`);
	}
	return blockDef;
}

const TypeBox = ({ block, blockDef, onChangeVariant }) => {
	if (blockDef.icon) {
		return (
			<div className={css.Block__typeBox}>
				<img src={blockDef.icon} alt={blockDef.type} />
			</div>
		);
	}
	if (blockDef.variants) {
		return <BlockTypeOptions block={block} blockDef={blockDef} onChangeVariant={onChangeVariant} />;
	}
	return (
		<div className={css.Block__typeBox}>
			{block.type}
		</div>
	);
}

const BlockTypeOptions = ({ block, blockDef, onChangeVariant }) => {
	const [isOpen, setIsOpen] = useState(false);
	const handleOptionClick = (value) => {
		setIsOpen(false);
		onChangeVariant(value);
	};
	const selectedVariant = blockDef.variants.find((vari) => vari.value === block.content.variant);
	return (
		<Fragment>
			<Button className={css.Block__variantButton} onClick={() => setIsOpen(!isOpen)}>
				{selectedVariant.label}
			</Button>
			{isOpen ? (
				<div className={classnames(css.Block__variantSelector, isOpen && css['Block__variantSelector--is-open'])}>
					{blockDef.variants.map((variant) => (
						<Button
							key={variant.value}
							className={css.Block__variantOption}
							onClick={() => handleOptionClick(variant.value)}
						>
							{variant.label}
						</Button>
					))}
				</div>
			) : null}
		</Fragment>
	);
};

function moveItemInArray(list, predicate, direction) {
	const existingIndex = list.findIndex(predicate);
	if (existingIndex === -1) {
		throw new Error(`Invalid move index ${existingIndex}`);
	}
	const newIndex = Math.max(0, Math.min(list.length - 1, existingIndex + direction));

	const targetItem = list[existingIndex];
	const newContent = list.filter((item) => item !== targetItem);
	newContent.splice(newIndex, 0, targetItem);
	return newContent;
}