.CategoriesList {
}
.CategoriesItem {
	display: grid;
	grid-template-areas:
		'titles'
		'extra'
	;
	grid-column-gap: 10px;
	grid-row-gap: 5px;
	font-size: 18px;
	min-height: 20px;
	line-height: 25px;
	background: var(--white);
	padding: 15px;
	border-radius: 7px;
	box-shadow: var(--wide-shadow);
}
.CategoriesItem + .CategoriesItem {
	margin-top: 10px;
}
.CategoriesItem__titles {
	grid-area: titles;
	display: flex;
	align-items: baseline;
	grid-gap: 10px;
}
.CategoriesItem__title {
	display: flex;
	align-items: flex-start;
	font-weight: var(--bold);
	font-size: inherit;
}
.CategoriesItem__score {
	grid-area: score;
	font-size: 14px;
	font-weight: var(--semibold);
	color: var(--gray4);
}
.CategoriesItem__link {
	font: inherit;
	color: inherit;
}
.CategoriesItem__type {
	display: inline-block;
	font-size: 14px;
	line-height: 20px;
	font-weight: var(--semibold);
	color: var(--gray4);
}
.CategoriesItem__id {
	display: inline-block;
}
.CategoriesItem__rank {
	display: inline-block;
	color: var(--black);
	font-weight: var(--bold);
}
.CategoriesItem__extra {
	grid-area: extra;
	font-size: 14px;
	line-height: 20px;
}
.CategoriesItem__extraTitle {
	font-weight: var(--bold);
}