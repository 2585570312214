.CPCLinkCreator {}
.CPCLinkCreator__input {}
.CPCLinkCreator__result {
	margin-top: 30px;
}

.Link {
	display: grid;
	grid-template-rows: auto auto;
	grid-template-areas: 'label' 'url';
	grid-gap: 5px;
	position: relative;
	background: var(--gray1);
	padding: 10px;
	border-radius: 5px;
	cursor: pointer;
}
.Link__label {
	grid-area: label;
	font-size: 14px;
	font-weight: var(--bold);
}
.Link__url {
	grid-area: url;
	font-size: 20px;
}
.Link__message {}