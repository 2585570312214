import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';

import request from '~/src/util/request';
import notify from '~/src/util/notify';
import { getUpPathname } from '~/src/util/history';
import { Page } from '~/src/lib/Page';
import { Form, FormFade } from '~/src/lib/Form';
import { Button, DangerButton } from '~/src/lib/Buttons';

import { goTo } from '~/src/util/history';

class Editor extends Component {
	constructor(props) {
		super(props);
		const item = this.isNew() ? this.makeNewItem() : (this.props.item || null);
		this.state = {
			result: null,
			isLoading: !this.isNew(),
			item: item,
			key: Math.random(),
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
	}
	async componentDidMount() {
		const { loadEndpoint } = this.props;
		if (!this.isNew() && loadEndpoint) {
			this.loadItem();
		}
	}
	isNew() {
		return !!this.props.newItem;
	}
	makeNewItem() {
		if (typeof this.props.newItem === 'object') {
			return { ...this.props.newItem };
		}
		return {};
	}
	async loadItem() {
		const { loadEndpoint } = this.props;
		const result = await request('GET', loadEndpoint);
		if (result.status === 'error') {
			this.setState({ error: result });
		} else {
			this.setState({ isLoading: false, item: result.data, key: Math.random() });
		}
	}
	async handleSubmit(data) {
		if (this.state.isLoading) {
			notify.error('Already saving');
			return;
		}
		this.setState({ isLoading: true });
		// Artificially delay the request to take at least 250ms
		// to make it feel like it has done something
		const [result, ] = await Promise.all([
			this.submitData(data),
			delay(250),
		]);
		if (result.status === 'success') {
			this.setState({ isLoading: false, item: result.data, key: Math.random() });
			notify(this.isNew() ? 'Created!' : 'Saved!');
			this.triggerSuccess(result.data);
		} else {
			this.setState({ isLoading: false });
		}
	}
	async submitData(data) {
		const { submitEndpoint, method } = this.props;
		return await request(method, submitEndpoint, data);
	}
	async handleDelete() {
		const { submitEndpoint } = this.props;
		this.setState({ isLoading: true });
		const result = await request('DELETE', submitEndpoint);
		if (result.status === 'success') {
			this.triggerDelete();
		} else {
			this.setState({ isLoading: false });
		}
	}
	triggerSuccess(item) {
		const { onSuccess } = this.props;
		triggerCallback(onSuccess, item);
	}
	triggerDelete(item) {
		const { onDelete } = this.props;
		triggerCallback(onDelete, item);
	}
	render() {
		const { title, getTitle, children, allowDelete, actions } = this.props;
		const { error, item, isLoading, key } = this.state;
		if (error) {
			return (
				<Redirect to={getUpPathname()} />
			);
		}
		if (!item) {
			return null;
		}
		const actionRow = (
			<Fragment>
				<Button type="submit">Save</Button>
				{allowDelete && <DangerButton onClick={this.handleDelete}>Delete</DangerButton>}
				{typeof actions === 'function' ? actions(item) : actions}
			</Fragment>
		);
		return (
			<Form key={key} onSubmit={this.handleSubmit} isLoading={isLoading}>
				<Page title={getTitle ? getTitle(item) : title} actions={actionRow}>
					<FormFade>
						{children(item)}
					</FormFade>
				</Page>
			</Form>
		);
	}
}

function triggerCallback(callback, item) {
	if (typeof callback === 'string') {
		return goTo(callback);
	} else if (typeof callback === 'function') {
		const result = callback(item);
		if (typeof result === 'string') {
			return goTo(result);
		}
	}
}

function delay(wait) {
	return new Promise((done) => setTimeout(done, wait));
}

export default Editor;