import { parseISO, parse } from 'date-fns';

export function parseDate(date) {
	if (date instanceof Date) {
		return date;
	}
	if (typeof date !== 'string') {
		throw new Error(`Date must be of type string (ISO date), is "${typeof date}"`);
	}
	if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(date)) {
		return parse(date, 'yyyy-MM-dd', new Date());
	}
	return parseISO(date);
}
