import React from 'react';

import { TablePage } from '~/src/lib/Table';
import { useResource } from '~/src/util/useResource';
import { Red } from '~/src/lib/Text';
import { Button } from '~/src/lib/Buttons';

const HEADERS = [{
	label: 'Title',
	key: 'title',
	isWide: true
}, {
	label: 'Published?',
	key: 'is_published',
	renderItem: (item) => item.is_published ? 'Published' : (<Red>Not published</Red>),
}, {
	key: 'edit',
	renderItem: (item) => (
		<Button to={`/content/blog/posts/${item.id}`}>Edit</Button>
	)
}];

const Actions = () => (
	<Button to="/content/blog/posts/new">Create new</Button>
);

export const BlogPosts = () => {
	const { data, isLoading, error } = useResource('/v0/blog/posts');
	return (
		<TablePage
			keyProp="id"
			headers={HEADERS}
			items={data}
			title="Blog posts"
			isLoading={isLoading}
			error={error}
			actions={<Actions />}
		/>
	)
};
