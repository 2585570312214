.Meta {

}
.ItemEditor {
	margin-top: 50px;
}
.AddNewItem {
	display: flex;
	grid-gap: 10px;
	align-items: center;
	margin-top: 10px;
}
.Items {
	margin-top: 30px;
}
.Items li {
	list-style-type: none;
	margin: 0 0 10px;
}