import { useState } from 'react';
import { signout } from '~/src/util/auth';

const ENVIRONMENTS = {
	'PRODUCTION': {
		API_HOST: 'https://api.joinbeagle.com',
	},
	'STAGING': {
		API_HOST: 'https://api-staging.joinbeagle.com',
	},
	'AUTO': {
		API_HOST: process.env.API_HOST,
	},
};

function getEnvironmentKey() {
	return localStorage.getItem('environment') || 'AUTO';
}

export function getEnvironment() {
	const envKey = getEnvironmentKey();
	return ENVIRONMENTS[envKey]
}

function setEnvironmentKey(environment) {
	localStorage.setItem('environment', environment);
	signout();
}

function getNextEnvironmentKey() {
	const current = getEnvironmentKey();
	const envKeys = Object.keys(ENVIRONMENTS);
	let index = envKeys.indexOf(current) + 1;
	return envKeys[index > envKeys.length - 1 ? 0 : index];
}

export function useEnvironment() {
	const [env, setEnv] = useState(getEnvironmentKey());
	const toggleEnvironment = async () => {
		const env = getEnvironmentKey();
		const next = getNextEnvironmentKey();
		setEnvironmentKey(next);
		setEnv(next);
	};
	return [env, toggleEnvironment];
}

export function getHost() {
	const env = getEnvironment();
	return env.API_HOST;
}