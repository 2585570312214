.ActionCard {
	display: flex;
	flex-wrap: wrap;
	--pad-x: 20px;
	border-radius: 7px;
	box-shadow: 0 2px 14px rgba(0, 0, 0, 0.06), 0 1px 1px rgba(0, 0, 0, 0.08);
	margin-bottom: 30px;
	position: relative;
}
.ActionCard:last-child {
	margin-bottom: 0;
}
.Header {
	width: 100%;
	box-sizing: border-box;
	min-height: 75px;
	padding: 25px var(--pad-x) 0;
	display: flex;
	align-items: baseline;
	line-height: 30px;
	background: var(--secondary-gray);
	border: 1px solid rgba(0,0,0,0.05);
	border-bottom: none;
	border-radius: 7px 7px 0 0;
	column-gap: 10px;
}
.Footer {
	width: 100%;
	box-sizing: border-box;
	min-height: 75px;
	padding: 25px var(--pad-x);
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 30px;
	background: var(--secondary-gray);
	border: 1px solid rgba(0,0,0,0.05);
	border-bottom: none;
	border-radius: 0 0 7px 7px;
}
.Heading {
	font-size: 22px;
	line-height: inherit;
	font-weight: 500;
}
.Heading__link {
	text-decoration: none;
}
.Heading__link[href]:hover {
	text-decoration: underline;
}
.SubHeading {
	font-size: 20px;
	line-height: inherit;
	font-weight: 400;
	color: var(--gray4);
}
.SubHeading__link {
	text-decoration: none;
}
.SubHeading__link[href]:hover {
	text-decoration: underline;
}
.Source {
	font-size: 20px;
	line-height: inherit;
	font-weight: 400;
	text-decoration: none;
	white-space: nowrap;
}
.Source:hover {
	text-decoration: underline;
}
.Source:after {
	content: '';
	display: inline-block;
	--size: 15px;
	height: var(--size);
	width: var(--size);
	background-image: url('~/src/icons/external-link.svg');
	background-size: var(--size) var(--size);
	background-position: center center;
	background-repeat: no-repeat;
	margin-left: 8px;
	position: relative;
	top: 1px;
}
.Time {

}
.Spread {
	flex: 1;
}
.Body {
	width: 100%;
	box-sizing: border-box;
	background: var(--white);
	box-shadow: inset 0 2px 1px -1px rgba(0, 0, 0, 0.1);
	padding: 25px 0;
}
.Slot {
	width: 100%;
	background: var(--white);
}
.Slot__contents {
	display: grid;
	box-sizing: border-box;
	grid-template-columns: 1fr auto;
	grid-auto-rows: auto;
	box-shadow: inset 0 2px 1px -1px rgba(0, 0, 0, 0.1);
	padding: 25px 20px;
	row-gap: 20px;
}
.SlotHeader {
	display: flex;
	grid-row: 1;
	grid-column: 1;
	column-gap: 10px;
	align-items: center;
	min-height: 25px;
}
.SlotTitle {
	font-size: 18px;
	font-weight: var(--bold);
	line-height: 20px;
	display: inline;
}
.SlotHeaderSub {
	font-size: 14px;
	line-height: 20px;
	color: var(--gray5);
	white-space: nowrap;
}
.SlotGroup {
	grid-column: 1;
	grid-row: auto;
	display: grid;
	box-sizing: border-box;
	grid-template-columns: 1fr auto;
	grid-template-rows: auto auto;
	padding: 0 0;
	row-gap: 5px;
}
.SlotGroup:only-of-type {
	row-gap: 0;
}
.SlotGroup--has-hover-style:hover {
	padding: 10px;
	margin: -10px;
	background: var(--off-white);
	border-radius: 5px;
}
.SlotActions {
	grid-column: 2;
	grid-row: 1 / 3;
	align-self: center;
	margin-left: 20px;
	display: flex;
	column-gap: 10px;
}
.SlotActions--align-center {
	align-self: center;
}
.SlotActions--align-end {
	align-self: end;
}
.SlotBody {
	grid-column: 1;
	grid-row: 2;
	margin: 0;
	word-break: break-word;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto;
}