.LineEditor {
	display: flex;
	flex: 1;
}
.LineEditor__input {
	flex: 1;
}
.large {
	font-size: var(--editor-large-text);
}
.medium {
	font-size: var(--editor-medium-text);
}
.normal {
	font-size: var(--editor-normal-text);
}
.small {
	font-size: var(--editor-small-text);
}
