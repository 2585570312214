import React from 'react';

import { Page } from '~/src/lib/Page';
import { Label } from '~/src/lib/Text';
import { Column } from '~/src/lib/Layout';
import { SharpButton } from '~/src/lib/Buttons';

export const ImportsHome = () => (
	<Page title="Product imports">
		<Column gap={10}>
			<SharpButton to="/product-imports/imports">List imports</SharpButton>
			<Label>Recent products:</Label>
			<SharpButton to="/product-imports/recent-products/ready">Ready</SharpButton>
			<SharpButton to="/product-imports/recent-products/approved">Approved</SharpButton>
			<SharpButton to="/product-imports/recent-products/rejected">Rejected</SharpButton>
			<SharpButton to="/product-imports/recent-products/skipped">Skipped</SharpButton>
			<SharpButton to="/product-imports/recent-products/new">New</SharpButton>
		</Column>
	</Page>
);