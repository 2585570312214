.NotifyBanner {
	position: fixed;
	bottom: 0;
	left: var(--menu-width);
	width: calc(100% - var(--menu-width));
	z-index: var(--index-notification);
	border-top: 1px solid rgba(0,0,0,0.1);
	background: white;
	box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}
.messages {
	list-style: none;
}
.item {
	display: flex;
	align-items: center;
	padding: 10px 100px;
	min-height: 60px;
	border-bottom: 1px solid rgba(0,0,0,0.1);
}
.item:last-child {
	border-bottom: none;
}
.isError {
	color: red;
}
.isInfo {
}

.text {
	font-weight: 600;
}
.button {
	margin-left: auto;
}

.text:before {
	content: '';
	display: inline-block;
	width: 1.2em;
	height: 1.2em;
	margin: 0 0.5em -0.2em 0;
	background-size: 100% auto;
	background-position: center center;
	background-repeat: no-repeat;
}
.isError .text:before {
	background-image: svg-load('icons/alert-triangle.svg', stroke: red);
}
.isInfo .text:before {
	background-image: url('~/src/icons/info.svg');
}


.close {
	position: absolute;
	left: 30px;
	width: 40px;
	height: 40px;
	background-color: transparent;
	background-image: url('~/src/icons/x.svg');
	background-repeat: no-repeat;
	background-size: 20px auto;
	background-position: center center;
	border: none;
	opacity: 0.6;
	cursor: pointer;
}
.close:hover {
	opacity: 1;
}