import React, { Fragment, useState, useRef } from 'react';

import flattenTree from '~/src/util/flattenTree';
import notify from '~/src/util/notify';
import { useKeyboard } from '~/src/util/useKeyboard';
import { FeedbackButton, Button } from '~/src/lib/Buttons';
import { ProtoEditorFields } from '~/src/lib/ProtoEditor';
import { Banner } from '~/src/lib/Banner';
import { getFormData } from '~/src/lib/Form';

import * as css from './GoldSifterPicker.css';

const SKIP_ACTION = {
	action: 'skip',
};

export const GoldSifterPicker = ({ batch, dataset, onSubmit: submitBatch }) => {
	const formRef = useRef();
	const [{ currentIndex, answers }, updateState] = useState({
		currentIndex: 0,
		answers: batch.data.map(() => ({})),
	});
	const submitItem = (event, answer) => {
		if (event) {
			event.preventDefault();
		}
		const newAnswer = answer === SKIP_ACTION ? answer : getFormData(formRef.current);
		for (let [key, value] of Object.entries(newAnswer)) {
			if (value === '') {
				notify.error({
					message: `${key} cannot be left blank, please skip if no available option`,
					duration: 4000,
				});
				return;
			}
		}
		const freshAnswers = answers.map((answer, index) => index === currentIndex ? newAnswer : answer);
		const nextIndex = currentIndex + 1;
		updateState({
			currentIndex: nextIndex,
			answers: freshAnswers,
		});
		if (nextIndex >= batch.data.length) {
			submitBatch(freshAnswers);
		}
	};
	useKeyboard(({ isEnter }) => {
		if (isEnter) {
			submitItem();
		}
	}, [submitItem]);
	const isDone = currentIndex >= batch.data.length;
	if (isDone) {
		return null;
	}
	return (
		<form ref={formRef} onSubmit={submitItem}>
			<Banner
				text={dataset.meta.instruction}
				actions={(
					<Fragment>
						<Button
							onClick={(event) => submitItem(event, SKIP_ACTION)}
							variant="bare"
						>
							Skip
						</Button>
						<FeedbackButton
							type="submit"
							size="large"
							variant="neutral"
							onClick={submitItem}
						>
							Submit
						</FeedbackButton>
					</Fragment>
				)}
			/>
			<div className={css.Picker}>
				<PickerPreview
					ordinal={dataset.interface.batchSize > 1 ? `${currentIndex + 1} / ${dataset.interface.batchSize}` : null}
					item={batch.data[currentIndex]}
					itemLayout={dataset.interface.itemLayout}
				/>
				<PickerInput
					key={currentIndex}
					item={batch.data[currentIndex]}
					dataset={dataset}
				/>
			</div>
		</form>
	);
};

function createCategoryOptions(items) {
	return flattenTree(items)
		.map((item) => ({
			group: item.parents.map((parent) => parent.name).join(' / '),
			label: item.name,
			value: item.category_id,
			search: [...item.parents.map((parent) => parent.name), item.name].join(' ').toLowerCase(),
		}))
	;
}

const PickerPreview = ({ ordinal, item, itemLayout={} }) => {
	switch (itemLayout.type) {
		case 'text-link':
			return (
				<div className={css.PickerPreview}>
					{ordinal}
					<p className={css.PickerPreview__text}>{item[itemLayout.text || 'text']}</p>
					{item[itemLayout.link || 'link'] && (
						<p>
							<a
								className={css.PickerPreview__href}
								href={item[itemLayout.link || 'link']}
								target="_blank"
								rel="noopener noreferrer"
							>
								{item[itemLayout.link || 'link']}
							</a>
						</p>
					)}
				</div>
			);
		case 'crumbs':
			return (
				<div className={css.PickerPreview}>
					<div className={css.PickerPreview__title}>Breadcrumbs:</div>
					<div className={css.PickerPreview__crumbs}>
						{item.crumbs.map((crumb) => (
							<span key={crumb.text} className={css.PickerPreview__crumb}>{crumb.text}</span>
						))}
					</div>
				</div>
			);
	}
	return (
		<div className={css.PickerPreview}>
			{JSON.stringify(item)}
		</div>
	);
};

const PickerInput = ({ item, dataset }) => {
	const fields = prepFields(dataset.interface.fields);
	if (dataset.interface.inputType === 'fields') {
		return (
			<div className={css.PickerInput}>
				<ProtoEditorFields fields={fields} item={item} />
			</div>
		);
	}
	return null;
};


function prepFields(rawFields) {
	return rawFields.map((field) => {
		switch (field.dataset) {
			case 'categories':
				field.endpoint = '/v0/categories?format=tree';
				field.dataMapper = createCategoryOptions;
				return field;
			default:
				return field;
		}
	})
}