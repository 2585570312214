.Expander {
	background: var(--white);
	border-radius: 11px;
	box-shadow: var(--wide-shadow);
}
.Expander + .Expander {
	margin-top: 20px;
}
.Expander__header {
	padding: 15px;
	cursor: pointer;
	display: flex;
	align-items: end;
	--line-height: 30px;
}
.Expander__title {
	font-size: 25px;
	line-height: var(--line-height);
	display: inline-block;
}
.Expander__prompt {
	display: inline-block;
	margin-left: 10px;
	display: none;
}
.Expander__header:hover .Expander__prompt {
	text-decoration: underline;
}
.Expander__icon {
	display: inline-block;
	margin-left: auto;
	width: var(--line-height);
	height: var(--line-height);
	background-size: 18px auto;
	background-repeat: no-repeat;
	background-position: center center;
	opacity: 0.5;
	transition: opacity 150ms ease;
}
.Expander__header:hover .Expander__icon {
	opacity: 1;
}
.Expander__icon--open {
	background-image: url('~/src/icons/maximize.svg');
}
.Expander__icon--close {
	background-image: url('~/src/icons/minimize.svg');
}
.Expander__contents {
	padding: 0px 20px;
}