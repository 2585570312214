import React, { Fragment, useState } from 'react';
import getAtPath from 'lodash/get';
import isEmptyObject from 'lodash/isEmpty';

import { useResource } from '~/src/util/useResource';
import {
	Hidden,
	TextArea,
	Divider,
	URLField,
	ToggleSwitch,
	BaseField,
	TransparentSelect,
	JSONField,
} from '~/src/lib/Form';
import {
	LineEditor,
	DateEditor,
	CategoryEditor,
	ImageUploadEditor,
	VideoEmbedEditor,
} from '~/src/lib/Editors';
import { Link } from '~/src/lib/Buttons';

const noopTrue = () => true;
const DEFAULT_VALIDATORS = {
	slug: (value) => value ? /^[a-z0-9-]+$/.test(`${value}`) : true,
};

export const EditorField = ({ field, item, onChange }) => {
	const [value, setValue] = useState(getValue(item, field.name, field.defaultValue));
	const needsRemoteData = field.endpoint;
	const fetchedData = useResource(needsRemoteData ? field.endpoint : null);
	const validator = field.validator || DEFAULT_VALIDATORS[field.type] || noopTrue;
	if (!field.type) {
		throw new Error(`Field definition requires type (is ${field.type})`);
	}
	if (field.type !== 'divider' && !field.name) {
		throw new Error(`Field definition requires name (is ${field.name})`);
	}
	const label = field.label || field.name;
	if (typeof field.description === 'function') {
		throw new Error('EditorField\'s sdescription cannot be a function');
	}
	const description = field.description;
	const handleChange = (value) => {
		setValue(value);
		onChange && onChange(field.name, value)
	};
	const isValid = validator(value);
	return (
		<Fragment>
			{
				getField({
					field,
					label,
					value,
					description,
					handleChange,
					needsRemoteData,
					fetchedData,
					validator,
					isValid,
				})
			}
		</Fragment>
	);
}

function getField({ field, label, description, handleChange, value, needsRemoteData, fetchedData, isValid }) {
	switch (field.type) {
		case 'divider':
			return (<Divider key={field.name || label} label={label} />);
		case 'text':
		case 'slug':
			return (
				<BaseField
					tag="div"
					key={field.name}
					label={label}
					description={description}
					isInvalid={!isValid}
				>
					<LineEditor
						{...field}
						defaultValue={value}
						onChange={handleChange}
						hasFocusStyle={false}
					/>
				</BaseField>
			);
		case 'date':
			return (
				<BaseField
					tag="div"
					key={field.name}
					label={label}
					description={description}
					isInvalid={!isValid}
				>
					<DateEditor
						{...field}
						value={value}
						onChange={handleChange}
						hasFocusStyle={false}
					/>
				</BaseField>
			);
		case 'hidden':
			return (<Hidden key={field.name} name={field.name} value={value} />);
		case 'select':
			return (
				<BaseField
					tag="div"
					key={field.name}
					label={label}
					description={(
						<Fragment>
							{description}
							{' '}
							{needsRemoteData && field.showRefresh && (
								<Link variant="prominent" onClick={fetchedData.refresh}>Refresh</Link>
							)}
						</Fragment>
					)}
				>
					<TransparentSelect
						allowBlank
						{...field}
						defaultValue={value}
						options={getOptions(field, fetchedData)}
						onChange={handleChange}
						placeholder={needsRemoteData && fetchedData.isLoading ? 'Loading...' : field.placeholder}
					/>
				</BaseField>
			);
		case 'category':
			return (
				<BaseField
					tag="div"
					key={field.name}
					label={label}
					description={description}
				>
					<CategoryEditor
						{...field}
						key={field.name}
						defaultValue={value}
						onChange={handleChange}
					/>
				</BaseField>
			);
		case 'image':
			return (
				<BaseField
					tag="div"
					key={field.name}
					label={label}
					description={description}
					gap={10}
				>
					<ImageUploadEditor
						{...field}
						key={field.name}
						defaultValue={value}
						onChange={handleChange}
					/>
				</BaseField>
			);
		case 'textarea':
			return (
				<TextArea
					{...field}
					key={field.name}
					label={label}
					size={field.size}
					description={description}
					isSimple
					defaultValue={value}
					onChange={handleChange}
				/>
			);
		case 'json':
			return (
				<JSONField
					{...field}
					key={field.name}
					label={label}
					description={description}
					subLabel={field.subLabel || 'JSON'}
					isSimple
					defaultValue={JSON.stringify(value, null, '\t')}
					onChange={handleChange}
				/>
			);
		case 'url':
			return (
				<URLField
					{...field}
					key={field.name}
					label={label}
					description={description}
					defaultValue={value}
					onChange={handleChange}
				/>
			);
		case 'toggle':
			return (
				<ToggleSwitch
					{...field}
					key={field.name}
					label={label}
					description={description}
					defaultValue={value}
					onChange={handleChange}
				/>
			);
		case 'video':
			return (
				<BaseField
					tag="div"
					key={field.name}
					label={label}
					description={description}
					gap={10}
				>
					<VideoEmbedEditor
						{...field}
						key={field.name}
						defaultValue={value}
						onChange={handleChange}
					/>
				</BaseField>
			);
		default:
			throw new Error(`Unknown field type: ${field.type}`);
	}
}

function getValue(item, name, defaultValue) {
	const value = getAtPath(item, name);
	const isUndefined = typeof value === 'undefined';
	const isEmpty = typeof value === 'object' && isEmptyObject(value);
	if (isUndefined || isEmpty) {
		return defaultValue;
	}
	return value;
}

function getOptions(field, fetchedData) {
	if (field.options) {
		return field.options;
	}
	if (!fetchedData.data) {
		return [];
	}
	if (field.dataMapper) {
		return field.dataMapper(fetchedData.data)
	}
	return fetchedData.data;
}