import React from 'react';

import { useResource } from '~/src/util/useResource';
import { Page } from '~/src/lib/Page';
import { AutoLoader } from '~/src/lib/AutoLoader';

import { GoldSifterRows } from './GoldSifterRows';
import { GoldSifterPicker } from './GoldSifterPicker';
import { GoldSifterEvaluator } from './GoldSifterEvaluator';
import { submitBatch } from './GoldSifterCommon';

export const GoldSifterBatchPage = ({ match }) => {
	const {datasetId, batchId} = match.params;
	const result = useResource(`/v0/goldsifter/datasets/${datasetId}/batches/${batchId}`);
	const { dataset, batch } = result.data ? result.data : {};
	return (
		<Page
			backLink="/goldsifter"
			title={dataset ? `Gold sifter: ${dataset.meta.title}` : 'Gold sifter'}
			contentWidth="large"
			headerProps={{ isShort: true }}
		>
			<AutoLoader error={result.error} isLoading={result.isLoading} backLink="/goldsifter">
				{dataset && batch && <GoldSifterAuto dataset={dataset} batch={batch} onSubmit={(answers) => submitBatch(datasetId, batchId, answers)} />}
			</AutoLoader>
		</Page>
	);
};

const GoldSifterAuto = ({ dataset, ...props }) => {
	switch (dataset.interface.layout) {
		case 'rows':
			return (
				<GoldSifterRows dataset={dataset} {...props} />
			);
		case 'picker':
			return (
				<GoldSifterPicker dataset={dataset} {...props} />
			);
		case 'evaluator':
			return (
				<GoldSifterEvaluator dataset={dataset} {...props} />
			);
		default:
			return (
				<div>Unknown layout “{dataset.interface.layout}”</div>
			);
	}
};
