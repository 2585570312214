import React, { useState, useMemo } from 'react';
import { format as formatDate, addMonths } from 'date-fns';

import { TablePage } from '~/src/lib/Table';
import { useResource } from '~/src/util/useResource';

import * as css from './CPCLinkPerformance.css';

const HEADERS = [{
	label: 'Domain',
	key: 'domain',
	isWide: true
}, {
	label: 'Clicks',
	key: 'clicks',
}];

export const CPCLinkPerformance = () => {
	const [month, setMonth] = useState(getCurrentMonth());
	const { data, isLoading, error } = useResource(`/v0/cpc/performance-by-brand?month=${month}`);
	const options = useMemo(() => Array.from(getAllMonthOptions()), []);
	return (
		<TablePage
			keyProp="domain"
			headers={HEADERS}
			items={data}
			title="CPC Links performance"
			isLoading={isLoading}
			error={error}
			actions={(
				<select
					defaultValue={month}
					className={css.MonthSelector}
					onChange={(event) => setMonth(event.target.value)}
					placeholder="Month"
				>
					{options.map((option) => (
						<option key={option} value={option}>{option}</option>
					))}
				</select>
			)}
		/>
	)
};

function formatMonth(date) {
	return formatDate(date, 'LLLL ’yy');
}

function getCurrentMonth() {
	return formatMonth(new Date());
}

const START_DATE = new Date('2022-09-01T12:00:00.000Z');
function* getAllMonthOptions() {
	const today = new Date();
	let currentDate = new Date(START_DATE);
	while (currentDate < today) {
		yield formatMonth(currentDate);
		currentDate = addMonths(currentDate, 1);
	}
}