import React from 'react';

import { getTagIcon } from '~/src/util/getTagIcon';

import * as css from './TagIcon.css';

export const TagIcon = ({ id, name }) => {
	return (
		<div className={css.Tag}>
			<img className={css.Tag__img} src={getTagIcon(id)} alt={name} />
			<span className={css.Tag__label}>{name}</span>
		</div>
	);
};
