import React, { useState } from 'react';
import classnames from 'classnames';

import { pluralize, formatTimeAgoStrict, removeOrigin } from '~/src/util/format';
import { TimeAgo } from '~/src/lib/Text';
import { Link, Button } from '~/src/lib/Buttons';

import * as css from './ScanItems.css';

export const SiteItem = ({ site }) => {
	return (
		<div className={css.ScanItem}>
			<h3 className={css.ScanItem__title}>
				<Link	 to={`/scanner/sites/${site.id}`}>{site.domain}</Link>
			</h3>
			<p className={css.ScanItem__meta}>
				<TimeAgo className={css.ScanItem__time}>{site.created_at}</TimeAgo>
				<span className={css.ScanItem__id}>{site.id}</span>
			</p>
			<div className={css.ScanItem__stats}>
				<Link to={`/scanner/sites/${site.id}/scans`}>{site.scans_count} {pluralize(site.scans_count, 'scan', 'scans')}</Link>
				<Link to={`/scanner/sites/${site.id}/scans`}>{site.active_scans_count} {pluralize(site.active_scans_count, 'active', 'active')}</Link>
			</div>
			<div className={css.ScanItem__actions}>
				<Button to={`/scanner/sites/${site.id}`}>View</Button>
			</div>
		</div>
	);
};

export const SiteScanItem = ({ scan }) => {
	const isDone = scan.state === 'DONE';
	const hasErrors = scan.error_count > 0;
	return (
		<div className={css.ScanItem}>
			<h3 className={css.ScanItem__title}>
				<Link to={`/scanner/scans/${scan.id}`}>{getScanTitle(scan)}</Link>
			</h3>
			<p className={css.ScanItem__meta}>
				<TimeAgo className={css.ScanItem__time}>{scan.created_at}</TimeAgo>
				<span className={css.ScanItem__id}>{scan.id}</span>
			</p>
			<div className={css.ScanItem__stats}>
				<Link to={`/scanner/scans/${scan.id}/pages`}>{scan.page_count} {pluralize(scan.page_count, 'page', 'pages')}</Link>
				<Link to={`/scanner/scans/${scan.id}/pages`}>{isDone ? `${scan.import_count} imports` : `${scan.done_count || 0} done`}</Link>
				<Link to={`/scanner/scans/${scan.id}/errors`} className={classnames(hasErrors && css.red)}>{scan.error_count} {pluralize(scan.error_count, 'error', 'errors')}</Link>
			</div>
			<div className={css.ScanItem__actions}>
				<Button to={`/scanner/scans/${scan.id}`}>View</Button>
			</div>
		</div>
	);
};

function getScanTitle(scan) {
	if (scan.state === 'NEW') {
		return 'Started';
	}
	if (scan.state === 'DONE') {
		const completionTime = scan.statechange_at ? formatTimeAgoStrict(scan.statechange_at, scan.created_at) : 'unknown time';
		return `Completed in ${completionTime}`;
	}
	if (scan.state === 'ERROR') {
		return 'Cancelled due to error';
	}
	const duration = scan.statechange_at ? formatTimeAgoStrict(scan.statechange_at) : 'unknown time';
	return `In progress for ${duration}`;
}

export const PageScanItem = ({ page }) => {
	const hasErrors = page.error_count > 0;
	return (
		<div className={css.ScanItem}>
			<h3 className={css.ScanItem__title}>
				<Link to={`/scanner/scans/${page.site_scan_id}/pages/${page.id}`}>{removeOrigin(page.url)}</Link>
			</h3>
			<p className={css.ScanItem__meta}>
				<TimeAgo className={css.ScanItem__time}>{page.created_at}</TimeAgo>
				<span className={css.ScanItem__id}>{page.id}</span>
			</p>
			<div className={css.ScanItem__stats}>
				<Link to={`/scanner/scans/${page.site_scan_id}/pages/${page.id}/results`}>{page.result_count} {pluralize(page.result_count, 'result', 'results')}</Link>
				<Link to={`/scanner/scans/${page.site_scan_id}/pages/${page.id}/errors`} className={classnames(hasErrors ? css.red : css.fade)}>{page.error_count} {pluralize(page.error_count, 'error', 'errors')}</Link>
			</div>
			<div className={css.ScanItem__actions}>
				<Button to={`/scanner/scans/${page.site_scan_id}/pages/${page.id}`}>View</Button>
			</div>
		</div>
	);
};

export const ScanResultItem = ({ result }) => {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<div className={css.ScanResultItem} onClick={() => setIsOpen(!isOpen)}>
			<h3 className={css.ScanResultItem__key}>{result.key} <span className={css.ScanResultItem__index}>{outputIndex(result)}</span></h3>
			<p className={css.ScanResultItem__value}>{outputValue(result.value)}</p>
			{isOpen && result.source ? (
				<div className={css.ScanResultItem__meta} onClick={(event) => event.stopPropagation()}>
					{Object.entries(result.source).map(([key, value]) => (
						<div key={key} className={css.ScanResultItem__metaItem}>
							<strong>{key}</strong>{' '}{value}
						</div>
					))}
				</div>
			) : null}
		</div>
	);
};

function outputValue(value) {
	if (!value) {
		return `Invalid value: ${value}`;
	}
	if (typeof value === 'string') {
		return value;
	}
	if ('amount' in value && 'currency' in value) {
		return `${value.amount} ${value.currency}`;
	}
	if (value instanceof Array) {
		return value.map((item) => item.text).join(' / ');
	}
	return JSON.stringify(value);
}

function outputIndex(result) {
	if ('source' in result && 'index' in result.source) {
		return `(${result.source.index})`;
	}
}

export const ScanErrorItem = ({ error, scanId }) => {
	const url = error.url || (error.error ? error.error.url : null);
	const link = error.source_type === 'page-scan' ? `/scanner/scans/${scanId}/pages/${error.source_id}` : `/scanner/scans/${scanId}`;
	return (
		<div className={classnames(css.ScanErrorItem, url && css['ScanErrorItem--with-context'])}>
			{url && <p className={css.ScanErrorItem__context}>{url}</p>}
			<Link to={link} className={css.ScanErrorItem__link}>View {error.source_type}</Link>
			<h3 className={css.ScanErrorItem__message}>{error.error.name}: {error.error.message}</h3>
			<div className={css.ScanErrorItem__stack}>
				<pre>{error.error.stack}</pre>
			</div>
		</div>
	);
};
