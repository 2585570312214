.QueryInspector {}

.Inspect {
	display: grid;
	column-gap: var(--grid-column-gap);
	row-gap: var(--section-spacing);
	grid-template-rows: auto auto;
	margin-bottom: 50px;
}
.Inspect {
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto auto auto;
	grid-template-areas:
		"sca res"
		"ana cat"
	;
}

@media (min-width: 1500px) {
	.Inspect {
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-areas:
			"sca ana res"
			"cat ana res"
		;
		grid-template-rows: auto auto;
	}
}

.Inspect__section[data-section="Scan"] {
	grid-area: sca;
}
.Inspect__section[data-section="Analysis"] {
	grid-area: ana;
}
.Inspect__section[data-section="Results"] {
	grid-area: res;
}
.Inspect__section[data-section="Categories"] {
	grid-area: cat;
}

.Inspect__image {
	height: 200px;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	border-radius: 5px;
	position: relative;
	background: var(--white);
	box-shadow: var(--tight-shadow);
	padding: 20px;
	margin-bottom: 20px;

}
.Inspect__image img {
	max-width: 100%;
	max-height: 100%;
}

.Results__source {
	color: var(--gray4);
	font-weight: var(--semibold);
	margin-bottom: 20px;
	font-size: 16px;
}
.Results__source em {
	color: var(--black);
	font-style: normal;
	font-weight: var(--bold);
}

.timings {
}