.CategoryEditor {
	--spacer-gap: 10px;
	font-size: var(--editor-medium-text);
	display: flex;
	flex-wrap: wrap;
	column-gap: 20px;
	row-gap: 10px;
}
.CategoryEditor:focus {
	background: var(--off-white);
	outline: none;
	border-radius: 5px;
	padding: 10px 5px;
	margin: -10px -5px;
}
.CategoryEditor__refreshButton {
}
.CategoryEditor__input {
	display: none;
}
.CategoryBrowser {
	display: flex;
	flex-wrap: wrap;
	column-gap: 40px;
	row-gap: 10px;
}
.CategoryBrowser__path {
	display: flex;
	flex-wrap: wrap;
	column-gap: var(--spacer-gap);
}
.CategoryBrowser__stem {
	transition: opacity 150ms ease;
}
.CategoryBrowser__stem--fade {
	opacity: 0.7;
}
.CategoryBrowser__stem--fade:hover {
	opacity: 1;
}
.CategoryBrowser__stem:after {
	content: '';
	display: inline-block;
	margin-left: var(--spacer-gap);
	width: 16px;
	height: 16px;
	background-image: url('~/src/icons/chevron-right.svg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 10px auto;
	opacity: 0.8;
}
.CategoryBrowser__leaf {
}
.CategoryBrowser__searchButton {
}

.CategorySearcher {
	position: relative;
	border-radius: 5px;
	min-width: 450px;
}
.CategorySearcher:focus-within {
	background: var(--off-white);
	padding: 8px 10px;
	margin: -8px -10px;
}
.CategorySearcher__inputs {
	display: flex;
}
.CategorySearcher__cancel {
	width: auto;
}
.CategorySearcher__input {
	flex: 1;
}
.ResultsList {
	position: absolute;
	top: 100%;
	width: 100%;
	left: 0;
	background: white;
	z-index: var(--index-dropdown);
	border: 1px solid var(--gray1);
	border-top: 0;
	list-style: none;
	padding: 5px 0;
	line-height: 1.2;
	animation: results-intro 150ms ease;
	box-shadow: var(--wide-shadow);
}
.ResultsList__result {
	cursor: pointer;
	padding: 10px 10px;
	min-height: 48px;
	display: flex;
	flex-direction: column;
	grid-gap: 5px;
	justify-content: flex-end;
	border-bottom: 1px solid var(--gray1);
}
.ResultsList__result--focus {
	background: var(--blue-light);
}
.ResultsList__result:hover {
	background: var(--blue-light);
}
.ResultsList__resultPath {
	font-size: 14px;
	display: block;
	color: var(--gray4);
}
.ResultsList__resultName {
	font-size: 20px;
}
@keyframes results-intro {
	0% {
		opacity: 0;
	}
}