import React, { useMemo } from 'react';

import request from '~/src/util/request';
import { useResource } from '~/src/util/useResource';
import { SelectInput } from '~/src/lib/Form';

import * as css from './BrandEditor.css';

const noop = () => {};

// defaultValue should be of the same format as the brand_id
export const BrandEditor = ({ defaultValue, name, onChange=noop }) => {
	const brandsResource = useResource('/v0/brands');
	const brands = useMemo(() => {
		if (brandsResource.data) {
			return createBrandOptions(brandsResource.data)
		}
		return [];
	}, [brandsResource.data]);
	const handleChange = (value) => {
		onChange(value);
	};
	if (!brands.length) {
		return null;
	}
	return (
		<div className={css.BrandEditor}>
			<SelectInput
				name={name}
				defaultValue={defaultValue}
				options={brands}
				onChange={handleChange}
				allowInsert={{
					onInsert: createBrand,
					label: 'Create new brand'
				}}
			/>
		</div>
	);
};

function createBrandOptions(items) {
	return items
		.map((item) => ({
			label: item.name,
			value: item.brand_id,
			search: item.name.toLowerCase(),
		}))
	;
}

async function createBrand(brandName) {
	const result = await request('POST', '/v0/brands', { name: brandName });
	if (result.status === 'success') {
		return {
			label: result.data.name,
			value: result.data.brand_id,
		};
	}
	return null;
}