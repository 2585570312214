import React, { Fragment } from 'react';

import pick from '~/src/util/pick'
import { TablePage } from '~/src/lib/Table';
import Resource from '~/src/lib/Resource';
import { Button } from '~/src/lib/Buttons';
import { Field, Hidden, MultiSelect } from '~/src/lib/Form';
import Editor from '~/src/lib/Editor';

const CERT_HEADERS = [{
	label: 'Certification',
	key: 'name',
	isWide: true
}, {
	key: 'edit',
	renderItem: (item) => (
		<Button to={`/certifications/${item.certification_id}`}>Edit</Button>
	)
}];

const Actions = () => (
	<Button to="/certifications/new">Create new</Button>
);

export const Certifications = () => (
	<Resource endpoint="/v0/certifications">
		{({ data }) => (
			<TablePage
				keyProp="certification_id"
				headers={CERT_HEADERS}
				items={data}
				title="Certifications"
				actions={<Actions />}
			/>
		)}
	</Resource>
);

export const EditCertification = ({ match }) => (
	<Editor
		title="Edit certification"
		submitEndpoint={`/v0/certifications/${match.params.id}`}
		method="PUT"
		onSuccess="/certifications"
		loadEndpoint={`/v0/certifications/${match.params.id}`}
	>
		{(item) => (
			<Fragment>
				<Hidden name="certification_id" value={item.certification_id} />
				<SharedFields item={item} />
			</Fragment>
		)}
	</Editor>
);

export const CreateCertification = () => (
	<Editor
		title="Create certification"
		submitEndpoint="/v0/certifications"
		method="POST"
		onSuccess={redirectToItem}
		newItem
	>
		{(item) => <SharedFields item={item} />}
	</Editor>
);

const SharedFields = ({ item }) => (
	<Fragment>
		<Field label="Name" name="name" defaultValue={item.name} />
		<Resource endpoint="/v0/tags" dataMapper={createOptions}>
			{({ data }) => (
				<MultiSelect
					label="Tags"
					name="tags"
					selections={pick(item.tags, 'id')}
					options={data}
				/>
			)}
		</Resource>
	</Fragment>
);

function redirectToItem(item) {
	return `/certifications/${item.certification_id}`;
}

function createOptions(items) {
	return items
		.map((item) => ({
			label: item.name,
			value: item.tag_id,
		}))
	;
}