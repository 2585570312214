import React, { Component } from 'react';

import { useResource, useResourceRefresher } from '~/src/util/useResource';
import request from '~/src/util/request';
import { AutoLoader } from '~/src/lib/AutoLoader';

// If the itemEndpoint is used, pass on to the legacy resource component
const ResourceRouter = (props) => {
	if (props.itemEndpoint) {
		return (<LegacyResource {...props} />);
	}
	return (<Resource {...props} />);
};

export default ResourceRouter;

export const Resource = ({ endpoint, dataMapper, children }) => {
	const resource = useResource(endpoint);
	const refresh = useResourceRefresher(endpoint);
	return (
		<AutoLoader error={resource.error} isLoading={resource.isLoading}>
			{resource.data ? children({
				data: mapData(resource.data, dataMapper),
				meta: resource.data.meta,
				refresh,
				removeItem: () => { throw new Error('removeItem is deprecated, please use LegacyResource'); }
			}) : null}
		</AutoLoader>
	);
};

function mapData(data, dataMapper) {
	if (!dataMapper) {
		return data;
	}
	return dataMapper(data);
}

export class LegacyResource extends Component {
	constructor(props) {
		console.warn('LegacyResource in use');
		super(props);
		this.state = {
			total: null,
			data: null,
			isLoading: true,
			hasError: false,
		};
		this.removeItem = this.removeItem.bind(this);
		this.refresh = this.refresh.bind(this);
	}
	componentDidMount() {
		this.load();
	}
	async load() {
		const { endpoint } = this.props;
		const json = await request('GET', endpoint);
		if (!json || json.status === 'error') {
			this.setState({
				hasError: true,
				isLoading: false,
			});
			return;
		}
		if (this.props.log) {
			console.info(endpoint, json.data);
		}
		this.setState({
			data: this.mapData(json.data),
			meta: json.meta,
			total: json.total,
			isLoading: false,
		});
	}
	async refresh() {
		this.setState({ isLoading: true });
		this.load();
	}
	async removeItem(item) {
		const { itemEndpoint } = this.props;
		const endpoint = typeof itemEndpoint === 'function' ? itemEndpoint(item) : itemEndpoint;
		this.setState({ isLoading: true });
		const result = await request('DELETE', endpoint);
		if (result.status === 'success') {
			await this.load();
		} else {
			this.setState({ isLoading: false });
		}
	}
	mapData(data) {
		if (this.props.dataMapper) {
			return this.props.dataMapper(data);
		}
		return data;
	}
	renderLoader() {
		// TODO: Nicer loader
		if (this.state.data) {
			return (
				<div style={{ opacity: 0.3, pointerEvents: 'none' }}>
					{this.renderChildren()}
				</div>
			);
		}
		return (<p>Loading</p>);
	}
	renderError() {
		// TODO: Nicer error
		return (<p>Error</p>);
	}
	renderChildren() {
		const { children } = this.props;
		const { data, meta, total } = this.state;
		return children({ data, meta, total, removeItem: this.removeItem, refresh: this.refresh });
	}
	render() {
		const { isLoading, hasError } = this.state;
		if (isLoading) {
			return this.renderLoader();
		}
		if (hasError) {
			return this.renderError();
		}
		return this.renderChildren();
	}
}
