import React from 'react';

import { Page } from '~/src/lib/Page';
import { Table } from '~/src/lib/Table';
import { useResource } from '~/src/util/useResource';
import { Button } from '~/src/lib/Buttons';

const AVAILABLE_DATES_HEADERS = [{
	label: 'Date',
	key: 'date',
	isWide: true
}, {
	key: 'view',
	renderItem: (item, aux) => (
		<Button to={`/metrics/runtime/${aux.route}/usage-by-date/${item.date}`}>View</Button>
	)
}];

const USAGE_HEADERS = [{
	label: 'Key',
	key: 'name',
	isWide: true
}, {
	label: 'Count',
	key: 'count',
}];

function getProps(route) {
	switch (route) {
		case 'endpoints':
			return {
				label: 'Endpoint',
				endpoint: '/v0/metrics/endpoints/usage/dates',
				route,
			};
		case 'commands':
			return {
				label: 'Command',
				endpoint: '/v0/metrics/commands/usage/dates',
				route,
			};
		case 'data-providers':
			return {
				label: 'Data provider',
				endpoint: '/v0/metrics/data-providers/usage/dates',
				route,
			};
		default:
			throw new Error(`Invalid route "${route}"`);
	}
}

export const RuntimeUsage = ({ match }) => {
	const props = getProps(match.params.route);
	if (match.params.date) {
		return (
			<RuntimeUsageByDate
				date={match.params.date}
				{...props}
			/>
		);
	}
	return (
		<AvailableDates
			{...props}
		/>
	);
};

const AvailableDates = ({ endpoint, label, route }) => {
	const result = useResource(endpoint);
	return (
		<Page contentWidth="wide" title={`${label} usage, dates`} error={result.error} isLoading={result.isLoading}>
			{result.data && (
				<Table
					keyProp="date"
					headers={AVAILABLE_DATES_HEADERS}
					items={result.data}
					headerItemAux={{ route }}
				/>
			)}
		</Page>
	)
};

const RuntimeUsageByDate = ({ date, endpoint, label }) => {
	const result = useResource(`${endpoint}/${date}`);
	return (
		<Page contentWidth="wide" title={`${label} usage ${date}`} error={result.error} isLoading={result.isLoading}>
			{result.data && (
				<Table
					keyProp="name"
					headers={USAGE_HEADERS}
					initialSortBy="count"
					initialSortOrder="desc"
					items={result.data}
				/>
			)}
		</Page>
	)
};