import React, { Fragment, useState } from 'react';

import request from '~/src/util/request';
import { pluralize, formatTimeAgoStrict } from '~/src/util/format';
import { useResource } from '~/src/util/useResource';
import { Spread } from '~/src/lib/Layout';
import { Page } from '~/src/lib/Page';
import { SmallText, LabelledText } from '~/src/lib/Text';
import { Button } from '~/src/lib/Buttons';
import { ListItemTag, ListItemMeta, ListItemStat } from '~/src/lib/ListItem';
import { SortableList, SortableListItem } from '~/src/lib/SortableList';

export const Collections = () => {
	const endpoint = '/v0/collections';
	const [saveState, setSaveState] = useState('Saved');
	const { isLoading, error, data, mutate } = useResource(endpoint);
	const collections = data || [];
	const handleOrderChange = () => setSaveState('Saving...');
	const handleSaveOrder = (newItems) => {
		setSaveState('Saving...')
		mutate(newItems, { revalidate: false });
		request('PUT', endpoint, {
			action: 'reorder',
			items: newItems.map((item) => ({ id: item.id, order: item.order })),
		})
			.then((response) => setSaveState(response.ok ? 'Saved' : 'Server error'))
			// .then(() => mutate())
		;
	};
	return (
		<Page
			title="Collections"
			contentWidth="medium"
			isLoading={isLoading}
			error={error}
			actions={
				<Fragment>
					<Button to="/content/collections/new">Create collection</Button>
					<Spread />
					<SmallText>{saveState}</SmallText>
				</Fragment>
			}
		>
			{collections && collections.length ? (
				<SortableList items={collections} onSave={handleSaveOrder} onChange={handleOrderChange}>
					{(items) => (
						items.map((collection) => (
							<SortableListItem
								key={collection.id}
								item={collection}
								title={collection.title || 'Unnamed collection'}
								href={`/content/collections/${collection.id}`}
								tags={getTags(collection)}
								metas={getMetas(collection)}
								stats={getStats(collection)}
							/>
						))
					)}
				</SortableList>
			) : (
				'No collections'
			)}
		</Page>
	);
};

function getTags(collection) {
	if (collection.archived_at) {
		return <ListItemTag text="Archived" color="yellow" />;
	}
	if (!collection.is_published) {
		return <ListItemTag text="Draft" color="gray" />;
	}
	return null;
}
function getMetas(collection) {
	return (
		<Fragment>
			<ListItemMeta key="date" text={getDateText(collection)} />
			<ListItemMeta key="slug" text={collection.slug || 'Missing slug'} color={collection.slug ? 'black' : 'red'} />
		</Fragment>
	);
}
function getDateText(collection) {
	if (collection.archived_at) {
		return `Archived ${formatTimeAgoStrict(collection.archived_at)} ago`;
	}
	if (collection.published_at) {
		return `Published ${formatTimeAgoStrict(collection.published_at)} ago`;
	}
	return `Created ${formatTimeAgoStrict(collection.created_at)} ago`;
}

function getStats(collection) {
	return (
		<Fragment>
			<LabelledText tight label="Products">{collection.product_count || '0'}</LabelledText>
			<LabelledText tight label="Categories">{collection.category_count || '0'}</LabelledText>
		</Fragment>
	);
}
