import React, { useState, useEffect } from 'react';
import { Reorder, useDragControls } from 'framer-motion';
import { useDebounceCallback } from '@react-hook/debounce';
import classnames from 'classnames';

import { createOrderSequence } from '~/src/util/order';
import { SlimIconButton, ButtonBar } from '~/src/lib/Buttons';
import { ListItem } from '~/src/lib/ListItem';

import css from './SortableList.css';

export const SortableList = ({ children, items: inputItems, onSave, onChange, className }) => {
	// HERE: TODO: Paste parts from CollectionEditor to make this a reusable component
	const [items, handleReorder] = useSorting(inputItems, onSave, onChange);
	if (typeof children !== 'function') {
		throw new Error('SortableList\'s children must be a function');
	}
	return (
		<Reorder.Group axis="y" values={items} onReorder={handleReorder} className={classnames(css.SortableList, className)}>
			{children(items)}
		</Reorder.Group>
	);
};

export const SortableListItem = ({ actions, item, ...props }) => {
	const dragControls = useDragControls();
	if (!item) {
		throw new Error('SortableListItem requires item property');
	}
	return (
		<Reorder.Item
			value={item}
			dragListener={false}
			dragControls={dragControls}
			style={{ listStyleType: 'none' }}
			className={css.SortableListItem}
		>
			<ListItem
				item={item}
				{...props}
				actions={(
					<ButtonBar>
						{actions}
						<SlimIconButton icon="move" onPointerDown={(event) => dragControls.start(event)}></SlimIconButton>
					</ButtonBar>
				)}
			/>
		</Reorder.Item>
	);
};

function useSorting(inputItems, onSave, onChange) {
	const [items, setItems] = useState(inputItems || []);
	const triggerReorderSave = useDebounceCallback((newItems) => {
		const rank = createOrderSequence();
		// Updated order to push remotely
		newItems.forEach((item) => {
			item.order = rank.next().value;
		});
		// Stored the updated order locally
		setItems(newItems);
		// Trigger remote save
		onSave(newItems);
	}, 2500);
	useEffect(() => {
		if (inputItems) {
			setItems(inputItems);
		}
	}, [inputItems]);
	const handleReorder = (items) => {
		setItems(items);
		onChange && onChange(items);
		triggerReorderSave(items);
	};
	return [items, handleReorder];
}