import React, { Fragment, useState, useMemo, useEffect } from 'react';
import classnames from 'classnames';
import isNil from 'lodash/isNil';

import flattenTree from '~/src/util/flattenTree';
import { useResource } from '~/src/util/useResource';
import { SlimIconButton } from '~/src/lib/Buttons';
import { TransparentSelect, TransparentInput } from '~/src/lib/Form';

import * as css from './CategoryEditor.css';

const LEAF = Symbol();

// defaultValue should be of the same format as the category_id
export const CategoryEditor = ({ defaultValue, name, onChange }) => {
	const [value, setValue] = useState(isNil(defaultValue) ? '' : defaultValue);
	const [isSearching, setIsSearching] = useState(false);
	const categoriesResource = useResource('/v0/categories?format=tree');
	const categoryList = useMemo(() => {
		// Produce a list of products with .search and .path provided
		if (categoriesResource.data) {
			const flat = flattenTree(categoriesResource.data);
			flat.forEach((item) => {
				item.path = item.parents.map((parent) => parent.name).join(' / ');
				item.search = createSearchString(item);
			});
			return flat;
		}
		return [];
	}, [categoriesResource.data]);
	const handlePickItem = (item) => {
		if (!item) {
			setValue('');
		}
		setValue(item.category_id);
		setIsSearching(false);
		onChange(item.category_id);
	};
	return (
		<div className={css.CategoryEditor}>
			{name && (
				<input
					className={css.CategoryEditor__input}
					readOnly
					value={value}
					name={name}
				/>
			)}
			{isSearching ? (
				<CategorySearcher
					categoryList={categoryList}
					onCancelSearch={() => setIsSearching(false)}
					onPickItem={handlePickItem}
				/>
			) : (
				<CategoryBrowser
					name={name}
					value={value}
					setValue={setValue}
					categoryList={categoryList}
					onChange={onChange}
					onStartSearch={() => setIsSearching(true)}
				/>
			)}
			<SlimIconButton icon="refresh" onClick={() => categoriesResource.refresh()} className={css.CategoryEditor__refreshButton}></SlimIconButton>
		</div>
	);
};

const CategorySearcher = ({ onCancelSearch, onPickItem, categoryList }) => {
	const [focusIndex, setFocusIndex] = useState(-1);
	const [query, setQuery] = useState('');
	const matchedItems = useMemo(() => {
		if (query.length < 3) {
			return [];
		}
		const processedQuery = query.toLowerCase();
		return categoryList.filter((item) => doesItemMatchQuery(item, processedQuery)).slice(0, 30);
	}, [query, categoryList]);
	const handleSubmit = (event) => {
		event.preventDefault();
		event.stopPropagation();
		if (matchedItems[focusIndex]) {
			onPickItem(matchedItems[focusIndex]);
		}
	};
	const handleItemClick = (event) => {
		const indexOfClick = Array.from(event.currentTarget.parentElement.children).indexOf(event.currentTarget);
		onPickItem(matchedItems[indexOfClick]);
	};
	return (
		<div className={css.CategorySearcher}>
			<div className={css.CategorySearcher__inputs}>
				<TransparentInput
					className={css.CategorySearcher__input}
					autoFocus
					hasFocusStyle={false}
					type="text"
					placeholder="Type to search..."
					onChange={(value) => setQuery(value)}
					onCancel={onCancelSearch}
					// onBlur={handleBlur}
					onSubmit={handleSubmit}
					onUp={() => setFocusIndex(Math.max(0, focusIndex - 1))}
					onDown={() => setFocusIndex(Math.min(focusIndex + 1, matchedItems.length - 1))}
					defaultValue={query}
				/>
				<SlimIconButton icon="cancel" onClick={onCancelSearch} className={css.CategorySearcher__cancel}>Cancel</SlimIconButton>
			</div>
			{matchedItems.length ? (
				<ResultsList results={matchedItems} focusIndex={focusIndex} onItemClick={handleItemClick} />
			) : null}
		</div>
	);
};

const ResultsList = ({ results, focusIndex, onItemClick }) => {
	return (
		<ul className={css.ResultsList}>
			{results.map((item, index) => (
				<li
					key={item.category_id}
					className={classnames(css.ResultsList__result, focusIndex === index && css['ResultsList__result--focus'])}
					onClick={onItemClick}
				>
					<span className={css.ResultsList__resultPath}>{item.path || '/'}</span>
					<span className={css.ResultsList__resultName}>{item.name}</span>
				</li>
			))}
		</ul>
	);
};


const CategoryBrowser = ({ value, setValue, categoryList, onChange, onStartSearch }) => {
	const catLookup = createCategoryLookup(categoryList);
	const currentCat = catLookup[value];
	const categoryPath = getCategoryPath(currentCat, catLookup);
	const leafNodes = getCategoriesWithParentId(currentCat ? currentCat.category_id : null, categoryList);
	const handleChange = (input, index) => {
		const value = getValue(input, index, categoryPath);
		setValue(value);
		onChange(value);
	};
	return (
		<div className={css.CategoryBrowser}>
			<div className={css.CategoryBrowser__path}>
				{categoryPath.map((category, index) => {
					const isHead = index === categoryPath.length - 1;
					return (
						<TransparentSelect
							className={isHead && !leafNodes.length ? css.CategoryBrowser__leaf : css.CategoryBrowser__stem}
							passiveClassName={isHead ? '' : css['CategoryBrowser__stem--fade']}
							key={category.category_id}
							defaultValue={category.category_id}
							onChange={(option) => handleChange(option, index)}
							options={categoriesToOptions(getCategoriesWithParentId(category.parent_id, categoryList))}
							allowBlank
							addInputElement={false}
						/>
					);
				})}
				{leafNodes && leafNodes.length ? (
					<TransparentSelect
						key={`parent-${currentCat && currentCat.category_id}`}
						className={css.CategoryBrowser__leaf}
						defaultValue={null}
						onChange={(option) => handleChange(option, LEAF)}
						options={categoriesToOptions(leafNodes)}
						placeholder="Select..."
						allowBlank
					/>
				) : null}
			</div>
			<SlimIconButton icon="search" onClick={onStartSearch} className={css.CategoryBrowser__searchButton}>Search</SlimIconButton>
		</div>
	);
};

function createCategoryLookup(categories) {
	if (!categories) {
		return {};
	}
	return categories.reduce((out, cat) => {
		out[cat.category_id] = cat;
		return out;
	}, {});
}

// Return a list with the headCat as the last value,
// and all previous elements refering to the path leading there
function getCategoryPath(headCat, catLookup) {
	const path = [];
	let cat = headCat;
	while (cat) {
		path.unshift(cat);
		if (!cat.parent_id) {
			return path;
		}
		cat = catLookup[cat.parent_id];
	}
	return path;
}

function getCategoriesWithParentId(parent_id, categoryList=[]) {
	return categoryList.filter((category) => parent_id === category.parent_id);
}

function categoriesToOptions(categories) {
	return categories.map(categoryToOption);
}

function categoryToOption(category) {
	return {
		label: category.name,
		value: category.category_id,
	};
}

function getValue(value, index, categoryPath) {
	if (value) {
		return value;
	} else {
		// If there's no option, the "Blank" item has been selected
		if (index === LEAF) {
			// Leave currently selected category as the selected one
			const lastItem = categoryPath[categoryPath.length - 1];
			if (lastItem) {
				return lastItem.category_id;
			}
			return '';
		} else {
			// Select the parent of the level we "blanked" at
			const parentIndex = index - 1;
			const parentCategory = categoryPath[parentIndex];
			if (parentCategory) {
				return categoryToOption(parentCategory).value;
			} else {
				// If there's no parent category we've cleared the top level item
				return '';
			}
		}
	}
}

function createSearchString(item) {
	return [...item.parents.map((parent) => parent.name), item.name].join(' ').toLowerCase();
}

function doesItemMatchQuery(item, query) {
	if (!query) {
		return true;
	}
	return item.search.indexOf(query) !== -1;
}
