import React, { Fragment } from 'react';

import request from '~/src/util/request';
import { SectionTitle, Label } from '~/src/lib/Text';
import { Button, ButtonBar } from '~/src/lib/Buttons';
import { TitleBar } from '~/src/lib/Layout';
import { AutoLoader } from '~/src/lib/AutoLoader';
import { useResource, useResourceRefresher } from '~/src/util/useResource';
import { Page } from '~/src/lib/Page';
import { JSONPreview } from '~/src/lib/JSONPreview';
import { pluralize, formatDuration, formatTimeAgoStrict } from '~/src/util/format';
import { InfiniteOverflowScroller } from '~/src/lib/InfiniteOverflowScroller';

import { PageScanItem, ScanErrorItem } from './ScanItems';

export const ScannerScan = ({ match }) => {
	const scanId = match.params.id;
	const endpoint = `/v0/scanner/scans/${scanId}`;
	const { error, isLoading, data } = useResource(endpoint);
	const refresh = useResourceRefresher(endpoint);
	const scan = data && data.scan;
	const isDone = scan && scan.state === 'DONE';
	const seedGoldsifter = async () => {
		await request('POST', `/v0/scanner/scans/${scanId}/seed-goldsifter`);
		return 'Queued';
	};
	return (
		<Page
			backLink={scan ? `/scanner/sites/${scan.scanner_site_id}` : true}
			title="Site scan"
			actions={(
				<ButtonBar>
					<Button variant="secondary" onClick={refresh}>Refresh</Button>
					{isDone ? (
						<Fragment>
							<Button variant="primary" to={`/product-imports/new?site_scan_id=${scanId}`}>Import products</Button>
							<Button variant="primary" onClick={seedGoldsifter}>Seed gold sifter</Button>
						</Fragment>
					) : null}
				</ButtonBar>
			)}
		>
			<AutoLoader error={error} isLoading={isLoading}>
				{scan ? (
					<Fragment>
						{isDone ? (
							<p>Completed {formatTimeAgoStrict(scan.created_at)} ago</p>
						) : (
							<Fragment>
								<p>In progress since {formatTimeAgoStrict(scan.created_at)} ago.</p>
								<p>Estimated time until done: {formatDuration(scan.estimatedTimeUntilDone)}</p>
							</Fragment>
						)}
						<br />
						<Label>
							Config:
						</Label>
						<JSONPreview>{scan.config}</JSONPreview>
						<TitleBar>
							<SectionTitle>{scan.error_count} {pluralize(scan.error_count, 'error', 'errors')}</SectionTitle>
							<Button variant="blue" to={`/scanner/scans/${scanId}/errors`}>View errors</Button>
						</TitleBar>
						<TitleBar>
							<SectionTitle>{scan.page_count} {pluralize(scan.page_count, 'page', 'pages')}</SectionTitle>
							<Button variant="blue" to={`/scanner/scans/${scanId}/pages`}>View pages</Button>
						</TitleBar>
					</Fragment>
				) : null}
			</AutoLoader>
		</Page>
	);
}

export const ScannerScanPages = ({ match }) => {
	const scanId = match.params.id;
	const endpoint = `/v0/scanner/scans/${scanId}/pages`;
	const { error, isLoading, data } = useResource(endpoint);
	const refresh = useResourceRefresher(endpoint);
	const pages = data && data.pages;
	const rerunAnalysis = async () => {
		await request('POST', `/v0/scanner/scans/${scanId}/results`);
		refresh();
	};
	return (
		<Page
			title="Site scan"
			actions={(
				<ButtonBar>
					<Button variant="secondary" onClick={refresh}>Refresh</Button>
					<Button variant="secondary" onClick={rerunAnalysis}>Re-analyse all pages</Button>
				</ButtonBar>
			)}
		>
			<AutoLoader error={error} isLoading={isLoading}>
				<SectionTitle>Pages</SectionTitle>
				<div style={{ flex: 2 }}>
					{pages && pages.length ? (
						<InfiniteOverflowScroller
							items={pages}
							itemHeight={90}
							render={(page, style) => (
								<div style={style} key={page.id}>
									<PageScanItem page={page} />
								</div>
							)}
						/>
					) : (
						'No pages'
					)}
				</div>
			</AutoLoader>
		</Page>
	);
}


export const ScannerScanErrors = ({ match }) => {
	const scanId = match.params.id;
	const endpoint = `/v0/scanner/scans/${scanId}/errors`;
	const { error, isLoading, data } = useResource(endpoint);
	const refresh = useResourceRefresher(endpoint);
	const errors = data && data.errors;
	return (
		<Page
			title="Site scan errors"
			actions={(
				<Button variant="secondary" onClick={refresh}>Refresh</Button>
			)}
		>
			<AutoLoader error={error} isLoading={isLoading}>
				<SectionTitle>Errors</SectionTitle>
				<div>
					{errors && errors.length ? errors.map((error) => (
						<ScanErrorItem key={error.id} scanId={scanId} error={error} />
					)) : (
						'No errors'
					)}
				</div>
			</AutoLoader>
		</Page>
	);
}