import React, { useState } from 'react';

import { EditorField } from '~/src/lib/Editors';

import * as css from './QuoteEditor.css';

const noop = (value) => console.log('QuoteEditor.onChange', value);

const FIELDS = [{
	name: 'text',
	type: 'textarea',
	label: 'Quote',
	size: 'large',
}, {
	name: 'attribution',
	type: 'text',
	label: 'Attribution',
}, {
	name: 'href',
	type: 'url',
	label: 'Attribution href',
}];

export const QuoteEditor = ({ onChange=noop, defaultValue={} }) => {
	// TODO: SIZE
	console.log('defaultValue', defaultValue);
	const [data, setData] = useState(defaultValue || {});
	const handleChange = (key, value) => {
		const newData = { ...data, [key]: value };
		setData(newData);
		onChange(newData);
	};
	return (
		<div className={css.QuoteEditor}>
			{FIELDS.map((field) => (
				<EditorField
					key={field.name}
					field={field}
					onChange={handleChange}
					item={data}
				/>
			))}
		</div>
	);
};
