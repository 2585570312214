.SelectField {
	cursor: pointer;
}
.SelectInput {
	flex: 1;
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 42px;
	position: relative;
	border-radius: 3px 3px 0 0;
	border: 1px solid transparent;
	border-bottom-style: none;
	padding-left: 6px;
}
.SelectInput--is-open {
	border-color: var(--green-light);
	box-shadow: 0px 0px 5px rgba(0,0,0,0.15);
}
.SelectInput__input::placeholder {
}
.SelectInput--is-open  .SelectInput__input::placeholder,
.SelectInput--has-selection .SelectInput__input::placeholder {
	color: inherit;
}
.SelectInput__arrow {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	width: 50px;
	background-image: url('~/src/icons/chevron-down.svg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 25px auto;
	opacity: 0.3;
	transition: opacity 150ms ease, transform 150ms ease;
}
.SelectInput:hover .SelectInput__arrow {
	opacity: 1;
}
.SelectInput--is-open .SelectInput__arrow {
	transform: scale(1, -1);
}
.SelectInput__options {
	position: absolute;
	display: none;
	top: 100%;
	margin-top: -5px;
	padding-top: 5px;
	left: -1px;
	background: white;
	z-index: 10;
	width: 100%;
	border: 1px solid var(--green-light);
	border-top: none;
	border-radius: 0 0 3px 3px;
	cursor: default;
	box-shadow: 0px 5px 5px rgba(0,0,0,0.15);
	animation: options-open 150ms ease backwards;
}
@keyframes options-open {
	0% {
		transform: scaleY(0);
		transform-origin: top left;
	}
	100% {
		transform: scaleY(1);
		transform-origin: top left;
	}
}
.SelectInput__options--is-open {
	display: block;
}
.SelectInput__option {
	padding: 5px 10px;
	cursor: pointer;
}
.SelectInput__option:hover {
	background: var(--green-lightest);
}
.SelectInput__option--is-selected,
.SelectInput__option--is-selected:hover {
	background: var(--green-lighter);
}
.SelectInput__group::selection,
.SelectInput__option::selection {
	background: transparent;
}
.SelectInput__group {
	opacity: 0.4;
}
.SelectInput__option--create-new {
	color: var(--link-color);
	text-decoration: underline;
}
.SelectInput__option[data-prefix]:before {
	content: attr(data-prefix);
	display: inline-block;
	margin-right: 0.25em;
	line-height: inherit;
	vertical-align: inherit;
	opacity: 0.7;
}