import React from 'react';
import { Switch, Route } from 'react-router-dom';

import {
	GoldSifterHome,
	AllDatasetsPage,
	SingleDatasetPage,
} from './GoldSifterListings';
import {
	GoldSifterEditPage,
	UploadDatasetPage,
	GoldSifterAddDataPage,
	SingleDatasetPageWithBatches,
} from './GoldSifterDatasets';
import {
	GoldSifterBatchPage,
} from './GoldSifterBatches';

export const GoldSifter = () => {
	return (
		<Switch>
			<Route path={'/goldsifter/datasets/:datasetId/edit'} component={GoldSifterEditPage} />
			<Route path={'/goldsifter/datasets/:datasetId/add-data'} component={GoldSifterAddDataPage} />
			<Route path={'/goldsifter/datasets/:datasetId/batches'} component={SingleDatasetPageWithBatches} />
			<Route path={'/goldsifter/datasets/:datasetId/:batchId'} component={GoldSifterBatchPage} />
			<Route path={'/goldsifter/datasets/new'} component={UploadDatasetPage} />
			<Route path={'/goldsifter/datasets/:datasetId'} component={SingleDatasetPage} />
			<Route path={'/goldsifter/datasets'} component={AllDatasetsPage} />
			<Route path={'/goldsifter'} component={GoldSifterHome} />
		</Switch>
	);
};

