.ErrorBoundary {
	max-width: 90%;
	margin: 50px;
	padding-left: var(--menu-width);
}

.ErrorBoundary__title {}
.ErrorBoundary__prompt {
	margin: 20px 0;
}
.ErrorBoundary__error {
	background: var(--white);
	border-radius: 5px;
	padding: 20px;
	font-size: 18px;
	color: var(--red);
	tab-size: 4;
	word-break: break-all;
}
.ErrorBoundary__errorPart {
	margin-bottom: 10px;
	white-space: pre-wrap;
}