import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { subDays } from 'date-fns';
import { parse as parseQueryString } from 'query-string';

import { formatDate, formatQueryString } from '~/src/util/format';
import { useResource } from '~/src/util/useResource';
import { Spread } from '~/src/lib/Layout';
import { Page } from '~/src/lib/Page';
import { Button, Link } from '~/src/lib/Buttons';
import { BaseField, DateField } from '~/src/lib/Form';
import { TransparentSelect } from '~/src/lib/Form';

import * as css from './MatchEvaluatorRecent.css';

const Lead = () => (
	<Fragment>
		<p>Evaluated queries appear here. Use the filters to select the date range and whether you want to see only a subset of queries.</p>
		<p>Click on the query title to open the query, and click the result title to open the product.</p>
		<p>The evaluation action & reason will be shown next to the item if filters aren’t applied.</p>
		<p>If the evaluation is wrong, click the item’s answer to re-evaluate.</p>
	</Fragment>
);

export const MatchEvaluatorRecent = ({ location }) => {
	const history = useHistory();
	const query = parseQueryString(location.search);
	const [startDate, setStartDate] = useState(query.startDate || formatDate(subDays(new Date(), 7)));
	const [endDate, setEndDate] = useState(query.endDate || formatDate(new Date()));
	const [answer, setAnswer] = useState(query.answer || null);
	const [reason, setReason] = useState(query.reason || null);
	const queryString = formatQueryString({
		startDate,
		endDate,
		answer,
		reason,
	});
	useEffect(() => {
		history.replace({ search: queryString });
	}, [queryString, history]);
	const optionsResource = useResource('/v0/match-evaluator/options');
	const resource = useResource(`/v0/match-evaluator/recent?${queryString}`);
	const answerOptions = optionsResource.data ? Object.values(optionsResource.data.actions) : [];
	const reasonOptions = optionsResource.data ? optionsResource.data.reasons[answer] || [] : [];
	const showAnswer = (answer && reason) ? false : {
		answer: !answer,
		reason: !answer || !reason,
	};
	return (
		<Page
			title="Recent evaluations"
			isLoading={resource.isLoading}
			error={resource.error}
			lead={<Lead />}
			actions={(
				<Fragment>
					<Spread />
					<Button icon="stats" variant="neutral" to={`/match-evaluator/stats?${queryString}`}>Stats</Button>
				</Fragment>
			)}
		>
			<div className={css.FilterBar}>
				<DateField
					label="Start date"
					name="startDate"
					description="Inclusive"
					value={startDate}
					onChange={(date) => date && setStartDate(date)}
				/>
				<DateField
					label="End date"
					name="endDate"
					description="Inclusive"
					value={endDate}
					onChange={(date) => date && setEndDate(date)}
				/>
				<BaseField
					label="Answer"
				>
					<TransparentSelect
						allowBlank
						defaultValue={answer}
						className={css.FilterBar__select}
						options={answerOptions}
						onChange={setAnswer}
						placeholder="Answer..."
					/>
				</BaseField>
				<BaseField
					label="Reason"
				>
					<TransparentSelect
						allowBlank
						defaultValue={reason}
						className={css.FilterBar__select}
						options={reasonOptions}
						onChange={setReason}
						placeholder={reasonOptions.length ? 'Reason...' : 'Not available'}
					/>
				</BaseField>
			</div>
			<MatchGrid items={resource.data} showAnswer={showAnswer} />
		</Page>
	)
};

const MatchGrid = ({ items, showAnswer }) => {
	if (!items) {
		return null;
	}
	return (
		<section className={css.MatchGrid}>
			{items.map((item) => (
				<MatchPreview key={item.id} item={item} showAnswer={showAnswer} />
			))}
		</section>
	);
};

const MatchPreview = ({ item, showAnswer }) => {
	const history = useHistory();
	const returnTo = `?return_to=${encodeURIComponent(history.location.pathname + history.location.search)}`;
	return (
		<article className={css.MatchPreview}>
			<Link to={`/querylog/${item.query.id}`} className={classnames(css.MatchPreview__row, css['MatchPreview__row--query'])}>
				<div className={css.MatchPreview__label}>Query</div>
				<div className={css.MatchPreview__text}>{item.query.name}</div>
			</Link>
			<Link to={`/products/${item.result.id}`} className={classnames(css.MatchPreview__row, css['MatchPreview__row--result'])}>
				<div className={css.MatchPreview__label}>Result</div>
				<div className={css.MatchPreview__text}>{item.result.name}</div>
			</Link>
			<Link className={css.MatchPreview__answer} to={`/match-evaluator/${item.id}${returnTo}`}>
				{item.answer ? (
					<Fragment>
						{showAnswer?.action && (
							<span className={css.MatchPreview__answerAction}>
								{item.answer.action}
							</span>
						)}
						{showAnswer?.reason && (
							<span className={css.MatchPreview__answerReason}>
								{item.answer.reason}
							</span>
						)}
						{!showAnswer && (<span>Re-evaluate</span>)}
					</Fragment>
				) : null}
			</Link>
		</article>
	);
};