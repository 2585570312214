import { createBrowserHistory } from 'history';
import { stringify as stringifyQueryString, parse as parseQueryString } from 'query-string';

export const history = createBrowserHistory();

export const goTo = (url) => {
	history.push(url);
};

export const goUp = (amount) => {
	history.push(getUpPathname(amount));
};

export const getUpPathname = (amount=1) => {
	const segments = history.location.pathname.replace(/\/+$/, '').split('/');
	for (let iteration = 0; iteration < Math.abs(amount); iteration++) {
		segments.pop();
	}
	const joined = segments.join('/');
	if (joined) {
		return joined;
	}
	return '/';
}

export const updateQueryString = (properties) => {
	history.replace({
		search: stringifyQueryString(properties),
	});
};

export const getQueryParams = () => {
	return parseQueryString(history.location.search);
};