.Page {
	position: relative;
	flex: 1;
	display: flex;
	flex-direction: column;
}
.Page + .Page {
	--top-space: 0px;
}
.content {
	padding: 0 var(--pad-x) 100px;
	position: relative;
	flex: 1;
	display: flex;
	flex-direction: column;
}
.content--width-wide {
	padding-left: 0;
	padding-right: 0;
}
.content--width-medium {
	max-width: var(--content-width-medium);
}
.content--width-large {
	max-width: var(--content-width-large);
}
.content--width-normal {
}
