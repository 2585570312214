import React from 'react';

import request from '~/src/util/request';
import { niceURL } from '~/src/util/nice';
import { TablePage } from '~/src/lib/Table';
import { useResource } from '~/src/util/useResource';
import { Button } from '~/src/lib/Buttons';

const HEADERS = [{
	label: 'ID',
	key: 'id',
}, {
	label: 'Title',
	key: 'title',
}, {
	label: 'url',
	key: 'url',
	renderItem: (item) => (
		<a href={item.url} rel="noopener noreferrer" target="_blank">{niceURL(item.url)}</a>
	)
}, {
	key: 'actions',
	renderItem: (item, aux) => (
		<Button variant="neutral" onClick={() => aux.archiveItem(item)}>Archive</Button>
	)
}];

const RecommendationsNeeded = () => {
	const { isLoading, error, data, refresh } = useResource('/v0/user-feedback/need-recommendations');
	const archiveItem = async (item) => {
		const response = await request('POST', '/v0/user-feedback/need-recommendations/archived', { id: item.id });
		if (response.ok) {
			refresh();
		}
	};
	return (
		<TablePage
			keyProp="id"
			headers={HEADERS}
			items={data}
			headerItemAux={{ archiveItem }}
			title="Recommendations needed"
			isLoading={isLoading}
			error={error}
		/>
	);
};

export default RecommendationsNeeded;