.ProductsSearch {
	display: flex;
	flex-direction: column;
	--item-pad-x: 15px;
	--page-pad-x: 15px;
	flex: 1;
}

.ProductsSearch h1, h2, h3, p, input {
	margin: 0;
	padding: 0;
}

.header {
	position: sticky;
	top: 0;
	z-index: var(--index-header);
	background: var(--background-color);
	padding: 20px var(--page-pad-x) 20px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
	grid-gap: 15px;
	display: grid;
	align-items: baseline;
	grid-template-rows: auto auto;
	grid-template-columns: auto 1fr auto;
	grid-template-areas:
		'title help help'
		'search search control'
	;
}
.title {
	font-size: 30px;
	grid-area: title;
}

.subtitle {
	grid-area: help;
	font-weight: normal;
}

.Control {
	grid-area: control;
	margin-left: auto;
	display: flex;
	align-self: stretch;
	align-items: center;
	column-gap: 10px;
}

.Search {
	grid-area: search;
	background: white;
	border-radius: 5px;
	display: grid;
	grid-template-columns: auto 1fr;
	grid-template-areas: 'label input';
	flex-wrap: wrap;
	padding: 10px var(--item-pad-x) 10px;
	box-shadow: inset 0 -1px 1px rgba(0,0,0,0.15);
}
.Search p {
	grid-area: label;
	font-size: 20px;
	color: var(--gray3);
	font-weight: bold;
	margin-right: 10px;
}
.Search__input {
	grid-area: input;
	font-size: 20px;
	box-sizing: border-box;
	outline: none;
	border: none;
}

.Results {
	flex: 1;
	margin-top: 10px;
	padding: 0 var(--page-pad-x);
}

.Result {
	height: 150px;
	box-sizing: border-box;
	background: #fff;
	margin: 0 0 20px;
	padding: 20px var(--item-pad-x);
	border-radius: 7px;
	display: grid;
	box-shadow: var(--card-shadow);
	grid-template-rows: min-content;
	grid-gap: 10px 20px;
	grid-template-columns: 70px 1fr;
	grid-template-areas:
		'image info'
	;
}
.Result--show-attributes {
	height: 200px;
	grid-template-columns: 70px 1fr 300px;
	grid-template-areas:
		'image info attributes'
	;
}

.Result a {
	text-decoration: none;
}
.Result a:hover {
	text-decoration: underline;
}

.Result__info {
	grid-area: info;
	overflow: hidden;
}
.Result__title {
	font-size: 20px;
	font-weight: var(--bold);
	margin-bottom: 5px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
.Result__category,
.Result__brand,
.Result__seller {
	font-weight: var(--semibold);
}
.Result__label {
	font-weight: var(--semibold);
	color: var(--gray5);
}
.Result__image {
	grid-area: image;
	border-radius: 4px;
	overflow: hidden;
	aspect-ratio: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}
.Result__image img {
	display: block;
	max-width: 100%;
	max-height: 100%;
}
.Result__id {
	font-size: 16px;
	margin-right: 10px;
	color: var(--gray5);
	vertical-align: baseline;
}
.Result__id::selection {
	background: gold;
}
.Result__searchString {
	grid-area: search;
	order: 2;
	align-self: flex-end;
}
.Result__score {
	grid-area: score;
	order: 1;
	align-self: flex-start;
}
.Result__attributes {
	grid-area: attributes;
	font-size: 14px;
}
.Result__attributeKey {
	color: var(--gray5);
	font-weight: var(--semibold);
}
.Result__attributeValue {

}
.TypingPrompt {
	font-size: 30px;
	text-align: center;
	margin: 30px 0;
}