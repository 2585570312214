.ImageUploadEditor {
}
.ImagePreview {
	display: grid;
	grid-template-rows: auto 1fr;
	grid-template-columns: 1fr 2fr;
	grid-template-areas:
		'preview meta'
		'actions meta'
	;
	grid-gap: 0px 20px;
}
.ImagePreview__preview {
	grid-area: preview;
	display: flex;
	align-items: stretch;
	justify-content: stretch;
}
.ImagePreview__image {
	flex: 1;
}
.ImagePreview__frame {
	background: var(--off-white);
	border: 1px solid var(--gray2);
	border-radius: 3px;
	padding: 5px;
}
.ImagePreview__preview img {
	max-width: 100%;
	max-height: 100%;
	display: block;
}
.ImagePreview__meta {
	grid-area: meta;
	--field-padding-x: 10px;
	--field-padding-y: 10px;
	--field-background: var(--off-white);
	display: flex;
	flex-direction: column;
	grid-gap: 10px;
}
.ImagePreview__actions {
	grid-area: actions;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	grid-gap: 20px;
	margin-top: 10px;
}
.ImageUploader {
	height: 150px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	grid-gap: 20px;
	cursor: pointer;
}
.ImageUploader__cta {
	color: var(--blue-darker);
	text-decoration: underline;
	font-size: 24px;
	font-weight: var(--regular);
}
.ImageUploader__error,
.ImageUploader__status {
	color: var(--gray4);
	font-size: 24px;
	font-weight: var(--regular);
}
.ImageUploader__error {
	color: var(--red-dark);
}
.ImageUploader input[type="file"] {
	display: none;
}
