import React, { Component } from 'react';
import * as Sentry from '@sentry/react';

import * as css from './ErrorBoundary.css';

export const ErrorBoundary = ({ children }) => (
	<Sentry.ErrorBoundary fallback={Fallback}>
		{children}
	</Sentry.ErrorBoundary>
);

const Fallback = ({ error, componentStack }) => {
	return (
		<div className={css.ErrorBoundary}>
			<h1 className={css.ErrorBoundary__title}>An error occurred</h1>
			<p className={css.ErrorBoundary__prompt}>Please report this in the #dev slack channel, providing a screenshot of the whole page.</p>
			<div className={css.ErrorBoundary__error}>
				<div className={css.ErrorBoundary__errorPart}>Name: {error.name}</div>
				<div className={css.ErrorBoundary__errorPart}>Message: {error.message}</div>
				<div className={css.ErrorBoundary__errorPart}>Stack: {error.stack}</div>
				{componentStack && (
					<div className={css.ErrorBoundary__errorPart}>Component stack: {componentStack}</div>
				)}
			</div>
		</div>
	);
};
