import React, { useState } from 'react';
import classnames from 'classnames';

import { useResource } from '~/src/util/useResource';

import * as css from './MultiSelectEditor.css';

const noop = (value) => {
	console.info('MultiSelectEditor.onChange', value);
}

// defaultValues should be an array of IDs
// onChange receives an array of option_ids, formatted as strings
export const MultiSelectEditor = ({
	name,
	defaultValue=[],
	onChange=noop,
	options:inputOptions=[],
	getValue=defaultGetValue,
	getLabel=defaultGetLabel,
	endpoint=null
}) => {
	if (!(defaultValue instanceof Array)) {
		throw new Error('MultiSelectEditor\'s defaultValue must be array');
	}
	const resource = useResource(endpoint ? endpoint : null);
	const [selectedValues, setSelectedValues] = useState(new Set(defaultValue));
	const handleToggleItem = (event) => {
		const isChecked = event.target.checked;
		const value = event.target.value;
		const newValues = new Set(selectedValues);
		if (isChecked) {
			newValues.add(value);
		} else {
			newValues.delete(value);
		}
		setSelectedValues(newValues);
		onChange(Array.from(newValues));
	}
	const options = endpoint ? resource.data : inputOptions;
	return (
		<div className={css.MultiSelectEditor}>
			{options && options.map((option) => (
				<Option
					key={getValue(option)}
					name={`${name}[]`}
					value={getValue(option)}
					label={getLabel(option)}
					isSelected={selectedValues.has(getValue(option))}
					onChange={handleToggleItem}
				/>
			))}
		</div>
	);
};

const Option = ({ name, value, label, isSelected, onChange }) => (
	<label
		key={value}
		className={classnames(css.MultiSelectEditor__option, isSelected && css['MultiSelectEditor__option--is-selected'])}
	>
		{label}
		<input
			type="checkbox"
			name={name}
			value={value}
			checked={isSelected}
			className={css.MultiSelectEditor__optionCheckbox}
			onChange={onChange}
		/>
	</label>
);

const defaultGetValue = (option) => {
	return option.value || String(option.id);
}

const defaultGetLabel = (option) => {
	return option.label || option.name || 'Missing label';
}