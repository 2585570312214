import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { MatchEvaluatorStats } from './MatchEvaluatorStats';
import { MatchEvaluatorRecent } from './MatchEvaluatorRecent';
import { EvaluatorFeed, ExistingEvaluation, PrepareMatchEvaluatorSet } from './MatchEvaluator';

export const MatchEvaluator = () => {
	return (
		<Switch>
			<Route path="/match-evaluator/stats" component={MatchEvaluatorStats} />
			<Route path="/match-evaluator/recent" component={MatchEvaluatorRecent} />
			<Route path="/match-evaluator/new" component={PrepareMatchEvaluatorSet} />
			<Route path="/match-evaluator/:id" component={ExistingEvaluation} />
			<Route component={EvaluatorFeed} />
		</Switch>
	);
};
