.LoadingSpinner {
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	animation: loading-intro 500ms ease 500ms backwards;
	width: 40px;
	height: 40px;
}
.LoadingSpinner__inner {
	display: block;
	width: 100%;
	height: 100%;
	animation: loading-spinner 2s linear infinite;
	background-image: url('~/src/icons/loader.svg');
	background-size: 100%;
	background-repeat: no-repeat;
}

@keyframes loading-intro {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes loading-spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}