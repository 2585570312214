import React from 'react';

import { updateQueryString, getQueryParams } from '~/src/util/history';
import { SlimIconButton } from '~/src/lib/Buttons';
import { Label } from '~/src/lib/Text';

import * as css from './FilterBar.css';

export const useFilter = () => {
	const { filter } = getQueryParams();
	return filter;
};

export const FilterBar = ({ filters }) => {
	const queryParams = getQueryParams();
	const applyFilter = (filter) => {
		if (queryParams.filter === filter) {
			updateQueryString();
		} else {
			updateQueryString({ filter });
		}
	};
	return (
		<div className={css.FilterBar}>
			<Label>Filters: </Label>
			{filters.map((filter) => (
				<SlimIconButton
					key={filter.key}
					onClick={() => applyFilter(filter.key)}
					icon="filter"
					isSelected={filter.key === queryParams.filter}
					variant="yellow"
				>
					{filter.label}
				</SlimIconButton>
			))}
		</div>
	);
};
