import React, { Fragment } from 'react';
import { parse as parseQueryString } from 'query-string';

import { useResource } from '~/src/util/useResource';
import { Page } from '~/src/lib/Page';
import { SectionTitle, SubSectionTitle } from '~/src/lib/Text';
import { KeyVals } from '~/src/lib/Debug';
import { AutoLoader } from '~/src/lib/AutoLoader';
import { SharpButton, Button } from '~/src/lib/Buttons';
import { ProtoEditor, useEditor, getEditorActions } from '~/src/lib/ProtoEditor';

import * as css from './GoldSifter.css';

const SHARED_EDITOR_FIELDS = [{
	name: 'key',
	label: 'Key',
	type: 'text',
	description: 'The key is a human readable slug used to quickly identify different datasets',
}, {
	name: 'meta.title',
	label: 'Title',
	type: 'text',
}, {
	name: 'meta.instruction',
	label: 'Instruction',
	type: 'text',
}, {
	type: 'divider',
	label: 'Interface',
}, {
	name: 'interface',
	label: 'Interface',
	type: 'json',
}];

const ID_FIELD = {
	name: 'id',
	type: 'hidden',
};

const EDITOR_DATA_FIELDS = [{
	type: 'divider',
	label: 'Data',
}, {
	name: 'data',
	label: 'Data',
	type: 'json',
	defaultValue: []
}, {
	name: 'question',
	label: 'Question',
	type: 'json',
	defaultValue: {}
}];

export const UploadDatasetPage = ({ location }) => {
	const query = parseQueryString(location.search);
	const basedOn = useResource(query.base ? `/v0/goldsifter/datasets/${query.base}` : null);
	const editor = useEditor({
		createEndpoint: '/v0/goldsifter/datasets',
		fields: [...SHARED_EDITOR_FIELDS, ...EDITOR_DATA_FIELDS],
		isNew: true,
		newItem: stripID(basedOn.data),
	});
	return (
		<Page
			title="Gold sifter: Upload dataset"
			actions={getEditorActions(editor)}
		>
			<ProtoEditor editor={editor} />
		</Page>
	);
};

export const GoldSifterAddDataPage = ({ match }) => {
	const datasetId = match.params.datasetId;
	const editor = useEditor({
		endpoint: `/v0/goldsifter/datasets/${datasetId}/batches`,
		isNew: true,
		fields: [ID_FIELD, ...EDITOR_DATA_FIELDS],
	});
	return (
		<Page
			title="Gold sifter: Add batch to dataset"
			actions={getEditorActions(editor)}
		>
			<ProtoEditor editor={editor} />
		</Page>
	);
};

function stripID(item) {
	if (!item) {
		return item;
	}
	delete item.id;
	return item;
}

export const GoldSifterEditPage = ({ match }) => {
	const datasetId = match.params.datasetId;
	const editor = useEditor({
		endpoint: `/v0/goldsifter/datasets/${datasetId}`,
		fields: [ID_FIELD, ...SHARED_EDITOR_FIELDS],
	});
	return (
		<Page
			title="Gold sifter: Edit dataset"
			actions={getEditorActions(editor)}
		>
			<ProtoEditor editor={editor} />
		</Page>
	);
};

export const SingleDatasetPageWithBatches = ({ match }) => {
	const { datasetId } = match.params;
	const result = useResource(`/v0/goldsifter/datasets/${datasetId}/batches`);
	if (!result.data) {
		return 'Loading...';
	}
	const { batches, ...dataset } = result.data;
	return (
		<Page
			title="Gold sifter dataset batches"
			actions={
				<Fragment>
					<Button variant="neutral" to={`/goldsifter/datasets/${datasetId}/edit`}>Edit</Button>
					<Button variant="neutral" to={`/goldsifter/datasets/${datasetId}/add-data`}>Add data</Button>
				</Fragment>
			}
		>
			<AutoLoader error={dataset.error} isLoading={dataset.isLoading}>
				<SectionTitle>Batches</SectionTitle>
				{batches.map((batch) => (
					<Fragment key={batch.id}>
						<SubSectionTitle>
							Batch: {batch.id}
							<SharpButton to={`/goldsifter/datasets/${datasetId}/${batch.id}`} className={css.GoldSifter__setItemButton}>View</SharpButton>
						</SubSectionTitle>
						<KeyVals data={batch} />
					</Fragment>
				))}
			</AutoLoader>
		</Page>
	);
};