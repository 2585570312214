.Menu {
	--x-space: 12px;
	display: flex;
	flex-direction: column;
	background: var(--menu-background);
	box-shadow: inset -10px 0 10px -10px hsl(0, 6%, 50%, 0.05);
	box-shadow: 0 0 15px hsl(0, 6%, 50%, 0.05);
	border-right: 2px solid rgba(0,0,0,0.08);
	border-bottom: none;
	border-top: none;
	z-index: var(--index-menu);
	overflow-x: hidden;
	overflow-y: auto;
}
.item {
	display: block;
	padding: 8px var(--x-space);
	color: inherit;
	font-size: 16px;
	font-weight: var(--bold);
	line-height: 20px;
	text-decoration: none;
	outline: none;
	position: relative;
	margin: 4px 6px;
	border-radius: 5px;
}
.item:hover {
	background-color: var(--gray2);
}
.active,
.active:hover {
	background: var(--purple);
	box-shadow: 0 0 3px hsl(0, 3%, 50%, 0.02), 0 0 15px hsl(0, 3%, 50%, 0.1), 15px 0 8px -5px hsl(0, 3%, 50%, 0.05);
	color: white;
}
.item::selection {
	background: transparent;
}
.item--external::after {
	content: '';
	display: inline-block;
	width: 14px;
	height: 14px;
	background-image: url('~/src/icons/external-link.svg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 14px auto;
	margin: -3px 0 0 10px;
	vertical-align: middle;
}
.logo {
	display: block;
	height: 25px;
	margin: 20px 0 0;
	background-image: url('~/src/icons/beagle.svg');
	background-size: auto 25px;
	background-position: var(--x-space) top;
	background-repeat: no-repeat;
}
@media (min-height: 900px) {
	.logo {
		height: 30px;
		background-size: auto 30px;
		margin-top: 25px;
		margin-bottom: 10px;
	}
}

.MenuSection__title::selection{
	background: transparent;
}
.MenuSection__title {
	font-size: 14px;
	font-weight: var(--semibold);
	margin: 10px 0 0;
	padding: 10px var(--x-space);
	cursor: pointer;
}
.MenuSection__title:after {
	content: ' +';
	display: inline-block;
	float: right;
	font-size: 1.2em;
	line-height: 0.9em;
	vertical-align: bottom;
	opacity: 0.6;
}
.MenuSection__title:hover:after {
	opacity: 1;
}
.MenuSection__title--isOpen:after {
	content: ' -';
	font-size: 1.4em;
}
.MenuSection__items {
}

.footer {
	padding: 10px var(--x-space);
	margin-top: auto;
	font-size: 12px;
}
.footer__link {
	cursor: pointer;
}
.footer__link:hover {
	text-decoration: underline;
}