import React, { Fragment, useState } from 'react';
import { NavLink as RouterNavLink, Link } from 'react-router-dom';
import classnames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';

import { signout } from '~/src/util/auth';

import * as css from './Menu.css';

const Menu = ({ className }) => {
	const [openPages, setOpenPages] = useState(new Set());
	const setOpenPage = (key, open) => {
		if (open) {
			setOpenPages(new Set([key]));
		} else {
			setOpenPages(new Set());
		}
	}
	return (
		<nav className={classnames(css.Menu, className)}>
			<Link className={css.logo} to="/"></Link>
			<MenuSection
				label="Quick links"
				openPages={openPages}
				setOpenPage={setOpenPage}
			>
				<NavLink exact to="/products">Products</NavLink>
				<NavLink to="/categories">Categories</NavLink>
				<NavLink to="/services">Services</NavLink>
				<NavLink to="/link-health">Link health</NavLink>
				<NavLink to="/match-evaluator">Match evaluator</NavLink>
				<NavLink to="/goldsifter">Gold sifter</NavLink>
				<NavLink to="/product-imports">Product importer</NavLink>
			</MenuSection>
			<MenuSection
				label="Products"
				openPages={openPages}
				setOpenPage={setOpenPage}
			>
				<NavLink exact to="/products">All products</NavLink>
				<NavLink to="/brands">Brands</NavLink>
				<NavLink to="/sites">Seller sites</NavLink>
				<NavLink to="/retailers">Targeted retailers</NavLink>
				<NavLink to="/scrape-results">Scrape results</NavLink>
				<NavLink exact to="/products-search">Product search</NavLink>
				<NavLink to="/services">Services</NavLink>
			</MenuSection>
			<MenuSection
				label="Taxonomies"
				openPages={openPages}
				setOpenPage={setOpenPage}
			>
				<NavLink to="/categories">Categories</NavLink>
				<NavLink to="/attributes">Attributes</NavLink>
				<NavLink to="/tags">Tags</NavLink>
				<NavLink to="/certifications">Certifications</NavLink>
			</MenuSection>
			<MenuSection
				label="User feedback"
				openPages={openPages}
				setOpenPage={setOpenPage}
			>
				<NavLink to="/user-feedback/bad-matches">Bad matches</NavLink>
				<NavLink to="/user-feedback/suggested-retailers">Suggested retailers</NavLink>
				<NavLink to="/user-feedback/recommendations">Suggested products</NavLink>
				<NavLink to="/user-feedback/recommendations-needed">Recommendations needed</NavLink>
				<NavLink to="/user-feedback/forms">User feedback forms</NavLink>
			</MenuSection>
			<MenuSection
				label="Knowledge"
				openPages={openPages}
				setOpenPage={setOpenPage}
			>
				<NavLink to="/dictionaries/multiwords">Multiword dictionary</NavLink>
				<NavLink to="/knowledge/tags">Tag lookup</NavLink>
				<NavLink to="/knowledge/certifications">Certification lookup</NavLink>
				<NavLink to="/knowledge/product-terms">Product terms</NavLink>
			</MenuSection>
			<MenuSection
				label="Content"
				openPages={openPages}
				setOpenPage={setOpenPage}
			>
				<NavLink to="/trending-products">Trending products</NavLink>
				<NavLink to="/content/collections">Collections</NavLink>
				<NavLink to="/content/brand-pages">Brand pages</NavLink>
				<NavLink to="/content/blog/posts">Blog posts</NavLink>
			</MenuSection>
			<MenuSection
				label="Data"
				openPages={openPages}
				setOpenPage={setOpenPage}
			>
				<NavLink to="/data/cpc/brands">CPC link performance</NavLink>
				<NavLink to="/search/log">Searchlog</NavLink>
				<NavLink to="/affiliates/notifications">Affiliate notifications</NavLink>
				<NavLink to="/metrics">Runtime metrics</NavLink>
				<ExternalLink to="https://plausible.io/joinbeagle.com">Site metrics</ExternalLink>
			</MenuSection>
			<MenuSection
				label="Tools"
				openPages={openPages}
				setOpenPage={setOpenPage}
			>
				<NavLink to="/tools/link-creator">Link creator</NavLink>
				<NavLink to="/tools/cpc-links">CPC link creator</NavLink>
				<NavLink to="/match-evaluator">Match evaluator</NavLink>
				<NavLink to="/link-health">Link health</NavLink>
				<NavLink to="/goldsifter">Gold sifter</NavLink>
				<NavLink to="/product-imports">Product importer</NavLink>
				<NavLink to="/scanner">Scanner</NavLink>
				<NavLink to="/querylog">Querylog</NavLink>
				<NavLink to="/queue">Queue</NavLink>
			</MenuSection>
			<div className={css.footer}>
				<a className={css.footer__link} onClick={signout}>Sign out</a>
			</div>
		</nav>
	);
};

const NavLink = (props) => (
	<RouterNavLink {...props} activeClassName={css.active} className={css.item} />
);

const ExternalLink = ({ to, children }) => (
	<a href={to} className={classnames(css.item, css['item--external'])} target="_blank" rel="noreferrer">
		{children}
	</a>
);

const MenuSection = ({ label, children, openPages, setOpenPage }) => {
	const isOpen = openPages.has(label);
	const toggleOpen = () => setOpenPage(label, !isOpen);
	return (
		<Fragment>
			<div
				className={classnames(css.MenuSection__title, isOpen && css['MenuSection__title--isOpen'])}
				onClick={toggleOpen}
			>
				{label}
			</div>
			<AnimatePresence>
				<motion.div
					className={css.MenuSection__items}
					animate={isOpen ? 'visible' : 'hidden'}
					enter="visible"
					initial={false}
					exit="hidden"
					transition={{ ease: 'easeInOut', duration: 0.15 }}
					variants={{
						visible: { opacity: 1, height: 'auto', pointerEvents: 'all', overflow: 'initial' },
						hidden: { opacity: 0, height: 0, pointerEvents: 'none', overflow: 'hidden' }
					}}
				>
					{children}
				</motion.div>
			</AnimatePresence>
		</Fragment>
	);
};

export default Menu;
