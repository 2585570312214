import React from 'react';

import { Link, Button } from '~/src/lib/Buttons';
import { ProductAvailabilityIcons } from '~/src/lib/ProductAvailabilityIcons';

import * as css from './ProductList.css';

export const ProductList = ({ products, isMinimal }) => (
	<ul className={css.ProductList}>
		{products && products.map((product) => isMinimal ? (
			<MinimalProductListItem key={product.product_id} product={product} />
		) : (
			<ProductListItem key={product.product_id} product={product} />
		))}
	</ul>
);

export const ProductListItem = ({ product, actions=null }) => {
	const thumbnail = product.thumbnail || product.image_sizes && product.image_sizes['512x320'];
	return (
		<li key={product.product_id} className={css.ProductListItem}>
			<div className={css.ProductListItem__media}>
				{thumbnail && (<img src={thumbnail} alt="" />)}
			</div>
			<div className={css.ProductListItem__titles}>
				<h2 className={css.ProductListItem__title}>
					<Link target="_blank" to={`/products/${product.product_id}`} className={css.ProductListItem__link}>{product.title}</Link>
				</h2>
				<div className={css.ProductListItem__meta}>
					<Link target="_blank" to={`/products/${product.product_id}`} className={css.ProductListItem__id}>{product.product_id}</Link>
					{product.rank && (
						<span className={css.ProductListItem__rank}>Rank: {product.rank}</span>
					)}
				</div>
			</div>
			<div className={css.ProductListItem__info}>
				<ProductAvailabilityIcons isInStock={product.is_in_stock} isPublished={product.is_published} />
			</div>
			<div className={css.ProductListItem__actions}>
				{actions ? typeof actions === 'function' ? actions(product) : actions : (
					<Button variant="secondary" target="_blank" to={`/products/${product.product_id}`}>View</Button>
				)}
			</div>
		</li>
	);
};

const MinimalProductListItem = ({ product }) => (
	<li key={product.product_id} className={css.MinimalProductListItem}>
		<Link target="_blank" to={`/products/${product.product_id}`} className={css.MinimalProductListItem__link}>
			<div className={css.MinimalProductListItem__media}>
				{product.thumbnail && (<img src={product.thumbnail} alt="" />)}
			</div>
			<h4 className={css.MinimalProductListItem__title}>
				{product.title}
			</h4>
			<div className={css.MinimalProductListItem__meta}>
				<span className={css.MinimalProductListItem__id}>{product.product_id}</span>
			</div>
		</Link>
	</li>
);
