import React from 'react';

import * as css from './Content.css';

const Content = ({ children }) => (
	<div className={css.Content}>
		{children}
	</div>
);

export default Content;
