import flattenTree from '~/src/util/flattenTree';
import { useResource } from '~/src/util/useResource';

const FORMATS = {
	options: createCategoryOptions,
	parentLookup: createParentLookup,
};

export const useCategories = ({ format }) => {
	const { isLoading, error, data } = useResource('/v0/categories?format=tree');
	if (!format) {
		throw new Error('useCategories requires format');
	}
	if (!(format in FORMATS)) {
		throw new Error(`useCategories format invalid ("${format}")`);
	}
	return {
		isLoading,
		error,
		data: data ? FORMATS[format](data) : null,
	}
};

function createCategoryOptions(items) {
	return flattenTree(items)
		.map((item) => ({
			group: item.parents.map((parent) => parent.name).join(' / '),
			label: item.name,
			value: item.category_id,
			search: [...item.parents.map((parent) => parent.name), item.name].join(' ').toLowerCase(),
		}))
	;
}

function createParentLookup(tree) {
	return flattenTree(tree).reduce((keep, category) => {
		keep[category.category_id] = category;
		return keep;
	}, {});
}

export const useCategoryProducts = ({ id }) => {
	return useResource(`/v0/categories/${id}/products`);
}