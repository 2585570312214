import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { ScannerSite, ViewSiteScans } from './ScannerSite';
import { ScannerSites } from './ScannerSites';
import { ScannerScan, ScannerScanPages, ScannerScanErrors } from './ScannerScan';
import { ScannerPage, ScannerPageErrors, ScannerPageResults } from './ScannerPage';

export const Scanner = () => (
	<Switch>
		<Route exact path="/scanner/sites" component={ScannerSites} />
		<Route exact path="/scanner/sites/:id" component={ScannerSite} />
		<Route exact path="/scanner/sites/:id/scans" component={ViewSiteScans} />
		<Route exact path="/scanner/scans/:id" component={ScannerScan} />
		<Route exact path="/scanner/scans/:id/pages" component={ScannerScanPages} />
		<Route exact path="/scanner/scans/:id/errors" component={ScannerScanErrors} />
		<Route exact path="/scanner/scans/:scan_id/pages/:id" component={ScannerPage} />
		<Route exact path="/scanner/scans/:scan_id/pages/:id/errors" component={ScannerPageErrors} />
		<Route exact path="/scanner/scans/:scan_id/pages/:id/results" component={ScannerPageResults} />
		<Route exact path="/scanner" component={ScannerSites} />
		<Redirect to="/scanner" />
	</Switch>
);
