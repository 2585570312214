import React, { Fragment } from 'react';

import { TablePage } from '~/src/lib/Table';
import { Button } from '~/src/lib/Buttons';
import { AutoLoader } from '~/src/lib/AutoLoader';
import { useResource } from '~/src/util/useResource';
import { Page } from '~/src/lib/Page';
import { ProtoEditor, useEditor, getEditorActions } from '~/src/lib/ProtoEditor';

const HEADERS = [{
	label: 'Service',
	key: 'title',
	isWide: true,
}, {
	label: 'Type',
	key: 'type',
}, {
	label: 'Is active',
	key: 'is_active',
	renderItem: (item) => item.is_active ? 'Active' : 'Inactive',
}, {
	key: 'edit',
	renderItem: (item) => (
		<Button to={`/services/${item.id}`}>Edit</Button>
	)
}];

const Actions = () => (
	<Fragment>
		<Button to="/services/new">Create new</Button>
	</Fragment>
);

export const Services = () => {
	const { data, error, isLoading } = useResource('/v0/services');
	return (
		<AutoLoader error={error} isLoading={isLoading}>
			<TablePage
				keyProp="id"
				headers={HEADERS}
				items={data}
				title="Services"
				actions={<Actions />}
			/>
		</AutoLoader>
	);
};

const SERVICE_EDITOR_FIELDS = [{
	name: 'id',
	type: 'hidden',
}, {
	name: 'title',
	label: 'Title',
	type: 'text',
}, {
	name: 'url',
	label: 'URL',
	type: 'url',
}, {
	name: 'content.type',
	label: 'Type',
	type: 'text',
}, {
	name: 'content.description',
	label: 'Description',
	type: 'textarea',
}, {
	name: 'is_active',
	label: 'Is active?',
	type: 'toggle',
	legends: { on: 'Active', off: 'Inactive' },
}, {
	name: 'image_source_url',
	label: 'Image URL',
	type: 'url',
}, {
	name: 'patterns',
	label: 'URL patterns',
	type: 'textarea',
	description: [
		'Provide URLs for pages on which the service should appear on.',
		'To ensure a correct URL is entered, open the page in your browser and copy the URL from the URL bar.',
		'Please make sure to add any domains in this list to the "Targeted retailers" list in the admin panel.',
	].join(' '),
}];

export const EditService = ({ match }) => {
	const serviceId = match.params.id;
	const isNew = serviceId === 'new';

	const editor = useEditor({
		endpoint: `/v0/services/${serviceId}`,
		createEndpoint: '/v0/services',
		fields: SERVICE_EDITOR_FIELDS,
		allowDelete: true,
		isNew,
	});
	if (editor.error) {
		return (
			<Page title={`Error: ${editor.error.message}`}>
			</Page>
		);
	}
	return (
		<Page
			title={isNew ? 'New service' : `${editor.isLoading ? 'Loading...' : editor.item.title}`}
			actions={getEditorActions(editor)}
		>
			<ProtoEditor key={serviceId} editor={editor} />
		</Page>
	);
};
