import 'babel-polyfill';
import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing'

import App from './App';

if (process.env.SENTRY_DSN) {
	Sentry.init({
		dsn: process.env.SENTRY_DSN,
		integrations: [new Integrations.BrowserTracing()],
	});
}

const root = createRoot(document.querySelector('#app'));

root.render(<App />);
