function flattenTree(tree, depth=0, parentNode) {
	const list = [];
	tree.forEach((branch) => {
		list.push(branch);
		if (parentNode) {
			branch.parents = [...parentNode.parents, parentNode];
		} else {
			branch.parents = [];
		}
		if (!('children' in branch)) {
			throw new Error('flattenTree requires each branch to have children property');
		}
		const children = flattenTree(branch.children, depth + 1, branch);
		branch.childCount = children.length;
		branch.depth = depth;
		list.push(...children);
	});
	return list;
}

export default flattenTree;