import React from 'react';
import classnames from 'classnames';

import * as css from './Debug.css';

export const InspectRaw = ({ data }) => {
	return (
		<pre style={{ overflow: 'auto' }}><code style={{ tabWidth: 2 }}>
			{JSON.stringify(data, null, '\t')}
		</code></pre>
	);
};

export const KeyVals = ({ data, order, ...props }) => (
	<ul className={css.KeyVals}>
		{data && entriesByKeyOrder(data, order).map(([key, val]) => (
			<KeyValItem
				key={key}
				keyProp={key}
				val={val}
				{...props}
			/>
		))}
	</ul>
);

function entriesByKeyOrder(data, order) {
	if (!order) {
		return Object.entries(data);
	}
	const out = [];
	const doneKeys = new Set();
	for (let key of order) {
		if (key in data) {
			doneKeys.add(key);
			out.push([key, data[key]]);
		}
	}
	for (let [key, value] of Object.entries(data)) {
		if (!doneKeys.has(key)) {
			out.push([key, value]);
		}
	}
	return out;
}

const KeyValItem = ({ keyProp: key, val, pickProp, printType, rawJSON }) => {
	if (!val) {
		// return null;
	}
	const value = getValue(val, pickProp);
	const isLink = typeof value === 'string' && value.startsWith('https://');
	if (typeof value === 'function') {
		return null;
	}
	return (
		<li className={css.KeyVals__item}>
			<div className={css.KeyVals__key}>{key} {printType ? <span>({getType(value)})</span> : null} {val && val.source ? (<span>{val.source}</span>) : null}</div>
			{isLink ? (
				<a href={value} target="_blank" rel="noopener noreferrer" className={css.KeyVals__value}>{printValue(value)}</a>
			) : (
				<div className={css.KeyVals__value}>{printValue(value, rawJSON)}</div>
			)}
		</li>
	);
};

function getValue(val, pickProp) {
	if (!(val && typeof val === 'object')) {
		return val;
	}
	if (pickProp && pickProp in val) {
		return getValue(val[pickProp]);
	}
	if ('data' in val && Object.keys(val).length < 3) {
		return val.data;
	}
	return val;
}

function getType(val) {
	if (val === null) {
		return 'null';
	}
	return typeof val;
}

function printValue(val, rawJSON) {
	if (val instanceof Array) {
		return (
			<ol className={css.KeyVals__list}>
				{val.map((sub, index) => (
					<li className={css.KeyVals__listItem} key={index}>{printValue(sub)}</li>
				))}
			</ol>
		);
	}
	if (!val) {
		return formatValue(val);
	}
	if (typeof val === 'object') {
		if (rawJSON && Object.keys(val).length > 3) {
			return (
				<pre className={css.KeyVals__raw}>
					{JSON.stringify(val, null, '\t')}
				</pre>
			);
		} else {
			return (
				<ul className={classnames(css.KeyVals__list, css['KeyVals__list--unordered'])}>
					{Object.entries(val).map(([key, sub], index) => (
						<li className={css.KeyVals__listItem} key={index}><span>{key}:</span> {printValue(sub)}</li>
					))}
				</ul>
			);
		}
	}
	return formatValue(val);
}

function formatValue(val) {
	if (val === null || typeof val === 'undefined') {
		return '-';
	}
	if (typeof val === 'boolean') {
		return val ? 'True' : 'False';
	}
	if (val instanceof Array) {
		// If all items are strings
		if (!val.find((item) => typeof item !== 'string')) {
			return val.join(', ');
		}
	}
	if (typeof val === 'object') {
		return JSON.stringify(val, null, '\t');
	}
	if (typeof val === 'function') {
		return `Function: ${val.name}`;
	}
	return val;
}