import React from 'react';

import { Link } from '~/src/lib/Buttons';

import * as css from './ListItem.css';

export const ListItem = ({ thumbnail, title, href, target, tags, metas, stats, actions=null }) => {
	return (
		<div className={css.ListItem}>
			{thumbnail ? (
				<div className={css.ListItem__thumb}>
					<img src={thumbnail} alt="" />)
				</div>
			) : null}
			<div className={css.ListItem__titles}>
				<h2 className={css.ListItem__title}>
					<Link target={target} to={href} className={css.ListItem__link}>{title}</Link>
				</h2>
				{tags ? (
					<div className={css.ListItem__tags}>{tags}</div>
				) : null}
			</div>
			<div className={css.ListItem__metas}>
				{metas}
			</div>
			<div className={css.ListItem__stats}>
				{stats}
			</div>
			<div className={css.ListItem__actions}>
				{actions}
			</div>
		</div>
	);
};

export const ListItemTag = ({ text, color='gray' }) => {
	return (
		<div className={css.Tag} data-color={color}>
			{text}
		</div>
	);
};

export const ListItemMeta = ({ text, color='black' }) => {
	return (
		<div className={css.Meta} data-color={color} >
			{text}
		</div>
	);
};

export const ListItemStat = ({ text, color='black' }) => {
	return (
		<div className={css.Stat} data-color={color}>
			{text}
		</div>
	);
};