.PriceEditor {
	font-size: var(--editor-medium-text);
}

.PriceEditor__amount {
	display: inline-block;
	min-width: 1em;
}
.PriceEditor__currency {
	display: inline-block;
	min-width: 1em;
}