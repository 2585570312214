import React, { Fragment, useState, useEffect } from 'react';
import fromPairs from 'lodash/fromPairs';
import { format as formatTime } from 'date-fns';

import { Table } from '~/src/lib/Table';
import { parseDate } from '~/src/util/parse';
import { useResource } from '~/src/util/useResource';
import { Button, ButtonBar } from '~/src/lib/Buttons';
import { ProtoEditor, useEditor, getEditorActions } from '~/src/lib/ProtoEditor';
import { Page } from '~/src/lib/Page';
import { AutoLoader } from '~/src/lib/AutoLoader';

const HEADERS = [{
	label: 'Site',
	key: 'domain',
	isWide: true,
}, {
	label: 'Discount code',
	key: 'discount_code',
}, {
	label: 'Approved site',
	key: 'is_approved',
	renderItem: (item) => (item.is_approved ? 'Approved' : '')
}, {
	label: 'Affiliate scheme',
	key: 'affiliate_scheme',
}, {
	label: 'Products',
	key: 'product_count',
}, {
	key: 'edit',
	renderItem: (item) => (
		<ButtonBar lozenge>
			<Button variant="secondary" to={`/sites/${item.site_id}/products`}>Products</Button>
			<Button to={`/sites/${item.site_id}`}>Edit</Button>
		</ButtonBar>
	)
}];

const SITE_EDITOR_FIELDS = [{
	name: 'site_id',
	type: 'hidden',
}, {
	name: 'domain',
	label: 'Domain',
	type: 'text',
}, {
	name: 'is_approved',
	label: 'Approved site?',
	type: 'toggle',
	legends: { on: 'Approved', off: 'Not approved' },
}, {
	name: 'discount_code',
	label: 'Discount code',
	description: 'The code provided by the site',
	type: 'text',
}, {
	name: 'discount_amount',
	label: 'Discount amount',
	description: 'For example 5% or £10',
	type: 'text',
	validator: (value) => value ? /^([£][0-9.]+|[0-9]+%$)/.test(`${value}`.trim()) : true,
}, {
	name: 'discount_expiry',
	label: 'Discount expiry',
	description: 'Provide the date of expiry, or leave blank if indefinite',
	type: 'date',
	validator: (value) => value ? parseDate(value) > new Date() : true,
}, {
	name: 'affiliate_scheme',
	label: 'Affiliate scheme',
	type: 'select',
}, {
	name: 'affiliate_details',
	label: 'Affiliate details',
	description: 'Provide required properties',
	type: 'json',
}, ];

const Actions = ({ onDownload }) => (
	<Fragment>
		<Button to="/sites/new">Create new</Button>
		<Button variant="secondary" onClick={onDownload}>Download CSV</Button>
	</Fragment>
);

export const Sites = () => {
	const { error, isLoading, data } = useResource('/v0/sites');
	const handleCSVDownload = () => {
		const csv = sitesToCSV(data);
		const file = new Blob([csv], { type: 'text/csv' });
		const objectUrl = URL.createObjectURL(file);
		const anchorTag = document.createElement('a');
		anchorTag.download = `seller-sites_${formatTime(new Date, 'yyyy-MM-dd_HH-mm-ss')}.csv`;
		anchorTag.href = objectUrl;
		anchorTag.style.display = 'none';
		document.body.appendChild(anchorTag);
		anchorTag.click();
		URL.revokeObjectURL(objectUrl);
		document.body.removeChild(anchorTag);
	};
	return (
		<Page
			title="Seller sites"
			actions={<Actions onDownload={handleCSVDownload} />}
		>
			<AutoLoader error={error} isLoading={isLoading}>
				<Table
					keyProp="site_id"
					headers={HEADERS}
					items={data}
				/>
			</AutoLoader>
		</Page>
	)
};

function sitesToCSV(sites) {
	const headers = [
		'site_id',
		'domain',
		'is_approved',
		'discount_code',
		'discount_amount',
		'affiliate_scheme',
		'product_count',
	].join(',');
	return `${headers}\n${sites.map(serializeSiteAsCSV).join('\n')}`;
}

function serializeSiteAsCSV(site) {
	return [
		site.site_id,
		site.domain,
		site.is_approved,
		site.discount_code || '',
		site.discount_amount || '',
		site.affiliate_scheme || '',
		site.product_count,
	]
		.map((col) => `${col}`) // to string
		.map((str) => str.includes('"') ? str.replace(/"/g, '""') : str) // escape double quotes per RFC-4180
		.map((str) => str.includes(',') ? `"${str}"` : str) // wrap in quotes if need be
		.join(',')
	;
}

export const EditSite = ({ match }) => {
	const schemesResource = useResource('/v0/affiliate-schemes');
	const [selectedSchemeKey, setSelectedSchemeKey] = useState(null);
	const siteId = match.params.id || 'new';
	const isNew = siteId === 'new';

	const editor = useEditor({
		endpoint: `/v0/sites/${siteId}`,
		createEndpoint: '/v0/sites',
		fields: patchFields(SITE_EDITOR_FIELDS, schemesResource.data || [], selectedSchemeKey),
		allowDelete: true,
		onSuccess: redirectToItem,
		onChange: (key, value) => {
			if (key !== 'affiliate_scheme') {
				return;
			}
			setSelectedSchemeKey(value);
		},
		isNew,
	});

	// On the first render we need to provide the selectedSchemeKey
	useEffect(() => {
		if (!editor?.item?.affiliate_scheme) {
			return;
		}
		setSelectedSchemeKey(editor.item.affiliate_scheme);
	}, [editor?.item?.affiliate_scheme]);

	if (!editor.isLoading && !editor.item && !isNew) {
		return (
			<Page title="Site missing">
			</Page>
		);
	}

	return (
		<Page
			title={isNew ? 'New site' : `${editor.isLoading ? 'Loading...' : editor?.item?.domain || 'Missing site'}`}
			actions={(
				<Fragment>
					{getEditorActions(editor)}
					<Button variant="neutral" to={`/sites/${match.params.id}/products`}>View products</Button>
				</Fragment>
			)}
		>
			<ProtoEditor key={siteId} editor={editor} />
		</Page>
	);
};

function redirectToItem(item) {
	return `/sites/${item.site_id}`;
}

function patchFields(fields, schemes, selectedSchemeKey) {
	return fields.map((field) => {
		if (field.name === 'affiliate_scheme') {
			// Provide options from the schemesResource
			return {
				...field,
				placeholder: schemes.length ? 'Select' : 'Loading...',
				options: schemes,
			};
		}
		// Replace the defaultValue and description for the affiliate_details field,
		// constructing it from data from the currently selected scheme
		if (field.name === 'affiliate_details') {
			const currentScheme = schemes.find((scheme) => scheme.value === selectedSchemeKey)
			if (!(currentScheme && currentScheme.requiredDetails)) {
				return null;
			}
			return {
				...field,
				key: `${field.name}_${currentScheme.value}`,
				description: currentScheme.help,
				defaultValue: fromPairs(currentScheme.requiredDetails.map((key) => [key, ''])),
			};
		}
		return field;
	}).filter((exists) => exists);
}