import React from 'react';
import { useHistory, Switch, Route } from 'react-router-dom';

import request from '~/src/util/request';
import { Page } from '~/src/lib/Page';
import { Column } from '~/src/lib/Layout';
import { TablePage } from '~/src/lib/Table';
import { useResource, useResourceRefresher } from '~/src/util/useResource';
import { Button, SharpButton } from '~/src/lib/Buttons';
import { LabelledBlock, ExternalLink } from '~/src/lib/Text';
import { ProductListItem } from '~/src/lib/ProductList';

import Recommendations from '~/src/pages/Recommendations';
import RecommendationsNeeded from '~/src/pages/RecommendationsNeeded';
import { UserFeedbackForms, ViewUserFeedbackForm } from '~/src/pages/UserFeedbackForms';

const SR_HEADERS = [{
	label: 'Title',
	key: 'title',
	isWide: true,
}, {
	label: 'URL',
	key: 'url',
	format: 'url',
}, {
	label: 'Created at',
	key: 'created_at',
	format: 'time',
}, {
	key: 'edit',
	renderItem: (item) => (
		<Button variant="secondary" to={`/user-feedback/suggested-retailers/${item.id}`}>View</Button>
	)
}];

export const SuggestedRetailers = () => {
	const { isLoading, error, data } = useResource('/v0/user-feedback/suggested-retailers');
	return (
		<TablePage
			keyProp="id"
			headers={SR_HEADERS}
			items={data}
			title="User suggested retailers"
			isLoading={isLoading}
			error={error}
		/>
	);
};

export const ViewSuggestedRetailer = ({ match }) => {
	const history = useHistory();
	const { isLoading, error, data } = useResource(`/v0/user-feedback/suggested-retailers/${match.params.id}`);
	const refresh = useResourceRefresher('/v0/user-feedback/suggested-retailers');
	const handleArchive = async () => {
		const response = await request('POST', '/v0/user-feedback/suggested-retailers/archived', { id: match.params.id });
		if (response.ok) {
			refresh();
			history.push('/user-feedback/suggested-retailers');
		}
	};
	return (
		<Page
			title="Suggested retailer"
			isLoading={isLoading}
			error={error}
			actions={<Button variant="neutral" onClick={handleArchive}>Archive</Button>}
		>
			{data && (
				<Column>
					<LabelledBlock label="Title">
						{data.title}
					</LabelledBlock>
					<LabelledBlock label="URL">
						<ExternalLink href={data.url} />
					</LabelledBlock>
					<LabelledBlock label="Created at">
						{data.created_at}
					</LabelledBlock>
					<LabelledBlock label="Archived at">
						{data.archived_at ? data.archived_at : '-'}
					</LabelledBlock>
				</Column>
			)}
		</Page>
	);
};

const BM_HEADERS = [{
	label: 'Product',
	key: 'productName',
	isWide: true,
}, {
	label: 'Reason',
	key: 'reason',
}, {
	label: 'Created at',
	key: 'created_at',
	format: 'time',
}, {
	key: 'edit',
	renderItem: (item) => (
		<Button variant="secondary" to={`/user-feedback/bad-matches/${item.id}`}>View</Button>
	)
}];

export const BadMatches = () => {
	const { isLoading, error, data } = useResource('/v0/user-feedback/bad-matches');
	return (
		<TablePage
			keyProp="id"
			headers={BM_HEADERS}
			items={data}
			title="User submitted bad matches"
			isLoading={isLoading}
			error={error}
		/>
	);
};

export const ViewBadMatch = ({ match }) => {
	const history = useHistory();
	const { isLoading, error, data } = useResource(`/v0/user-feedback/bad-matches/${match.params.id}`);
	const productResource = useResource(data && data.product_id && `/v0/products/${data.product_id}/preview`);
	const refresh = useResourceRefresher('/v0/user-feedback/bad-matches');
	const handleArchive = async () => {
		const response = await request('POST', '/v0/user-feedback/bad-matches/archived', { id: match.params.id });
		if (response.ok) {
			refresh();
			history.push('/user-feedback/bad-matches');
		}
	};
	return (
		<Page
			title="Bad match"
			isLoading={isLoading}
			error={error}
			actions={<Button variant="neutral" onClick={handleArchive}>Archive</Button>}
		>
			{data && (
				<Column>
					<LabelledBlock label="Product">
						<ProductListItem
							product={productResource.data || {}}
						/>
					</LabelledBlock>
					<LabelledBlock label="Reason">
						{data.reason || '-'}
					</LabelledBlock>
					<LabelledBlock label="URL">
						<ExternalLink href={data.url} />
					</LabelledBlock>
					<LabelledBlock label="Created at">
						{data.created_at}
					</LabelledBlock>
					<LabelledBlock label="Archived at">
						{data.archived_at ? data.archived_at : '-'}
					</LabelledBlock>
				</Column>
			)}
		</Page>
	);
};

export const UserFeedbackHome = () => (
	<Page title="User feedback">
		<Column>
			<h3>Extension:</h3>
			<SharpButton to="/user-feedback/bad-matches">Bad matches</SharpButton>
			<SharpButton to="/user-feedback/suggested-retailers">Suggested retailers</SharpButton>
			<SharpButton to="/user-feedback/recommendations">Recommendations</SharpButton>
			<SharpButton to="/user-feedback/recommendations-needed">Recommendations needed</SharpButton>
			<h3>Forms:</h3>
			<SharpButton to="/user-feedback/forms">All forms</SharpButton>
			<SharpButton to="/user-feedback/forms?filter=uninstalls">Uninstalls</SharpButton>
			<SharpButton to="/user-feedback/forms?filter=let-me-know">Let me knows</SharpButton>
		</Column>
	</Page>
);

export const UserFeedback = () => {
	return (
		<Switch>
			<Route path="/user-feedback/bad-matches/:id" component={ViewBadMatch} />
			<Route path="/user-feedback/bad-matches" component={BadMatches} />
			<Route path="/user-feedback/suggested-retailers/:id" component={ViewSuggestedRetailer} />
			<Route path="/user-feedback/suggested-retailers" component={SuggestedRetailers} />
			<Route path="/user-feedback/recommendations" component={Recommendations} />
			<Route path="/user-feedback/recommendations-needed" component={RecommendationsNeeded} />
			<Route path="/user-feedback/forms/:id" component={ViewUserFeedbackForm} />
			<Route path="/user-feedback/forms" component={UserFeedbackForms} />
			<Route path="/user-feedback" component={UserFeedbackHome} />
		</Switch>
	);
};
