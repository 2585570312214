import React from 'react';
import classnames from 'classnames';

import { Input } from './core';

import * as css from './Tags.css';

export const Tags = ({ children }) => (
	<div className={css.Tags}>
		{children}
	</div>
);

export const Tag = ({ name, value, label, isSelected, onChange }) => (
	<label
		key={value}
		className={classnames(css.Tag, isSelected && css['Tag--is-selected'])}
	>
		{label}
		<input
			type="checkbox"
			name={name}
			value={value}
			checked={isSelected}
			className={css.Tag__checkbox}
			onChange={onChange}
		/>
	</label>
);

export const TagsInput = (props) => (
	<Input {...props} className={css.Tags__input} />
);
