.LoginPage {
	display: flex;
	height: 100%;
	width: 100%;
	align-items: top;
	justify-content: center;
	background: linear-gradient(-30deg, #DEFFF7, #FFE6DE);
}

.content {
	min-width: 300px;
	width: 30vw;
	margin: 30vh auto auto;
	font-size: 18px;
	line-height: 1.5;
	position: relative;
}
.form {
	animation: fade-in 300ms ease-in-out 0ms backwards;
	transition: opacity 300ms ease-in-out;
}
@keyframes fade-in {
	0% {
		opacity: 0;
	}
}

.isLoading .form {
	opacity: 0;
}

.loading {
	opacity: 0;
	transition: opacity 300ms ease-in-out;
	text-align: center;
	pointer-events: none;
}
.isLoading .loading {
	opacity: 1;
}
.loading p {
	margin-top: 1em;
}

.loader {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: -50px;
	margin-top: -50px;
	pointer-events: none;
	width: 100px;
	height: 100px;
	background-image: url('~/src/icons/loader.svg');
	background-size: 100% auto;
	background-repeat: no-repeat;
	background-position: center center;
}
.isLoading .loader {
	opacity: 0.5;
	transition: opacity 300ms ease-in-out 100ms;
	animation: spinning-loader 3s linear infinite;
}
@keyframes spinning-loader {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
