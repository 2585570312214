import React, { useState, useEffect, Fragment } from 'react';
import { Redirect } from 'react-router-dom';

import notify from '~/src/util/notify';
import request from '~/src/util/request';
import { SectionTitle } from '~/src/lib/Text';
import { Link } from '~/src/lib/Buttons';
import { Page } from '~/src/lib/Page';
import { usePageEditor, ContentEditor, MetaEditor, PageEditorActions } from '~/src/lib/PageEditor';

import * as css from './BlogPostEditor.css';

export const BlogPostEditor = ({ match }) => {
	const postId = match.params.id;
	if (postId === 'new') {
		return <CreateNewPost />
	}
	return <EditBlogPost postId={postId} />
};

const CreateNewPost = () => {
	const [postId, setPostId] = useState(null);
	useEffect(() => {
		request('POST', '/v0/blog/posts')
			.then(({ data }) => {
				setPostId(data.id);
			})
			.catch((error) => notify.error(error))
		;
	}, []);
	if (postId) {
		return (
			<Redirect to={`/content/blog/posts/${postId}`} />
		);
	}
	return (
		<Page>
			<div className={css.CreateNewPost}>
				Creating new blog post...
			</div>
		</Page>
	);
};

const EditBlogPost = ({ postId }) => {
	const editor = usePageEditor({
		endpoint: `/v0/blog/posts/${postId}`,
		getPublicUrl: (slug) => `${process.env.SITE_HOST}/blog/${slug}`,
	});
	const contentEditorParams = {
		uploadPrefix: '/blog',
		disableBlocks: ['tags_and_certifications'],
	};
	return (
		<Page
			title="Blog post"
			subtitle={(
				<Fragment>
					<Link target="_blank" to={editor.publicUrl} variant="external">
						{editor.publishedAt ? 'View post' : 'Preview post'}
					</Link>
				</Fragment>
			)}
			actions={(
				<PageEditorActions editor={editor} />
			)}
		>
			<div className={css.BlogPostEditor}>
				<section className={css.BlogPostEditor__meta}>
					<SectionTitle>Meta</SectionTitle>
					<MetaEditor editor={editor} fields={['title', 'slug', 'metaTitle', 'metaDescription', 'posterImage']} />
				</section>
				<section className={css.BlogPostEditor__content}>
					<SectionTitle>Content</SectionTitle>
					<ContentEditor
						editor={editor}
						params={contentEditorParams}
					/>
				</section>
			</div>
		</Page>
	);
};