import React, { useState } from 'react';

import { FeedbackButton as Button, ButtonBar, SlimIconButton, SmallButton } from '~/src/lib/Buttons';
import * as ActionCard from '~/src/lib/ActionCard';
import { removeOrigin, formatTimeAgoStrict } from '~/src/util/format';

import { ValueEditor } from './ValueEditor';
import {
	MANUAL_SOURCE,
	renderSource,
	areValuesDifferent,
	mergeOldAndNewValueArrays,
} from './common';

export const PendingProduct = ({ product, isReady, onSkip, onReject, onSave, onPublish }) => {
	const [selectedProperties, setSelectedOptions] = useState(product.selectedProperties);
	const setOptionAtKey = (key, payload) => {
		setSelectedOptions({
			...selectedProperties,
			[key]: payload,
		});
	};
	return (
		<ActionCard.ActionCard>
			<ActionCard.Header>
				<ActionCard.Source href={product.url}>{removeOrigin(product.url)}</ActionCard.Source>
				<ActionCard.Spread />
				<ActionCard.Time>Created {formatTimeAgoStrict(product.scanned_at)} ago</ActionCard.Time>
			</ActionCard.Header>
			{product.availableProperties.map((property) => (
				<PropertySlot
					key={property.name}
					property={property}
					activeOption={selectedProperties[property.name]}
					setActiveOption={(payload) => setOptionAtKey(property.name, payload)}
				/>
			))}
			<ActionCard.Footer>
				<ButtonBar variant="center">
					{isReady ? (
						<Button onClick={onReject} variant="red">Reject</Button>
					) : null}
					<Button onClick={onSkip} variant="bare">Skip</Button>
					<ActionCard.Spread />
					<Button onClick={() => onSave(selectedProperties)} variant="blue">Save</Button>
					{isReady ? (
						<Button onClick={() => onPublish(selectedProperties)} variant="green">Publish</Button>
					) : null}
				</ButtonBar>
			</ActionCard.Footer>
		</ActionCard.ActionCard>
	);
};

const PropertySlot = ({ property, activeOption, setActiveOption }) => {
	const [isExpanded, setIsExpanded] = useState(false);
	const wasEdited = activeOption && activeOption.wasEdited;
	// If the selected option is manual, add it to the list of options to pick from
	const allOptions = wasEdited ? [activeOption, ...property.options] : property.options;
	const enableOptionSwitcher = allOptions.length > 1 && property.editorType === 'option-switcher';
	const showOptions = enableOptionSwitcher && isExpanded;
	const handlePickOption = (option) => {
		setActiveOption(option);
		setIsExpanded(false);
	};
	const updateActiveOption = (newValue) => {
		// If an array is passed in, merge it with the existing array
		// to keep sources intact, and provide manual options
		if (newValue instanceof Array) {
			setActiveOption(mergeOldAndNewValueArrays(activeOption, newValue));
			return;
		}
		// If there is currently no active option, provide
		if (!activeOption) {
			setActiveOption({
				source: MANUAL_SOURCE,
				value: newValue,
			});
			return;
		}
		// If a property changed, set the source to be based on the previous option
		const valueDidChange = areValuesDifferent(activeOption.value, newValue);
		const source = activeOption.source || MANUAL_SOURCE;
		setActiveOption({
			source: source,
			wasEdited: source !== MANUAL_SOURCE && valueDidChange,
			value: newValue,
		});
	};
	return (
		<ActionCard.Slot>
			<ActionCard.SlotHeader>
				<ActionCard.SlotTitle>{property.label}</ActionCard.SlotTitle>
				<ActionCard.SlotHeaderSub>{showOptions ? null : renderSource(activeOption)}</ActionCard.SlotHeaderSub>
				<ActionCard.SlotHeaderSub>
					{enableOptionSwitcher && !isExpanded && (
						<SmallButton onClick={() => setIsExpanded(true)} icon="edit">Change source</SmallButton>
					)}
				</ActionCard.SlotHeaderSub>
			</ActionCard.SlotHeader>
			{showOptions ? (
				allOptions.map((option) => (
					<OptionPicker
						key={`${option.id}-${option.source}`}
						property={property}
						option={option}
						pickOption={handlePickOption}
						showSource
					/>
				))
			) : (
				<OptionEditor
					property={property}
					activeOption={activeOption}
					updateActiveOption={updateActiveOption}
					isExpanded={isExpanded}
					setIsExpanded={setIsExpanded}
				/>
			)}
		</ActionCard.Slot>
	);
}

const OptionEditor = ({ property, updateActiveOption, activeOption }) => {
	const handleSubmitOption = (newValue) => {
		console.log('submit option', property.name, newValue);
		// TODO
	};
	return (
		<ActionCard.SlotGroup>
			<ActionCard.SlotBody>
				<ValueEditor
					property={property}
					value={activeOption}
					label={property.label}
					onSubmit={handleSubmitOption}
					onChange={updateActiveOption}
				/>
			</ActionCard.SlotBody>
		</ActionCard.SlotGroup>
	);
};

const OptionPicker = ({ property, option, pickOption, showSource }) => {
	// We are using the full ValueEditor but we are disabling editing here,
	// as to simplify the data path (what option are we updating if multiple
	// options are edited? Which one is "the one"?). By disabling editing we
	// can make sure that we only edit the one and only selected option.
	return (
		<ActionCard.SlotGroup onClick={() => pickOption(option)} style={{ cursor: 'pointer' }}>
			{showSource && (
				<ActionCard.SlotHeader>
					<ActionCard.SlotHeaderSub>{renderSource(option)}</ActionCard.SlotHeaderSub>
				</ActionCard.SlotHeader>
			)}
			<ActionCard.SlotBody>
				<div style={{ display: 'flex', columnGap: 20, pointerEvents: 'none' }}>
					<ValueEditor
						property={property}
						value={option}
						label={property.label}
						size="small"
					/>
					<SlimIconButton icon="pick">Use</SlimIconButton>
				</div>
			</ActionCard.SlotBody>
		</ActionCard.SlotGroup>
	);
};
