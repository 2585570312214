import React from 'react';

import { TablePage } from '~/src/lib/Table';
import Resource from '~/src/lib/Resource';
import { ButtonBar, Button, DangerButton } from '~/src/lib/Buttons';

const HEADERS = [{
	label: 'ID',
	key: 'scrape_id',
}, {
	label: 'Title',
	key: 'pageTitle',
	renderItem: (item) => item.page_title,
}, {
	label: 'Site',
	key: 'og:sitename',
	renderItem: (item) => item.site_name,
}, {
	key: 'actions',
	renderItem: (item, aux) => (
		<ButtonBar lozenge>
			<Button to={`/scrape-results/${item.scrape_id}`}>View</Button>
			<DangerButton onClick={() => aux.removeItem(item)}>Delete</DangerButton>
		</ButtonBar>
	)
}];

const Scrapes = () => (
	<Resource endpoint="/v0/scrapes" itemEndpoint={(item) => `/v0/scrapes/${item.scrape_id}`}>
		{({ data, removeItem }) => (
			<TablePage
				keyProp="scrape_id"
				headers={HEADERS}
				headerItemAux={{ removeItem }}
				items={data}
				title="Scrape results"
			/>
		)}
	</Resource>
);

export default Scrapes;