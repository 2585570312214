.expander,
.input {
	--line-height: 1.6em;
	background: transparent;
	border: none;
	font-size: inherit;
	line-height: inherit;
	resize: vertical;
	font-family: inherit;
	font-size: inherit;
	line-height: var(--line-height);
	flex: 1;
	padding: 0;
	box-sizing: border-box;
	white-space: pre-line;
	max-height: 50vh;
	tab-size: 4;
	font-family: monospace;
}
.input:focus {
	outline: none;
}

.expander {
	pointer-events: none;
	visibility: hidden;
	padding-bottom: var(--line-height);
	min-height: calc(var(--line-height) * 4);
}
.input {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	resize: none;
}