import award from '~/src/icons/tags/icon-award.svg';
import biodegradable from '~/src/icons/tags/icon-biodegradable.svg';
import bpaFree from '~/src/icons/tags/icon-bpa-free.svg';
import check from '~/src/icons/tags/icon-check.svg';
import chemicalFree from '~/src/icons/tags/icon-chemical-free.svg';
import cureltyFree from '~/src/icons/tags/icon-curelty-free.svg';
import fairTrade from '~/src/icons/tags/icon-fair-trade.svg';
import giveBack from '~/src/icons/tags/icon-give-back.svg';
import handMade from '~/src/icons/tags/icon-hand-made.svg';
import longLasting from '~/src/icons/tags/icon-long-lasting.svg';
import lowCarbon from '~/src/icons/tags/icon-low-carbon.svg';
import madeInUk from '~/src/icons/tags/icon-made-in-uk.svg';
import makesLessWaste from '~/src/icons/tags/icon-makes-less-waste.svg';
import minimalPackaging from '~/src/icons/tags/icon-minimal-packaging.svg';
import negative from '~/src/icons/tags/icon-negative.svg';
import organic from '~/src/icons/tags/icon-organic.svg';
import palmOil from '~/src/icons/tags/icon-palm-oil.svg';
import plantBased from '~/src/icons/tags/icon-plant-based.svg';
import plasticFree from '~/src/icons/tags/icon-plastic-free.svg';
import recyclable from '~/src/icons/tags/icon-recyclable.svg';
import refillable from '~/src/icons/tags/icon-refillable.svg';
import reusable from '~/src/icons/tags/icon-reusable.svg';
import saveMoney from '~/src/icons/tags/icon-save-money.svg';
import secondHand from '~/src/icons/tags/icon-second-hand.svg';
import upcycled from '~/src/icons/tags/icon-upcycled.svg';
import vegan from '~/src/icons/tags/icon-vegan.svg';
import women from '~/src/icons/tags/icon-women.svg';

const icons = {
	52: award,
	49: biodegradable,
	57: bpaFree,
	8: chemicalFree,
	4: cureltyFree,
	6: fairTrade,
	12: giveBack,
	59: handMade,
	5: longLasting,
	7: lowCarbon,
	14: madeInUk,
	53: makesLessWaste,
	50: minimalPackaging,
	1: organic,
	16: negative,
	58: plantBased,
	2: plasticFree,
	15: recyclable,
	13: recyclable, // recycled
	56: refillable,
	51: reusable,
	54: saveMoney,
	11: secondHand,
	60: palmOil,
	55: upcycled,
	3: vegan,
	10: women,
	_: check,
};

export const getTagIcon = (tagID) => {
	return icons[tagID] || icons._;
};