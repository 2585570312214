/* eslint-disable quotes */

export const descriptions = {
	canonical_url: `The normal website address (URL) of the product (this will auto-fill if you’ve used the scraper to add this product)`,
	name: `What the product is, used for matching, eg “Skinny Jeans”. Just a specific description of what the product is, without any extras`,
	title: `The marketing name of the product shown to the user, eg “Organic Women’s Jeans - Black”. Use the format {one sustainability feature} + {what the product is} + {if necessary, the colour/flavour}`,
	is_in_stock: `Use this Stock toggle instead of unpublishing a product when a product is out of stock`,
	is_published: `Products which are not published will not be matched against, and will never be shown to users`,
	unpublish_reason: `When unpublishing, indicating the reason is important to ensure future publishing is not done incorrectly. Field is cleared automatically when publishing`,
	is_placeholder: `Placeholder products are matched against but never shown to users. Use where we know of a product type to match against, but where we don't (yet) have a sustainable alternative`,
	price: `The price, in £ pounds sterling. Convert at current rates if necessary. If it is currently discounted, use the higher price`,
	manufacturer_product_url: `If the canonical URL above is for a marketplace selling the product, then put a link to the brand who makes the product here. For example, Earth Conscious (manufacturer) products are sold on Ethical Superstore (canonical URL)`,
	image_source_url: `If this hasn’t auto-filled, right click on the product image in the store, select “copy image address” and paste it here`,
	description: `To include a summary of what the product is, and also why it is sustainable. Remove any other detail that may have auto-filled, such as sizing and delivery info`,
	category_id: `What type of product it is. Make sure you have selected the most specific category, eg for a pair of Womens’ Jeans, find Jeans in the Women’s category`,
	brand_id: `The brand that makes the product. If it’s sold on a marketplace, this should not be the market place name. Eg use Earth Conscious for their products sold on Ethical Superstore`,
	certifications: `External bodies that have certified the product. Look on the website of the brand and marketplace (if relevant). Only select these if you are certain`,
	tags: `The things that make a product sustainable. Look on the website of the brand and marketplace (if relevant). Only select these if you are certain`,
	keywords: `Alternative names for this product. This field has the same weighting as the name field`,
	attributes: `Attributes help define the product and should be used instead of keywords where applicable. "Unisex" refers to grownup clothes, do not apply it to baby clothes.`,
};

export const labels = {
	canonical_url: `Canonical URL`,
	affiliate_url: `Affiliate URL`,
	name: `Name`,
	title: `Title`,
	is_in_stock: `In Stock?`,
	is_published: `Published?`,
	unpublish_reason: `Reason for unpublishing`,
	is_placeholder: `Placeholder product?`,
	price: `Price`,
	manufacturer_product_url: `Manufacturer's product URL`,
	image_source_url: `Image URL`,
	description: `Description`,
	category_id: `Category`,
	brand_id: `Brand`,
	certifications: `Certifications`,
	tags: `Tags`,
	keywords: `Synonyms`,
	attributes: `Product Attributes`,
};
