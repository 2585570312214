.Form, .fade {
	position: relative;
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 20px;
}
.content {
	transition: opacity 300ms ease;
}
.content--isLoading .fade {
	transition-duration: 150ms;
	opacity: 0.3;
	pointer-events: none;
}
.loader {
	position: absolute;
	top: 35%;
	left: 50%;
	width: 75px;
	height: 75px;
}
