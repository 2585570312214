import React from 'react';

import iconOutOfStock from '~/src/icons/out-of-stock.svg';
import iconInStock from '~/src/icons/in-stock.svg';
import iconPublished from '~/src/icons/published.svg';
import iconUnpublished from '~/src/icons/unpublished.svg';

import * as css from './ProductList.css';

export const ProductAvailabilityIcons = ({ isInStock, isPublished }) => {
	return (
		<span className={css.ProductAvailabilityIcons}>
			<img src={isInStock ? iconInStock : iconOutOfStock} alt={isInStock ? 'In stock' : 'Out of stock'} />
			<img src={isPublished ? iconPublished : iconUnpublished} alt={isPublished ? 'Published' : 'Not published'} />
		</span>
	);
};