import React, { forwardRef } from 'react';
import classnames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';

import * as css from './ActionCard.css';

export const ActionCard = ({ children, isVisible=true }) => (
	<AnimatePresence>
		{isVisible ? (
			<motion.article
				className={css.ActionCard}
				key="card"
				animate={isVisible ? 'visible' : 'hidden'}
				exit="hidden"
				variants={{
					visible: { opacity: 1, height: 'auto' },
					hidden: { opacity: 0, height: 0, marginBottom: 0 }
				}}
			>
				{children}
			</motion.article>
		) : null}
	</AnimatePresence>
);

export const Header = ({ children }) => (
	<header className={css.Header}>
		{children}
	</header>
);

export const Footer = ({ children }) => (
	<footer className={css.Footer}>
		{children}
	</footer>
);

export const Heading = ({ children, href }) => (
	<h1 className={css.Heading}>
		<a href={href} target="_blank" rel="noopener noreferrer" className={css.Heading__link}>
		{children}
		</a>
	</h1>
);
export const SubHeading = ({ children, href }) => (
	<h2 className={css.SubHeading}>
		<a href={href} target="_blank" rel="noopener noreferrer" className={css.SubHeading__link}>
			{children}
		</a>
	</h2>
);
export const Source = ({ href, children }) => (
	<a href={href} target="_blank" rel="noopener noreferrer" className={css.Source}>
		{children}
	</a>
);
export const Time = ({ href, children }) => (
	<span className={css.Time}>
		{children}
	</span>
);
export const Spread = () => (
	<span className={css.Spread} />
);

export const Body = ({ children }) => (
	<section className={css.Body}>
		{children}
	</section>
);

export const Slot = ({ children, isVisible=true }) => (
	<AnimatePresence>
		{isVisible ? (
			<motion.section
				className={css.Slot}
				key="card"
				animate={isVisible ? 'visible' : 'hidden'}
				exit="hidden"
				variants={{
					visible: { opacity: 1, height: 'auto' },
					hidden: { opacity: 0, height: 0 }
				}}
			>
				<div className={css.Slot__contents}>
					{children}
				</div>
			</motion.section>
		) : null}
	</AnimatePresence>
);
export const SlotGroup = ({ children, onClick, ...props }) => {
	const hasOnClick = !!onClick;
	return (
		<div
			className={classnames(css.SlotGroup, hasOnClick && css['SlotGroup--has-hover-style'])}
			onClick={onClick}
			{...props}
		>
			{children}
		</div>
	);
};
export const SlotHeader = ({ children }) => (
	<header className={css.SlotHeader}>
		{children}
	</header>
);
export const SlotTitle = ({ children }) => (
	<h1 className={css.SlotTitle}>
		{children}
	</h1>
);
export const SlotHeaderSub = ({ children, title }) => (
	<span className={css.SlotHeaderSub} title={title}>
		{children}
	</span>
);
export const SlotActions = ({ children, align='center' }) => (
	<div className={classnames(css.SlotActions, css[`SlotActions--align-${align}`])}>
		{children}
	</div>
);
export const SlotBody = ({ children, ...props }) => (
	<div className={css.SlotBody} {...props}>
		{children}
	</div>
);
