import React from 'react';

import { Link } from '~/src/lib/Buttons';

import * as css from './Stats.css';

/*
data should be an array containing objects, each with the following keys:
key: String,
label: String,
sublabel: String || null,
value: Number || String,
ratio: (item.value / maxValue),
url: String || null,
*/

export const Stats = ({ data }) => {
	return (
		<div className={css.Stats}>
			<div className={css.Legends}>
				{data.map((item) => (
					<Link key={item.key} className={css.Legend} to={item.url}>
						{item.label}
						{item.sublabel ? (`: ${item.sublabel}`) : ''}
					</Link>
				))}
			</div>
			<div className={css.Bars}>
				{data.map((item) => (
					<div key={item.key} className={css.Bar} style={{ width: `${item.ratio * 100}%` }}>
						{item.value}
					</div>
				))}
			</div>
		</div>
	);
}