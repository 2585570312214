.items {
	width: 100%;
}
.noItems {
	padding: 0 calc(var(--pad-x) + 20px);
	font-size: 20px;
}
.header,
.itemValues {
	display: flex;
	flex-direction: row;
	padding: 0 var(--pad-x);
}
.header,
.item {
	width: 100%;
	position: relative;
	box-sizing: border-box;
}
.item:hover {
	background: hsl(0, 3%, 92%);
	background: hsl(0, 3%, 95%);
	background: var(--gray1);
	--background-color: var(--gray1);
	cursor: default;
}
.headerValue,
.itemValue {
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 0%;
	word-break: break-all;
}
.headerValue {
	display: flex;
	align-items: center;
	font-weight: 600;
	padding: 20px 0 20px 20px;
	cursor: pointer;
	white-space: nowrap;
}
.itemValue {
	display: flex;
	align-items: center;
	padding: 10px 0 10px 20px;
	vertical-align: middle;
	min-height: 30px;
	line-height: 1.3;
}
.headerValue:first-child:not(.isWide),
.itemValue:first-child:not(.isWide) {
	flex-grow: 0.5;
	flex-shrink: 0.5;
	flex-basis: 0%;
}
.headerValue:first-child,
.itemValue:first-child {
	padding-left: 0;
}
.headerValue:last-child,
.itemValue:last-child {
	justify-content: flex-end;
}
.isWide {
	flex: 3;
}
.noWrap {
	white-space: nowrap;
}
.headerValue.sortedBy::after {
	content: '';
	display: block;
	width: 0;
	height: 0;
	border-top: 8px solid black;
	border-bottom: none;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	margin-left: 5px;
}
.headerValue.sortedBy.asc::after {
	transform: rotate(180deg);
}

.Indent {
	display: inline-block;
	width: 0em;
	height: 2em;
	margin: -0.5em 0.25em -0.5em 0;
	background-image: url('~/src/icons/corner-down-right.svg');
	background-size: auto 1em;
	background-repeat: no-repeat;
	background-position: center right;
}
.Indent[data-depth="1"] {
	width: 1em;
}
.Indent[data-depth="2"] {
	width: 2em;
}
.Indent[data-depth="3"] {
	width: 3em;
}
.Indent[data-depth="4"] {
	width: 4em;
}
.Indent[data-depth="5"] {
	width: 5em;
}

.expander {
	padding: 20px var(--pad-x);
	border-bottom: 2px solid var(--gray2);
	cursor: initial;
}

.link {
	max-width: 15em;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}