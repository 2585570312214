import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { RuntimeUsage } from './RuntimeUsage';
import { MetricsHome } from './MetricsHome';

export const Metrics = () => (
	<Switch>
		<Route exact path="/metrics/runtime/:route/usage-by-date/:date?" component={RuntimeUsage} />
		<Route exact path="/metrics" component={MetricsHome} />
		<Redirect to="/metrics" />
	</Switch>
);
