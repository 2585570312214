import React, { Fragment } from 'react';

import { TablePage } from '~/src/lib/Table';
import Resource from '~/src/lib/Resource';
import { Button } from '~/src/lib/Buttons';
import { Field, Hidden, ToggleSwitch } from '~/src/lib/Form';
import Editor from '~/src/lib/Editor';

const TAG_HEADERS = [{
	label: 'Tag',
	key: 'name',
	isWide: true
}, {
	label: 'Favourite?',
	key: 'is_favourite',
	renderItem: (item) => item.is_favourite ? '❤️' : '',
}, {
	key: 'edit',
	renderItem: (item) => (
		<Button to={`/tags/${item.tag_id}`}>Edit</Button>
	)
}];

const Actions = () => (
	<Button to="/tags/new">Create new</Button>
);

export const Tags = () => (
	<Resource endpoint="/v0/tags">
		{({ data }) => (
			<TablePage
				keyProp="tag_id"
				headers={TAG_HEADERS}
				items={data}
				title="Tags"
				actions={<Actions />}
			/>
		)}
	</Resource>
);

export const EditTag = ({ match }) => (
	<Editor
		title="Edit tag"
		submitEndpoint={`/v0/tags/${match.params.id}`}
		method="PUT"
		onSuccess="/tags"
		loadEndpoint={`/v0/tags/${match.params.id}`}
	>
		{(item) => (
			<Fragment>
				<Hidden name="tag_id" value={item.tag_id} />
				<SharedFields item={item} />
			</Fragment>
		)}
	</Editor>
);

export const CreateTag = () => (
	<Editor
		title="Create tag"
		submitEndpoint="/v0/tags"
		method="POST"
		onSuccess={redirectToItem}
		newItem
	>
		{(item) => <SharedFields item={item} />}
	</Editor>
);

const SharedFields = ({ item }) => (
	<Fragment>
		<Field label="Name" name="name" defaultValue={item.name} />
		<ToggleSwitch label="Favourite?" name="is_favourite" defaultValue={item.is_favourite} legends={{ on: 'Favourite', off: '' }} />
	</Fragment>
);

function redirectToItem(item) {
	return `/tags/${item.tag_id}`;
}