import React, { Fragment, useEffect, useState, useRef } from 'react';
import classnames from 'classnames';

import { SmallButton, ButtonBar, HelpButton } from '~/src/lib/Buttons';
import { getUpPathname } from '~/src/util/history';

import * as css from './Header.css';

const useSticky = (enable, triggerHeight=0) => {
	const lastChangeRef = useRef(0);
	const [isStuck, setIsSticky] = useState(false);
	useEffect(() => {
		if (!enable) {
			return;
		}
		const handler = () => {
			if (Date.now() - lastChangeRef.current < 150) {
				return;
			}
			// A little bit of hysteresis
			if (window.scrollY < 20) {
				if (isStuck) {
					lastChangeRef.current = Date.now();
					setIsSticky(false);
				}
			} else if (window.scrollY > 30) {
				if (!isStuck) {
					lastChangeRef.current = Date.now();
					setIsSticky(true);
				}
			}
		};
		window.addEventListener('scroll', handler, { passive: true });
		return () => window.removeEventListener('scroll', handler, { passive: true });
	}, [enable, isStuck, triggerHeight]);
	return isStuck;
};

const getElementHeight = (element) => {
	const computed = getComputedStyle(element);
	return element.offsetHeight + parseInt(computed.marginBottom, 10);
}

const useHeight = (isStuck) => {
	const [height, setHeight] = useState(null);
	const ref = useRef();
	useEffect(() => {
		if (height && isStuck) {
			return;
		}
		if (ref.current) {
			setHeight(getElementHeight(ref.current));
		}
		const timeout = setTimeout(() => {
			const newHeight = getElementHeight(ref.current);
			if (height !== newHeight) {
				setHeight(newHeight);
			}
		}, 300);
		return () => clearTimeout(timeout);
	}, [ref, height, isStuck]);
	return [height, ref];
};

export const Header = ({ backLink, title, subtitle, actions, extra, lead, isSticky=false, isShort=false }) => {
	const isStuck = useSticky(isSticky);
	const [showLead, setShowLead] = useState(false);
	const [height, heightRef] = useHeight(isStuck);
	const canShowLead = lead;
	return (
		<Fragment>
			<header
				ref={heightRef}
				className={classnames(
					css.Header,
					height && isSticky && css['Header--is-sticky'],
					isStuck && css['Header--is-stuck'],
					isShort && css['Header--is-short'],
					showLead && css['Header--show-lead'],
				)}
			>
				{backLink ? (
					<div className={css.topRow}>
						<SmallButton variant="back" className={css.backLink} to={typeof backLink === 'boolean' ? getUpPathname() : backLink}>Back</SmallButton>
					</div>
				) : null}
				{extra && (
					<div className={css.extra}>
						{extra}
					</div>
				)}
				<div className={css.titleBar}>
					<h1 className={css.title}>{title}</h1>
					{canShowLead && (
						<HelpButton className={css.help} onClick={() => setShowLead(!showLead)} />
					)}
				</div>
				{subtitle && (<h2 className={css.subtitle}>{subtitle}</h2>)}
				{actions ? (
					<ButtonBar className={css.actions}>{actions}</ButtonBar>
				) : null}
					{canShowLead && showLead ? (
						<div className={css.lead}>{lead}</div>
					) : null}
			</header>
		</Fragment>
	);
}

export default Header;
