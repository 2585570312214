import React, { Fragment } from 'react';
import { parse as parseQueryString } from 'query-string';

import flattenTree from '~/src/util/flattenTree';
import { Field, Hidden, Select } from '~/src/lib/Form';
import Editor from '~/src/lib/Editor';
import Resource from '~/src/lib/Resource';

export const CreateCategory = ({ location }) => {
	const query = parseQueryString(location.search);
	const newItem = { parent_id: query.parent || null };
	return (
		<Editor
			title="Create category"
			submitEndpoint="/v0/categories"
			method="POST"
			onSuccess={redirectToItem}
			newItem={newItem}
		>
			{(item) => <SharedFields item={item} />}
		</Editor>
	);
};

export const EditCategory = ({ match }) => (
	<Editor
		title="Edit category"
		submitEndpoint={`/v0/categories/${match.params.id}`}
		method="PUT"
		onSuccess="/categories"
		loadEndpoint={`/v0/categories/${match.params.id}`}
		allowDelete
		onDelete="/categories"
	>
		{(item) => (
			<Fragment>
				<Hidden name="category_id" value={item.category_id} />
				<SharedFields item={item} />
			</Fragment>
		)}
	</Editor>
);

function createOptions(items) {
	return flattenTree(items)
		.map((item) => ({
			group: item.parents.map((parent) => parent.name).join(' / '),
			label: item.name,
			value: item.category_id,
			search: [...item.parents.map((parent) => parent.name), item.name].join(' ').toLowerCase(),
		}))
	;
}

function redirectToItem(item) {
	return `/categories/${item.category_id}`;
}

const SharedFields = ({ item }) => (
	<Fragment>
		<Field label="Name" name="name" defaultValue={item.name} />
		<Resource endpoint="/v0/categories?format=tree" dataMapper={createOptions}>
			{({ data }) => (
				<Select
					label="Parent category"
					name="parent_id"
					defaultValue={item.parent_id}
					options={data}
				/>
			)}
		</Resource>
		<Field label="Description" name="description" defaultValue={item.description} />
		<Field label="Fact" name="fact" defaultValue={item.fact} />
	</Fragment>
);
