import React, { Fragment } from 'react';
import { parseISO, formatDistance } from 'date-fns';

import { usePaginatedResource } from '~/src/util/useResource';
import { Table } from '~/src/lib/Table';
import { AutoLoader } from '~/src/lib/AutoLoader';
import { Button } from '~/src/lib/Buttons';
import { Paginate } from '~/src/lib/Paginate';
import { FilterBar, useFilter } from '~/src/lib/FilterBar';
import { Page } from '~/src/lib/Page';

const HEADERS = [{
	label: 'Query',
	key: 'query',
	isWide: true,
	renderItem: (entry) => `${entry.query.text} ` + (entry.query.page ? `(page: ${entry.query.page})` : ''),
}, {
	label: 'Product count',
	key: 'product_count',
	renderItem: (entry) => entry.product_count,
}, {
	label: 'Age',
	key: 'age',
	renderItem: (entry) => formatDistance(parseISO(entry.created_at), new Date(), 'YYYY-MM-DD HH:mm:ss'),
}, {
	label: 'Actions',
	key: 'actions',
	renderItem: (entry) => <Button to={`/search/log/${entry.id}`}>View</Button>,
}];

const Actions = ({ refresh }) => (
	<Fragment>
		<Button variant="neutral" onClick={refresh}>Refresh</Button>
	</Fragment>
);


export const Searchlog = () => {
	const filter = useFilter();
	const { error, isLoading, data, nextLink, refresh } = usePaginatedResource(`/v0/search/log?filter=${filter}`);
	return (
		<AutoLoader error={error} isLoading={isLoading}>
			<Page
				title="Latest searches"
				actions={<Actions refresh={refresh} />}
				contentWidth="wide"
			>
				<FilterBar
					filters={[{
						key: 'no-results',
						label: 'No results',
					}, {
						key: 'few-results',
						label: '< 5 results',
					}, {
						key: 'many-results',
						label: '> 15 results',
					}]}
				/>
				<Table
					keyProp="id"
					headers={HEADERS}
					items={data?.entries}
				/>
				{data && nextLink && (<Paginate nextLink={nextLink} />)}
			</Page>
		</AutoLoader>
	);
};
