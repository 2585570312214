import React from 'react';

import { TablePage } from '~/src/lib/Table';
import { useResource } from '~/src/util/useResource';
import { Red } from '~/src/lib/Text';
import { Button } from '~/src/lib/Buttons';

const HEADERS = [{
	label: '',
	key: 'logo',
	renderItem: (item) => (<img src={item.logoSrc} alt={item.name} style={{ maxWidth: 50, maxHeight: 50 }} />)
}, {
	label: 'Brand',
	key: 'name',
	isWide: true
}, {
	label: 'Published?',
	key: 'is_published',
	renderItem: (item) => item.is_published ? 'Published' : (<Red>Not published</Red>),
}, {
	key: 'edit',
	renderItem: (item) => (
		<Button to={`/brands/${item.brand_id}/page`}>Edit</Button>
	)
}];

export const BrandPages = () => {
	const { data, isLoading, error } = useResource('/v0/brand-pages');
	return (
		<TablePage
			keyProp="brand_id"
			headers={HEADERS}
			items={data}
			title="Brand pages"
			isLoading={isLoading}
			error={error}
		/>
	)
};
