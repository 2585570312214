.menu {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: var(--menu-width);
}
.main {
	padding-left: var(--menu-width);
	min-height: 100%;
	display: flex;
	flex-direction: column;
}
.QuickLinks {
	position: fixed;
	top: 5px;
	right: 10px;
	font-size: 12px;
	z-index: var(--index-quicklinks);
	display: flex;
	grid-gap: 10px;
}
.QuickLinks--hidden {
	opacity: 0;
}
.QuickLinks:hover {
	opacity: 1;
}
.QuickLinks a {
	text-decoration: none;
}
.QuickLinks a:hover {
	text-decoration: underline;
	cursor: pointer;
}