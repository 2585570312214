import React, { createContext, useContext } from 'react';
import classnames from 'classnames';

import * as css from './TabSwitcher.css';

const TabSwitcherContext = createContext();

export const TabSwitcher = ({ children, onChange, value }) => {
	return (
		<TabSwitcherContext.Provider value={[value, onChange]}>
			<div className={css.TabSwitcher}>
				{children}
			</div>
		</TabSwitcherContext.Provider>
	);
};

export const TabSwitcherTab = ({ children, name }) => {
	const [tab, onChange] = useContext(TabSwitcherContext);
	if (typeof name === 'undefined') {
		throw new Error(`TabSwitcherTab requires name paramenter ("${name}")`);
	}
	const isSelected = name === tab;
	return (
		<div
			onClick={() => onChange(name)}
			onMouseEnter={() => onChange(name)}
			className={classnames(css.TabSwitcher__tab, isSelected && css['TabSwitcher__tab--is-selected'])}
		>
			{children}
		</div>
	);
};