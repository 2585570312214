import React, { useState } from 'react';
import classnames from 'classnames';
import capitalize from 'lodash/capitalize';

import { TransparentInput } from '~/src/lib/Form';

import * as css from './MultiInsertEditor.css';

const noop = (value) => {
	console.info('MultiInsertEditor.onChange', value);
}

// defaultValues should be an array of values formatted as strings
// onChange receives an array of values, formatted as strings
export const MultiInsertEditor = ({
	name,
	className,
	defaultValue=[],
	onChange=noop,
	placeholder,
}) => {
	if (!(defaultValue instanceof Array)) {
		throw new Error('MultiInsertEditor\'s defaultValue must be array');
	}
	const [selectedOptions, setSelectedOptions] = useState(defaultValue);
	const handleItemClick = (event) => {
		const value = event.target.value;
		const newOptions = selectedOptions.filter((option) => option.value !== value);
		setSelectedOptions(newOptions);
		onChange(newOptions);
	};
	const handleSubmitNew = (event) => {
		if (!event.target.value) {
			return;
		}
		const option = optionFromValue(event.target.value);
		event.target.value = '';
		const newOptions = [...selectedOptions, option];
		setSelectedOptions(newOptions);
		onChange(newOptions.map((option) => option.value));
	};
	const handleCancelNew = (event) => {
		event.target.value = '';
		event.target.blur();
	};
	const handleBlur = (event) => {
		const value = event.target.value;
		if (value) {
			handleSubmitNew(event);
		}
	};
	return (
		<div className={classnames(css.MultiInsertEditor, className)}>
			{selectedOptions.map((option) => (
				<Option
					key={getValue(option.value)}
					name={`${name}[]`}
					value={getValue(option.value)}
					label={getLabel(option.label, option.value)}
					onClick={handleItemClick}
				/>
			))}
			<TransparentInput
				className={css.MultiInsertEditor__input}
				hasFocusStyle
				type="text"
				name={name}
				placeholder={placeholder}
				onSubmit={handleSubmitNew}
				onTab={handleSubmitNew}
				onCancel={handleCancelNew}
				onBlur={handleBlur}
			/>
		</div>
	);
};

const Option = ({ name, value, label, onClick }) => (
	<label
		key={value}
		className={css.MultiInsertEditor__option}
		onClick={onClick}
	>
		{label}
		<input
			type="checkbox"
			name={name}
			value={value}
			checked
			readOnly
			className={css.MultiInsertEditor__optionCheckbox}
		/>
	</label>
);

function optionFromValue(value) {
	return {
		value: value.toLowerCase(),
		label: capitalize(value),
	};
}

function getValue(value) {
	if (typeof value === 'object') {
		return value.value || value;
	}
	return value;
}

function getLabel(label, value) {
	if (label) {
		return label;
	}
	if (typeof value === 'object') {
		return value.label || value.value || value;
	}
	return value;
}