.BrandPageEditor {
	display: grid;
	column-gap: var(--grid-column-gap);
	row-gap: var(--section-spacing);
	grid-template-rows: auto auto;
	grid-template-columns: 1fr;
	grid-template-areas:
		"meta"
		"content"
	;
}
.BrandPageEditor__basic {
	grid-area: basic;
}
.BrandPageEditor__page {
	grid-area: page;
}

.BrandPageEditor__meta {
	grid-area: meta;
}
.BrandPageEditor__content {
	grid-area: content;
}
