.CPCLinkPerformance {}

.MonthSelector {
	font-size: 16px;
	border-radius: 5px;
	font-family: inherit;
	padding: 6px 36px 6px 12px;
	background: var(--white);
	font-weight: var(--bold);
	border: 1px solid var(--gray3);
	appearance: none;
	background-image: url('~/src/icons/chevron-down.svg');
	background-size: 15px auto;
	background-repeat: no-repeat;
	background-position: right 12px center;
}
.MonthSelector:hover {
	border-color: var(--gray4);
	cursor: pointer;
}
