.InlineDiff {

}
.InlineDiff__part {
	color: inherit;
}
.InlineDiff__part--added {
	background-color: var(--green-light);
	border-radius: 2px;
}
.InlineDiff__part--removed {
	background-color: var(--red-light);
	border-radius: 2px;
}