import React from 'react';
import { Switch, Route } from 'react-router-dom';

import request from '~/src/util/request';
import { useResource } from '~/src/util/useResource';
import { Button } from '~/src/lib/Buttons';
import { ProtoEditor, useEditor, getEditorActions } from '~/src/lib/ProtoEditor';
import { Page } from '~/src/lib/Page';
import { TablePage } from '~/src/lib/Table';

const useCertifications = () => useResource('/v0/knowledge/certifications/lookups');
const useSingleCertification = (rawId) => {
	const { isLoading, error, refresh, data: allCertifications } = useCertifications();
	const certificationId = parseInt(rawId, 10);
	const certification = allCertifications?.find((certification) => certification.id === certificationId);
	return {
		isLoading,
		error,
		refresh,
		certification,
		certificationId,
	};
};

const CERT_HEADERS = [{
	label: 'Certification',
	key: 'name',
	isWide: true
}, {
	label: 'Lookup count',
	key: 'count',
	renderItem: (item) => item.lookups.length,
}, {
	key: 'edit',
	renderItem: (item) => (
		<Button to={`/knowledge/certifications/${item.id}/lookups`}>Edit</Button>
	)
}];

const CertificationsLookupHome = () => {
	const { isLoading, error, data } = useCertifications();
	return (
		<TablePage
			keyProp="id"
			headers={CERT_HEADERS}
			items={data}
			title="Certifications lookup"
			isLoading={isLoading}
			error={error}
		/>
	);
};

const CERT_LOOKUPS_HEADERS = [{
	label: 'Lookup text',
	key: 'text',
	isWide: true
}, {
	key: 'edit',
	renderItem: (item, aux) => (
		<Button variant="danger" onClick={() => aux.removeItem(item)}>Delete</Button>
	)
}];

const SingleCertificationLookup = ({ match }) => {
	const { isLoading, error, certification, certificationId, refresh } = useSingleCertification(match.params.id);
	const removeItem = async (text) => {
		await request('DELETE', `/v0/knowledge/certifications/${certificationId}/lookups/${encodeURIComponent(text)}`);
		refresh();
	};
	return (
		<TablePage
			backLink="/knowledge/certifications"
			headers={CERT_LOOKUPS_HEADERS}
			items={certification?.lookups}
			title={`Lookups for certification ${certification?.name}`}
			isLoading={isLoading}
			error={error}
			headerItemAux={{ removeItem }}
			actions={<Button to={`/knowledge/certifications/${certificationId}/lookups/new`}>Add new</Button>}
		/>
	);
};

const EDITOR_FIELDS = [{
	name: 'text',
	label: 'Text',
	type: 'text',
	autoFocus: true
}];

const NewCertificationLookup = ({ match }) => {
	const { isLoading, error, certification, certificationId, refresh } = useSingleCertification(match.params.id);
	const editor = useEditor({
		endpoint: `/v0/knowledge/certifications/${certificationId}/lookups`,
		fields: EDITOR_FIELDS,
		isNew: true,
		newItem: { id: certificationId },
		// We pass in our own refresh handler as the resource we're loading
		// the certifications from is not the same as the resource we're posting to
		refresh,
	});
	return (
		<Page
			title={`Add lookup for "${certification?.name}"`}
			actions={getEditorActions(editor)}
			isLoading={isLoading}
			error={error}
		>
			<ProtoEditor key={certificationId} editor={editor} />
		</Page>
	);
};

export const CertificationsLookup = () => (
	<Switch>
		<Route exact path="/knowledge/certifications/:id/lookups" component={SingleCertificationLookup} />
		<Route exact path="/knowledge/certifications/:id/lookups/new" component={NewCertificationLookup} />
		<Route component={CertificationsLookupHome} />
	</Switch>
);