import React from 'react';

import { Page } from '~/src/lib/Page';
import { Table } from '~/src/lib/Table';
import { useResource } from '~/src/util/useResource';

const HEADERS = [{
	label: 'Network',
	key: 'network',
}, {
	label: 'Date',
	key: 'created_at',
	isWide: true
}, {
	label: 'Payload',
	key: 'payload',
	isWide: true,
	renderItem: (item) => <pre>{JSON.stringify(item.payload, null, ' ')}</pre>,
}];

export const AffiliateNotifications = () => {
	const { data, isLoading, error } = useResource('/v0/affiliates/transaction-notifications');
	return (
		<Page contentWidth="wide" title="Affiliate notifications" error={error} isLoading={isLoading}>
			{data && (
				<Table
					keyProp="id"
					headers={HEADERS}
					items={data}
				/>
			)}
		</Page>
	)
};
