.ToggleSwitch {
	cursor: pointer;
}
.ToggleSwitch__output {
	display: flex;
	align-items: center;
}
.ToggleSwitch__toggle {
	display: inline-block;
	width: 70px;
	height: 36px;
	border-radius: 18px;
	border: 1px solid rgba(0, 0, 0, 0.15);
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
	cursor: pointer;
}
.ToggleSwitch__toggle--redgreen {
	background: var(--red);
}
.ToggleSwitch__checkbox:checked + .ToggleSwitch__toggle--redgreen {
	background: var(--green);
}
.ToggleSwitch__toggle--grayyellow {
	background: var(--gray1);
}
.ToggleSwitch__checkbox:checked + .ToggleSwitch__toggle--grayyellow {
	background: var(--yellow);
}
.ToggleSwitch__checkbox {
	position: absolute;
	opacity: 0;
	pointer-events: none;
}
.ToggleSwitch__toggle:before {
	content: '';
	display: block;
	width: 26px;
	height: 26px;
	background: white;
	border-radius: 14px;
	box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.1);
	border: 1px solid rgba(0, 0, 0, 0.2);
	transform: translateX(-15px);
	transition: 150ms ease-in-out transform, 150ms linear color;
}
.ToggleSwitch__checkbox:checked + .ToggleSwitch__toggle:before {
	transform: translateX(15px);
}
.ToggleSwitch__legend {
	display: inline-block;
	font-size: 13px;
	opacity: 0.8;
	user-select: none;
}
.ToggleSwitch__off {
	display: block;
}
.ToggleSwitch__on {
	display: none;
}
.ToggleSwitch__checkbox:checked ~ .ToggleSwitch__legend .ToggleSwitch__off {
	display: none;
}
.ToggleSwitch__checkbox:checked ~ .ToggleSwitch__legend .ToggleSwitch__on {
	display: block;
}
.ToggleSwitch__checkbox:focus + .ToggleSwitch__toggle {
	box-shadow: 0 0 1px 2px var(--green-light), 0 0 8px 2px var(--green-light);
}
