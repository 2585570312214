import React, { Fragment, useState, useEffect, useRef } from 'react';

import request from '~/src/util/request';
import notify from '~/src/util/notify';
import { Link } from '~/src/lib/Buttons';
import { EditorField } from '~/src/lib/Editors';

import * as css from './ImageUploadEditor.css';

const META_FIELDS = [{
	name: 'alt',
	type: 'text',
	label: 'Alt text',
	description: 'Provide a description of the image for hard-of-seeing users and SEO engines',
}, {
	name: 'url',
	type: 'url',
	label: 'Link',
}, {
	name: 'captionText',
	type: 'text',
	label: 'Caption',
	description: 'Use when an image caption is required, such as when the image license requires attribution'
}, {
	name: 'captionUrl',
	type: 'url',
	label: 'Caption URL',
}];

export const ImageUploadEditor = ({ params={}, onChange, defaultValue={}, name=null, noMeta=false }) => {
	const [imageParams, setImageParams] = useState(
		typeof defaultValue === 'string' ?
		{ id: defaultValue } :
		{ id: defaultValue.id, imageUrl: defaultValue.url }
	);
	const [meta, setMeta] = useState(defaultValue.meta || {});
	const handleUploadComplete = (newParams) => {
		setImageParams(newParams);
		onChange({ meta, id: newParams.id });
	};
	const handleChangeMeta = (newMeta) => {
		setMeta(newMeta);
		onChange({ meta: newMeta, id: imageParams.id });
	};
	if (imageParams && imageParams.id) {
		return (
			<ImagePreview
				id={imageParams.id}
				meta={meta}
				onClear={() => setImageParams(null)}
				onChangeMeta={handleChangeMeta}
				url={imageParams.imageUrl}
				name={name}
				noMeta={noMeta}
			/>
		);
	}
	return (
		<ImageUploader uploadPrefix={params.uploadPrefix} onUploaded={handleUploadComplete} />
	);
};

const ImagePreview = ({ id, url: inputUrl, meta, onClear, onChangeMeta, name, noMeta }) => {
	const url = id ? inputUrl || `${process.env.API_HOST}/v0/media/${id}/url` : null;
	return (
		<div className={css.ImagePreview}>
			{name ? (
				<input type="hidden" name={name} value={id} />
			) : null}
			<div className={css.ImagePreview__preview}>
				<div className={css.ImagePreview__image}>
					<div className={css.ImagePreview__frame}>
						{url && (
							<img src={url} alt={meta.alt} />
						)}
					</div>
				</div>
			</div>
			{noMeta ? null : (
				<div className={css.ImagePreview__meta}>
					{META_FIELDS.map((field) => (
						<EditorField
							key={field.name}
							field={field}
							item={meta}
							onChange={(key, value) => onChangeMeta({ ...meta, [key]: value })}
						/>
					))}
				</div>
			)}
			<div className={css.ImagePreview__actions}>
				<Link variant="danger" onClick={onClear}>Replace image</Link>
			</div>
		</div>
	);
};

const ImageUploader = ({ onUploaded, uploadPrefix }) => {
	const [status, setStatus] = useState(null);
	const [file, setFile] = useState(null);
	const inputRef = useRef();
	const handleFileChange = (event) => {
		setFile(event.target.files.item(0));
	};
	useEffect(() => {
		if (!file) {
			return;
		}
		if (status !== null) {
			return;
		}
		setStatus('REQUEST_UPLOAD');
		let uploadParams;
		getUploadParameters({ file, uploadPrefix })
			.then((params) => {
				uploadParams = params;
				const body = new FormData();
				body.append('file', file);
				setStatus('UPLOADING');
				return fetch(params.uploadUrl, {
					method: 'POST',
					mode: 'cors',
					body,
				});
			})
			.then((response) => {
				if (!response.ok) {
					console.warn('response', response);
					throw new Error(`Response not OK: ${response.statusText} (${response.status})`);
				}
				setStatus('UPLOADED');
				onUploaded(uploadParams);
			})
			.catch((error) => {
				notify.error(error);
				setStatus('ERROR');
			})
		;
	}, [file, uploadPrefix, status, onUploaded]);
	useEffect(() => {
		if (status === 'ERROR') {
			const timeout = setTimeout(() => {
				setFile(null);
				setStatus(null);
				inputRef.current.value = null;
			}, 2000);
			return () => {
				clearTimeout(timeout);
			};
		}
	}, [status]);

	return (
		<Fragment>
			<label className={css.ImageUploader}>
			{status ? (
				status === 'ERROR' ? (
					<p className={css.ImageUploader__error}>Error</p>
				) : (
					<p className={css.ImageUploader__status}>Uploading...</p>
				)
			) : (
				<p className={css.ImageUploader__cta}>Select file</p>
			)}
				<input type="file" onChange={handleFileChange} ref={inputRef} />
			</label>
		</Fragment>
	);
};


function getUploadParameters({ file, uploadPrefix }) {
	const params = {
		filename: file.name,
		contentType: file.type,
		prefix: uploadPrefix,
	};
	return request(
		'POST',
		'/v0/media/request-upload',
		params,
		{ notify: false }
	)
		.then((response) => {
			if (!response.ok) {
				throw new Error(response.message);
			}
			return response.data;
		})
	;
}
