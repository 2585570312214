.DateEditor {
	flex: 1;
}
.DateEditor__input {
}
.DateEditor__clear {
	border: none;
	padding: 0;
	margin: 0 0 0 20px;
	background: none;
	font-size: 12px;
	font-weight: var(--bold);
	cursor: pointer;
}
