import React from 'react';

import * as css from './Banner.css';

export const Banner = ({ text, actions }) => {
	return (
		<div className={css.Banner}>
			<div className={css.Banner__content}>
				<div className={css.Banner__text}>{text}</div>
				<div className={css.Banner__actions}>{actions}</div>
			</div>
		</div>
	);
};
