import { getStoreState, updateStore, reloadStoreState } from '~/src/store';
import request from '~/src/util/request';
import notify from '~/src/util/notify';

const CHECK_INTERVAL = 1000;

export function getAccessToken() {
	return getStoreState().accessToken;
}

export function signout() {
	updateStore({
		accessToken: null,
		role: null,
		user_id: null,
	});
	notify('You have been logged out');
}

export async function submitLoginToken(signinToken) {
	const response = await request('POST', '/v0/signin', { token: signinToken });
	const wasSuccessful = response.status === 'success';
	if (wasSuccessful) {
		updateStore({
			accessToken: response.data.accessToken,
			role: response.data.user.role,
			user_id: response.data.user.user_id,
		});
	}
	return wasSuccessful;
}

export async function submitLoginEmail(email) {
	const response = await request('POST', '/v0/signin', { email });
	const wasSuccessful = response.status === 'success';
	return wasSuccessful;
}

export function waitForAccessToken(maxChecks=300) {
	let checkTimeout;
	return new Promise((done, fail) => {
		let checkCount = 0;
		let checker = () => {
			clearTimeout(checkTimeout);
			checkCount++;
			reloadStoreState();
			if (getAccessToken()) {
				return done();
			} else if (checkCount < maxChecks) {
				checkTimeout = setTimeout(checker, CHECK_INTERVAL);
			} else {
				return fail();
			}
		};
		checkTimeout = setTimeout(checker, CHECK_INTERVAL);
	});
}