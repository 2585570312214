import React from 'react';
import classnames from 'classnames';

import { BaseField } from './core';

import * as css from './ToggleSwitch.css';

export const ToggleSwitch = ({ label, ...props }) => (
	<BaseField noFocusStyle label={label} className={css.ToggleSwitch} {...props}>
		<BareToggleSwitch {...props} isBare={false} />
	</BaseField>
);

export const BareToggleSwitch = ({ name, defaultValue, legends, onChange, variant='redgreen', isBare=true }) => {
	const Comp = isBare ? 'label' : 'div';
	return (
		<Comp className={css.ToggleSwitch__output}>
			<input
				type="checkbox"
				name={name}
				defaultChecked={defaultValue}
				className={css.ToggleSwitch__checkbox}
				onChange={(event) => onChange && onChange(event.target.checked)}
				value=""
			/>
			<div className={classnames(css.ToggleSwitch__toggle, css[`ToggleSwitch__toggle--${variant}`])} />
			{legends && (
				<div className={css.ToggleSwitch__legend}>
					<span className={css.ToggleSwitch__on}>{legends.on || 'Yes'}</span>
					<span className={css.ToggleSwitch__off}>{legends.off || 'No'}</span>
				</div>
			)}
		</Comp>
	);
};