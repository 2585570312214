import React, { Fragment, useState, useEffect, useRef } from 'react';
import classnames from 'classnames';

import { TransparentInput } from '~/src/lib/Form';

import * as css from './ImageEditor.css';

const LOAD = Symbol('LOAD');
const WAIT = Symbol('WAIT');
const LOADING = Symbol('LOADING');
const READY = Symbol('READY');

const TOO_SMALL_THRESHOLD = 400 * 400; // area of image, aka pixel count

const noop = () => {};

export const ImageEditor = ({ onChange=noop, defaultValue, ...props }) => {
	const isMounted = useRef(false);
	const [src, setSrc] = useState(defaultValue);
	const [image, setImage] = useState(null);
	const [error, setError] = useState(null);
	const [state, setState] = useState(defaultValue ? LOAD : WAIT);
	const allowEdit = state === WAIT || state === READY;
	// Keep track of if we're mounted, so we don't change the state after unmount
	useEffect(() => {
		isMounted.current = true;
		return () => isMounted.current = false;
	});
	useEffect(() => {
		if (!(src && state === LOAD)) {
			return;
		}
		setState(LOADING);
		const img = document.createElement('img');
		const handleLoad = () => {
			// Only take action if we are still mounted
			if (!isMounted.current) {
				return;
			}
			setState(READY);
			setImage({
				width: img.width,
				height: img.height,
				src: img.src,
			})
		};
		const handleError = () => {
			// Only take action if we are still mounted
			if (!isMounted.current) {
				return;
			}
			setError('Unable to load image');
			setState(WAIT);
		};
		img.addEventListener('load', handleLoad);
		img.addEventListener('error', handleError);
		img.src = src;
	}, [src, state]);
	useEffect(() => {
		if (state !== WAIT) {
			return;
		}
		if (error) {
			return;
		}
		if (!src) {
			return;
		}
		const timeout = setTimeout(() => {
			setState(LOAD);
		}, 2000);
		return () => {
			clearTimeout(timeout);
		};
	}, [src, state]);
	const handleChange = (value) => {
		onChange(value);
		setError(null);
		setSrc(value);
		setState(WAIT);
	};
	const handleBlur = () => {
		if (src) {
			setState(LOAD);
		}
	};
	const handleSubmit = () => {
		if (src) {
			setState(LOAD);
		}
	};
	return (
		<div className={css.ImageEditor}>
			<div className={css.ImageEditor__preview}>
				{error ? error
				: state === LOADING ? 'Loading...'
				: state === WAIT ? '...'
				: image ? (
					<Fragment>
						<img
							src={image.src}
							alt=""
						/>
						<span className={classnames(css.ImageEditor__size, isSizeTooSmall(image) && css['ImageEditor__size--too-small'])}>{image.width}x{image.height}</span>
					</Fragment>
				) : 'No image'}
			</div>
			<TransparentInput
				className={css.ImageEditor__input}
				hasFocusStyle
				disabled={!allowEdit}
				type="text"
				onChange={handleChange}
				onBlur={handleBlur}
				onSubmit={handleSubmit}
				defaultValue={src}
				{...props}
			/>
		</div>
	);
};

function isSizeTooSmall(image) {
	return image.width * image.height < TOO_SMALL_THRESHOLD;
}