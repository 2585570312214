.LinkCreator {
	display: flex;
	flex-direction: column;
	grid-gap: 30px;
}
.LinkCreator__input {}
.LinkCreator__prompt {}
.LinkCreator__result {
}

.LinkCreator__actions {
	margin-top: 10px;
	display: flex;
	justify-content: flex-end;
}

.Link {
	display: grid;
	grid-template-rows: auto auto;
	grid-template-areas: 'label scheme' 'url url';
	grid-gap: 5px;
	position: relative;
	background: var(--gray1);
	padding: 10px;
	border-radius: 5px;
	cursor: pointer;
}
.Link__scheme {
	grid-area: scheme;
	margin-left: auto;
	font-size: 14px;
}
.Link__label {
	grid-area: label;
	font-size: 14px;
	font-weight: var(--bold);
}
.Link__url {
	grid-area: url;
	font-size: 12px;
	overflow: hidden;
}
.Link:hover .Link__urlHighlight {
	background: rgba(0,0,0,0.05);
}
.Link__message {}

.Action {
	display: flex;
	flex-direction: column;
	grid-gap: 10px;
	border: 3px solid var(--yellow);
	border-radius: 5px;
	background: var(--white);
	padding: 10px;
}
.Action__message {
	font-size: 20px;
	font-weight: bold;
}
.Action__button {}