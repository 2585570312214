import React, { Fragment, useState } from 'react';

import { useCategories, useCategoryProducts } from '~/src/content/categories';
import { ProductList } from '~/src/lib/ProductList';
import { Link } from '~/src/lib/Buttons';

import * as css from './CategoryPreview.css';

export const CategoryPreview = ({ title, href, question }) => {
	return (
		<div className={css.CategoryPreview}>
			<h2 className={css.CategoryPreview__title}>
				{href ? (
					<a href={href} target="_blank" rel="noopener noreferrer">{title}</a>
				) : title}
			</h2>
			<Crumbs id={question.category_id} />
			<Products id={question.category_id} />
		</div>
	);
};

const Crumbs = ({ id }) => {
	const categories = useCategories({ format: 'parentLookup' });
	const { message, category } = getCrumbsData(categories, id);
	return (
		<div className={css.CategoryPreview__crumbs}>
			{message ? message : (
				category.parents.map((cat) => (
					<a
						className={css.CategoryPreview__crumb}
						key={cat.category_id}
						href={`/categories/${cat.category_id}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						{cat.name}
					</a>
				))
			)}
		</div>
	);
};

function getCrumbsData(result, id) {
	if (result.error) {
		return {
			message: 'Error loading categories',
			category: null,
		};
	}
	if (result.isLoading) {
		return {
			message: 'Loading categories...',
			category: null,
		};
	}
	if (!result.data) {
		return {
			message: 'Invalid category data',
			category: null,
		};
	}
	const category = result.data[id];
	if (!category) {
		return {
			message: `Cannot find category with ID ${id}`,
			category: null,
		};
	}
	return {
		message: null,
		category,
	}
}

const Products = ({ id }) => {
	const [showMore, setShowMore] = useState(false);
	const { message, products } = getProductsData(useCategoryProducts({ id }));
	const productSelection = products ? (showMore ? products : products.slice(0, 2)) : [];
	return (
		<div className={css.CategoryPreview__products}>
			{productSelection.length ? (
				<Fragment>
					<h3 className={css.CategoryPreview__sectionTitle}>Example recommendations
						{' '}
						<Link className={css.CategoryPreview__showMore} onClick={() => setShowMore(!showMore)}>{showMore ? 'Show less' : 'Show more'}</Link>
					</h3>
					{message ? message : (
						<ProductList products={productSelection} isMinimal />
					)}
				</Fragment>
			) : 'No product examples'}
		</div>
	);
}

function getProductsData(result) {
	if (result.error) {
		return {
			message: 'Error loading products',
			products: null,
		};
	}
	if (result.isLoading) {
		return {
			message: 'Loading products...',
			products: null,
		};
	}
	if (!result.data) {
		return {
			message: 'Missing products data',
			products: null,
		};
	}
	return {
		message: null,
		products: result.data,
	}
}