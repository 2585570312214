import React, { Fragment } from 'react';
import sortBy from 'lodash/sortBy';

import request from '~/src/util/request';
import { SectionTitle } from '~/src/lib/Text';
import { TitleBar } from '~/src/lib/Layout';
import { Link, Button, ButtonBar } from '~/src/lib/Buttons';
import { AutoLoader } from '~/src/lib/AutoLoader';
import { useResource, useResourceRefresher } from '~/src/util/useResource';
import { Page } from '~/src/lib/Page';
import { pluralize } from '~/src/util/format';

import { ScanResultItem, ScanErrorItem } from './ScanItems';

export const ScannerPage = ({ match }) => {
	const scanId = match.params.scan_id;
	const pageId = match.params.id;
	const endpoint = `/v0/scanner/pages/${pageId}`;
	const { isLoading, error, data } = useResource(endpoint);
	const refresh = useResourceRefresher(endpoint);
	const rerunAnalysis = async () => {
		await request('POST', `/v0/scanner/pages/${pageId}/results`);
		refresh();
	};
	return (
		<Page
			title="Page scan info"
			actions={(
				<ButtonBar>
					<Button variant="primary" onClick={rerunAnalysis}>Re-analyse</Button>
					<Button variant="secondary" onClick={refresh}>Refresh</Button>
				</ButtonBar>
			)}
		>
			<AutoLoader error={error} isLoading={isLoading}>
				{data ? (
					<Fragment>
						<SectionTitle><Link variant="external" to={data?.url}>{data?.url}</Link></SectionTitle>
						<TitleBar>
							<SectionTitle>{data.result_count} {pluralize(data.result_count, 'result', 'results')}</SectionTitle>
							<Button variant="neutral" to={`/scanner/scans/${scanId}/pages/${pageId}/results`}>View results</Button>
						</TitleBar>
						<TitleBar>
							<SectionTitle>{data.error_count} {pluralize(data.error_count, 'error', 'errors')}</SectionTitle>
							<Button variant="danger" to={`/scanner/scans/${scanId}/pages/${pageId}/errors`}>View errors</Button>
						</TitleBar>
					</Fragment>
				) : null}
			</AutoLoader>
		</Page>
	);
}

export const ScannerPageResults = ({ match }) => {
	const pageId = match.params.id;
	const endpoint = `/v0/scanner/pages/${pageId}/results`;
	const { isLoading, error, data } = useResource(endpoint);
	const refresh = useResourceRefresher(endpoint);
	const { page, results } = data || {};
	const rerunAnalysis = async () => {
		await request('POST', `/v0/scanner/pages/${pageId}/results`);
		refresh();
	};
	return (
		<Page
			title="Page scan results"
			actions={(
				<ButtonBar>
					<Button variant="primary" onClick={rerunAnalysis}>Re-analyse</Button>
					<Button variant="secondary" onClick={refresh}>Refresh</Button>
				</ButtonBar>
			)}
		>
			<AutoLoader error={error} isLoading={isLoading}>
				<SectionTitle><Link variant="external" to={page?.url}>{page && page.url}</Link></SectionTitle>
				<SectionTitle>{results ? results.length : 'No'} results</SectionTitle>
				<div>
					{results && results.length ? sortBy(results, ['key', 'source.index']).map((result) => (
						<ScanResultItem key={result.id} result={result} />
					)) : (
						'No results'
					)}
				</div>
			</AutoLoader>
		</Page>
	);
}

export const ScannerPageErrors = ({ match }) => {
	const pageId = match.params.id;
	const endpoint = `/v0/scanner/pages/${pageId}/errors`;
	const { isLoading, error, data } = useResource(endpoint);
	const refresh = useResourceRefresher(endpoint);
	const { page, errors } = data || {};
	return (
		<Page
			title="Page scan errors"
			actions={(
				<Button variant="secondary" onClick={refresh}>Refresh</Button>
			)}
		>
			<AutoLoader error={error} isLoading={isLoading}>
				<SectionTitle><Link variant="external" to={page?.url}>{page && page.url}</Link></SectionTitle>
				<SectionTitle>Errors</SectionTitle>
				<div>
					{errors && errors.length ? errors.map((error) => (
						<ScanErrorItem key={error.id} error={error} />
					)) : (
						'No errors'
					)}
				</div>
			</AutoLoader>
		</Page>
	);
}
