.Dashboard {
	display: flex;
	flex-wrap: wrap;
	grid-gap: 25px;
	align-items: stretch;
}
.DashboardItem {
	display: flex;
	width: 270px;
	box-sizing: border-box;
	flex-direction: column;
	justify-content: start;
	align-items: center;
	padding: 30px;
	aspect-ratio: 1 / 1;
	background: var(--purple);
	border-radius: 10px;
	box-shadow: 0px 1px 2px rgba(10, 0, 0, 0.1), 0px 1px 15px rgba(10, 0, 0, 0.1);
	color: var(--white);
}
.DashboardItem:hover {
	text-decoration: none;
	box-shadow: 0px 1px 2px rgba(10, 0, 0, 0.15), 0px 1px 25px rgba(10, 0, 0, 0.1);
}
.DashboardItem--purple {
	background: var(--purple);
	color: var(--white);
}
.DashboardItem--red {
	background: var(--red);
	color: var(--black);
	text-shadow: 0px 0.5px 1px rgba(255, 255, 255, 0.4);
}
.DashboardItem--yellow {
	background: var(--yellow);
	color: var(--black);
	text-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.4);
}
.DashboardItem--green {
	background: var(--green);
	color: var(--white);
}
.DashboardItem__category {
	font-size: 16px;
	font-weight: var(--regular);
	margin-bottom: 0;
}
.DashboardItem__title {
	margin-top: 20px;
	text-align: center;
	font-size: 20px;
	margin-bottom: 20px;
	min-height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 1;
	font-weight: var(--semibold);
}
.DashboardItem__value {
	text-align: center;
	font-size: 60px;
	white-space: nowrap;
	margin-bottom: auto;
	line-height: 1;
	font-weight: var(--bold);
}
.DashboardItem__sub {
	margin-top: 10px;
	font-size: 16px;
	font-weight: var(--bold);
	text-align: center;
}