import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { SetupImport } from './SetupImport';
import { ListImports } from './ListImports';
import { ImportProduct } from './ImportProduct';
import { ListRecentProducts } from './ListRecentProducts';
import { ImportsHome } from './ImportsHome';

export const ProductImporter = () => (
	<Switch>
		<Route exact path="/product-imports/new" component={SetupImport} />
		<Route exact path="/product-imports/recent-products/:page" component={ListRecentProducts} />
		<Route exact path="/product-imports/imports" component={ListImports} />
		<Route exact path="/product-imports/imports/:import_id/products/:page_scan_id?" component={ImportProduct} />
		<Route exact path="/product-imports" component={ImportsHome} />
		<Redirect to="/product-imports" />
	</Switch>
);
