import React, { Fragment } from 'react';

import { SectionTitle } from '~/src/lib/Text';
import { Link } from '~/src/lib/Buttons';
import { Page } from '~/src/lib/Page';
import { usePageEditor, ContentEditor, MetaEditor, PageEditorActions } from '~/src/lib/PageEditor';
import { useResource } from '~/src/util/useResource';

import * as css from './BrandPageEditor.css';

export const BrandPageEditor = ({ match }) => {
	const brandId = match.params.id;
	const { data: brand } = useResource(`/v0/brands/${brandId}`);
	const editor = usePageEditor({
		endpoint: `/v0/brands/${brandId}/page`,
		getPublicUrl: (slug) => `${process.env.SITE_HOST}/eco-heroes/${slug}`,
		defaultSlug: makeSlug(brand?.title),
		defaultTitle: brand?.title,
	});
	const contentEditorParams = {
		uploadPrefix: '/brands/pages',
		tagsEndpoint: `/v0/brands/${brandId}/tags`,
		certificationsEndpoint: `/v0/brands/${brandId}/certifications`,
	};
	return (
		<Page
			title={`${brand?.name || ''}`}
			subtitle={(
				<Fragment>
					Public brand page
					{editor.publishedAt ? (
						<Fragment>
							&nbsp;-&nbsp;
							<Link target="_blank" to={editor.publicUrl} variant="external">Open page</Link>
						</Fragment>
					) : null}
				</Fragment>
			)}
			actions={(
				<PageEditorActions editor={editor} />
			)}
		>
			<div className={css.BrandPageEditor}>
				<section className={css.BrandPageEditor__meta}>
					<SectionTitle>Meta</SectionTitle>
					<MetaEditor editor={editor} fields={['slug', 'pageTitle', 'metaTitle', 'metaDescription']} />
				</section>
				<section className={css.BrandPageEditor__content}>
					<SectionTitle>Content</SectionTitle>
					<ContentEditor
						editor={editor}
						params={contentEditorParams}
					/>
				</section>
			</div>
		</Page>
	);
};

const WHITESPACE = /\s+/g;
const NON_ALPHANUM = /[^a-z0-9-]/g;

function makeSlug(title) {
	if (typeof title !== 'string') {
		return '';
	}
	return title.toLowerCase().replace(WHITESPACE, '-').replace(NON_ALPHANUM, '');
}
