import { LexoRank } from 'lexorank';

export function getMiddleOrder() {
	return LexoRank.middle();
}
export function getMinOrder() {
	return LexoRank.min();
}
export function getMaxOrder() {
	return LexoRank.max();
}
export function getPreviousOrder(existing) {
	return LexoRank.parse(existing).genPrev();
}
export function getInbetweenOrder(inputA, inputB) {
	const orderA = LexoRank.parse(inputA);
	const orderB = LexoRank.parse(inputB);
	return orderA.between(orderB);
}

export function* createOrderSequence() {
	// Start in the middle to have space before
	let rank = getMiddleOrder();
	while (true) {
		yield rank.toString();
		rank = rank.genNext();
	}
}