import React from 'react';
import { useHistory } from 'react-router-dom';

import request from '~/src/util/request';
import { Table } from '~/src/lib/Table';
import { useResource } from '~/src/util/useResource';
import { Button, ButtonBar } from '~/src/lib/Buttons';
import { Page } from '~/src/lib/Page';
import { Column } from '~/src/lib/Layout';
import { LabelledBlock } from '~/src/lib/Text';
import { KeyVals } from '~/src/lib/Debug';
import { FilterBar, useFilter } from '~/src/lib/FilterBar';

const HEADERS = [{
	label: 'ID',
	key: 'id',
}, {
	label: 'Type',
	key: 'type',
}, {
	label: 'UUID',
	key: 'uuid',
}, {
	label: 'Campaign',
	key: 'campaign',
}, {
	key: 'actions',
	renderItem: (item, aux) => (
		<ButtonBar>
			<Button variant="yellow" onClick={() => aux.archiveItem(item)}>Archive</Button>
			<Button variant="neutral" to={`/user-feedback/forms/${item.id}`}>View</Button>
		</ButtonBar>
	)
}];

export const UserFeedbackForms = () => {
	const filter = useFilter();
	const { isLoading, error, data, refresh } = useResource(`/v0/user-feedback/forms/${filter || 'all'}`);
	const archiveItem = async (item) => {
		const response = await request('POST', '/v0/user-feedback/forms/archived', { id: item.id });
		if (response.ok) {
			refresh();
		}
	};
	return (
		<Page
			title="User feedback forms"
			contentWidth="wide"
			isLoading={isLoading}
			error={error}
		>
			<FilterBar
				filters={[{
					key: 'shop-feedback',
					label: 'Shop feedback',
				}, {
					key: 'let-me-know',
					label: 'Let me know',
				}, {
					key: 'uninstall',
					label: 'Uninstall',
				}]}
			/>
			<Table
				keyProp="id"
				headers={HEADERS}
				items={data}
				headerItemAux={{ archiveItem }}
			/>
		</Page>
	);
};

export const ViewUserFeedbackForm = ({ match }) => {
	const history = useHistory();
	const { isLoading, error, data, refresh } = useResource(`/v0/user-feedback/forms/all/${match.params.id}`);
	const handleArchive = async () => {
		const response = await request('POST', '/v0/user-feedback/forms/archived', { id: match.params.id });
		if (response.ok) {
			refresh();
			history.goBack();
		}
	};
	const type = data?.type;
	const {browser, flow, form, utm, version} = data?.data || {};
	return (
		<Page
			title="User feedback form"
			isLoading={isLoading}
			error={error}
			actions={<Button variant="yellow" onClick={handleArchive}>Archive</Button>}
		>
			{data && (
				<Column align="stretch">
					{type && (
						<LabelledBlock label="Meta">
							<KeyVals data={{ type, version }} />
						</LabelledBlock>
					)}
					{form && (
						<LabelledBlock label="Form">
							{form && <KeyVals data={form} />}
						</LabelledBlock>
					)}
					{utm && (
						<LabelledBlock label="UTMs">
							{utm && <KeyVals data={utm} />}
						</LabelledBlock>
					)}
					{flow && (
						<LabelledBlock label="Flow">
							{flow && <KeyVals data={flow} />}
						</LabelledBlock>
					)}
					{browser && (
						<LabelledBlock label="Browser">
							{browser && <KeyVals data={browser} />}
						</LabelledBlock>
					)}
				</Column>
			)}
		</Page>
	);
};
