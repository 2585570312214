import React, { Component, Fragment } from 'react';
import { parse as parseQueryString } from 'query-string';

import request from '~/src/util/request';
import notify from '~/src/util/notify';
import { updateQueryString } from '~/src/util/history';
import { Form, Field, ToggleSwitch } from '~/src/lib/Form';
import { Page } from '~/src/lib/Page';
import { TablePage } from '~/src/lib/Table';
import { Button } from '~/src/lib/Buttons';
import { Row } from '~/src/lib/Layout';

const HEADERS = [{
	label: 'ID',
	key: 'product_id'
}, {
	label: 'Product',
	key: 'name',
}, {
	label: 'Rank',
	key: 'rank',
}, {
	label: 'Keywords',
	key: 'keywords',
	renderItem: (item) => item.keywords ? item.keywords.join(', ') : '',
}, {
	label: 'Category',
	key: 'category',
}, {
	label: 'Demography',
	key: 'demography',
	renderItem: (item) => item.attributes && item.attributes.demography ? item.attributes.demography.join(', ') : '',
}, {
	key: 'view',
	renderItem: (item) => (
		<Button to={`/products/${item.product_id}`}>View</Button>
	)
}];

const getUrlProperty = (location, key) => {
	if (!location) {
		return '';
	}
	const parsed = parseQueryString(location.search);
	return decodeURIComponent(parsed[key] || '');
}

export class DebugMatching extends Component {
	constructor(props) {
		super(props);
		this.state = {
			query: getUrlProperty(props.location, 'query'),
			url: getUrlProperty(props.location, 'url'),
			results: null,
			isLoading: false,
			hasError: false,
			showUnpublished: false,
			showOutOfStock: false,
			showPlaceholder: true,
			showLowRanks: false,
			showCustomScores: false,
			showExtraFields: !!getUrlProperty(props.location, 'url'),
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handlePublishedToggle = this.handlePublishedToggle.bind(this);
		this.handleStockToggle = this.handleStockToggle.bind(this);
		this.handlePlaceholderToggle = this.handlePlaceholderToggle.bind(this);
		this.handleLowRankToggle = this.handleLowRankToggle.bind(this);
		this.handleCustomScoreToggle = this.handleCustomScoreToggle.bind(this);
		this.handleExtraFieldsToggle = this.handleExtraFieldsToggle.bind(this);
	}
	componentDidMount() {
		if (this.state.query) {
			this.submitQuery({ query: this.state.query, url: this.state.url });
		}
	}
	handleSubmit(data) {
		if (!data.query) {
			this.setState({
				hasError: true,
			});
			notify.error('Missing query');
			return;
		}
		this.submitQuery(data);
	}
	async submitQuery(data) {
		const { showUnpublished, showOutOfStock, showPlaceholder, showLowRanks } = this.state;
		this.setState({
			query: data.query,
			url: data.url,
			hasError: false,
			isLoading: true,
		});
		const minRank = showLowRanks ? '0.4' : '';
		const json = await request(
			'POST',
			`/v0/findProductMatch?include_unpublished=${showUnpublished}&include_out_of_stock=${showOutOfStock}&include_placeholder=${showPlaceholder}&min_rank=${minRank}`,
			data
		);
		if (!json || json.status === 'error') {
			this.setState({
				hasError: true,
				isLoading: false,
			});
			return;
		}
		console.info('\n', 'Query:', data.query, '\n', 'Products:', ...json.data.products);
		this.setState({
			results: json.data.products,
			isLoading: false,
		});
		updateQueryString({ query: data.query, url: data.url });
	}
	handleExtraFieldsToggle(event) {
		this.setState({
			showExtraFields: event.target.checked,
		});
	}
	handlePublishedToggle(event) {
		this.setState({
			showUnpublished: event.target.checked,
		});
	}
	handleStockToggle(event) {
		this.setState({
			showOutOfStock: event.target.checked,
		});
	}
	handlePlaceholderToggle(event) {
		this.setState({
			showPlaceholder: event.target.checked,
		});
	}
	handleLowRankToggle(event) {
		this.setState({
			showLowRanks: event.target.checked,
		});
	}
	handleCustomScoreToggle(event) {
		this.setState({
			showCustomScores: event.target.checked,
		});
	}
	render() {
		const { query, url, results, hasError, isLoading, showCustomScores, showExtraFields } = this.state;
		return (
			<Fragment>
				<Page title="Search for matches">
					<Form onSubmit={this.handleSubmit}>
						<Field name="query" label="Title" isSimple defaultValue={query} />
						{showExtraFields && (
							<Field name="url" label="URL" isSimple defaultValue={url} />
						)}
						<Row>
							<Row>
								<ToggleSwitch
									label="Extra fields"
									defaultValue={showExtraFields}
									isInline
									onChange={this.handleExtraFieldsToggle}
								/>
								<ToggleSwitch
									label="Show unpublished?"
									name="include_unpublished"
									defaultValue={this.state.showUnpublished}
									isInline
									onChange={this.handlePublishedToggle}
								/>
								<ToggleSwitch
									label="Show out of stock?"
									name="include_out_of_stock"
									defaultValue={this.state.showOutOfStock}
									isInline
									onChange={this.handleStockToggle}
								/>
								<ToggleSwitch
									label="Show placeholders?"
									name="include_placeholder"
									defaultValue={this.state.showPlaceholder}
									isInline
									onChange={this.handlePlaceholderToggle}
								/>
								<ToggleSwitch
									label="Show all > 0.4"
									name="include_low_rank"
									defaultValue={this.state.showLowRanks}
									isInline
									onChange={this.handleLowRankToggle}
								/>
								<ToggleSwitch
									label="Custom scores"
									defaultValue={this.state.showCustomScores}
									isInline
									onChange={this.handleCustomScoreToggle}
								/>
							</Row>
							<Button type="submit">Search</Button>
						</Row>
						{showCustomScores && (
							<Row>
								<Field label="Name" isSimple name="custom_ranks[name]" defaultValue={1.0} />
								<Field label="Keyword" isSimple name="custom_ranks[keywords]" defaultValue={1.0} />
								<Field label="Category" isSimple name="custom_ranks[category]" defaultValue={0.5} />
								<Field label="Attribute" isSimple name="custom_ranks[attributes]" defaultValue={0.25} />
							</Row>
						)}
					</Form>
					{hasError && (<p>Error</p>)}
					{isLoading && (<p>Loading</p>)}
				</Page>
				{results && (
					<TablePage
						keyProp="product_id"
						headers={HEADERS}
						items={results}
						title="Results"
					/>
				)}
			</Fragment>
		);
	}
}
