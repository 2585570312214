.Tags {
	margin-top: 5px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	flex: 1;
}

.Tags__input,
.Tag {
	display: inline-block;
	font-size: 14px;
	padding: 3px 3px 3px 8px;
	margin: 0 5px 5px 0;
}
.Tag {
	border-radius: 25px;
	background: var(--gray1);
	cursor: pointer;
	user-select: none;
}
.Tag:hover {
	background: var(--gray2);
}
.Tag--is-selected,
.Tag--is-selected:hover {
	background: var(--green-lighter);
}
.Tag:after {
	content: '';
	display: inline-block;
	width: 1em;
	height: 1em;
	background-image: url('~/src/icons/plus.svg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 100% auto;
	vertical-align: middle;
	margin-left: 0.5em;
	margin-right: 0.25em;
	margin-top: -1px;
}
.Tag--is-selected:after {
	background-image: url('~/src/icons/check.svg');
	animation: force-checkmark 0.75s steps(2) backwards;
}
.Tag--is-selected:hover:after {
	background-image: url('~/src/icons/minus.svg');
}
.Tag__checkbox {
	display: none;
}
@keyframes force-checkmark {
	0%, 99% {
		background-image: url('~/src/icons/check.svg');
	}
}

.Tags__input {
	min-height: 0;
	min-width: 40px;
}