import notify from '~/src/util/notify';
import request from '~/src/util/request';
import { goTo } from '~/src/util/history';

export async function submitBatch(datasetId, batchId, answers) {
	const result = await request(
		'POST',
		`/v0/goldsifter/datasets/${datasetId}/batches/${batchId}`,
		{ answers },
		{ notify: false }
	);
	if (result.status === 'success') {
		const startNewSet = await startSet(datasetId, false);
		if (!startNewSet) {
			goTo('/goldsifter');
			notify('Completed dataset!');
		}
	} else {
		notify.error(result);
	}
}

export async function startSet(dataset_id, outputNotification=true) {
	const result = await request('POST', `/v0/goldsifter/datasets/${dataset_id}/claims`, undefined, { notify: false });
	if (result.status === 'success') {
		goTo(`/goldsifter/datasets/${result.data.dataset_id}/${result.data.batch_id}`);
		return true;
	} else {
		outputNotification && notify.error(result);
	}
	return false;
}
