import React, { useState } from 'react';

import request from '~/src/util/request';
import { useTemporaryMessage } from '~/src/util/useTemporaryMessage';
import { setClipboard } from '~/src/util/clipboard';
import { SectionTitle } from '~/src/lib/Text';
import { Page } from '~/src/lib/Page';
import { Button } from '~/src/lib/Buttons';
import { URLField } from '~/src/lib/Form';

import * as css from './CPCLinkCreator.css';

export const CPCLinkCreator = () => {
	const [message, setMessage] = useTemporaryMessage(3000);
	const [isLoading, setIsLoading] = useState(false);
	const [inputUrl, setInputUrl] = useState();
	const [generatedUrl, setGeneratedUrl] = useState();
	const handleChange = (value) => {
		setInputUrl(value);
	};
	const handleSubmit = async () => {
		setIsLoading(true);
		try {
			const result = await request(
				'POST',
				'/v0/cpc/create-shortlink',
				{ target_url: inputUrl }
			);
			if (result.ok) {
				setGeneratedUrl(result.data.url);
			}
		} finally {
			setIsLoading(false);
		}
	};
	const handleCopyUrl = async () => {
		await setClipboard(generatedUrl);
		setMessage('Copied!');
	};
	const isValid = validateUrl(inputUrl);
	return (
		<Page
			title="CPC Link Creator"
			isLoading={isLoading}
		>
			<div className={css.CPCLinkCreator}>
				<section className={css.CPCLinkCreator__input}>
					<SectionTitle>Link creator</SectionTitle>
					<URLField
						label="URL"
						defaultValue={inputUrl}
						onChange={handleChange}
					/>
					<br />
					<Button disabled={!isValid} variant="neutral" onClick={handleSubmit}>Generate CPC link</Button>
				</section>
				<section className={css.CPCLinkCreator__result}>
					{generatedUrl ? (
						<div className={css.Link} onClick={handleCopyUrl}>
							<div className={css.Link__label}>
								Click to copy
								{message ? (
									<span className={css.Link__message}>
										&nbsp;- {message}
									</span>
								) : null}
							</div>
							<div className={css.Link__url}>
								{generatedUrl}
							</div>
						</div>
					) : null}
				</section>
			</div>
		</Page>
	);
};

function validateUrl(inputUrl) {
	try {
		new URL(inputUrl);
		return true;
	} catch (error) {
		return false;
	}
}