import React from 'react';
import classnames from 'classnames';

import { noOrphan } from '~/src/util/format';
import { Link } from '~/src/lib/Buttons';

import * as css from './Dashboard.css';

export const Dashboard = ({ data }) => (
	<div className={css.Dashboard}>
		{data.map((item) => <DashboardItem key={item.name} {...item} />)}
	</div>
);

const DashboardItem = ({ name, data, color='purple', sub, category, url }) => {
	return (
		<Link to={url} className={classnames(css.DashboardItem, css[`DashboardItem--${color}`])}>
			{category ? (
				<div className={css.DashboardItem__category}>{category}</div>
			) : null}
			<div className={css.DashboardItem__title}>{noOrphan(name)}</div>
			<div className={css.DashboardItem__value}>{data}</div>
			{sub ? (
				<div className={css.DashboardItem__sub}>{sub}</div>
			) : null}
		</Link>
	);
};
