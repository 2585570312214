import React, { Fragment, useMemo, useState } from 'react';
import { subDays } from 'date-fns';
import capitalize from 'lodash/capitalize';
import orderBy from 'lodash/orderBy';

import { formatDate, formatQueryString } from '~/src/util/format';
import { useResource } from '~/src/util/useResource';
import { Spread } from '~/src/lib/Layout';
import { Page } from '~/src/lib/Page';
import { Button } from '~/src/lib/Buttons';
import { Stats } from '~/src/lib/Stats';
import { DateField } from '~/src/lib/Form';

import * as css from './MatchEvaluatorRecent.css';

const Help = () => (
	<Fragment>
		<p>The OK and Bad rows are sums of all OK & Bad, respectively.</p>
		<p>Click a bar’s label to view examples of each within the selected date range.</p>
		<p>The date range is inclusive, so data from both the start date and end date will be visible.</p>
	</Fragment>
);

const useStatsData = ({ startDate, endDate }) => {
	const statsResource = useResource(`/v0/match-evaluator/stats?startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(endDate)}`);
	const stats = useMemo(() => {
		if (!statsResource.data) {
			return null;
		}
		statsResource.data.forEach((item) => item.count = parseInt(item.count, 10))
		const total = statsResource.data.reduce((sum, item) => sum + item.count, 0);
		const max = statsResource.data.reduce((max, item) => Math.max(max, item.count), 0);
		const summedOK = statsResource.data.filter((item) => item.answer === 'ok').reduce((sum, item) => sum + item.count, 0);
		const summedBad = statsResource.data.filter((item) => item.answer === 'bad').reduce((sum, item) => sum + item.count, 0);
		const allItems = [
			...statsResource.data,
			statsResource.data.length ? {
				answer: 'ok',
				reason: null,
				count: summedOK,
				url: getItemUrl('ok', null, startDate, endDate),
			} : null,
			statsResource.data.length ? {
				answer: 'bad',
				reason: null,
				count: summedBad,
				url: getItemUrl('bad', null, startDate, endDate),
			} : null,
		].filter((exists) => exists);
		const data = allItems.map((item) => ({
			key: `${item.answer} ${item.reason}`,
			label: capitalize(item.answer),
			sublabel: item.reason,
			value: `${((item.count / total) * 100).toFixed(1)}%`,
			ratio: (item.count / max),
			count: item.count,
			url: item.url || getItemUrl(item.answer, item.reason, startDate, endDate),
		}));
		return {
			max,
			total,
			data: orderBy(data, ['count'], ['desc'])
		};
	}, [statsResource.data, startDate, endDate]);
	return {
		stats,
		isLoading: statsResource.isLoading,
		error: statsResource.error,
	};
};

export const MatchEvaluatorStats = () => {
	const [startDate, setStartDate] = useState(formatDate(subDays(new Date(), 7)));
	const [endDate, setEndDate] = useState(formatDate(new Date()));
	const { stats, isLoading, error } = useStatsData({ startDate, endDate });
	const queryString = formatQueryString({
		startDate,
		endDate,
	});
	return (
		<Page
			lead={<Help />}
			title="Match evaluator stats"
			isLoading={isLoading}
			error={error}
			actions={(
				<Fragment>
					<Spread />
					<Button icon="activity" variant="neutral" to={`/match-evaluator/recent?${queryString}`}>Recent</Button>
				</Fragment>
			)}
		>
			<div className={css.FilterBar}>
				<DateField
					label="Start date"
					name="startDate"
					description="Inclusive"
					value={startDate}
					onChange={(date) => date && setStartDate(date)}
				/>
				<DateField
					label="End date"
					name="endDate"
					description="Inclusive"
					value={endDate}
					onChange={(date) => date && setEndDate(date)}
				/>
			</div>
			{stats && (
				<Stats {...stats} />
			)}
		</Page>
	);
};

function getItemUrl(answer, reason=null, startDate, endDate) {
	const query = formatQueryString({
		answer,
		reason,
		startDate,
		endDate,
	});
	return `/match-evaluator/recent?${query}`;
}