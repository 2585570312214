.JSONPreview {
}
.JSONPreview pre {
	background: var(--gray1);
	border-radius: 7px;
	color: var(--black);
	font-family: monospace;
	font-size: 12px;
	tab-size: 2;
	line-height: 15px;
	overflow: hidden;
	padding: 15px 10px;
	margin: 0;
	cursor: text;
}
.JSONPreview--closed pre {
	max-height: 15px;
	cursor: pointer;
}

.JSONPreview__toggle {
	margin: 5px 10px 0;
	font-size: 14px;
	font-weight: var(--semibold);
	color: var(--gray4);
	cursor: pointer;
}