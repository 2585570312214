.TagsAndCertsEditor {
	min-height: 150px;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto auto auto;
	grid-template-areas: 'tags' 'certs' 'actions';
	flex-direction: column;
	align-items: center;
	justify-content: center;
	grid-gap: 20px;
	padding: 0 0 20px;
	cursor: pointer;
}
.TagsAndCertsEditor__title {
	flex: 100%;
	text-align: center;
}
.TagsAndCertsEditor__missing {
	flex: 100%;
	text-align: center;
}
.TagsAndCertsEditor__tags {
	background: var(--off-white);
	padding: 10px;
	border-radius: 5px;
	grid-area: tags;
	display: flex;
	grid-gap: 15px 10px;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
}
.TagsAndCertsEditor__certifications {
	background: var(--off-white);
	padding: 10px;
	border-radius: 5px;
	grid-area: certs;
	display: flex;
	grid-gap: 15px 10px;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
}
.TagsAndCertsEditor__cert {
	font-size: 12px;
	font-weight: var(--bold);
	color: var(--purple);
}
.TagsAndCertsEditor__cta {
	grid-area: actions;
	color: var(--blue-darker);
	text-decoration: underline;
	font-size: 20px;
	/*padding: 20px 0 20px;*/
	font-weight: var(--regular);
}