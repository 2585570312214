import React, { Fragment, useState, useEffect } from 'react';

import request from '~/src/util/request';
import { EditorField, BlocksEditor } from '~/src/lib/Editors';
import { Spread } from '~/src/lib/Layout';
import { ButtonBar, Button } from '~/src/lib/Buttons';
import { LabelledText, TimeAgo } from '~/src/lib/Text';

import * as css from './PageEditor.css';

const FIELDS = {
	slug: {
		name: 'slug',
		label: 'Slug',
		type: 'slug',
	},
	title: {
		name: 'title',
		label: 'Title',
		type: 'text',
		size: 'large',
	},
	pageTitle: {
		name: 'pageTitle',
		label: 'Page title',
		type: 'text',
	},
	metaTitle: {
		name: 'metaTitle',
		label: 'Meta title',
		type: 'text',
		description: 'Max 55 characters, appears as search result title',
		maxLength: 55,
	},
	metaDescription: {
		name: 'metaDescription',
		label: 'Meta description',
		type: 'textarea',
		size: 'normal',
		description: 'Up to 155 characters, appears in search results underneath the title',
		maxLength: 155,
	},
	posterImage: {
		name: 'posterImage',
		label: 'Poster image',
		type: 'image',
		description: 'Images should be in 16/9 aspect ratio, ideally at size 1360x765',
	}
};

export function usePageEditor({ endpoint, defaultSlug='', defaultTitle='', getPublicUrl }) {
	if (!endpoint) {
		throw new Error(`usePageEditor's endpoint is missing ("${endpoint}")`);
	}
	const [isLoading, setIsLoading] = useState(true);
	const [content, setContent] = useState([]);
	const [meta, setMeta] = useState({ slug: defaultSlug });
	const [hasLocalChanges, setHasLocalChanges] = useState(false);
	useEffect(() => {
		if (defaultSlug && !meta.slug) {
			setMeta({ ...meta, slug: defaultSlug });
		}
		if (defaultTitle && !meta.title) {
			setMeta({ ...meta, title: defaultTitle });
		}
	}, [defaultSlug, defaultTitle, meta]);
	const handleBlocksChange = (content) => {
		setHasLocalChanges(true);
		setContent(content);
	};
	const handleMetaChange = (name, data) => {
		setHasLocalChanges(true);
		setMeta({ ...meta, [name]: data });
	};
	const handleSubmit = () => {
		const data = {
			meta,
			content,
		};
		setIsLoading(true);
		return save(endpoint, data)
			.then(() => {
				setHasLocalChanges(false);
				setIsLoading(false);
			})
			.catch((error) => {
				// TODO: Deal with error
				console.warn(error);
				setIsLoading(false);
			})
		;
	};
	const handleLoad = () => {
		load(endpoint)
			.then((data) => {
				setContent(data.content);
				setMeta(data.meta);
				setIsLoading(false);
			})
			.catch((error) => {
				// TODO: Deal with error
				console.warn(error);
				setIsLoading(false);
			})
		;
	};
	const handleChangePublishState = async (type) => {
		if (hasLocalChanges) {
			await handleSubmit();
		}
		setIsLoading(true);
		return changePublishState(endpoint, type)
			.then(() => {
				setIsLoading(false);
				return handleLoad();
			})
			.catch((error) => {
				// TODO: Deal with error
				console.warn(error);
				setIsLoading(false);
			})
		;
	};
	useEffect(handleLoad, [endpoint]);

	return {
		content,
		meta,
		publishedAt: meta?.publishedAt || null,
		isLoading,
		hasLocalChanges,
		publicUrl: meta?.slug && getPublicUrl ? getPublicUrl(meta.slug) : null,
		onChangeBlocks: handleBlocksChange,
		onChangeMeta: handleMetaChange,
		onSubmit: handleSubmit,
		onChangePublishState: handleChangePublishState,
	};
}

export function ContentEditor({ editor, params }) {
	if (editor.isLoading) {
		return null;
	}
	return (
		<div className={css.ContentEditor}>
			<BlocksEditor
				onChange={editor.onChangeBlocks}
				content={editor.content}
				params={params}
			/>
		</div>
	);
}

export function MetaEditor({ editor, fields }) {
	if (editor.isLoading) {
		return null;
	}
	return (
		<div className={css.MetaEditor}>
			{getFields(fields).map((field) => (
				<EditorField
					key={field.name}
					field={field}
					item={editor.meta}
					onChange={editor.onChangeMeta}
				/>
			))}
		</div>
	);
}

function getFields(fieldKeys) {
	return fieldKeys.map((item) => {
		if (typeof item === 'string') {
			if (!(item in FIELDS)) {
				throw new Error(`Field "${item}" is unknown`);
			}
			return FIELDS[item];
		}
		if (item && 'name' in item) {
			return item;
		}
		console.info(item);
		throw new Error(`Invalid field ${item}`);
	});
}

export function PageEditorActions({ editor }) {
	return (
		<ButtonBar>
			<Button variant="neutral" onClick={editor.onSubmit}>Save</Button>
			<Spread />
			{editor.publishedAt ? (
				<Fragment>
					<LabelledText tight label="Published"><TimeAgo>{editor.publishedAt}</TimeAgo></LabelledText>
					<Button variant="yellow" onClick={() => editor.onChangePublishState('unpublish')}>Un-publish</Button>
				</Fragment>
			) : (
				<Button variant="green" onClick={() => editor.onChangePublishState('publish')}>Publish</Button>
			)}
		</ButtonBar>
	);
}

async function load(endpoint) {
	const response = await request('GET', endpoint);
	if (response.ok) {
		return response.data;
	}
	throw new Error(`Loading content failed: "${response.message}"`);
}

async function save(endpoint, data) {
	const response = await request('PUT', endpoint, data);
	if (response.ok) {
		return response.data;
	}
	throw new Error(`Saving content failed: "${response.message}"`);
}

async function changePublishState(rawEndpoint, data) {
	const endpoint = `${rawEndpoint}/publish`;
	const publish = data === 'publish';
	const response = await request(publish ? 'POST' : 'DELETE', endpoint);
	if (response.ok) {
		return response.data;
	}
	throw new Error(`Changing published state failed: "${response.message}"`);
}
