import React, { Fragment, useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import request from '~/src/util/request';
import { useResource } from '~/src/util/useResource';
import {
	Form,
	FormFade,
} from '~/src/lib/Form';
import { EditorField } from '~/src/lib/Editors';
import { Button, DangerButton } from '~/src/lib/Buttons';
import notify from '~/src/util/notify';

import { goTo, goUp } from '~/src/util/history';

// We're using a WeakMap here to keep track of the reference
// that needs to be passed into the Form element. By keeping
// it here we can avoid exposing it to the editor object,
// and keeping it in a WeakMap means it disappears as soon
// as our editor object disappears.
const REF_MAP = new WeakMap();

export const useEditor = ({
	endpoint, deleteEndpoint=endpoint, updateEndpoint=endpoint,
	loadEndpoint=endpoint, createEndpoint=endpoint,
	fields=[], allowDelete=false, isNew=false, newItem=null,
	onSuccess=0, onDelete=-1, onChange, refresh: externalRefresh,
}) => {
	const history = useHistory();
	const { isLoading, error, data, refresh } = useResource(isNew ? null : loadEndpoint, { revalidateOnFocus: false });
	const item = useItem({ isNew, newItem, data, fields });
	const formRef = useRef();
	const handleSubmit = async () => {
		const method = isNew ? 'POST' : 'PUT';
		const endpoint = isNew ? createEndpoint : updateEndpoint;
		if (!endpoint) {
			throw new Error(`Invalid endpoint "${endpoint}"`);
		}
		const payload = formRef.current.getFormData();
		const response = await request(method, endpoint, payload);
		if (response.status === 'success') {
			if (isNew) {
				// The refresh parameter allows for a custom refresh handler
				// in case the resource posted to is not the same loaded from
				if (externalRefresh) {
					externalRefresh();
				} else {
					refresh();
				}
				if (history.location.pathname.endsWith('/new') && !onSuccess) {
					// We have created an item, so let's redirect to it if we can
					if (response.data.id) {
						triggerCallback(history.location.pathname.replace('new', response.data.id));
					} else {
						triggerCallback(history.location.pathname.replace('/new', ''));
					}
				} else {
					triggerCallback(onSuccess, response.data || item);
					if (!response?.data?.message) {
						notify('Saved');
					}
				}
			} else {
				triggerCallback(onSuccess, item);
				if (!response?.data?.message) {
					notify('Saved');
				}
				// When updating, we mutate the item directly
				refresh({ ...item, ...payload });
			}
		}
	};
	const handleDelete = async () => {
		const result = await request('DELETE', deleteEndpoint);
		if (result.status === 'success') {
			triggerCallback(onDelete, item);
			notify(result.data && result.data.message || 'Deleted');
		}
	};
	const editor = {
		isLoading: !isNew && isLoading,
		error,
		isNew,
		allowDelete,
		handleSubmit,
		handleDelete,
		fields,
		item,
		onChange: (key, value) => {
			if (onChange) {
				onChange(key, value);
			}
		},
	};
	REF_MAP.set(editor, formRef);
	return editor;
};

function getQueryPayload(fields) {
	const url = new URL(window.location.href);
	const rawParams = url.searchParams.get('item');
	if (!rawParams) {
		return null;
	}
	const params = JSON.parse(rawParams);
	return fields
		// Only pick fields with defined names
		.filter((field) => field.name)
		// Pick the name and the param value
		.map((field) => [field.name, params[field.name]])
		// Remove undefined params
		.filter(([, value]) => typeof value !== 'undefined')
		// Bundle it into an object
		.reduce((out, [key, value]) => {
			out[key] = value;
			return out;
		}, Object.create(null))
	;
}

export const getEditorActions = (editor) => {
	return (
		<Fragment>
			<Button onClick={editor.handleSubmit}>Save</Button>
			{editor.allowDelete && <DangerButton onClick={editor.handleDelete}>Delete</DangerButton>}
		</Fragment>
	);
};

export const ProtoEditor = ({ editor }) => {
	// The hidden submit button is needed for the form
	// to be possible to submit using the return key
	return (
		<Form onSubmit={editor.handleSubmit} isLoading={editor.isLoading} ref={REF_MAP.get(editor)}>
			<FormFade>
				{editor.isLoading ? null : (
					<ProtoEditorFields
						fields={editor.fields}
						item={editor.item}
						onChange={editor.onChange}
					/>
				)}
			</FormFade>
			<button type="submit" style={{ position: 'absolute', pointerEvents: 'none', opacity: 0 }} />
		</Form>
	);
};

export const ProtoEditorFields = ({ fields, item, onChange }) => {
	return (
		<Fragment>
			{fields.map((field) => field ? (
				<EditorField
					key={field.key || field.name || field.label}
					field={field}
					item={item}
					onChange={onChange}
				/>
			) : null)}
		</Fragment>
	);
}

function useItem({ isNew, newItem, data, fields }) {
	const [hasSeenNotification, setHasSeenNotification] = useState(false);
	const queryPayload = getQueryPayload(fields);
	// Use data from the `item` query parameter as the default item if undefined
	const item = (isNew ? newItem || queryPayload : data) || {};
	// If we have a query payload and it's not new, we need to update the item...
	if (queryPayload && !isNew) {
		Object.assign(item, queryPayload);
	}
	// ...in which case we send a notification about it as well
	useEffect(() => {
		if (queryPayload && !hasSeenNotification && !isNew) {
			setHasSeenNotification(true);
			notify({
				duration: 10000,
				message: `The following properties were updated: "${Object.keys(queryPayload).join(', ')}", press save to save changes`,
			});
		}
	}, [queryPayload, hasSeenNotification, isNew]);
	return item;
}

function triggerCallback(callback, item) {
	if (typeof callback === 'string') {
		return goTo(callback);
	} else if (typeof callback === 'number') {
		return goUp(callback);
	} else if (typeof callback === 'function') {
		const result = callback(item);
		if (typeof result === 'string') {
			return goTo(result);
		}
	}
}