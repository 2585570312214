.SearchInspector h2 {
	margin-bottom: 1em;
}
.SearchInspector section {
	margin-bottom: 50px;
}
.ProductGrid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-gap: 40px 20px;
}
.Product {
	background: white;
	border-radius: 5px;
	overflow: hidden;
	padding: 5px;
	display: flex;
	flex-direction: column;
	align-items: stretch;
}
.Product__image {
}
.Product__image img {
	aspect-ratio: 0.8;
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.Product__title {
	text-decoration: none;
	padding-top: 10px;
	font-size: 15px;
	font-weight: var(--bold);
	margin-bottom: 10px;
}
.Product__image:hover + .Product__title,
.Product__title:hover {
	text-decoration: underline;
}
.Product__price {
	margin-top: auto;
	font-size: 13px;
	font-weight: var(--bold);
}