.MatchSplit {
	display: flex;
}
.MatchSplit > *:first-child {
	border-right: 1px solid var(--light-gray);
}
.MatchPreview {
	flex: 50%;
	text-align: center;
	padding: 0 20px;
}
.MatchPreview__title {
	font-size: 18px;
	line-height: 20px;
	font-weight: var(--bold);
}
.MatchPreview__titleId {
}
.MatchPreview__id {
	font-size: 14px;
	line-height: inherit;
	color: var(--gray4);
	margin: 5px auto 0;
	line-height: 15px;
}
.MatchPreview__idLink {
	color: inherit;
}
.MatchPreview__image {
	height: 160px;
	width: 160px;
	margin: 10px auto 35px;
	position: relative;
}
.MatchPreview__image img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}
.MatchPreview__heading {
	font-size: 20px;
	font-weight: var(--bold);
	line-height: 25px;
}
.MatchPreview__editLink {
	font-size: 14px;
	color: var(--gray2);
	margin-left: 0.25em;
	position: absolute;
	line-height: inherit;
}
.MatchPreview__link {
	margin-top: 20px;
	display: inline-block;
	color: var(--gray4);
	text-decoration: none;
	font-size: 12px;
	word-break: break-all;
	margin-top: auto;
}
.MatchPreview__link:after {
	content: '';
	display: inline-block;
	--size: 10px;
	height: var(--size);
	width: var(--size);
	background-image: url('~/src/icons/external-link.svg');
	opacity: 0.4;
	background-size: var(--size) var(--size);
	background-position: center center;
	background-repeat: no-repeat;
	margin-left: 4px;
	position: relative;
	top: 1px;
}
.MatchPreview__link:hover {
	color: var(--gray4);
	text-decoration: underline;
}

.ReasonPopover {
	position: absolute;
	width: 240px;
	height: auto;
	bottom: 50px;
	left: 40%;
	z-index: 100;
	border-radius: 7px;
	overflow: hidden;
	box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
	background: var(--white);
	counter-reset: items;
	border: 1px solid var(--gray1);
	animation: popover-intro 150ms ease;
}
.ReasonPopover__title {
	font-size: 16px;
	line-height: 40px;
	font-weight: var(--semibold);
	border-bottom: 1px solid var(--gray1);
	text-align: center;
}
.ReasonPopover__list {
	list-style: none;
	padding: 0;
	margin: 0;
}
.ReasonPopover__item {
	padding: 0 16px;
	font-size: 14px;
	font-weight: var(--bold);
	line-height: 20px;
	min-height: 32px;
	margin: 4px;
	border-radius: 5px;
	display: flex;
	align-items: center;
	counter-increment: items;
}
.ReasonPopover__item:hover {
	background: var(--gray1);
	cursor: pointer;
}
.ReasonPopover__item:before {
	content: counter(items);
	display: inline-block;
	color: var(--gray4);
	font-weight: var(--regular);
	margin-right: 10px;
}
.ReasonPopover__otherItem {
	border-top: 1px solid var(--gray1);
}
.ReasonPopover__otherForm {
	display: flex;
	align-items: stretch;
	justify-content: stretch;
}
.ReasonPopover__otherInput {
	flex: 1;
	line-height: 40px;
	font-size: 14px;
	color: var(--gray6);
	font-weight: var(--regular);
	padding: 0 16px;
	border: none;
	outline: none;
}
.ReasonPopover__otherButton {
	border: none;
	background: none;
	text-indent: -999px;
	width: 40px;
	background-image: url('~/src/icons/submit-arrow.svg');
	background-size: 16px auto;
	background-position: center center;
	background-repeat: no-repeat;
	opacity: 0.75;
	transition: opacity 200ms ease;
}
.ReasonPopover__otherButton:hover {
	cursor: pointer;
	opacity: 1;
}
.ReasonPopover__otherInput:placeholder-shown + .ReasonPopover__otherButton {
	opacity: 0;
}
@keyframes popover-intro {
	0% {
		opacity: 0;
		transform: scale(0.8) translateY(30px);
	}
	70% {
		opacity: 1;
	}
}

.BigPop {
	position: absolute;
	top: 200px;
	left: 50%;
	text-align: center;
	font-size: 80px;
	line-height: 0.9;
	transform: translate(-50%, -50%);
	pointer-events: none;
	animation: big-pop 300ms ease both;
	transform-origin: 0 0;
}
@keyframes big-pop {
	0% {
		opacity: 0;
		transform: scale(0.5) translate(-50%, -50%);
	}
	20% {
		opacity: 1;
		transform: scale(1) translate(-50%, -50%);
	}
	70% {
		opacity: 1;
	}
	90% {
		opacity: 0;
	}
	100% {
		transform: scale(1.5) translate(-50%, -50%);
		opacity: 0;
	}
}
