.Stats {
	display: grid;
	grid-template-columns: auto 1fr;
	grid-template-rows: auto;
	grid-template-areas: 'legends bars';
	--row-height: 40px;
	--row-gap: 10px;
}
.Legends,
.Bars {
	display: grid;
	grid-template-columns: 1fr;
	grid-row-gap: var(--row-gap);
}
.Legends {
	grid-area: legends;
}
.Bars {
	display: grid;
}
.Legend,
.Bar {
	height: var(--row-height);
	display: flex;
	align-items: center;
	padding: 0 15px;
	box-sizing: border-box;
}
.Legend {
	background: var(--gray1);
	border-radius: 4px 0 0 4px;
}
.Bar {
	background: var(--gray2);
	font-weight: var(--bold);
	border-radius: 0 4px 4px 0;
}
