import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Page } from '~/src/lib/Page';
import { useResource } from '~/src/util/useResource';
import request from '~/src/util/request';
import notify from '~/src/util/notify';
import { AutoLoader } from '~/src/lib/AutoLoader';

import {
	validateSelectedOptions,
} from './common';

import { PendingProduct } from './PendingProduct';

export const ImportProduct = ({ match }) => {
	const history = useHistory();
	const importId = match.params.import_id;
	const pageScanId = match.params.page_scan_id;
	useEffect(() => {
		if (!importId) {
			throw new Error('Missing importId');
		}
		if (!pageScanId) {
			request('POST', `/v0/product-importer/imports/${importId}/products`)
				.then((response) => {
					if (response.data.done) {
						notify('No more pending imports');
						history.push('/product-imports');
					} else if (response.data.id) {
						history.push(`/product-imports/imports/${importId}/products/${response.data.id}`);
					} else {
						throw new Error(`Unknown data from import endpoint: ${Object.keys(response.data)}`);
					}
				})
				.catch((error) => {
					notify.error(error);
				})
			;
		}
	}, [importId, pageScanId, history]);
	if (pageScanId) {
		return <ImportProductStep2 importId={importId} pageScanId={pageScanId} />;
	}
	return 'Loading...';
};

export const ImportProductStep2 = ({ importId, pageScanId }) => {
	const history = useHistory();
	const ENDPOINT = `/v0/product-importer/products/${pageScanId}`;
	const { isLoading, error, data, refresh } = useResource(ENDPOINT);
	const pendingProduct = data && data.pendingProduct;
	console.log('pendingProduct', pendingProduct);
	const handleSkip = async () => {
		const response = await request('POST', '/v0/product-importer/skipped', { page_scan_id: pendingProduct.page_scan_id });
		if (response.ok) {
			history.push(`/product-imports/imports/${importId}/products`);
		}
	};
	const handleReject = async () => {
		const response = await request('POST', '/v0/product-importer/rejections', { page_scan_id: pendingProduct.page_scan_id });
		if (response.ok) {
			history.push(`/product-imports/imports/${importId}/products`);
		}
	};
	const handleSave = async (selectedProperties) => {
		// Validate
		const isValid = validateSelectedOptions(selectedProperties);
		if (!isValid) {
			console.warn('Data not valid');
			return;
		}
		// Submit to API
		const result = await request('PUT', ENDPOINT, {
			selectedProperties,
		});
		// TODO: Handle errors
		console.log('approve product result', result);
		if (result.ok) {
			// Refresh in case it's being loaded later
			refresh();
			history.push(`/product-imports/imports/${importId}/products`);
		}
	};
	const handlePublish = async (selectedProperties) => {
		const isValid = validateSelectedOptions(selectedProperties);
		if (!isValid) {
			console.warn('Data not valid');
			return;
		}
		const result = await request('POST', '/v0/product-importer/products', {
			page_scan_id: pendingProduct.page_scan_id,
			// pendingProduct,
			selectedProperties,
		});
		if (result.ok) {
			history.push('/product-imports/ready');
		}
	};
	return (
		<Page
			title="Product import"
			contentWidth="medium"
			backLink="/product-imports"
		>
			<AutoLoader error={error} isLoading={isLoading}>
				{data && data.pendingProduct ? (
					<PendingProduct
						product={data.pendingProduct}
						isReady={pendingProduct.state === 'READY'}
						onSkip={handleSkip}
						onReject={handleReject}
						onSave={handleSave}
						onPublish={handlePublish}
					/>
				) : (
					<div>No pending products</div>
				)}
			</AutoLoader>
		</Page>
	);
};
