import React, { useState } from 'react';
import classnames from 'classnames';

import * as css from './Expander.css';

export const Expander = ({ children, title, initial=false }) => {
	const [isExpanded, setIsExpanded] = useState(initial);
	return (
		<div className={css.Expander}>
			<div className={css.Expander__header} onClick={() => setIsExpanded(!isExpanded)}>
				<h2 className={css.Expander__title}>{title}</h2>
				<span className={css.Expander__prompt}>{isExpanded ? 'Click to expand' : 'Click to close'}</span>
				<span className={classnames(css.Expander__icon, isExpanded ? css['Expander__icon--close'] : css['Expander__icon--open'])} />
			</div>
			{isExpanded && (
				<div className={css.Expander__contents}>
					{children}
				</div>
			)}
		</div>
	);
};