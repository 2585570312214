import React, { Fragment } from 'react';
import { parse as parseQueryString } from 'query-string';
import omit from 'lodash/omit';

import request from '~/src/util/request';
import pick from '~/src/util/pick';
import flattenTree from '~/src/util/flattenTree';
import { createItemFromScrape } from '~/src/util/scrapes';
import { Page } from '~/src/lib/Page';
import { TablePage, Table } from '~/src/lib/Table';
import { Red } from '~/src/lib/Text';
import Resource from '~/src/lib/Resource';
import { Button, Link } from '~/src/lib/Buttons';
import { Field, URLField, CopyURLField, Hidden, ToggleSwitch, Select, InputRow, MultiSelect, MultiSelectInput, MultiInputField, MultiInput, TextArea } from '~/src/lib/Form';
import Editor from '~/src/lib/Editor';
import { FilterBar, useFilter } from '~/src/lib/FilterBar';
import { useResource } from '~/src/util/useResource';
import { AutoLoader } from '~/src/lib/AutoLoader';

import { descriptions, labels } from '~/src/content/productFields';

const EDITLOG_HEADERS = [{
	label: 'User',
	key: 'email',
}, {
	label: 'Action',
	key: 'action',
}, {
	label: 'Timestamp',
	key: 'created_at',
}];

const QUERIES_HEADERS = [{
	label: 'ID',
	key: 'query_id',
}, {
	label: 'Rank',
	key: 'rank',
}, {
	label: 'Query text',
	key: 'query_text',
	isWide: true,
}, {
	key: 'view',
	renderItem: (item) => (
		<Button to={`/querylog/${item.query_id}`}>Inspect</Button>
	)
}];

const Actions = () => (
	<Fragment>
		<Button to="/products/new">Create new</Button>
		<Button variant="secondary" to="/products-search">Search for products</Button>
	</Fragment>
);

export const Products = ({ endpoint='/v0/products', getTitle=null, title, actions }) => {
	const filter = useFilter();
	const { error, isLoading, data } = useResource(`${endpoint}?filter=${filter}`);
	const headers = getHeaders(filter);
	return (
		<Page
			title={getTitle ? getTitle(data?.meta, title) : title || 'Products'}
			actions={actions || <Actions />}
			contentWidth="wide"
		>
			<AutoLoader error={error} isLoading={isLoading}>
				<FilterBar
					filters={[{
						key: 'unpublished',
						label: 'Unpublished',
					}, {
						key: 'published',
						label: 'Published',
					}, {
						key: 'in-stock',
						label: 'In stock',
					}, {
						key: 'out-of-stock',
						label: 'Out of stock',
					}]}
				/>
				<Table
					keyProp="product_id"
					headers={headers}
					items={data}
				/>
			</AutoLoader>
		</Page>
	);
};

function getHeaders(filter) {
	const filterForUnpublished = filter === 'unpublished';
	return [{
		label: 'ID',
		key: 'product_id',
	},
	{
		label: 'Product',
		key: 'title',
		isWide: true,
	},
	filterForUnpublished ? null : {
		label: 'Published?',
		key: 'is_published',
		renderItem: (item) => item.is_published ? 'Published' : (<Red>Not published</Red>),
	},
	filterForUnpublished ? null : {
		label: 'In stock?',
		key: 'is_in_stock',
		renderItem: (item) => item.is_in_stock ? 'In stock' : (<Red>Out of stock</Red>),
	},
	filterForUnpublished ? {
		label: 'Unpublish reason',
		key: 'unpublish_reason',
		isWide: true,
	} : null,
	{
		key: 'edit',
		renderItem: (item) => (
			<Button to={`/products/${item.product_id}`}>Edit</Button>
		)
	}].filter((exists) => exists);
}

export const QueriesByProduct = ({ match }) => (
	<Resource endpoint={`/v0/products/${match.params.id}/queries`}>
		{({ data }) => (
			<TablePage
				keyProp="query_id"
				headers={QUERIES_HEADERS}
				items={data.queries}
				title={`Queries for product "${data.product.name}"`}
			/>
		)}
	</Resource>
);

export const ProductsByCategory = (props) => (
	<Products
		{...props}
		endpoint={`/v0/categories/${props.match.params.id}/products`}
		getTitle={(meta) => meta && meta.category && meta.category.name ? `Products in ${meta.category.name}` : 'Products by category'}
		actions={
			<Fragment>
				<Button to={`/categories/${props.match.params.id}`}>Edit category</Button>
				<Button variant="neutral" to={`/products/new?category=${props.match.params.id}`}>New product</Button>
			</Fragment>
		}
	/>
);

export const ProductsBySellerSite = (props) => (
	<Products
		{...props}
		endpoint={`/v0/sites/${props.match.params.id}/products`}
		getTitle={(meta) => meta && meta.site && meta.site.domain ? `Products from ${meta.site.domain}` : 'Products by site'}
		actions={
			<Fragment>
				<Button to={`/sites/${props.match.params.id}`}>Edit site</Button>
			</Fragment>
		}
	/>
);

export const ViewProductEdits = ({ match }) => (
	<Resource endpoint={`/v0/products/${match.params.id}/edits`}>
		{({ data }) => (
			<TablePage
				keyProp="id"
				headers={EDITLOG_HEADERS}
				items={data}
				title="Edits"
			/>
		)}
	</Resource>
);

export const CreateProductFromExisting = (props) => {
	return (
		<Resource endpoint={`/v0/products/${props.match.params.id}`}>
			{({ data }) => (
				<CreateBlankProduct title="Duplicate product" itemBase={duplicateExistingItem(data)} />
			)}
		</Resource>
	);
};

export const CreateProduct = (props) => {
	const query = parseQueryString(props.location.search);
	const isBasedOnScrape = 'scrape_id' in query && query.scrape_id;
	if (!isBasedOnScrape) {
		return <CreateBlankProduct {...props} itemBase={{ category_id: query.category || null }} />;
	}
	return (
		<Resource endpoint={`/v0/scrapes/${query.scrape_id}`}>
			{({ data }) => (
				<CreateBlankProduct itemBase={createItemFromScrape(data)} />
			)}
		</Resource>
	);
};

export const CreateBlankProduct = ({ title, itemBase }) => (
	<Editor
		title={title || 'Create product'}
		submitEndpoint="/v0/products"
		method="POST"
		onSuccess={redirectToItem}
		newItem={itemBase || true}
	>
		{(item) => (
			<SharedFields item={item} />
		)}
	</Editor>
);

export const EditProduct = ({ match }) => (
	<Editor
		title="Edit product"
		getTitle={(item) => `Editing ${item.name}`}
		submitEndpoint={`/v0/products/${match.params.id}`}
		method="PUT"
		loadEndpoint={`/v0/products/${match.params.id}`}
		allowDelete
		onDelete="/products"
		actions={(item) => (
			<Fragment>
				<Button variant="neutral" to={`/sites/${item.site_id}`}>View site</Button>
				<Button variant="secondary" to={`/products/${match.params.id}/duplicate`}>Duplicate</Button>
				<Button variant="secondary" to={`/products/${match.params.id}/queries`}>View queries</Button>
				<Link variant="subtle" to={`/products/${match.params.id}/edits`}>View edits</Link>
			</Fragment>
		)}
	>
		{(item) => (
			<SharedFields item={item} />
		)}
	</Editor>
);

const SharedFields = ({ item }) => (
	<Fragment>
		{item.product_id && (<Hidden name="product_id" description={descriptions.product_id} value={item.product_id} />)}
		{item.site_id && (<Hidden name="site_id" description={descriptions.site_id} value={item.site_id} />)}
		{(item.scrape_source_id || item.scrape_id) && (<Hidden name="scrape_source_id" description={descriptions.scrape_source_id} value={item.scrape_source_id || item.scrape_id} />)}
		<URLField name="canonical_url" label={labels.canonical_url} description={descriptions.canonical_url} defaultValue={item.canonical_url} />
		<CopyURLField name="affiliate_url" label={labels.affiliate_url} description={descriptions.affiliate_url} value={item.affiliate_url} />
		<Field name="name" label={labels.name} description={descriptions.name} defaultValue={item.name} />
		<Field name="title" label={labels.title} description={descriptions.title} defaultValue={item.title} />
		<ToggleSwitch name="is_in_stock" label={labels.is_in_stock} description={descriptions.is_in_stock} defaultValue={item.is_in_stock} legends={{ on: 'In Stock', off: 'Out Of Stock' }} />
		<ToggleSwitch name="is_published" label={labels.is_published} description={descriptions.is_published} defaultValue={item.is_published} legends={{ on: 'Published', off: 'Not published' }} />
		<Field name="unpublish_reason" label={labels.unpublish_reason} description={descriptions.unpublish_reason} defaultValue={item.unpublish_reason} />
		<Field prefix="£" name="price" label={labels.price} description={descriptions.price} defaultValue={item.price ? item.price.amount : ''} />
		<URLField name="manufacturer_product_url" label={labels.manufacturer_product_url} description={descriptions.manufacturer_product_url} defaultValue={item.manufacturer_product_url} />
		<URLField name="image_source_url" label={labels.image_source_url} description={descriptions.image_source_url} defaultValue={item.image_source_url} />
		<TextArea name="description" label={labels.description} description={descriptions.description} defaultValue={item.description} />
		<Resource endpoint="/v0/categories?format=tree" dataMapper={createCategoryOptions}>
			{({ data }) => (
				<Select
					name="category_id"
					label={labels.category_id}
					description={descriptions.category_id}
					defaultValue={item.category_id}
					options={data}
					subLabel={item.category_id ? <Link to={`/categories/${item.category_id}`} target="_blank" variant="prominent">View</Link> : null}
				/>
			)}
		</Resource>
		<Resource endpoint="/v0/brands" dataMapper={createBrandOptions}>
			{({ data }) => (
				<Select
					name="brand_id"
					label={labels.brand_id}
					description={descriptions.brand_id}
					defaultValue={item.brand_id}
					options={data}
					allowInsert={{
						onInsert: createBrand,
						label: 'Create new brand'
					}}
				/>
			)}
		</Resource>
		<Resource endpoint="/v0/certifications" dataMapper={createCertificationOptions}>
			{({ data }) => (
				<MultiSelect
					name="certifications"
					label={labels.certifications}
					description={descriptions.certifications}
					selections={pick(item.certifications, 'id')}
					options={data}
				/>
			)}
		</Resource>
		<Resource endpoint="/v0/tags" dataMapper={createTagOptions}>
			{({ data }) => (
				<MultiSelect
					name="tags"
					label={labels.tags}
					description={descriptions.tags}
					selections={pick(item.tags, 'id')}
					options={data}
				/>
			)}
		</Resource>
		<Attributes
			item={item}
			label={labels.attributes}
			description={descriptions.attributes}
		/>
		<MultiInputField
			name="keywords"
			label={labels.keywords}
			description={descriptions.keywords}
			items={item.keywords}
			getValue={({ name }) => name}
		/>
	</Fragment>
);

const Attributes = ({ item, label, description }) => {
	const itemAttributeMap = (item.attributes || []).reduce((keep, attr) => {
		keep[attr.type] = attr.values;
		return keep;
	}, {})
	return (
		<Resource endpoint="/v0/product-attributes/types">
			{({ data }) => (
				<Field noFocusStyle tag="div" label={label} description={description}>
					{data.map((attribute) => (
						<InputRow key={attribute.type} label={`${attribute.name}:`}>
							{attribute.allowed_values ? (
								<MultiSelectInput
									name={`attributes[${attribute.type}]`}
									selections={itemAttributeMap[attribute.type]}
									options={attribute.allowed_values.map((value) => ({ label: value, value: value }))}
								/>
							) : (
								<MultiInput
									name={`attributes[${attribute.type}]`}
									label={labels.attributes}
									description={descriptions.attributes}
									items={itemAttributeMap[attribute.type]}
									placeholder=""
								/>
							)}
						</InputRow>
					))}
				</Field>
			)}
		</Resource>
	);
}

const duplicateExistingItem = (item) => {
	return omit(item, 'product_id');
};

function createTagOptions(items) {
	return items
		.map((item) => ({
			label: item.name,
			value: item.tag_id,
		}))
	;
}

function createCertificationOptions(items) {
	return items
		.map((item) => ({
			label: item.name,
			value: item.certification_id,
		}))
	;
}

function createCategoryOptions(items) {
	return flattenTree(items)
		.map((item) => ({
			group: item.parents.map((parent) => parent.name).join(' / '),
			label: item.name,
			value: item.category_id,
			search: [...item.parents.map((parent) => parent.name), item.name].join(' ').toLowerCase(),
		}))
	;
}

function createBrandOptions(items) {
	return items
		.map((item) => ({
			label: item.name,
			value: item.brand_id,
			search: item.name.toLowerCase(),
		}))
	;
}

function redirectToItem(item) {
	return `/products/${item.product_id}`;
}

async function createBrand(brandName) {
	const result = await request('POST', '/v0/brands', { name: brandName });
	if (result.status === 'success') {
		return {
			label: result.data.name,
			value: result.data.brand_id,
		};
	}
	return null;
}
