import React, { useState } from 'react';

import request from '~/src/util/request';
import notify from '~/src/util/notify';
import { Link } from '~/src/lib/Buttons';
import { TagIcon } from '~/src/lib/TagIcon';
import { CertificationIcon } from '~/src/lib/CertificationIcon';

import * as css from './TagsAndCertsEditor.css';

export const TagsAndCertsEditor = ({ onChange, defaultValue={}, params={} }) => {
	const [tagsAndCerts, setTagsAndCerts] = useState(defaultValue);
	const hasTags = tagsAndCerts?.tags?.length;
	const hasCerts = tagsAndCerts?.certifications?.length;
	const handleChange = (data) => {
		setTagsAndCerts(data);
		onChange(data);
	};
	const runImport = async () => {
		const { tags, certifications } = await loadTagsAndCerts(params);
		if (!(tags.length || certifications.length)) {
			notify('No tags or certifications found');
		} else {
			handleChange({
				tags,
				certifications,
			});
		}
	};
	return (
		<div className={css.TagsAndCertsEditor}>
			<div className={css.TagsAndCertsEditor__tags}>
				<h4 className={css.TagsAndCertsEditor__title}>Tags</h4>
				{hasTags ? (
					tagsAndCerts.tags.map((tag) => (
						<TagIcon
							key={tag.tag_id}
							id={tag.tag_id}
							name={tag.name}
						/>
					))
				) : (
					<span className={css.TagsAndCertsEditor__missing}>No tags</span>
				)}
			</div>
			<div className={css.TagsAndCertsEditor__certifications}>
				<h4 className={css.TagsAndCertsEditor__title}>Certifications</h4>
				{hasCerts ? (
					tagsAndCerts.certifications.map((certification) => (
						<CertificationIcon
							key={certification.certification_id}
							id={certification.certification_id}
							name={certification.name}
						/>
					))
				) : (
					<span className={css.TagsAndCertsEditor__missing}>No certifications</span>
				)}
			</div>
			<Link
				className={css.TagsAndCertsEditor__cta}
				onClick={runImport}
			>
				{(hasTags || hasCerts) ? 'Reload tags & certifications' : 'Import tags & certifications from products'}
			</Link>
		</div>
	);
};

async function loadTagsAndCerts({ tagsEndpoint, certificationsEndpoint }) {
	const [tags, certifications] = await Promise.all([
		request('GET', tagsEndpoint),
		request('GET', certificationsEndpoint),
	]);
	return {
		tags: tags.data.tags,
		certifications: certifications.data.certifications,
	};
}
