.Banner {
	padding: 30px 0;
	z-index: 20;
	background: var(--white);
	margin-bottom: 40px;
	border-radius: 11px;
	box-shadow: var(--wide-shadow);
}
.Banner__content {
	padding: 0 40px;
	display: flex;
	align-items: center;
}
.Banner__text {
	margin-right: auto;
	font-size: 25px;
	line-height: 25px;
	font-weight: var(--semibold);
}
.Banner__actions {
	margin-left: auto;
	display: flex;
	align-items: center;
}