import React from 'react';

import * as css from './Code.css';

function formatJSON(json) {
	return JSON.stringify(json, null, '\t');
}

const Code = ({ children, type }) => (
	<code className={css.Code}>
		<pre>
			{type === 'json' ? formatJSON(children) : children}
		</pre>
	</code>
);

export default Code;
