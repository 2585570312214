.TransparentInput {
	display: inline-block;
	font-size: inherit;
	font-family: inherit;
	line-height: inherit;
	outline: none;
	box-shadow: none;
	border: none;
	flex: 1;
	background: transparent;
	border-radius: 4px;
	padding: 5px;
	margin: -5px;
}
.TransparentInput--has-focus-style:focus {
	background: var(--off-white);
}
.TransparentInput:focus {
}
.TransparentInput::placeholder {
	color: var(--gray3);
}

.TransparentSelect {
	position: relative;
	display: flex;
	align-items: center;
	cursor: pointer;
}
.TransparentSelect__input {
	display: none;
}
.TransparentSelect__value {
}
.TransparentSelect__placeholder {
	color: var(--gray3);
}
.TransparentSelect__options {
	z-index: var(--index-dropdown);
	position: absolute;
	top: 0;
	transform: translateY(-30%);
	background: var(--white);
	border-radius: 4px;
	box-shadow: var(--card-shadow), var(--wide-shadow), var(--tight-shadow);
	border: 1px solid var(--secondary-gray);
	padding: 5px 0;
}
.TransparentSelect__blankOption,
.TransparentSelect__option {
	white-space: nowrap;
	line-height: 1.8em;
	padding: 0 10px;
	display: flex;
	align-items: center;
	cursor: pointer;
}
.TransparentSelect__blankOption:hover,
.TransparentSelect__option:hover {
	background: var(--secondary-gray);
}
.TransparentSelect__blankOption:before {
	content: '';
	display: inline-block;
	width: 1em;
	height: 1em;
	margin-right: 0.2em;
	background-image: url('~/src/icons/cancel.svg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 0.8em auto;
}