import React from 'react';

import { Link } from '~/src/lib/Buttons';
import iconPublished from '~/src/icons/published.svg';
import iconUnpublished from '~/src/icons/unpublished.svg';

import * as css from './ServicesList.css';

export const ServicesList = ({ services }) => {
	if (!services) {
		return null;
	}
	return (
		<ul className={css.ServicesList}>
			{services.map((item) => (
				<ServicesItem key={item.id} service={item} />
			))}
		</ul>
	);
};

const ServicesItem = ({ service }) => {
	console.log(service);
	return (
		<li className={css.ServicesItem}>
			<div className={css.ServicesItem__media}>
				{service.thumbnail && (<img src={service.thumbnail} alt="" />)}
			</div>
			<div className={css.ServicesItem__titles}>
				<h2 className={css.ServicesItem__title}>
					<Link target="_blank" to={`/services/${service.id}`} className={css.ServicesItem__link}>{service.title}</Link>
				</h2>
			</div>
			<div className={css.ServicesItem__meta}>
				{service.content && service.content.type && (
					<Link target="_blank" to="/services" className={css.ServicesItem__type}>{service.content.type}</Link>
				)}
			</div>
			<div className={css.ServicesItem__info}>
				<img src={service.is_active ? iconPublished : iconUnpublished} alt={service.is_active ? 'Active' : 'Inactive'} title={service.is_active ? 'Active' : 'Inactive'} />
			</div>
			<div className={css.ServicesItem__extra}>
				<div className={css.ServicesItem__patterns}>
					<span className={css.ServicesItem__extraTitle}>Pattern: </span>
					{service.matchPattern}
				</div>
			</div>
		</li>
	)
};