import React from 'react';
import { Link } from '~/src/lib/Buttons';

import * as css from './AutoLoader.css';

export const AutoLoader = ({ error, isLoading, backLink, children }) => {
	// TODO: Implement nicer loading and error output
	if (error) {
		return (
			<div className={css.Error}>
				<p className={css.Error__message}>Error: {error.message}</p>
				<br />
				{backLink && (
					<Link variant="prominent" to={backLink}>Back</Link>
				)}
			</div>
		);
	}
	if (isLoading) {
		return (<div className={css.Loader}>Loading...</div>);
	}
	return children || null;
};