import React, { Fragment } from 'react';

import { useResource, useResourceRefresher } from '~/src/util/useResource';
import { Page } from '~/src/lib/Page';
import { AutoLoader } from '~/src/lib/AutoLoader';
import { Button } from '~/src/lib/Buttons';

import { SiteItem } from './ScanItems';

const ENDPOINT = '/v0/scanner/sites';

const Actions = ({ refresh }) => (
	<Fragment>
		<Button variant="secondary" onClick={refresh}>Refresh</Button>
		<Button to="/scanner/sites/new">Create new</Button>
	</Fragment>
);


export const ScannerSites = () => {
	const { error, isLoading, data } = useResource(ENDPOINT);
	const refresh = useResourceRefresher(ENDPOINT);
	return (
		<Page
			title="Scanner sites"
			actions={<Actions refresh={refresh} />}
		>
			<AutoLoader error={error} isLoading={isLoading}>
				{data && data.length ? data.map((site) => (
					<SiteItem key={site.id} site={site} />
				)) : (
					'No sites'
				)}
			</AutoLoader>
		</Page>
	);
};
