import React, { useState, useEffect, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import capitalize from 'lodash/capitalize';

import request from '~/src/util/request';
import notify from '~/src/util/notify';
import { getMiddleOrder, getPreviousOrder } from '~/src/util/order';
import { useResource } from '~/src/util/useResource';
import { SmallText, LabelledText, TimeAgo } from '~/src/lib/Text';
import { Page } from '~/src/lib/Page';
import { Row, Spread } from '~/src/lib/Layout';
import { Button, SlimIconButton } from '~/src/lib/Buttons';
import { ListItemMeta } from '~/src/lib/ListItem';
import { SortableList, SortableListItem } from '~/src/lib/SortableList';
import { ProductAvailabilityIcons } from '~/src/lib/ProductAvailabilityIcons';
import { ProtoEditor, useEditor, getEditorActions } from '~/src/lib/ProtoEditor';
import { Link } from '~/src/lib/Buttons';
import { Field } from '~/src/lib/Form';

import css from './CollectionEditor.css';

const EDITOR_FIELDS = [{
	name: 'collection_id',
	type: 'hidden',
}, {
	name: 'title',
	label: 'Title',
	type: 'text',
}, {
	name: 'slug',
	label: 'Slug',
	type: 'slug',
}, {
	name: 'content.intro',
	label: 'Collection description',
	type: 'textarea',
	maxLength: 300,
	description: 'Describe the collection using relevant keywords and terms, ending with a call to action',
}, {
	name: 'poster_upload_id',
	label: 'Poster image',
	type: 'image',
	description: 'Images should be in 16/9 aspect ratio and larger than 1360x765'
}];

export const CollectionEditor = ({ match }) => {
	const collectionId = match.params.id;
	if (collectionId === 'new') {
		return <CreateNewCollection />
	}
	return <EditCollection collectionId={collectionId} />
};

const CreateNewCollection = () => {
	const [id, setId] = useState(null);
	useEffect(() => {
		request('POST', '/v0/collections')
			.then(({ data }) => {
				setId(data.id);
			})
			.catch((error) => notify.error(error))
		;
	}, []);
	if (id) {
		return (
			<Redirect to={`/content/collections/${id}`} />
		);
	}
	return (
		<Page>
			<div>
				Creating new collection...
			</div>
		</Page>
	);
};

export const EditCollection = ({ collectionId }) => {
	const endpoint = `/v0/collections/${collectionId}`;
	const editor = useEditor({
		endpoint,
		fields: EDITOR_FIELDS,
		allowDelete: true,
	});
	const [publishedAt, setPublishedAt] = useState(undefined);
	useEffect(() => {
		if (publishedAt === undefined && 'published_at' in editor.item) {
			setPublishedAt(editor.item.published_at);
		}
	}, [publishedAt, editor.item]);
	const handleChangePublishState = async (state) => {
		await editor.handleSubmit();
		const result = await request(state === 'publish' ? 'POST' : 'DELETE', `${endpoint}/publish`)
		if (result.ok) {
			setPublishedAt(result.data.publishedAt);
		}
	};
	return (
		<Page
			title="Collection"
			subtitle={editor.item?.slug ? (
				<Fragment>
					<Link target="_blank" to={`${process.env.SITE_HOST}/collections/${editor.item.slug}`} variant="external">
						View collection
					</Link>
				</Fragment>
			) : null}
			actions={(
				<Fragment>
					{getEditorActions(editor)}
					<Spread />
					{publishedAt ? (
						<Fragment>
							<LabelledText tight label="Published"><TimeAgo>{publishedAt}</TimeAgo></LabelledText>
							<Button variant="yellow" onClick={() => handleChangePublishState('unpublish')}>Un-publish</Button>
						</Fragment>
					) : (
						<Button variant="green" onClick={() => handleChangePublishState('publish')}>Publish</Button>
					)}
				</Fragment>
			)}
		>
			<section className={css.Meta}>
				<ProtoEditor editor={editor} />
			</section>
			<section className={css.ItemEditor}>
				<ItemsEditor collectionId={collectionId} />
			</section>
		</Page>
	);
};

const ItemsEditor = ({ collectionId }) => {
	const endpoint = `/v0/collections/${collectionId}/items`;
	const { data, mutate } = useResource(endpoint);
	const [saveState, setSaveState] = useState('Saved');
	const items = data || [];
	const handleOrderChange = () => setSaveState('Saving...');
	const handleSaveOrder = (newItems) => {
		setSaveState('Saving...')
		mutate(newItems, { revalidate: false });
		request('PUT', endpoint, {
			action: 'reorder',
			items: newItems.map((item) => ({ id: item.id, order: item.order })),
		})
			.then((response) => setSaveState(response.ok ? 'Saved' : 'Server error'))
		;
	};
	const addNewItem = ({ target_type, target_id }) => {
		const payload = {
			target_type,
			target_id,
			order: getNewItemOrder(items),
		};
		setSaveState('Saving...');
		request('POST', endpoint, payload)
			.then(() => mutate())
			.then(() => setSaveState('Saved'))
		;
	};
	const handleDelete = (item) => {
		setSaveState('Saving...');
		request('DELETE', `${endpoint}/${item.id}`)
			.then(() => mutate())
			.then(() => setSaveState('Saved'))
		;
	};
	return (
		<Fragment>
			<Row valign="baseline">
				<h2>
					Items
				</h2>
				<SmallText>{saveState}</SmallText>
				<Spread />
				Find:
				<Link variant="prominent" to="/products-beta" target="_blank">Products</Link>
				|
				<Link variant="prominent" to="/categories" target="_blank">Categories</Link>
			</Row>
			<Row>
				<AddItemForm onSubmit={addNewItem} type="product" placeholder="Product ID" />
				<AddItemForm onSubmit={addNewItem} type="category" placeholder="Category ID" />
			</Row>
			{items && items.length ? (
				<SortableList items={items} onSave={handleSaveOrder} onChange={handleOrderChange} className={css.Items}>
					{(items) => (
						items.map((item) => (
						<CollectionItem
							key={item.id}
							item={item}
							onDelete={() => handleDelete(item)}
						/>
					))
					)}
				</SortableList>
			) : 'No items'}
		</Fragment>
	);
};

const CollectionItem = ({ item, onDelete }) => {
	return (
		<SortableListItem
			item={item}
			title={item.title}
			href={getHrefForItem(item)}
			target="_blank"
			stats={getStatsForItem(item)}
			metas={getMetasForItem(item)}
			actions={<SlimIconButton icon="trash" onClick={onDelete}></SlimIconButton>}
		/>
	);
};

const AddItemForm = ({ type, placeholder, onSubmit }) => {
	const [newItemInput, setNewItemInput] = useState();
	const handleSubmit = (event) => {
		event.preventDefault();
		onSubmit({ target_id: newItemInput, target_type: type });
	};
	return (
		<form className={css.AddNewItem} onSubmit={handleSubmit}>
			<Field
				placeholder={placeholder}
				onChange={setNewItemInput}
			/>
			<Button
				variant="blue"
				type="submit"
			>
			Add {type}
			</Button>
		</form>
	);
};

function getHrefForItem(item) {
	switch (item.type) {
		case 'product':
			return `/products/${item.item_id}`;
		case 'category':
			return `/categories/${item.item_id}`;
		default:
			return null;
	}
}

function getStatsForItem(item) {
	if (item.type === 'product') {
		return (
			<ProductAvailabilityIcons isInStock={item.is_in_stock} isPublished={item.is_published} />
		);
	}
	return null;
}
function getMetasForItem(item) {
	return (
		<Fragment>
			<ListItemMeta key="type" text={capitalize(item.type)} />
		</Fragment>
	);
}

function getNewItemOrder(items) {
	// Pick a middle order to make space before and after
	if (!items.length) {
		return getMiddleOrder().toString();
	}
	return getPreviousOrder(items[0].order).toString();
}
