.Picker {}
.PickerPreview {
	background: var(--white);
	border-radius: 7px;
	padding: 20px;
	margin-bottom: 30px;
}
.PickerPreview__title {
	font-size: 16px;
	font-weight: var(--bold);
	margin-bottom: 5px;
}
.PickerPreview__text {
	font-size: 20px;
	font-weight: var(--bold);
}
.PickerPreview__href {
	font-size: 16px;
	color: var(--gray4);
	text-decoration: none;
	word-break: break-all;
}
.PickerPreview__href:hover {
	text-decoration: underline;
}
.PickerPreview__crumbs {
	font-size: 26px;
	display: flex;
	grid-gap: 10px;
}
.PickerPreview__crumb {
}
.PickerPreview__crumb:after {
	content: '/';
	display: inline-block;
	margin-left: 10px;
}
.PickerPreview__crumb:last-child:after {
	content: none;
}
.PickerInput {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 20px;
}