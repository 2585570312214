.CreateNewPost {
	margin-top: 10vh;
	font-size: 20px;
}

.BlogPostEditor {
	display: grid;
	column-gap: var(--grid-column-gap);
	row-gap: var(--section-spacing);
	grid-template-rows: auto auto;
	grid-template-columns: 1fr;
	grid-template-areas:
		"meta"
		"content"
	;
}
.BlogPostEditor__basic {
	grid-area: basic;
}
.BlogPostEditor__page {
	grid-area: page;
}

.BlogPostEditor__meta {
	grid-area: meta;
}
.BlogPostEditor__content {
	grid-area: content;
}
