import React, { useState, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import sortBy from 'lodash/sortBy';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { BareToggleSwitch } from '~/src/lib/Form';
import { useTitle } from '~/src/util/page-meta';
import { InfiniteOverflowScroller } from '~/src/lib/InfiniteOverflowScroller';
import { AutoLoader } from '~/src/lib/AutoLoader';
import { useResource } from '~/src/util/useResource';

import * as css from './ProductsSearch.css';

export const ProductsSearch = () => {
	const { error, isLoading, data } = useResource('/v0/products-extended-info');
	return (
		<AutoLoader error={error} isLoading={isLoading}>
			{data && <ProductsTable items={data} />}
		</AutoLoader>
	)
};

function copyID(event) {
	const selection = window.getSelection();
	selection.selectAllChildren(event.currentTarget.querySelector('[data-id]'));
	document.execCommand('copy');
}

const ProductsTable = ({ items }) => {
	const searchIndex = useMemo(() => {
		return items.reduce((out, item) => {
			out[item.product_id] = getItemSearchCache(item);
			return out;
		}, {});
	}, [items]);
	const [query, setQuery] = useState('');
	const [showAttributes, setShowAttributes] = useState(false);
	const updateQuery = (query) => {
		setQuery(query.toLowerCase());
	};
	useTitle(query ? `"${query}"` : 'Search for products');
	const [results, setResults] = useState(items);
	// Clear the height cache when showAttributes changes
	useEffect(() => {
		if (!query) {
			setResults(items);
		} else {
			setResults(findItems(items, searchIndex, query));
		}
	}, [items, query, searchIndex]);
	return (
		<section className={css.ProductsSearch}>
			<header className={css.header}>
				<h2 className={css.title}>Search for products</h2>
				<h3 className={css.subtitle}>Clicking an item copies its ID. Clicking its title navigates to it.</h3>
				<div className={css.Control}>
					<span>Show attributes</span>
					<BareToggleSwitch
						defaultValue={showAttributes}
						onChange={(value) => setShowAttributes(value)}
					/>
				</div>
				<Search updateQuery={updateQuery} />
			</header>
			<Results items={results} showAttributes={showAttributes} />
		</section>
	);
};

const Search = ({ updateQuery }) => {
	return (
		<label className={css.Search}>
			<p>Search:</p>
			<input
				className={css.Search__input}
				autoFocus
				onChange={(event) => updateQuery(event.target.value)}
			/>
		</label>
	);
};

const Results = ({ items, showAttributes }) => {
	return (
		<div className={css.Results}>
			<InfiniteOverflowScroller
				items={items}
				itemHeight={showAttributes ? 200 : 150}
				render={(item, style) => (
					<div style={style} key={item.product_id}>
						<ResultItem item={item} showAttributes={showAttributes} />
					</div>
				)}
			/>
		</div>
	);
};

const ResultItem = ({ item, showAttributes=true }) => {
	// TODO: When the API returns brand_id and seller_id, add back in links
	return (
		<article className={classnames(css.Result, showAttributes && css['Result--show-attributes'])} onClick={copyID}>
			<div className={css.Result__image}>
				{item.image_sizes ? (
					<LazyLoadImage src={item.image_sizes['512x320']} alt={`Image of ${item.title}`} />
				) : null}
			</div>
			<div className={css.Result__info}>
				<p className={css.Result__title}>
					<span data-id className={css.Result__id}>{item.product_id}</span>
					<Link target="_blank" to={`/products/${item.product_id}`}>{item.title}</Link>
				</p>
				<p className={css.Result__category}>
					<span className={css.Result__label}>Category:</span>
					{' '}
					<Link target="_blank" to={`/categories/${item.category_id}`}>{item.category}</Link>
				</p>
				<p className={css.Result__brand}>
					<span className={css.Result__label}>Brand:</span>
					{' '}
					{<Link target="_blank" to={`/brands/${item.brand_id}`}>{item.brand}</Link>}
				</p>
				<p className={css.Result__seller}>
					<span className={css.Result__label}>Seller:</span>
					{' '}
					{<Link target="_blank" to={`/sites/${item.seller_id}`}>{item.seller_domain}</Link>}
				</p>
			</div>
			{showAttributes && item.attributes ? (
				<div className={css.Result__attributes}>
					{Object.entries(item.attributes).map(([key, attributes]) => (
						<div key={key}>
							<span className={css.Result__attributeKey}>{formatAttributeKey(key)}: </span>
							<span className={css.Result__attributeValue}>{attributes ? attributes.join(', ') : ''}</span>
						</div>
					))}
				</div>
			) : null}
			{/*<p className={css.Result__searchString}>{result.searchCache.text}</p>
			<p className={css.Result__score}>{'score' in result ? result.score : '-'}</p>*/}
		</article>
	);
}

// When rendering 1000s of products this takes a bit of time,
// so to help it on the way let's cache the result
const attributeNameLookup = {};
function formatAttributeKey(str) {
	if (!(str in attributeNameLookup)) {
		attributeNameLookup[str] = `${str[0].toUpperCase()}${str.replace('_', ' ').slice(1)}`;
	}
	return attributeNameLookup[str];
}

function findItems(items, searchIndex, query) {
	const queryWords = query ? query.split(' ') : [];
	const results = items
		.map((item) => {
			item.score = getItemScoreForQueryWords(searchIndex[item.product_id], queryWords);
			return item;
		})
		.filter((result) => result.score > 0)
	;
	return sortBy(results, 'score').reverse();
}

function getItemScoreForQueryWords(searchCache, queryWords) {
	let score = 0;
	for (let word of queryWords) {
		score += getItemWordScore(searchCache, word);
	}
	const firstWord = queryWords[0] || '';
	if (firstWord.startsWith('http') && searchCache.url.startsWith(simplifyUrl(firstWord))) {
		score += 5;
	}
	return score;
}

function getItemNameString(item) {
	return `${item.name}`.toLowerCase();
}

function getItemTitleString(item) {
	return `${item.title || ''}`.toLowerCase();
}

function getItemAttributeString(item) {
	if (!item.attributes) {
		return '';
	}
	return Object
		.values(item.attributes)
		.reduce(
			(keep, attrs) => `${keep} ${attrs.join(' ')}`,
			''
		)
		.toLowerCase()
}

function getItemSearchCache(item) {
	const name = getItemNameString(item);
	const attributes = getItemAttributeString(item);
	const keywords = item.keywords ? item.keywords.join(' ') : '';
	const title = getItemTitleString(item);
	return {
		text: [name, title, keywords, item.category, item.brand, item.seller_domain, attributes].join(' ').toLowerCase(),
		attributes: new Set(attributes.split(' ')),
		name: new Set(name.split(' ')),
		url: simplifyUrl(item.canonical_url),
	};
}

function simplifyUrl(url) {
	if (typeof url !== 'string') {
		return '';
	}
	return url.replace(/\?.*$/, '').replace(/^https?:\/\//, '');
}

function getItemWordScore(searchCache, word) {
	if (searchCache.text.includes(word)) {
		return 0.5;
	}
	if (searchCache.name.has(word)) {
		return 1;
	}
	if (searchCache.attributes.has(word)) {
		return 0.5;
	}
	return 0;
}