.ServicesList {
}
.ServicesItem {
	display: grid;
	grid-template-columns: auto 1fr auto;
	grid-template-rows: auto auto auto;
	grid-column-gap: 10px;
	grid-row-gap: 5px;
	font-size: 18px;
	min-height: 20px;
	line-height: 25px;
	background: var(--white);
	padding: 15px;
	border-radius: 7px;
	box-shadow: var(--wide-shadow);
}
.ServicesItem + .ServicesItem {
	margin-top: 10px;
}
.ServicesItem__media {
	width: 65px;
	height: 65px;
	background: var(--off-white);
	text-align: left;
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
	border-radius: 3px;
	grid-column: 1;
	grid-row: 1 / 3;
}
.ServicesItem__media img {
	width: 100%;
	height: 100%;
	object-fit: contain;
	overflow: hidden;
}
.ServicesItem__titles {
	align-self: center;
	grid-column: 2;
	grid-row: 1;
	display: flex;
	align-items: flex-start;
}
.ServicesItem__title {
	font-weight: var(--bold);
	font-size: inherit;
	flex: 100%;
	margin: 0;
	padding: 0;
	line-height: 1em;
	line-height: 20px;
}
.ServicesItem__link {
	font: inherit;
	color: inherit;
}
.ServicesItem__type {
	display: inline-block;
	font-size: 14px;
	line-height: 20px;
	font-weight: var(--semibold);
	color: var(--gray4);
}
.ServicesItem__meta {
	font-size: 14px;
	line-height: 20px;
	font-weight: var(--semibold);
	color: var(--gray4);
	display: flex;
	display: flex;
	grid-gap: 10px;
}
.ServicesItem__id {
	display: inline-block;
}
.ServicesItem__rank {
	display: inline-block;
	color: var(--black);
	font-weight: var(--bold);
}
.ServicesItem__info {
	display: flex;
	grid-column: 3;
	grid-row: 1 / 3;
	align-items: center;
	justify-content: flex-end;
}
.ServicesItem__info img {
	height: 26px;
}
.ServicesItem__extra {
	grid-column: 1 / 4;
	grid-row: 3;
	font-size: 14px;
	line-height: 20px;
}
.ServicesItem__extraTitle {
	font-weight: var(--bold);
}
.ServicesItem__patterns {
	margin-top: 5px;
}
