.Bubble {
	position: absolute;
	background: var(--yellow);
	padding: 5px 8px;
	border-radius: 3px;
	font-size: 12px;
	font-weight: 500;
	z-index: 1;
	box-shadow: 1px 3px 9px rgba(0, 0, 0, 0.05);
	opacity: 0;
	transform: translateY(5px);
	transition: opacity 300ms ease, transform 300ms ease;
}
.Bubble--is-visible {
	opacity: 1;
}

@keyframes bubble-intro {
	0% {
		transform: translateY(5px);
		opacity: 0;
	}
	5% {
		opacity: 0;
	}
}

@keyframes bubble-outro {
	95% {
		opacity: 0;
	}
	100% {
		transform: translateY(-5px);
		opacity: 0;
	}
}
