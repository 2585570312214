.Row {
	display: flex;
	align-items: center;
	font-size: 18px;
	line-height: 22px;
	min-height: 50px;
	background: var(--white);
	border-radius: 7px;
	padding: 10px 15px;
	box-sizing: border-box;
	box-shadow: var(--tight-shadow);
}
.Row + .Row {
	margin-top: 10px;
}
.Row__ordinal {
	color: var(--gray4);
	margin-right: 12px;
	flex: 0 0 auto;
}
.Row__text {
	font-weight: var(--semibold);
	overflow: hidden;
	flex: 1;
	margin-right: auto;
	padding-right: 50px;
	text-decoration: none;
}
.Row__text[href]:hover {
	text-decoration: underline;
}
.Row__raw {
	font-size: 12px;
	word-break: break-all;
}
.Row__keyValueExtra {
	flex: 1;
	display: flex;
	flex-wrap: wrap;
	font-size: 18px;
	line-height: 20px;
	overflow: hidden;
	text-decoration: none;
}
.Row__keyValueExtra[href]:hover .Row__extra {
	text-decoration: underline;
}
.Row__key {
	font-weight: var(--bold);
}
.Row__value {
	margin-left: 10px;
	overflow: hidden;
	text-overflow: ellipsis;
	color: var(--gray4);
	font-weight: var(--semibold);
}
.Row__extra {
	flex: 100%;
	max-width: 100%;
	font-size: 14px;
	margin-top: 10px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.Row__actions {
	margin-left: auto;
	margin-right: 0;
	display: flex;
}
