import React, { Fragment, useState } from 'react';
import classnames from 'classnames';
import orderBy from 'lodash/orderBy';

import { formatLink, formatDomain, formatTimeAgoStrict } from '~/src/util/format';
import { Page } from '~/src/lib/Page';

import * as css from './Table.css';

function renderItem(header, item, headerItemAux) {
	if (header.renderItem) {
		return header.renderItem(item, headerItemAux);
	}
	if (header.format === 'time') {
		return `${formatTimeAgoStrict(item[header.key])} ago`;
	}
	if (header.format === 'domain') {
		return formatDomain(item[header.key]);
	}
	if (header.format === 'url') {
		return (<a className={css.link} href={item[header.key]} target="_blank" rel="noopener noreferrer">{formatLink(item[header.key])}</a>);
	}
	if (typeof item === 'string') {
		return item;
	}
	return item[header.key];
}

export const TablePage = ({ title, actions, headers, headerItemAux, items, keyProp, renderExpander, ...props }) => (
	<Page title={title} actions={actions} contentWidth="wide" {...props}>
		<Table
			headers={headers}
			headerItemAux={headerItemAux}
			items={items}
			keyProp={keyProp}
			renderExpander={renderExpander}
		/>
	</Page>
);

export const Table = ({ headers, headerItemAux, items, keyProp='id', renderExpander, initialSortBy=null, initialSortOrder=null }) => {
	const [sortKey, setSortKey] = useState(initialSortBy);
	const [sortDir, setSortDir] = useState(initialSortOrder);
	const sortedItems = sortKey ? orderBy(items, sortKey, sortDir) : items;
	const updateSortOrder = (key) => {
		if (sortKey === key) {
			if (sortDir === 'desc') {
				setSortDir('asc');
			} else if (sortDir === 'asc') {
				setSortKey(null);
			}
		} else {
			setSortKey(key);
			setSortDir('desc');
		}
	}
	return (
		<div className={css.Table}>
			<div className={css.items}>
				<div className={css.header}>
					{headers.map((header) => (
						<div
							key={header.key}
							className={classnames(css.headerValue, header.isWide && css.isWide, header.noWrap && css.noWrap, sortKey === header.key ? css.sortedBy : null, css[sortDir])}
							onClick={() => updateSortOrder(header.key)}
						>
							{header.label}
						</div>
					))}
				</div>
				{sortedItems && sortedItems.length ? sortedItems.map((item) => (
					<TableRow
						key={getKey(item, keyProp)}
						headers={headers}
						item={item}
						headerItemAux={headerItemAux}
						renderExpander={renderExpander}
					/>
				)) : <div className={css.noItems}>No items</div>}
			</div>
		</div>
		)
};

function getKey(item, keyProp) {
	if (item === 'string') {
		return item;
	}
	if (typeof keyProp === 'function') {
		return keyProp(item);
	}
	return item[keyProp];
}

export const Indent = ({ depth }) => (
	depth > 0 ? (<span className={css.Indent} data-depth={depth} />) : null
);

const TableRow = ({ headers, item, headerItemAux, renderExpander }) => {
	const [isExpanded, setIsExpanded] = useState(false);
	return (
		<Fragment>
			<div className={css.item}>
				<div className={css.itemValues} onClick={() => setIsExpanded(!isExpanded)}>
					{headers.map((header) => (
						<div
							key={header.key}
							className={classnames(css.itemValue, header.isWide && css.isWide, header.noWrap && css.noWrap)}
						>
							{renderItem(header, item, headerItemAux)}
						</div>
					))}
				</div>
				{renderExpander && isExpanded ? (
					<div className={css.expander}>
						{renderExpander(item)}
					</div>
				) : null}
			</div>
		</Fragment>
	);
};
