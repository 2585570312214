import React, { Fragment } from 'react';

import pick from '~/src/util/pick'
import { TablePage } from '~/src/lib/Table';
import Resource from '~/src/lib/Resource';
import { Button } from '~/src/lib/Buttons';
import { Field, Hidden, MultiSelect } from '~/src/lib/Form';
import Editor from '~/src/lib/Editor';

const ATTRIBUTE_HEADERS = [{
	label: 'Attribute',
	key: 'name',
	isWide: true
}, {
	key: 'edit',
	renderItem: (item) => (
		<Button to={`/attributes/${item.attribute_id}`}>Edit</Button>
	)
}];

const Actions = () => (
	<Button to="/attributes/new">Create new</Button>
);

export const Attributes = () => (
	<Resource endpoint="/v0/attributes">
		{({ data }) => (
			<TablePage
				keyProp="attribute_id"
				headers={ATTRIBUTE_HEADERS}
				items={data}
				title="Attributes"
				actions={<Actions />}
			/>
		)}
	</Resource>
);

export const EditAttribute = ({ match }) => (
	<Editor
		title="Edit attribute"
		submitEndpoint={`/v0/attributes/${match.params.id}`}
		method="PUT"
		onSuccess="/attributes"
		loadEndpoint={`/v0/attributes/${match.params.id}`}
	>
		{(item) => (
			<Fragment>
				<Hidden name="attribute_id" value={item.attribute_id} />
				<SharedFields item={item} />
			</Fragment>
		)}
	</Editor>
);

export const CreateAttribute = () => (
	<Editor
		title="Create attribute"
		submitEndpoint="/v0/attributes"
		method="POST"
		onSuccess={redirectToItem}
		newItem
	>
		{(item) => <SharedFields item={item} />}
	</Editor>
);

const SharedFields = ({ item }) => (
	<Fragment>
		<Field label="Name" name="name" defaultValue={item.name} />
		<Resource endpoint="/v0/tags" dataMapper={createOptions}>
			{({ data }) => (
				<MultiSelect
					label="Tags"
					name="tags"
					selections={pick(item.tags, 'id')}
					options={data}
				/>
			)}
		</Resource>
	</Fragment>
);

function redirectToItem(item) {
	return `/attributes/${item.attribute_id}`;
}

function createOptions(items) {
	return items
		.map((item) => ({
			label: item.name,
			value: item.tag_id,
		}))
	;
}