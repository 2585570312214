import React, { Fragment } from 'react';

import { pluralize } from '~/src/util/format';
import { useResource } from '~/src/util/useResource';
import { Page } from '~/src/lib/Page';
import { KeyVals } from '~/src/lib/Debug';
import { AutoLoader } from '~/src/lib/AutoLoader';
import { SharpButton, Button } from '~/src/lib/Buttons';

import { startSet } from './GoldSifterCommon';

import * as css from './GoldSifter.css';

const Lead = () => (
	<Fragment>
		<p>The gold sifter helps you provide data which feed the machine learning algorithms.</p>
		<p>Pick a dataset and go through each of the examples and provide the information asked for.</p>
		<p>It is important that this information is correct, so if there are examples you are unsure about, skip them.</p>
	</Fragment>
);

export const GoldSifterHome = () => {
	const datasets = useResource('/v0/goldsifter/datasets');
	return (
		<Page
			title="Gold sifter: Home"
			actions={
				<Fragment>
					<Button variant="neutral" to="/goldsifter/datasets/new">Upload dataset</Button>
					<Button variant="bare" to="/goldsifter/datasets">View datasets</Button>
				</Fragment>
			}
			lead={<Lead />}
		>
			<AutoLoader error={datasets.error} isLoading={datasets.isLoading}>
				{datasets.data && datasets.data.length ? (
					<ul>
						{datasets.data.map((dataset) => (
							<li key={dataset.id} className={css.GoldSifter__setItem}>
								{dataset.meta.title}{' '}
								<SharpButton onClick={() => startSet(dataset.id)} className={css.GoldSifter__setItemButton}>{dataset.batch_count} {pluralize(dataset.batch_count, 'batch', 'batches')} available</SharpButton>
							</li>
						))}
					</ul>
				) : (
					<h2>No open datasets, please check back later</h2>
				)}
			</AutoLoader>
		</Page>
	);
};

export const AllDatasetsPage = () => {
	const datasets = useResource('/v0/goldsifter/datasets?selection=all');
	return (
		<Page
			title="Gold sifter datasets"
			lead={<Lead />}
		>
			<AutoLoader error={datasets.error} isLoading={datasets.isLoading}>
				{datasets.data && datasets.data.length ? (
					<ul>
						{datasets.data.map((dataset) => (
							<li key={dataset.id} className={css.GoldSifter__setItem}>
								{dataset.meta.title} ({dataset.batch_count}){' '}
								<SharpButton to={`/goldsifter/datasets/${dataset.id}`} className={css.GoldSifter__setItemButton}>View</SharpButton>
							</li>
						))}
					</ul>
				) : (
					<h2>No open datasets, please check back later</h2>
				)}
			</AutoLoader>
		</Page>
	);
};

export const SingleDatasetPage = ({ match }) => {
	const { datasetId } = match.params;
	const result = useResource(`/v0/goldsifter/datasets/${datasetId}`);
	if (!result.data) {
		return 'Loading...';
	}
	const dataset = result.data;
	return (
		<Page
			title="Gold sifter dataset"
			lead={<Lead />}
			actions={
				<Fragment>
					<Button variant="primary" to={`/goldsifter/datasets/${datasetId}/edit`}>Edit</Button>
					<Button variant="neutral" to={`/goldsifter/datasets/${datasetId}/batches`}>See batches</Button>
					<Button variant="secondary" to={`/goldsifter/datasets/${datasetId}/add-data`}>Add data</Button>
					<Button variant="secondary" to={`/goldsifter/datasets/new?base=${datasetId}`}>Duplicate</Button>
				</Fragment>
			}
		>
			<AutoLoader error={dataset.error} isLoading={dataset.isLoading}>
				<KeyVals data={dataset} rawJSON />
			</AutoLoader>
		</Page>
	);
};
