import React, { Component } from 'react';

import { BaseField } from './core';
import { Tags, Tag } from './Tags';

export class MultiSelectInput extends Component {
	constructor(props) {
		super(props);
		const selections = (props.selections || []).reduce((keep, selection) => {
			keep[selection] = true;
			return keep;
		}, {});
		this.state = {
			selections,
		};
		this.handleSelectionToggle = this.handleSelectionToggle.bind(this);
	}
	isOptionSelected(option) {
		const { selections } = this.state;
		return option.value in selections;
	}
	handleSelectionToggle(event) {
		const isChecked = event.target.checked;
		const value = event.target.value;
		this.setState((oldState) => {
			if (isChecked) {
				oldState.selections[value] = true;
			} else {
				delete oldState.selections[value];
			}
			return { selections: oldState.selections };
		});
	}
	renderOption(option) {
		const { name } = this.props;
		const isSelected = this.isOptionSelected(option);
		return (
			<Tag
				key={option.value}
				name={`${name}[]`}
				value={option.value}
				label={option.label}
				isSelected={isSelected}
				onChange={this.handleSelectionToggle}
			/>
		);
	}
	render() {
		const { options } = this.props;
		return (
			<Tags>
				{options.map((option) => this.renderOption(option))}
			</Tags>
		);
	}
}

export const MultiSelect = ({ selections, options, name, ...props }) => {
	return (
		<BaseField {...props} tag="div" tabIndex="0">
			<MultiSelectInput
				selections={selections}
				options={options}
				name={name}
			/>
		</BaseField>
	);
}