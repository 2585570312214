@import './fonts.css';

:root {
	--menu-width: 180px;
	--top-space: 20px;
	--pad-x: 20px;
	--black: #313030;
	--white: #FFFFFF;
	--off-whiter: hsl(0, 3%, 98%);
	--off-white: hsl(0, 3%, 97%);

	/* Purple */
	--purple: hsl(228, 46%, 46%);
	--purple-lighter: hsl(228, 46%, 46%, 0.2);

	/* Green */
	--green-darker: hsl(158, 61%, 44%);
	--green-dark: hsl(158, 50%, 50%);
	--green: hsl(158, 50%, 52%);
	--green-light: hsl(158, 50%, 82%);
	--green-lighter: hsl(158, 58%, 77%);
	--green-lightest: hsl(158, 48%, 95%);

	/* Reds */
	--red: hsl(5, 99%, 67%);
	--red-light: hsl(5, 79%, 82%);
	--red-dark: hsl(5, 99%, 55%);
	--red-darker: hsl(5, 99%, 45%);

	/* Blues */
	--blue: #78BCD9;
	--blue-darker: #54AACF;
	--blue-light: hsl(198, 68%, 87%);

	/* Yellow*/
	--yellow: hsl(36, 100%, 75%);
	--yellow-light: hsl(36, 100%, 83%);
	--yellow-dark: hsl(36, 100%, 70%);

	/* Grays */
	--gray1: #ECECEC;
	--gray2: #DEDEDE;
	--gray3: #B1B1B1;
	--gray4: #949494;
	--gray5: #828282;
	--gray6: #616161;
	--light-gray: hsl(0, 3%, 95%);
	--secondary-gray: #F3F3F1;

	/* Presets */
	--link-color: var(--green-darker);
	--background-color: var(--light-gray);
	--menu-background: var(--off-white);

	--regular: 400;
	--semibold: 500;
	--bold: 600;

	--content-width-medium: 850px;
	--content-width-large: 1150px;
	--section-spacing: 60px;
	--grid-column-gap: 40px;

	--wide-shadow: 0 1px 14px rgba(0, 0, 0, 0.05);
	--tight-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
	--card-shadow: 0 1px 14px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);
	--shadow-clickable: 0px 0.5px 1px rgba(0, 0, 0, 0.25);

	--editor-large-text: 28px;
	--editor-medium-text: 24px;
	--editor-normal-text: 18px;
	--editor-small-text: 14px;

	--index-block-variant-selector: 20;
	--index-dropdown: 50;
	--index-header: 2000;
	--index-menu: 2200;
	--index-notification: 2000;
	--index-quicklinks: 2500;
}

@media (min-width: 1000px) {
	:root {
		--pad-x: 40px;
		--menu-width: 220px;
	}
}
@media (min-width: 1400px) {
	:root {
		--pad-x: 60px;
		--menu-width: 260px;
	}
}

html, body {
	margin: 0;
	padding: 0;
	font-family: 'Nunito', system-ui, sans-serif;
	height: 100%;
	color: var(--black);
	line-height: 1.5;
	background: var(--background-color);
	text-rendering: geometricPrecision;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

:global(#app) {
	height: 100%;
}

a {
	color: inherit;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0;
	line-height: 1;
	/* vertical-align: middle; */
}
h2, h3 {
	margin: 1.5em 0 1em;
}
p {
	margin: 1em 0;
}
ol, ul, li {
	margin: 0;
	padding: 0;
	list-style-position: inside;
}
pre {
	tab-size: 2;
}
strong {
	font-weight: var(--bold);
}