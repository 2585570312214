import React, { Fragment } from 'react';

import Resource from '~/src/lib/Resource';
import Header from '~/src/lib/Header';
import Code from '~/src/lib/Code';
import Content from '~/src/lib/Content';
import { Button } from '~/src/lib/Buttons';

const Missing = () => (<p>Missing</p>);

const ViewScrape = ({ match }) => (
	<Resource endpoint={`/v0/scrapes/${match.params.id}`}>
		{({ data }) => (
			<section>
				<Header
					title="View scrape"
					actions={<Button to={`/products/new?scrape_id=${match.params.id}`}>Create product</Button>}
				/>
				<Content>
					<h2>URLs</h2>
					<p>Scraped: <a href={data.url} rel="noopener noreferrer" target="_blank">{data.url}</a></p>
					<p>Canonical: {data.properties.canonicalUrl ?
						(<a href={data.properties.canonicalUrl} rel="noopener noreferrer" target="_blank">{data.properties.canonicalUrl}</a>)
						: 'Missing'
					}
					</p>

					<h2>Page title</h2>
					<p>{data.properties.pageTitle}</p>

					<h2>Headings</h2>
					{data.properties.headings.h1.length ? (
						<Fragment>
							<h3>H1</h3>
							<ul>
								{data.properties.headings.h1.map((heading, index) => (
									<li key={index}>{heading}</li>
								))}
							</ul>
						</Fragment>
					) : null}
					{data.properties.headings.h2.length ? (
						<Fragment>
							<h3>H2</h3>
							<ul>
								{data.properties.headings.h2.map((heading, index) => (
									<li key={index}>{heading}</li>
								))}
							</ul>
						</Fragment>
					) : null}

					<h2>Prices</h2>
					{data.properties.priceNodes ? (
						<ul>
							{data.properties.priceNodes.map((price, index) => (
								<li key={index}>£{price.amount}</li>
							))}
						</ul>
					) : (
						<p>Missing</p>
					)}


					<h2>Description</h2>
					<p>{data.properties.description}</p>

					<h2>Open graph</h2>
					<Code type="json">{data.properties.og}</Code>

					<h2>Oembed</h2>
					{data.properties.headings.h2.length ? (
						<p>{data.properties.oembedHref}</p>
					) : <Missing />}
				</Content>
			</section>
		)}
	</Resource>
);

export default ViewScrape;