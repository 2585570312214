import React, { Fragment } from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import classnames from 'classnames';

import './global.css';

import { history } from '~/src/util/history';
import withStore from '~/src/store';
import { NotifyBanner } from '~/src/util/notify';
import { useEnvironment } from '~/src/util/env';

import Menu from '~/src/lib/Menu';
import { ErrorBoundary } from '~/src/lib/ErrorBoundary';
import Landing from '~/src/pages/Landing';
import LoginPage from '~/src/pages/LoginPage';
import { CreateCategory, EditCategory } from '~/src/pages/Category';
import { CategoryRedux, CategoriesRedux } from '~/src/pages/CategoriesRedux';
import { Services, EditService } from '~/src/pages/Services';
import { UserFeedback } from '~/src/pages/UserFeedback';
import ScrapeResults from '~/src/pages/ScrapeResults';
import ViewScrape from '~/src/pages/ViewScrape';
import { Attributes, EditAttribute, CreateAttribute } from '~/src/pages/Attributes';
import { Tags, EditTag, CreateTag } from '~/src/pages/Tags';
import { Certifications, EditCertification, CreateCertification } from '~/src/pages/Certifications';
import {
	Products, CreateProduct, CreateProductFromExisting, EditProduct,
	ViewProductEdits, ProductsByCategory, QueriesByProduct, ProductsBySellerSite,
} from '~/src/pages/Products';
import { ProductsSearch } from '~/src/pages/ProductsSearch';
import { Brands, BrandEditor, BrandProducts } from '~/src/pages/Brands';
import { BrandPageEditor } from '~/src/pages/BrandPageEditor';
import { BrandPages } from '~/src/pages/BrandPages';
import { BlogPostEditor } from '~/src/pages/BlogPostEditor';
import { BlogPosts } from '~/src/pages/BlogPosts';
import { Sites, EditSite } from '~/src/pages/Sites';
import { Retailers, CreateRetailer, EditRetailer } from '~/src/pages/TargetedRetailers';
import { MultiwordDictionary, CreateMultiword, EditMultiword } from '~/src/pages/MultiwordDictionary';
import { TagsLookup } from '~/src/pages/Knowledge/TagsLookup';
import { CertificationsLookup } from '~/src/pages/Knowledge/CertificationsLookup';
import { ProductTerms } from '~/src/pages/Knowledge/ProductTerms';
import { DebugMatching } from '~/src/pages/MatchingDebugger';
import { MultiMatchingDebugger } from '~/src/pages/MultiMatchingDebugger';
import { Internals } from '~/src/pages/Internals';
import { Queue } from '~/src/pages/Queue';
import { LinkHealth } from '~/src/pages/LinkHealth';
import { MatchEvaluator } from '~/src/pages/MatchEvaluator';
import { GoldSifter } from '~/src/pages/GoldSifter/';
import { QueryInspector } from '~/src/pages/QueryInspector';
import { Querylog } from '~/src/pages/Querylog';
import { Scanner } from '~/src/pages/Scanner';
import { ProductImporter } from '~/src/pages/ProductImporter';
import { TrendingProducts } from '~/src/pages/TrendingProducts';
import { Collections } from '~/src/pages/Collections';
import { CollectionEditor } from '~/src/pages/CollectionEditor';
import { LinkCreator } from '~/src/pages/LinkCreator';
import { CPCLinkCreator } from '~/src/pages/CPCLinkCreator';
import { CPCLinkPerformance } from '~/src/pages/CPCLinkPerformance';
import { Searchlog } from '~/src/pages/Searchlog';
import { SearchInspector } from '~/src/pages/SearchInspector';
import { AffiliateNotifications } from '~/src/pages/AffiliateNotifications';
import { Metrics } from '~/src/pages/Metrics';

import * as css from './App.css';

const LoggedIn = () => (
	<Fragment>
		<Menu className={css.menu} />
		<ErrorBoundary>
			<main className={css.main}>
				<Switch>
					<Route exact path="/" component={Landing} />
					<Route exact path="/categories" component={CategoriesRedux} />
					<Route exact path="/categories/:id" component={CategoryRedux} />
					<Route exact path="/categories/new" component={CreateCategory} />
					<Route exact path="/categories/:id" component={EditCategory} />
					<Route path="/categories/:id/products" component={ProductsByCategory} />
					<Route exact path="/services" component={Services} />
					<Route path="/services/:id" component={EditService} />
					<Route path="/user-feedback" component={UserFeedback} />
					<Route exact path="/scrape-results" component={ScrapeResults} />
					<Route path="/scrape-results/:id" component={ViewScrape} />
					<Route exact path="/attributes" component={Attributes} />
					<Route exact path="/attributes/new" component={CreateAttribute} />
					<Route exact path="/attributes/:id" component={EditAttribute} />
					<Route exact path="/tags" component={Tags} />
					<Route exact path="/tags/new" component={CreateTag} />
					<Route exact path="/tags/:id" component={EditTag} />
					<Route exact path="/certifications" component={Certifications} />
					<Route exact path="/certifications/new" component={CreateCertification} />
					<Route exact path="/certifications/:id" component={EditCertification} />
					<Route exact path="/products-search" component={ProductsSearch} />
					<Route exact path="/products-beta" render={() => (<Redirect to="/products-search" />)} />
					<Route exact path="/products" component={Products} />
					<Route exact path="/products/new" component={CreateProduct} />
					<Route exact path="/products/:id" component={EditProduct} />
					<Route exact path="/products/:id/duplicate" component={CreateProductFromExisting} />
					<Route exact path="/products/:id/edits" component={ViewProductEdits} />
					<Route exact path="/products/:id/queries" component={QueriesByProduct} />
					<Route exact path="/brands" component={Brands} />
					<Route exact path="/brands/:id" component={BrandEditor} />
					<Route exact path="/brands/:id/page" component={BrandPageEditor} />
					<Route path="/brands/:id/products" component={BrandProducts} />
					<Route exact path="/content/brand-pages" component={BrandPages} />
					<Route exact path="/content/blog/posts/:id" component={BlogPostEditor} />
					<Route exact path="/content/blog/posts" component={BlogPosts} />
					<Route exact path="/sites" component={Sites} />
					<Route path="/sites/new" component={EditSite} />
					<Route exact path="/sites/:id" component={EditSite} />
					<Route exact path="/sites/:id/products" component={ProductsBySellerSite} />
					<Route exact path="/retailers" component={Retailers} />
					<Route path="/retailers/new" component={CreateRetailer} />
					<Route exact path="/retailers/:id" component={EditRetailer} />
					<Route exact path="/matching/debug" component={DebugMatching} />
					<Route exact path="/matching/debug/multi" component={MultiMatchingDebugger} />
					<Route exact path="/dictionaries/multiwords" component={MultiwordDictionary} />
					<Route exact path="/dictionaries/multiwords/new" component={CreateMultiword} />
					<Route exact path="/dictionaries/multiwords/:id" component={EditMultiword} />
					<Route path="/knowledge/tags" component={TagsLookup} />
					<Route path="/knowledge/certifications" component={CertificationsLookup} />
					<Route path="/knowledge/product-terms" component={ProductTerms} />
					<Route path="/trending-products" component={TrendingProducts} />
					<Route path="/content/collections/:id" component={CollectionEditor} />
					<Route path="/content/collections" component={Collections} />
					<Route path="/internals" component={Internals} />
					<Route path="/queue" component={Queue} />
					<Route path="/link-health" component={LinkHealth} />
					<Route path="/match-evaluator/:page?" component={MatchEvaluator} />
					<Route path="/querylog/:id" component={QueryInspector} />
					<Route path="/querylog" component={Querylog} />
					<Route path="/goldsifter/:page?/:subpage?" component={GoldSifter} />
					<Route path="/scanner" component={Scanner} />
					<Route path="/product-imports" component={ProductImporter} />
					<Route path="/tools/link-creator" component={LinkCreator} />
					<Route path="/tools/cpc-links" component={CPCLinkCreator} />
					<Route path="/data/cpc/brands" component={CPCLinkPerformance} />
					<Route path="/search/log/:id" component={SearchInspector} />
					<Route path="/search/log" component={Searchlog} />
					<Route path="/metrics" component={Metrics} />
					<Route path="/affiliates/notifications" component={AffiliateNotifications} />
					<Route path="/signin">
						<Redirect to="/" />
					</Route>
					<FourOhFour />
				</Switch>
			</main>
		</ErrorBoundary>
	</Fragment>
);

const FourOhFour = ({ location }) => {
	console.warn('Missing route', location.pathname);
	return (
		<Redirect to="/" />
	);
};

const PANEL_ORIGIN = 'https://panel.joinbeagle.com';
const App = ({ store }) => {
	const [environment, toggleEnvironment] = useEnvironment();
	const panelLink = window.location.origin === PANEL_ORIGIN ? null : `${PANEL_ORIGIN}${history.location.pathname}`;
	return (
		<Router history={history}>
			<div className={classnames(css.QuickLinks, environment === 'AUTO' && css['QuickLinks--hidden'])}>
				{panelLink && <a href={panelLink} target="_blank" rel="noopener noreferrer">Open in panel</a>}
				<a onClick={toggleEnvironment}>Env: {environment}</a>
			</div>
			{store.accessToken ? (
				<LoggedIn />
			) : (
				<LoginPage />
			)}
			<NotifyBanner />
		</Router>
	);
};

export default withStore(App);