import { useState, useEffect } from 'react';

export const useTemporaryMessage = (duration) => {
	const [message, setMessage] = useState(null);
	const [timeOfSet, setTimeOfSet] = useState(null);
	const changeMessage = (newMessage) => {
		setMessage(newMessage);
		setTimeOfSet(Date.now());
	};
	useEffect(() => {
		if (timeOfSet) {
			const timeout = setTimeout(() => {
				setMessage(null);
				setTimeOfSet(null);
			}, duration);
			return () => {
				clearTimeout(timeout);
			}
		}
	}, [timeOfSet, duration]);
	return [message, changeMessage];
};