function getUrl(scrape) {
	return scrape.properties.canonicalUrl || scrape.url;
}

function getName(scrape) {
	if (scrape.properties.og && scrape.properties.og.type === 'product' && scrape.properties.og.title) {
		return scrape.properties.og.title;
	}
	if (scrape.properties.headings.h1.length) {
		return scrape.properties.headings.h1[0];
	}
	const siteName = getSiteName(scrape);
	// If we have a site name we can attempt to remove it from the page title
	if (siteName) {
		const titleParts = scrape.properties.pageTitle.split('|');
		// Remove title segments which don't include the site's name
		const titlePartsWithoutSitename = titleParts.filter(
			(part) => part.toLowerCase().indexOf(siteName.toLowerCase()) === -1
		);
		if (titlePartsWithoutSitename.length) {
			return titlePartsWithoutSitename[0];
		}
	}
	return scrape.properties.pageTitle;
}

function getSiteName(scrape) {
	if (scrape.properties.og && scrape.properties.og.siteName) {
		return scrape.properties.og.siteName;
	}
	return '';
}

function getDescription(scrape) {
	if (scrape.properties.og && scrape.properties.og.description) {
		return scrape.properties.og.description;
	}
	return scrape.properties.description || '';
}

function getImage(scrape) {
	if (scrape.properties.og && scrape.properties.og.image) {
		return scrape.properties.og.image;
	}
	return scrape.properties.image || '';
}

export function createItemFromScrape(scrape) {
	return {
		scrape_id: scrape.scrape_id,
		canonical_url: getUrl(scrape),
		name: getName(scrape),
		description: getDescription(scrape),
		image: getImage(scrape),
	};
}