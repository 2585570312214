.ProductList {
}
.ProductListItem {
	display: grid;
	grid-template-columns: auto 1fr auto auto;
	grid-column-gap: 15px;
	font-size: 18px;
	min-height: 20px;
	line-height: 25px;
	background: var(--white);
	padding: 15px 15px;
	border-radius: 7px;
	box-shadow: var(--wide-shadow);
}
.ProductListItem + .ProductListItem {
	margin-top: 10px;
}
.ProductListItem__media {
	width: 65px;
	height: 65px;
	background: var(--off-white);
	text-align: left;
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
	border-radius: 3px;
	overflow: hidden;
	grid-column: 1;
}
.ProductListItem__media img {
	width: 100%;
	height: 100%;
	object-fit: contain;
	overflow: hidden;
}
.ProductListItem__titles {
	align-self: center;
	grid-column: 2;
}
.ProductListItem__title {
	font-weight: var(--bold);
	font-size: inherit;
	flex: 100%;
	margin: 0;
	padding: 0;
	margin-top: 5px;
	line-height: 1em;
	line-height: 20px;
}
.ProductListItem__link {
	font: inherit;
	color: inherit;
}
.ProductListItem__id {
	display: inline-block;
	font-size: 14px;
	line-height: 20px;
	font-weight: var(--semibold);
	color: var(--gray4);
	margin-top: 5px;
}
.ProductListItem__meta {
	font-size: 14px;
	line-height: 20px;
	font-weight: var(--semibold);
	color: var(--gray4);
	margin-top: 5px;
}
.ProductListItem__id {
	display: inline-block;
}
.ProductListItem__rank {
	display: inline-block;
	margin-left: 10px;
	color: var(--black);
	font-weight: var(--bold);
}
.ProductListItem__info {
	grid-column: 3;
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.ProductListItem__actions {
	grid-column: 4;
	flex: auto;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-left: 10px;
}

.MinimalProductListItem {
	display: block;
	background: var(--white);
	background: rgba(253, 253, 253, 1);
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
	border-radius: 3px;
	padding: 7px;
}
.MinimalProductListItem + .MinimalProductListItem {
	margin-top: 10px;
}
.MinimalProductListItem__link {
	font: inherit;
	color: inherit;
	display: grid;
	grid-template-columns: auto 1fr;
	grid-template-rows: auto auto;
	grid-template-areas:
		'media title'
		'media meta'
	;
	grid-column-gap: 10px;
}
.MinimalProductListItem__link:hover {
	text-decoration: none;
}
.MinimalProductListItem__media {
	width: 40px;
	height: 40px;
	background: var(--off-white);
	text-align: left;
	border-radius: 3px;
	overflow: hidden;
	grid-area: media;
}
.MinimalProductListItem__media img {
	width: 100%;
	height: 100%;
	object-fit: contain;
	overflow: hidden;
}
.MinimalProductListItem__title {
	grid-area: title;
	font-weight: var(--bold);
	font-size: inherit;
	flex: 100%;
	margin: 0;
	padding: 0;
	line-height: 1em;
	line-height: 20px;
}
.MinimalProductListItem__link:hover .MinimalProductListItem__title {
	text-decoration: underline;
}
.MinimalProductListItem__meta {
	grid-area: meta;
	font-size: 12px;
	line-height: 20px;
	font-weight: var(--semibold);
	color: var(--gray4);
}
.MinimalProductListItem__id {

}