import React, { Fragment } from 'react';

import { possessive } from '~/src/util/format';
import { TablePage } from '~/src/lib/Table';
import Resource from '~/src/lib/Resource';
import { Button, ButtonBar } from '~/src/lib/Buttons';
import { Page } from '~/src/lib/Page';
import { Spread } from '~/src/lib/Layout';
import { ProtoEditor, useEditor, getEditorActions } from '~/src/lib/ProtoEditor';
import { useResource } from '~/src/util/useResource';

const HEADERS = [{
	label: 'Brand',
	key: 'name',
	isWide: true
}, {
	label: 'Products',
	key: 'count',
}, {
	key: 'edit',
	renderItem: (item) => (
		<ButtonBar lozenge>
			<Button to={`/brands/${item.brand_id}`}>Edit</Button>
			<Button variant="secondary" to={`/brands/${item.brand_id}/products`}>Products</Button>
		</ButtonBar>
	)
}];

const PRODUCTS_HEADERS = [{
	label: 'Product',
	key: 'name',
	isWide: true
}, {
	key: 'edit',
	renderItem: (item) => (
		<Button to={`/products/${item.product_id}`}>Edit</Button>
	)
}];

const BRAND_EDITOR_FIELDS = [{
	name: 'brand_id',
	type: 'hidden',
}, {
	name: 'name',
	label: 'Name',
	type: 'text',
}, {
	name: 'homepage_url',
	label: 'Homepage URL',
	type: 'url',
}, {
	name: 'logo_upload_id',
	label: 'Logo',
	type: 'image',
}];

const Actions = () => (
	<Button to="/brands/new">Create new</Button>
);

export const Brands = () => (
	<Resource endpoint="/v0/brands">
		{({ data }) => (
			<TablePage
				keyProp="brand_id"
				headers={HEADERS}
				items={data}
				title="Brands"
				actions={<Actions />}
			/>
		)}
	</Resource>
);

export const BrandProducts = ({ match }) => (
	<Resource endpoint={`/v0/brands/${match.params.id}/products`}>
		{({ data }) => (
			<TablePage
				keyProp="product_id"
				headers={PRODUCTS_HEADERS}
				items={data.products}
				title={`${possessive(data.brand.name)} products`}
			/>
		)}
	</Resource>
);

export const BrandEditor = ({ match }) => {
	const brandId = match.params.id;
	const isNew = brandId === 'new';
	const newItem = isNew ? {} : null;
	const brandResource = useResource(isNew ? null : `/v0/brands/${brandId}`);
	const brand = brandResource.data || {};

	const editor = useEditor({
		endpoint: `/v0/brands/${brandId}`,
		createEndpoint: '/v0/brands',
		fields: BRAND_EDITOR_FIELDS,
		allowDelete: true,
		isNew,
		newItem,
		onSuccess: (item) => `/brands/${item.brand_id}`,
	});
	return (
		<Page
			title={isNew ? 'New brand' : `${brandResource.isLoading ? 'Loading...' : brand.name || 'Missing brand'}`}
			actions={(
				<Fragment>
					{getEditorActions(editor)}
					<Spread />
					{!isNew ? (
						<Fragment>
							<Button variant="neutral" to={`/brands/${brandId}/page`}>Edit public page</Button>
							<Button variant="neutral" to={`/brands/${match.params.id}/products`}>View products</Button>
						</Fragment>
					) : null}
				</Fragment>
			)}
		>
			<ProtoEditor key={brandId} editor={editor} />
		</Page>
	);
};

