import React, { Fragment, useState, useRef, forwardRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Page } from '~/src/lib/Page';
import { AutoLoader } from '~/src/lib/AutoLoader';
import { useResource, useResourceRefresher } from '~/src/util/useResource';
import { Spread } from '~/src/lib/Layout';
import { Button, Link, ButtonBar } from '~/src/lib/Buttons';
import { Form, DateField, Field } from '~/src/lib/Form';
import * as ActionCard from '~/src/lib/ActionCard';
import { Column } from '~/src/lib/Layout';
import request from '~/src/util/request';
import { formatLink } from '~/src/util/format';
import { OnUndo, addToUndoQueue, purgeQueue } from '~/src/util/undo-queue';
import { useKeyboard, KEYS } from '~/src/util/useKeyboard';
import notify from '~/src/util/notify';

import * as css from './MatchEvaluator.css';

const ACTIONS = {
	GOOD: 'good',
	OK: 'ok',
	BAD: 'bad',
	SKIP: 'skip',
};

const Lead = () => (
	<Fragment>
		<p>A random selection of queries will be selected for evaluation and shown here.</p>
		<p>For each query, check whether the match was good, okay or bad, or skip if needed.</p>
		<p>To open the product editor, click the product ID after the title of the result.</p>
		<p>You can use the keyboard to submit answers to the top item in the list.</p>
		<p>Keyboard shortcuts: right=good, down=ok, up=skip, right=bad. Use number keys to pick bad reason.</p>
		<p>If you mark an item incorrectly, press z within 3 seconds to undo.</p>
	</Fragment>
);


const submitEvaluation = (id, action, meta=null) => {
	addToUndoQueue('Submit evaluation', () => request('PUT', `/v0/match-evaluator/evaluations/${id}`, {
		action,
		meta,
	}));
};

const Actions = ({ refresh }) => (
	<Fragment>
		<Button variant="secondary" onClick={refresh}>Refresh</Button>
		<Button variant="neutral" to="/match-evaluator/new">Prepare custom set</Button>
		<Spread />
		<Button icon="stats" variant="neutral" to="/match-evaluator/stats">Stats</Button>
		<Button icon="activity" variant="neutral" to="/match-evaluator/recent">Recent</Button>
	</Fragment>
);

export const PrepareMatchEvaluatorSet = () => {
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const handleSubmit = async (data) => {
		setIsLoading(true);
		const result = await request('POST', '/v0/match-evaluator/sets', data);
		if (result.status === 'success') {
			history.push('/match-evaluator');
		} else {
			setIsLoading(false);
		}
	};
	return (
		<Page
			title="Match evaluator: new set"
		>
			<Form onSubmit={handleSubmit} isLoading={isLoading}>
				<DateField
					label="Start date"
					name="startDate"
					description="Inclusive start date"
				/>
				<DateField
					label="End date"
					name="endDate"
					description="Inclusive end date"
				/>
				<Field
					label="Count"
					name="count"
					type="number"
					description="Count of queries to include"
					defaultValue="10"
				/>
				<Button type="submit">Create new set</Button>
			</Form>
		</Page>
	);
};

export const EvaluatorFeed = () => {
	const [isLoadingNewSet, setIsLoadingNewSet] = useState(false);
	const [counter, setCounter] = useState(1);
	const endpoint = `/v0/match-evaluator/feed?round=${counter}`;
	const feed = useResource(endpoint);
	const refresh = useResourceRefresher(endpoint);
	const handleSetDone = async () => {
		setIsLoadingNewSet(true);
		// Purge pending queue
		await purgeQueue({ shouldNotify: false });
		setCounter(counter + 1);
		setIsLoadingNewSet(false);
	};
	return (
		<Page
			title="Match evaluator"
			actions={<Actions refresh={feed.refresh} />}
			lead={<Lead />}
		>
			<AutoLoader error={feed.error} isLoading={isLoadingNewSet || feed.isLoading}>
				<OnUndo handler={refresh} />
				{!isLoadingNewSet && feed.data && (
					<MatchEvaluatorListing data={feed.data} onSetDone={handleSetDone} hasNoItems={feed.data && !feed.data.length} />
				)}
			</AutoLoader>
		</Page>
	);
};

export const ExistingEvaluation = ({ match }) => {
	const evaluationId = match.params.id;
	const itemResource = useResource(`/v0/match-evaluator/evaluations/${evaluationId}`);
	const history = useHistory();
	const searchParams = new URLSearchParams(history.location.search);
	const handleSetDone = async () => {
		// Purge pending queue
		await purgeQueue({ shouldNotify: false });
		if (searchParams.has('return_to')) {
			history.push(searchParams.get('return_to'));
		}
	};
	const item = itemResource.data;
	return (
		<Page
			title="Match evaluator"
			lead={<Lead />}
		>
			<AutoLoader error={itemResource.error} isLoading={itemResource.isLoading}>
				{item && (
					<MatchEvaluatorListing data={[item]} onSetDone={handleSetDone} />
				)}
			</AutoLoader>
		</Page>
	);
};

const useReasons = () => {
	const reasonsResource = useResource('/v0/match-evaluator/options');
	if (reasonsResource.data) {
		return reasonsResource.data.reasons;
	}
	return {};
};

const useItems = ({ data, submitAnswers }) => {
	const reasons = useReasons();
	const inputRef = useRef(null);
	const [lastAction, setLastAction] = useState(null);
	const [items, setItems] = useState(
		data.map((evaluation) => ({ evaluation, isVisible: true, popoverAction: null })),
	);
	const currentItem = items.find((item) => item.isVisible);
	const onClickItem = (action, meta) => {
		if (!currentItem) {
			return;
		}
		const isFirstStep = (action === ACTIONS.BAD || action === ACTIONS.OK) && !meta;
		if (isFirstStep) {
			setItems(
				items.map((listItem) => {
					return listItem.evaluation.id !== currentItem.evaluation.id ? listItem : {
						evaluation: listItem.evaluation,
						isVisible: true,
						popoverAction: action,
						popoverReasons: getReasons(action, reasons),
					};
				})
			);
		} else {
			setLastAction({ key: Math.random(), label: meta && meta.reason || action });
			setItems(
				items.map((listItem) => {
					return listItem.evaluation.id !== currentItem.evaluation.id ? listItem : {
						evaluation: listItem.evaluation,
						isVisible: false,
						popoverAction: null,
						popoverReasons: [],
					};
				})
			);
			submitAnswers(currentItem.evaluation.id, action, meta);
		}
	};
	useKeyboard(({ key, isNumber, isArrow, isEnter, isEscape, number }) => {
		if (isArrow) {
			switch (key) {
				case KEYS.UP:
					return onClickItem(ACTIONS.SKIP);
				case KEYS.DOWN:
					return onClickItem(ACTIONS.OK);
				case KEYS.LEFT:
					return onClickItem(ACTIONS.BAD);
				case KEYS.RIGHT:
					return onClickItem(ACTIONS.GOOD);
			}
		} else if (isNumber) {
			if (number) { // 1-9, inclusive
				const reason = currentItem.popoverReasons[number - 1];
				if (!reason) {
					return notify.error(`Unknown reason "${number}"`);
				}
				onClickItem(
					currentItem.popoverAction,
					// -1 as we have a zero indexed array,
					// and we're only dealing with 1..9
					{ reason }
				)
			} else { // zero
				if (inputRef.current) {
					inputRef.current.focus();
				}
			}
		} else if (isEnter) {
			if (inputRef.current.value) {
				onClickItem(
					currentItem.popoverAction,
					{ reason: inputRef.current.value, isCustom: true }
				)
			}
		} else if (isEscape) {
			setItems(
				items.map((listItem) => {
					return listItem.evaluation.id !== currentItem.evaluation.id ? listItem : {
						evaluation: listItem.evaluation,
						isVisible: true,
						popoverAction: null,
					};
				})
			);
		}
	}, [currentItem, inputRef]);

	return {
		lastAction,
		items,
		currentItem,
		inputRef,
		onClickItem,
	};
};

/*
Provide MatchEvaluatorListing with the following properties:
data: [{
	id: <ItemID>,
	query: {
		id,
		href,
		name,
		imageSrc
	},
	result: {
		id,
		href,
		name,
		imageSrc,
	},
}, ...]

submitAnswers receives: <ItemID>, action, meta
*/
export const MatchEvaluatorListing = ({ data, onSetDone, hasNoItems, submitAnswers=submitEvaluation }) => {
	const { inputRef, currentItem, lastAction, onClickItem } = useItems({ data, submitAnswers });

	const lastActionOutput = lastAction ? (
		<div key={lastAction.key} className={css.BigPop}>{lastAction.label}</div>
	) : null;

	useEffect(() => {
		// If we have no items from the API we don't need to tell it to load more
		if (hasNoItems) {
			return;
		}
		// If there's no current item, we're done with our set and should load more
		if (!currentItem) {
			onSetDone();
		}
	}, [hasNoItems, onSetDone, currentItem]);

	if (!currentItem) {
		return (
			<Fragment>
				<Column align="center">
					<h2>No more queries to evaluate</h2>
					<Button variant="neutral" onClick={onSetDone}>Load more queries</Button>
				</Column>
				{lastActionOutput}
			</Fragment>
		);
	}

	return (
		<Fragment>
			<EvaluationCard
				key={currentItem.evaluation.id}
				onAction={onClickItem}
				inputRef={inputRef}
				{...currentItem}
			/>
			{lastActionOutput}
		</Fragment>
	);
};

function getReasons(action, reasons) {
	if (!(action in reasons)) {
		throw new Error(`Unknown action "${action}"`);
	}
	return reasons[action];
}

const EvaluationCard = ({ evaluation, onAction, isVisible, popoverAction, popoverReasons, inputRef }) => {
	return (
		<Fragment>
			<ActionCard.ActionCard isVisible={isVisible}>
				<ActionCard.Body>
					<MatchSplit
						left={<MatchPreview {...evaluation.query} title="Query" idHref={`/querylog/${evaluation.query.id}`} />}
						right={<MatchPreview {...evaluation.result} title="Result" idHref={`/products/${evaluation.result.id}`} />}
					/>
				</ActionCard.Body>
				<ActionCard.Footer>
					<ButtonBar style={{ justifyContent: 'center', flex: 1 }}>
						<Button onClick={() => onAction(ACTIONS.BAD)} variant="red">Bad match</Button>
						<Button onClick={() => onAction(ACTIONS.OK)} variant="blue">Okay match</Button>
						<Button onClick={() => onAction(ACTIONS.GOOD)} variant="green">Good match</Button>
						<Link onClick={() => onAction(ACTIONS.SKIP)} style={{ position: 'absolute', right: 0, marginTop: 0 }} variant="subtle">Skip</Link>
					</ButtonBar>
				</ActionCard.Footer>
				{popoverAction && (
					<ReasonPopover
						action={popoverAction}
						reasons={popoverReasons}
						onClick={onAction}
						evaluationId={evaluation.id}
						ref={inputRef}
					/>
				)}
			</ActionCard.ActionCard>
		</Fragment>
	);
};

const ReasonPopover = forwardRef(({ onClick, action, reasons }, inputRef) => {
	const onSubmitOther = (event) => {
		event.preventDefault();
		onClick(action, { reason: inputRef.current.value, isCustom: true });
	};
	return (
		<div className={css.ReasonPopover}>
			<h3 className={css.ReasonPopover__title}>Reason for {action.toLowerCase()} match</h3>
			<ol className={css.ReasonPopover__list}>
				{reasons.map((reason) => (
					<li
						key={reason}
						className={css.ReasonPopover__item}
						onClick={() => onClick(action, { reason })}
					>
						{reason}
					</li>
				))}
				<li className={css.ReasonPopover__otherItem}>
					<form onSubmit={onSubmitOther} className={css.ReasonPopover__otherForm}>
						<input ref={inputRef} placeholder="Other..." type="text" className={css.ReasonPopover__otherInput} />
						<button
							type="submit"
							onClick={onSubmitOther}
							className={css.ReasonPopover__otherButton}
						>
							Submit
						</button>
					</form>
				</li>
			</ol>
		</div>
	);
});

const MatchSplit = ({ left, right }) => (
	<div className={css.MatchSplit}>
		{left}
		{right}
	</div>
);
const MatchPreview = ({ title, imageSrc, name, href, id, idHref }) => (
	<article className={css.MatchPreview}>
		<h2 className={css.MatchPreview__title}>
			{title}
		</h2>
		<p className={css.MatchPreview__id}>
			{idHref ? (
				<Link className={css.MatchPreview__idLink} to={idHref} target="_blank">({id})</Link>
			) : `(${id})`}
		</p>
		<div className={css.MatchPreview__image}>
			<img src={imageSrc} alt="" />
		</div>
		<h3 className={css.MatchPreview__heading}>
			{name}
		</h3>
		<a className={css.MatchPreview__link} href={href} target="_blank" rel="noopener noreferrer">{formatLink(href)}</a>
	</article>
);