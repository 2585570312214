import React, { Fragment } from 'react';

import { Field, Hidden } from '~/src/lib/Form';
import Editor from '~/src/lib/Editor';
import { TablePage } from '~/src/lib/Table';
import Resource from '~/src/lib/Resource';
import { Button, ButtonBar } from '~/src/lib/Buttons';

const HEADERS = [{
	label: 'Retailer',
	key: 'domain',
	isWide: true
}, {
	key: 'edit',
	renderItem: (item) => (
		<ButtonBar lozenge>
			<Button to={`/retailers/${item.id}`}>Edit</Button>
		</ButtonBar>
	)
}];

const Actions = () => (
	<Button to="/retailers/new">Create new</Button>
);

export const Retailers = () => (
	<Resource endpoint="/v0/retailers">
		{({ data }) => (
			<TablePage
				keyProp="id"
				headers={HEADERS}
				items={data}
				title="Targeted retailers"
				actions={<Actions />}
			/>
		)}
	</Resource>
);

export const CreateRetailer = () => (
	<Editor
		title="Create retailer"
		submitEndpoint="/v0/retailers"
		method="POST"
		onSuccess="/retailers"
		newItem
	>
		{(item) => <SharedFields item={item} />}
	</Editor>
);

export const EditRetailer = ({ match }) => (
	<Editor
		title="Edit retailer"
		submitEndpoint={`/v0/retailers/${match.params.id}`}
		method="PUT"
		onSuccess="/retailers"
		loadEndpoint={`/v0/retailers/${match.params.id}`}
		allowDelete
		onDelete="/retailers"
	>
		{(item) => (
			<Fragment>
				<Hidden name="id" value={item.id} />
				<SharedFields item={item} />
			</Fragment>
		)}
	</Editor>
);

const SharedFields = ({ item }) => (
	<Fragment>
		<Field label="Domain" name="domain" defaultValue={item.domain} />
	</Fragment>
);
