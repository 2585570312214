.Cert {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 80px;
	text-align: center;
	grid-gap: 5px;
}
.Cert__img {
	width: 32px;
	height: 32px;
	display: block;
}
.Cert__label {
	font-size: 12px;
	font-weight: var(--bold);
	color: var(--purple);
	line-height: 14px;
}