import React, { Fragment, useState, useEffect } from 'react';
import { parse as parseQueryString } from 'query-string';

import request from '~/src/util/request';
import { SectionTitle } from '~/src/lib/Text';
import { TitleBar } from '~/src/lib/Layout';
import { TablePage } from '~/src/lib/Table';
import { Button, ButtonBar } from '~/src/lib/Buttons';
import { AutoLoader } from '~/src/lib/AutoLoader';
import { useResource, useResourceRefresher } from '~/src/util/useResource';
import { Page } from '~/src/lib/Page';
import { ProtoEditor, useEditor, getEditorActions } from '~/src/lib/ProtoEditor';

import { SiteScanItem } from './ScanItems';

const EDITOR_FIELDS = [{
	name: 'id',
	type: 'hidden',
}, {
	name: 'seller_site_id',
	type: 'hidden',
}, {
	name: 'is_enabled',
	label: 'Is enabled',
	type: 'toggle',
}, {
	name: 'config',
	label: 'Config',
	type: 'json',
}];

export const ScannerSite = ({ match }) => {
	const siteId = match.params.id;
	const isNew = siteId === 'new';
	const query = parseQueryString(location.search);
	const sellerSiteId = query.seller_site_id;
	if (isNew) {
		if (!sellerSiteId) {
			return (<PickSiteBasis />);
		}
		return (<NewScannerSite sellerSiteId={sellerSiteId} />);
	}
	return (<ViewScannerSite id={siteId} />);
};

const NewScannerSite = ({ sellerSiteId }) => {
	const configResource = useResource(`/v0/scanner/default-site-config/${sellerSiteId}`)
	const newItem = {
		seller_site_id: sellerSiteId,
		config: configResource.data,
	};
	const editor = useEditor({
		createEndpoint: '/v0/scanner/sites',
		fields: EDITOR_FIELDS,
		isNew: true,
		newItem,
	});

	if (!origin) {
		return (
			<div>Loading site data...</div>
		);
	}

	return (
		<Page
			title="New scanner site"
			actions={getEditorActions(editor)}
		>
			<AutoLoader error={configResource.error} isLoading={configResource.isLoading}>
				<SectionTitle>Edit scanner site</SectionTitle>
				<ProtoEditor key="new" editor={editor} />
			</AutoLoader>
		</Page>
	);
};

const SUBMIT_TIMEOUT = 5000;
export const ViewScannerSite = ({ id }) => {
	const [lastSubmit, setLastSubmit] = useState(0);
	const editor = useEditor({
		endpoint: `/v0/scanner/sites/${id}`,
		fields: EDITOR_FIELDS,
		allowDelete: true,
	});
	const scanStartedRecently = Date.now() - lastSubmit < SUBMIT_TIMEOUT;
	const scansEndpoint = `/v0/scanner/sites/${id}/scans`;
	const scans = useResource(scansEndpoint);
	const refreshScans = useResourceRefresher(scansEndpoint);
	useEffect(() => {
		const timeout = setTimeout(() => {
			setLastSubmit(0);
		}, SUBMIT_TIMEOUT);
		return () => clearTimeout(timeout);
	}, [lastSubmit]);
	const startNewScan = async () => {
		setLastSubmit(Date.now());
		const response = await request('POST', `/v0/scanner/sites/${id}/scans`);
		// TODO: response.data.queue_id refers to the queue entry's ID
		if (response.status === 'success') {
			// Since we run the scan through a queue worker we
			// don't get a response right away, so refresh a
			// couple of times after the submit to ensure we
			// see the new entry.
			refreshScans();
			setTimeout(() => {
				refreshScans();
			}, 500);
			setTimeout(() => {
				refreshScans();
			}, 1500);
		}
	};
	return (
		<Page
			title="Scanner site"
			actions={getEditorActions(editor)}
		>
			<AutoLoader error={editor.error} isLoading={editor.isLoading}>
				<SectionTitle>Edit scanner site</SectionTitle>
				<ProtoEditor key={id} editor={editor} />
				<TitleBar>
					<SectionTitle>Scans</SectionTitle>
					<ButtonBar>
						<Button disabled={scanStartedRecently} onClick={startNewScan}>Start scan</Button>
						<Button variant="secondary" onClick={refreshScans}>Refresh</Button>
					</ButtonBar>
				</TitleBar>
				{scans.data && scans.data.length ? scans.data.map((scan) => (
					<SiteScanItem key={scan.id} scan={scan} />
				)) : (
					'No scans'
				)}
			</AutoLoader>
		</Page>
	);
};

export const ViewSiteScans = ({ match }) => {
	const id = match.params.id;
	const scansEndpoint = `/v0/scanner/sites/${id}/scans`;
	const scans = useResource(scansEndpoint);
	const refreshScans = useResourceRefresher(scansEndpoint);
	const startNewScan = async () => {
		const response = await request('POST', `/v0/scanner/sites/${id}/scans`);
		if (response.status === 'success') {
			refreshScans();
		}
	};
	return (
		<Page
			title="Site scans"
			actions={(
				<ButtonBar>
					<Button variant="blue" to={`/scanner/sites/${id}`}>Edit site</Button>
					<Button onClick={startNewScan}>Start new scan</Button>
					<Button variant="secondary" onClick={refreshScans}>Refresh</Button>
				</ButtonBar>
			)}
		>
			<AutoLoader error={scans.error} isLoading={scans.isLoading}>
				{scans.data && scans.data.length ? scans.data.map((scan) => (
					<SiteScanItem key={scan.id} scan={scan} />
				)) : (
					'No scans'
				)}
			</AutoLoader>
		</Page>
	);
};

const PICK_SITES_HEADERS = [{
	label: 'ID',
	key: 'id',
}, {
	label: 'Site',
	key: 'domain',
	isWide: true,
}, {
	label: 'Approved site',
	key: 'is_approved',
	renderItem: (item) => (item.is_approved ? 'Approved' : '')
}, {
	label: 'Products',
	key: 'product_count',
}, {
	key: 'actions',
	renderItem: (item) => (
		<Fragment>
			<Button to={`/scanner/sites/new?seller_site_id=${item.site_id}`}>Pick</Button>
		</Fragment>
	)
}];

const PickSiteBasis = () => {
	const endpoint = '/v0/scanner/available-seller-sites';
	const { error, isLoading, data } = useResource(endpoint);
	return (
		<AutoLoader error={error} isLoading={isLoading}>
			<TablePage
				keyProp="id"
				headers={PICK_SITES_HEADERS}
				items={data}
				title="Pick a site for scanning"
			/>
		</AutoLoader>
	);
};
