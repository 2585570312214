.TabSwitcher {
	overflow: hidden;
	border-radius: 7px;
	display: inline-flex;
	box-shadow: var(--tight-shadow);
	color: var(--text-black);
	vertical-align: text-bottom;
	background: var(--off-white);
}
.TabSwitcher__tab {
	border-radius: 9px;
	font-size: 14px;
	line-height: 15px;
	font-weight: var(--bold);
	letter-spacing: 0.025em;
	padding: 7px 14px;
	cursor: pointer;
	appearance: none;
	border: 4px solid var(--off-white);
	border-left-width: 0;
	border-right-width: 0;
	box-sizing: content-box;
	color: var(--gray6);
	transition: background-color 150ms ease;
}
.TabSwitcher:hover .TabSwitcher__tab {
	background: transparent;
}
.TabSwitcher__tab:first-child {
	border-left-width: 4px;
}
.TabSwitcher__tab:last-child {
	border-right-width: 4px;
}
.TabSwitcher__tab--is-selected,
.TabSwitcher__tab:hover,
.TabSwitcher:hover .TabSwitcher__tab:hover {
	background: var(--gray2);
	color: var(--text-black);
}
