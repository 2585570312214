import React, { useState, forwardRef, useEffect } from 'react';
import {motion, AnimatePresence } from 'framer-motion';
import classnames from 'classnames';

import * as css from './TransparentFields.css';

const noop = () => {};
const preventDefault = (event) => {
	event.preventDefault();
	event.stopPropagation();
};

// onChange passes the value and target
export const TransparentInput = forwardRef(({
	className,
	onSubmit=noop,
	onCancel=(event) => event.target.blur(),
	hasFocusStyle=false,
	autoComplete='off',
	onChange=noop,
	onUp=noop,
	onDown=noop,
	onTab,
	...props
}, ref) => (
	<input
		className={classnames(css.TransparentInput, hasFocusStyle && css['TransparentInput--has-focus-style'], className)}
		ref={ref}
		autoComplete={autoComplete}
		onChange={(event) => onChange(event.target.value, event.target)}
		onKeyDown={(event) => {
			if (event.which === 13) {
				event.preventDefault();
				onSubmit(event);
			} else if (event.which === 27) {
				event.preventDefault();
				onCancel(event);
			} else if (event.which === 40) {
				event.preventDefault();
				onDown(event);
			} else if (event.which === 38) {
				event.preventDefault();
				onUp(event);
			} else if (event.which === 9 && onTab) {
				if (event.target.value) {
					event.preventDefault();
					onTab(event);
				}
			}
		}}
		{...props}
	/>
));

/*
options should be an array either of strings, or of {label: String, value: 'String'} objects
*/
export const TransparentSelect = ({ className, name, defaultValue='', onChange=noop, options=[], placeholder, allowBlank, passiveClassName='', addInputElement=true }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [selectedOption, setSelectedOption] = useState('');
	const handleWrapClick = (event) => {
		// If it's clicked and we already have focus
		// we've just set it to open using the focus handler,
		// so no need to toggle it
		if (document.activeElement !== event.target) {
			setIsOpen(true);
		}
	};
	const handleOptionClick = (event, option) => {
		event.stopPropagation();
		setSelectedOption(option);
		setIsOpen(false);
		onChange(option ? getOptionValue(option) || '' : '');
		document.activeElement.blur();
	};
	const handleFocus = (event) => {
		setIsOpen(true);
		event.stopPropagation();
		event.preventDefault();
	};
	const handleBlur = () => {
		setIsOpen(false);
	};
	const isPassive = !isOpen && selectedOption;
	// Update selected option when options or defaultValue changes
	useEffect(() => {
		setSelectedOption(options.find((option) => getOptionValue(option) === defaultValue));
	}, [options, defaultValue]);
	return (
		<div
			className={classnames(css.TransparentSelect, isPassive && passiveClassName, className)}
			onClick={handleWrapClick}
			tabIndex="0"
			onFocus={handleFocus}
			onBlur={handleBlur}
		>
			{addInputElement && (
				<input
					type="text"
					value={selectedOption ? getOptionValue(selectedOption) : ''}
					name={name}
					readOnly
					onClick={preventDefault}
					className={css.TransparentSelect__input}
				/>
			)}
			{selectedOption ? (
				<div className={css.TransparentSelect__value}>{getOptionLabel(selectedOption)}</div>
			) : <div className={css.TransparentSelect__placeholder}>{placeholder || '\u00A0'}</div>}


			<AnimatePresence>
				{isOpen ? (
					<motion.div
						className={css.TransparentSelect__options}
						animate={isOpen ? 'visible' : 'hidden'}
						transition={{ ease: 'easeInOut', duration: 0.15 }}
						exit="hidden"
						variants={{
							visible: { opacity: 1 },
							hidden: { opacity: 0 }
						}}
					>
						{allowBlank ? (
							<div
								onClick={(event) => handleOptionClick(event, null)}
								className={css.TransparentSelect__blankOption}
							>
								None
							</div>
						) : null}
						{options.map((option) => (
							<div
								key={getOptionValue(option)}
								onClick={(event) => handleOptionClick(event, option)}
								className={css.TransparentSelect__option}
							>
								{getOptionLabel(option)}
							</div>
						))}
					</motion.div>
				) : null}
			</AnimatePresence>
		</div>
	);
};

function getOptionLabel(option) {
	if (typeof option === 'string') {
		return option;
	}
	return option.label;
}
function getOptionValue(option) {
	if (typeof option === 'string') {
		return option;
	}
	return option.value;
}