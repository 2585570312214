.MatchList {
}
.MatchList__cutoff {
	border: none;
	font-size: 14px;
	font-weight: var(--bold);
	display: flex;
	align-items: center;
	margin: 10px 0;
}
.MatchList__cutoff:before,
.MatchList__cutoff:after {
	content: '';
	height: 1px;
	flex: 1;
	background: red;
	margin: 0 10px;
}
.MatchItem {
	display: grid;
	grid-template-columns: auto 1fr auto;
	grid-template-rows: auto auto auto;
	grid-column-gap: 10px;
	grid-row-gap: 5px;
	font-size: 18px;
	min-height: 20px;
	line-height: 25px;
	background: var(--white);
	padding: 15px;
	border-radius: 7px;
	box-shadow: var(--wide-shadow);
}
.MatchItem + .MatchItem {
	margin-top: 10px;
}
.MatchItem__media {
	width: 65px;
	height: 65px;
	background: var(--off-white);
	text-align: left;
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
	border-radius: 3px;
	overflow: hidden;
	grid-column: 1;
	grid-row: 1 / 3;
}
.MatchItem__media img {
	width: 100%;
	height: 100%;
	object-fit: contain;
	overflow: hidden;
}
.MatchItem__titles {
	align-self: center;
	grid-column: 2;
	grid-row: 1;
	display: flex;
	align-items: flex-start;
}
.MatchItem__title {
	font-weight: var(--bold);
	font-size: inherit;
	flex: 100%;
	margin: 0;
	padding: 0;
	line-height: 1em;
	line-height: 20px;
}
.MatchItem__link {
	font: inherit;
	color: inherit;
}
.MatchItem__id {
	display: inline-block;
	font-size: 14px;
	line-height: 20px;
	font-weight: var(--semibold);
	color: var(--gray4);
}
.MatchItem__meta {
	font-size: 14px;
	line-height: 20px;
	font-weight: var(--semibold);
	color: var(--gray4);
	display: flex;
	display: flex;
	grid-gap: 10px;
}
.MatchItem__id {
	display: inline-block;
}
.MatchItem__rank {
	display: inline-block;
	color: var(--black);
	font-weight: var(--bold);
}
.MatchItem__info {
	display: flex;
	grid-column: 3;
	grid-row: 1 / 3;
	align-items: center;
	justify-content: flex-end;
}
.MatchItem__info img {
	height: 26px;
}
.MatchItem__extra {
	grid-column: 1 / 4;
	grid-row: 3;
	font-size: 14px;
	line-height: 20px;
}
.MatchItem__extraTitle {
	font-weight: var(--bold);
}
.MatchItem__scores {
	margin-top: 5px;
}
.MatchItem__scoreItem {
	font-size: 13px;
	display: inline-block;
	margin-right: 0.5em;
	font-weight: var(--semibold);
	background: var(--gray1);
	padding: 0px 5px;
	border-radius: 3px;
}
.MatchItem__filters {
	margin-top: 5px;
}
.MatchItem__filterItem {
}