import React from 'react';

import { MatchEvaluatorListing } from '~/src/pages/MatchEvaluator/MatchEvaluator';

const noop = () => {};

export const GoldSifterEvaluator = ({ batch, onSubmit }) => {
	const data = [{
		id: batch.id,
		query: batch.data[0],
		query_id: batch.data[0].id,
		result: batch.question,
	}];
	const submitAnswers = (id, action, meta=null) => {
		onSubmit([{ action, ...meta }]);
	};
	return (
		<MatchEvaluatorListing
			data={data}
			onSetDone={noop}
			submitAnswers={submitAnswers}
		/>
	);
};