.ScanItem {
	display: grid;
	grid-template-rows: auto auto;
	grid-template-columns: 3fr 2fr auto;
	grid-template-areas:
		'title stats actions'
		'meta stats actions'
	;
	row-gap: 5px;
	column-gap: 10px;
	background: var(--white);
	padding: 20px 30px 20px 20px;
	border-radius: 7px;
	box-shadow: var(--card-shadow);
	margin-bottom: 30px;
}
.ScanItem__title {
	grid-area: title;
	font-size: 18px;
	font-weight: var(--bold);
	line-height: 25px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.ScanItem__meta {
	grid-area: meta;
	font-size: 14px;
	font-weight: var(--regular);
	margin-top: 5px;
	line-height: 15px;
	display: flex;
	column-gap: 20px;
}
.ScanItem__time {
	color: var(--black);
}
.ScanItem__id {
	color: var(--gray4);
}
.ScanItem__stats {
	grid-area: stats;
	display: flex;
	align-items: center;
	column-gap: 40px;
	font-size: 18px;
	font-weight: var(--bold);
	line-height: 25px;
}
.ScanItem__stats a {
	display: block;
	text-decoration: none;
}
.ScanItem__stats a:hover {
	text-decoration: underline;
}
.ScanItem__actions {
	grid-area: actions;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.red {
	color: var(--red-dark);
}
.fade {
	color: var(--gray2);
}

.ScanResultItem {
	display: grid;
	grid-template-rows: auto auto min-content;
	grid-template-areas:
		'key'
		'value'
		'meta'
	;
	row-gap: 5px;
	column-gap: 10px;
	background: var(--white);
	padding: 20px 30px 20px 20px;
	border-radius: 7px;
	box-shadow: var(--card-shadow);
	margin-bottom: 20px;
}
.ScanResultItem__key {
	grid-area: key;
	font-size: 16px;
	font-weight: var(--bold);
	line-height: 25px;
}
.ScanResultItem__value {
	grid-area: value;
	font-size: 18px;
	font-weight: var(--regular);
	line-height: 25px;
	white-space: pre-line;
}
.ScanResultItem__index {
	color: var(--gray5);
	font-weight: var(--regular);
}
.ScanResultItem__meta {
	background: var(--off-white);
	margin: 0 -10px;
	border-radius: 3px;
	padding: 5px 10px;
	grid-area: meta;
	font-size: 14px;
}
.ScanResultItem__metaItem {
	display: block;
}

.ScanErrorItem {
	display: grid;
	grid-template-rows: auto auto;
	grid-template-columns: 1fr auto;
	grid-template-areas:
		'message link'
		'stack stack'
	;
	row-gap: 5px;
	background: var(--white);
	padding: 20px 0 0 0;
	border-radius: 7px;
	box-shadow: var(--card-shadow);
	margin-bottom: 20px;
	overflow: scroll;
}
.ScanErrorItem--with-context {
	grid-template-areas:
		'context link'
		'message message'
		'stack stack'
	;
}
.ScanErrorItem__message {
	grid-area: message;
	font-size: 16px;
	padding: 0 20px 10px;
	font-weight: var(--bold);
	line-height: 25px;
	color: var(--red-dark);
}
.ScanErrorItem__context {
	grid-area: context;
	font-size: 16px;
	padding: 0 20px 10px;
	font-weight: var(--bold);
	line-height: 25px;
	color: var(--black);
}
.ScanErrorItem__link {
	grid-area: link;
	padding-right: 20px;
	font-weight: var(--bold);
}
.ScanErrorItem__stack {
	grid-area: stack;
	font-size: 14px;
	font-weight: var(--regular);
	line-height: 25px;
	background: var(--secondary-gray);
	padding: 10px 20px 15px;
}
.ScanErrorItem__stack pre {
	color: var(--black);
	font-family: monospace;
	font-size: 12px;
	tab-width: 2;
	line-height: 15px;
}