import React, { useState } from 'react';
import reverse from 'lodash/reverse';

import request from '~/src/util/request';
import { Page } from '~/src/lib/Page';
import { Button, ButtonList } from '~/src/lib/Buttons';

const handleRefreshApprovedDomains = () => request('POST', '/v0/filters/approved-domains/refresh');
const handleRefreshSellerSites = () => request('POST', '/v0/filters/seller-sites/refresh');
const handleRefreshProductURLs = () => request('POST', '/v0/filters/product-urls/refresh');
const handleRefreshTargetedRetailers = () => request('POST', '/v0/filters/targeted-retailers/refresh');
const handleRefreshMatchingCache = () => request('POST', '/v0/matching/cache/refresh');
const handleQueueProductHealthCheck = () => request('POST', '/v0/link-health/checks');
const handleRefreshSearchCache = () => request('POST', '/v0/search/cache/refresh');

export const Internals = () => {
	const [output, setOutput] = useState([]);
	const makeClickHandler = (handler) => {
		return () => {
			handler()
				.then((result) => {
					console.log(result);
					setOutput([...output, result]);
				})
				.catch((error) => {
					console.warn('Error');
					console.error(error);
					setOutput([...output, error]);
				});
		};
	};
	return (
		<Page title="Internals">
			<ButtonList>
				<h2>Caches</h2>
				<Button onClick={makeClickHandler(handleRefreshApprovedDomains)}>Refresh approved domains</Button>
				<Button onClick={makeClickHandler(handleRefreshSellerSites)}>Refresh seller sites</Button>
				<Button onClick={makeClickHandler(handleRefreshProductURLs)}>Refresh product URLs</Button>
				<Button onClick={makeClickHandler(handleRefreshTargetedRetailers)}>Refresh targeted retailers</Button>
				<Button onClick={makeClickHandler(handleRefreshMatchingCache)}>Refresh phrase map</Button>
				<Button onClick={makeClickHandler(handleRefreshSearchCache)}>Refresh search cache</Button>
			</ButtonList>
			<ButtonList>
				<h2>Link health</h2>
				<Button onClick={makeClickHandler(handleQueueProductHealthCheck)}>Queue product health check</Button>
			</ButtonList>
			<div>
				{reverse(output).map((item, index) => (
					<pre key={index}>
						{output.length - index}:{'\n'}
						{JSON.stringify(item, null, '\t')}
					</pre>
				))}
			</div>
		</Page>
	);
};
