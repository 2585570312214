import React from 'react';
import classnames from 'classnames';

import { useTitle } from '~/src/util/page-meta';
import Header from '~/src/lib/Header';
import { AutoLoader } from '~/src/lib/AutoLoader';

import * as css from './Page.css';

export const Page = ({
	className,
	header,
	backLink=true,
	title,
	subtitle,
	actions,
	lead,
	extra,
	children,
	contentWidth='normal',
	hasStickyHeader=true,
	headerProps,
	error,
	isLoading,
}) => {
	useTitle(title);
	return (
		<section className={classnames(css.Page, className)}>
			{header || ((title || actions) ? <Header backLink={backLink} title={title} subtitle={subtitle} extra={extra} actions={actions} lead={lead} isSticky={hasStickyHeader} {...headerProps} /> : null)}
			<div className={classnames(css.content, css[`content--width-${contentWidth}`])}>
				<AutoLoader error={error} isLoading={isLoading}>
					{children}
				</AutoLoader>
			</div>
		</section>
	);
}