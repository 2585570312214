.GoldSifter {}
.GoldSifter__setItem {
	margin: 0 0 10px;
	font-size: 18px;
	font-weight: var(--semibold);
	display: flex;
	align-items: center;
}
.GoldSifter__setItemButton {
	margin-left: 10px;
}
