import React, { Fragment, useState } from 'react';

import { useKeyboard } from '~/src/util/useKeyboard';
import { FeedbackButton, Button, ButtonBar, Link } from '~/src/lib/Buttons';
import { Banner } from '~/src/lib/Banner';

import { Question } from './GoldSifterQuestion';

import * as css from './GoldSifterRows.css';

const ANSWERS = {
	POSITIVE: 'positive',
	NEGATIVE: 'negative',
	SKIP: 'skip',
};

const toggleAnswer = (answer) => {
	if (answer === ANSWERS.NEGATIVE) {
		return ANSWERS.POSITIVE;
	}
	if (answer === ANSWERS.POSITIVE) {
		return ANSWERS.SKIP;
	}
	return ANSWERS.NEGATIVE;
};

const getIndexFromOrdinal = (ordinal) => {
	return ordinal === 0 ? 9 : ordinal - 1;
};

export const GoldSifterRows = ({ batch, dataset, onSubmit }) => {
	if (!batch) {
		throw new Error('Batch missing');
	}
	if (!batch.data) {
		console.info({ batch, dataset });
		throw new Error('Batch data missing');
	}
	const [answers, setAnswers] = useState(batch.data.map(() => dataset.interface.defaultAnswer || ANSWERS.POSITIVE));
	useKeyboard(({ isNumber, number, isEnter }) => {
		if (isNumber) {
			const answerIndex = getIndexFromOrdinal(number);
			setAnswers(answers.map((answer, index) => index === answerIndex ? toggleAnswer(answer) : answer));
		} else if (isEnter) {
			onSubmit(answers);
		}
	}, [answers, setAnswers]);
	const onClickItem = (ordinal, answer) => {
		const answerIndex = getIndexFromOrdinal(ordinal);
		setAnswers(answers.map((existingAnswer, index) => answerIndex === index ? answer : existingAnswer));
	};
	const itemLayout = dataset.interface.itemLayout;
	return (
		<Fragment>
			<Banner
				text={dataset.meta.instruction}
				actions={(
					<FeedbackButton size="large" variant="neutral" onClick={() => onSubmit(answers)}>Submit</FeedbackButton>
				)}
			/>
			{batch.question && (
				<Question
					question={batch.question}
					format={dataset.interface.batchQuestion}
				/>
			)}
			<div className={css.Rows}>
				{batch.data.map((item, index) => (
					<Row
						key={item[itemLayout.key || 'source_id' || 'text']}
						dataset={dataset}
						ordinal={index + 1}
						item={item}
						onClick={onClickItem}
						answer={answers[index]}
					/>
				))}
			</div>
		</Fragment>
	);
};

const Row = ({ dataset, ordinal, item, answer, onClick }) => {
	const sourceLink = getSourceLink(item);
	return (
		<div className={css.Row} style={{ opacity: answer === 'skip' ? 0.5 : 1 }}>
			{sourceLink ? (
				<Link to={sourceLink} className={css.Row__ordinal}>{ordinal}</Link>
			) : (
				<span className={css.Row__ordinal}>{ordinal}</span>
			)}
			<RowContent itemLayout={dataset.interface.itemLayout} item={item} />
			<div className={css.Row__actions}>
				<RowActions
					ordinal={ordinal}
					answer={answer}
					labels={dataset.interface.labels}
					onClick={onClick}
					inputType={dataset.interface.inputType}
				/>
			</div>
		</div>
	);
};

// Depending on the itemLayout we render different content.
// Each key in itemLayout (except `type`) refers to the key
// in each item said value should be read at.
const RowContent = ({ itemLayout, item }) => {
	const isLink = 'link' in itemLayout || 'link' in item;
	const Wrap = isLink ? 'a' : 'span';
	const wrapProps = isLink ? {
		href: item[itemLayout.link || 'link'],
		target: '_blank',
		rel: 'noopener noreferrer',
	} : null;
	if (itemLayout.type === 'text') {
		return (
			<Wrap
				{...wrapProps}
				className={css.Row__text}
			>
				{item[itemLayout.text || 'text']}
			</Wrap>
		);
	} else if (itemLayout.type === 'key-value') {
		return (
			<Wrap {...wrapProps} className={css.Row__keyValueExtra}>
				<span className={css.Row__key}>{item[itemLayout.key || 'key']}</span>
				<span className={css.Row__value}>{item[itemLayout.value || 'value']}</span>
				{itemLayout.extra && (
					<span className={css.Row__extra}>{item[itemLayout.extra || 'extra']}</span>
				)}
			</Wrap>
		);
	}
	return (
		<div className={css.Row__raw}>
			{Object.entries(item).map(([key, value]) => <div key={key}>{key}: {value}</div>)}
		</div>
	);
};

const RowActions = ({ onClick, answer, ordinal, labels, inputType }) => {
	if (inputType === 'tristate') {
		return (
			<ButtonBar>
				<Button
					onClick={() => onClick(ordinal, ANSWERS.SKIP)}
					style={{ opacity: answer === ANSWERS.SKIP ? 1 : 0.3 }}
					variant="bare"
				>
					{labels.skip || 'Skip'}
				</Button>
				<Button
					variant="yellow"
					onClick={() => onClick(ordinal, ANSWERS.NEGATIVE)}
					style={{ opacity: answer === ANSWERS.NEGATIVE ? 1 : 0.3 }}
				>
					{labels.negative || 'No'}
				</Button>
				<Button
					variant="green"
					onClick={() => onClick(ordinal, ANSWERS.POSITIVE)}
					style={{ opacity: answer === ANSWERS.POSITIVE ? 1 : 0.3 }}
				>
					{labels.positive || 'Yes'}
				</Button>
			</ButtonBar>
		);
	}
	return (
		<div>
			Unknown input type {inputType}
		</div>
	);
};

function getSourceLink(item) {
	if (item && item.source_table === 'querylog') {
		return `/querylog/${item.source_id}`;
	}
	return null;
}