import React from 'react';

import { formatPrice } from '~/src/util/format';
import { usePaginatedResource } from '~/src/util/useResource';
import { Button } from '~/src/lib/Buttons';
import { KeyVals } from '~/src/lib/Debug';
import { Page } from '~/src/lib/Page';

import * as css from './SearchInspector.css';

export const SearchInspector = ({ match }) => {
	const { error, isLoading, data } = usePaginatedResource(`/v0/search/log/${match.params.id}`);
	console.log(data);
	if (!data) {
		return 'Loading';
	}
	return (
		<Page
			error={error}
			isLoading={isLoading}
			title={data.query.text ? `Search for "${data.query.text}"` : ''}
		>
			<div className={css.SearchInspector}>
				<section>
					<h2>Query</h2>
					<KeyVals data={data.query} />
				</section>
				<section>
					<h2>Products</h2>
					<div className={css.ProductGrid}>
						{data.products.length ? (
							data.products.map((product) => (
								<div key={product.id} className={css.Product}>
									<a href={`/products/${product.id}`} className={css.Product__image} target="_blank">
										<img src={product.image_url} alt={product.title} />
									</a>
									<a href={`/products/${product.id}`} className={css.Product__title} target="_blank">
										{product.title}
									</a>
									<p className={css.Product__price}>{formatPrice(product.price)}</p>
								</div>
							))
						) : ('No products')}
					</div>
				</section>
			</div>
		</Page>
	);
};
