import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { useResource } from '~/src/util/useResource';
import { Spread } from '~/src/lib/Layout';
import { Button, ButtonBar } from '~/src/lib/Buttons';
import { ProtoEditor, useEditor, getEditorActions } from '~/src/lib/ProtoEditor';
import { Page } from '~/src/lib/Page';
import { TablePage } from '~/src/lib/Table';

const useProductTerms = () => useResource('/v0/knowledge/product-terms');

const PT_HEADERS = [{
	label: 'ID',
	key: 'id',
}, {
	label: 'Term',
	key: 'term',
	isWide: true,
}, {
	key: 'actions',
	renderItem: (item) => (
		<ButtonBar>
		<Spread />
			<Button variant="neutral" to={`/knowledge/product-terms/${item.id}`}>Edit</Button>
		</ButtonBar>
	)
}];

const ProductTermsHome = () => {
	const { isLoading, error, data } = useProductTerms();
	return (
		<TablePage
			keyProp="id"
			headers={PT_HEADERS}
			items={data}
			title="Product terms"
			isLoading={isLoading}
			error={error}
			actions={<Button to="/knowledge/product-terms/new">Create new</Button>}
		/>
	);
};

const EDITOR_FIELDS = [{
	name: 'term',
	label: 'Term',
	type: 'text',
	autoFocus: true
}];

const ViewProductTerm = ({ match }) => {
	const termId = match.params.id;
	const isNew = termId === 'new';
	console.log('isNew', isNew);
	const editor = useEditor({
		endpoint: `/v0/knowledge/product-terms/${termId}`,
		createEndpoint: '/v0/knowledge/product-terms',
		fields: EDITOR_FIELDS,
		isNew,
		newItem: null,
		// We pass in our own refresh handler as the resource we're loading
		// the terms from is not the same as the resource we're posting to
	});
	return (
		<Page
			title={isNew ? 'Add product term' : 'Edit product term'}
			actions={getEditorActions(editor)}
			isLoading={editor.isLoading}
			error={editor.error}
		>
			<ProtoEditor key={termId} editor={editor} />
		</Page>
	);
};

export const ProductTerms = () => (
	<Switch>
		<Route exact path="/knowledge/product-terms/:id" component={ViewProductTerm} />
		<Route component={ProductTermsHome} />
	</Switch>
);