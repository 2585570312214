import React, { Component, Fragment } from 'react';

import request from '~/src/util/request';
import notify from '~/src/util/notify';
import { Form, Field, ToggleSwitch, TextArea } from '~/src/lib/Form';
import { Page } from '~/src/lib/Page';
import { TablePage } from '~/src/lib/Table';
import { Button } from '~/src/lib/Buttons';
import { Row } from '~/src/lib/Layout';

const HEADERS = [{
	label: 'Query',
	key: 'query',
}, {
	label: 'Products',
	key: 'count',
	renderItem: (row) => row.data && row.data.products && row.data.products.length,
}, {
	label: 'Top match',
	key: 'name',
	renderItem: (row) => row.status === 'error' ? row.message : (
		row.data && row.data.products && row.data.products.length ? `${row.data.products[0].name} (${row.data.products[0].product_id})` : '-'
	),
}, {
	label: 'Score',
	key: 'rank',
	renderItem: (row) => row.data && row.data.products && row.data.products.length ? row.data.products[0].rank : '-',
}, {
	key: 'view',
	renderItem: (row) => (
		<Button target="_blank" to={`/matching/debug?query=${encodeURIComponent(row.query)}`}>All matches</Button>
	)
}];

const runSequentially = async (list, fn) => {
	const results = [];
	for (let item of list) {
		results.push(await fn(item));
	}
	return results;
}

export class MultiMatchingDebugger extends Component {
	constructor(props) {
		super(props);
		this.state = {
			results: null,
			isLoading: false,
			hasError: false,
			showUnpublished: false,
			showOutOfStock: false,
			showLowRanks: false,
			showCustomScores: false,
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handlePublishedToggle = this.handlePublishedToggle.bind(this);
		this.handleLowRankToggle = this.handleLowRankToggle.bind(this);
		this.handleCustomScoreToggle = this.handleCustomScoreToggle.bind(this);
	}
	handleSubmit(data) {
		if (!data.query) {
			this.setState({
				hasError: true,
			});
			notify.error('Missing query');
			return;
		}
		this.submitQueries({ custom_ranks: data.custom_ranks, queries: data.query.trim().split('\n') });
	}
	async submitQueries({ custom_ranks, queries }) {
		this.setState({
			hasError: false,
			isLoading: true,
		});
		const results = await runSequentially(queries, (query) => this.submitQuery({ custom_ranks, query }));
		results.forEach((result) => {
			if (result.status === 'error') {
				console.warn('\n', 'Query:', result.query, '\n', 'Error:', result.message);
			} else if (result.data) {
				console.info('\n', 'Query:', result.query, '\n', 'Products:', ...result.data.products);
			} else {
				console.warn('\n', 'Unknown:', result);
			}
		});
		this.setState({
			results: results,
			isLoading: false,
		});
	}
	async submitQuery(data) {
		const { showUnpublished, showOutOfStock, showLowRanks } = this.state;
		const minRank = showLowRanks ? '0.4' : '';
		const result = await request('POST', `/v0/findProductMatch?include_unpublished=${showUnpublished}&include_out_of_stock=${showOutOfStock}&min_rank=${minRank}`, data);
		return {
			query: data.query,
			...result,
		};
	}
	handlePublishedToggle(event) {
		this.setState({
			showUnpublished: event.target.checked,
		});
	}
	handleStockToggle(event) {
		this.setState({
			showOutOfStock: event.target.checked,
		});
	}
	handleLowRankToggle(event) {
		this.setState({
			showLowRanks: event.target.checked,
		});
	}
	handleCustomScoreToggle(event) {
		this.setState({
			showCustomScores: event.target.checked,
		});
	}
	render() {
		const { results, hasError, isLoading, showCustomScores } = this.state;
		return (
			<Fragment>
				<Page title="Multi-search">
					<Form onSubmit={this.handleSubmit}>
						<TextArea name="query" label="Titles" subLabel="One query per line" isSimple />
						<Row>
							<Row>
								<ToggleSwitch
									label="Show unpublished?"
									name="include_unpublished"
									defaultValue={this.state.showUnpublished}
									isInline
									onChange={this.handlePublishedToggle}
								/>
								<ToggleSwitch
									label="Show out of stock?"
									name="include_out_of_stock"
									defaultValue={this.state.showOutOfStock}
									isInline
									onChange={this.handleStockToggle}
								/>
								<ToggleSwitch
									label="Show all > 0.4"
									name="include_low_rank"
									defaultValue={this.state.showLowRanks}
									isInline
									onChange={this.handleLowRankToggle}
								/>
								<ToggleSwitch
									label="Custom scores"
									defaultValue={this.state.showCustomScores}
									isInline
									onChange={this.handleCustomScoreToggle}
								/>
							</Row>
							<Button type="submit">Search</Button>
						</Row>
						{showCustomScores && (
							<Row>
								<Field label="Name" isSimple name="custom_ranks[name]" defaultValue={1.0} />
								<Field label="Keyword" isSimple name="custom_ranks[keywords]" defaultValue={1.0} />
								<Field label="Category" isSimple name="custom_ranks[category]" defaultValue={0.5} />
								<Field label="Attribute" isSimple name="custom_ranks[attributes]" defaultValue={0.25} />
							</Row>
						)}
					</Form>
					{hasError && (<p>Error</p>)}
					{isLoading && (<p>Loading</p>)}
				</Page>
				{results && (
					<TablePage
						keyProp="query"
						headers={HEADERS}
						items={results}
						title="Results"
					/>
				)}
			</Fragment>
		);
	}
}
