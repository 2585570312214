import * as Sentry from '@sentry/react';

export function reportError(error, extras) {
	if (!process.env.SENTRY_DSN) {
		console.warn('Sentry is not enabled. Reported error was not transmitted:');
		console.warn(error);
		if (extras) {
			console.info(extras);
		}
	}
	Sentry.withScope(function(scope) {
		if (extras) {
			scope.setExtras(extras);
		}
		Sentry.captureException(error);
	});
	return error;
}