import React, { Component } from 'react';
import { EventEmitter } from 'fbemitter';

function call(fn, ...args) {
	if (typeof fn === 'function') {
		return fn(...args);
	}
}

export function makeStore({ save, load } = {}) {
	const emitter = new EventEmitter();

	let data;

	try {
		data = call(load) || {};
	} catch (error) {
		console.warn(error);
		data = {};
		call(save, data);
	}

	// Save to the local storage
	emitter.addListener('change', () => {
		call(save, data);
	});

	const triggerChange = () => emitter.emit('change');

	return {
		all: () => data,
		update: (change) => {
			data = {
				...data,
				...change,
			};
			triggerChange();
		},
		listen: (callback) => emitter.addListener('change', callback),
		get: (key) => data[key],
		reload: () => {
			data = call(load) || {};
			return data;
		}
	};
}

const globalStore = makeStore({
	save: (data) => localStorage.setItem('store', JSON.stringify(data)),
	load: () => JSON.parse(localStorage.getItem('store')),
});


const withStore = (WrappedComponent) => {
	return class extends Component {
		constructor(props) {
			super(props);
			this.handleChange = this.handleChange.bind(this);
		}
		componentDidMount() {
			this.subscription = globalStore.listen(this.handleChange);
		}
		componentWillUnmount() {
			this.subscription.remove();
		}
		handleChange() {
			this.setState({
				random: Math.random(),
			});
		}
		render() {
			return <WrappedComponent updateStore={globalStore.update} store={globalStore.all()} {...this.props} />;
		}
	}
}

export const updateStore = (data) => {
	return globalStore.update(data);
}

export const getStoreState = () => {
	return globalStore.all();
};

export const reloadStoreState = () => {
	return globalStore.reload();
};

export default withStore;
