.BlocksEditor {
	display: flex;
	flex-direction: column;
	grid-gap: 20px;
	--field-shadow: none;
	--field-focus-color: transparent;
}
.BlocksEditor__blocks {
	display: flex;
	flex-direction: column;
	grid-gap: 20px;
}
.BlocksEditor__actions {
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
	grid-gap: 20px;
}
.Block {
	position: relative;
	display: grid;
	grid-template-columns: 1fr auto;
	grid-template-rows: 1fr;
	grid-template-areas: 'inner actions';
}
.Block__inner {
	grid-area: inner;
	background: var(--white);
	padding: 10px;
	border-radius: 7px;
	display: grid;
	grid-template-columns: 60px 1fr;
	grid-template-rows: 1fr;
	grid-template-areas: 'type content';
	grid-gap: 20px;
}
.Block__actions {
	grid-area: actions;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding: 0 5px;
	grid-gap: 10px 0;
	opacity: 0;
	transition: opacity 150ms ease;
}
.Block:hover .Block__actions {
	opacity: 1;
}
.Block__actionButton {
	background: var(--gray1);
}
.Block__type {
	position: relative;
	grid-area: type;
	font-size: 14px;
	font-weight: var(--bold);
	display: flex;
	align-items: center;
	justify-content: center;
}
.Block__typeBox {
	background-color: var(--secondary-gray);
	border-radius: 5px;
	height: 55px;
	flex: 1;
	align-self: stretch;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	word-break: break-all;
}
.Block__type img {
	display: block;
}
.Block__variantButton,
.Block__variantOption {
	appearance: none;
	color: inherit;
	font: inherit;
	background-color: var(--secondary-gray);
	height: 55px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	font-weight: var(--bold);
	box-shadow: none;
	border: 1px solid transparent;
	border-radius: 5px;
}
.Block__variantButton:hover,
.Block__variantOption:hover {
	background-color: var(--gray2);
	box-shadow: none;
}
.Block__variantButton:focus-visible,
.Block__variantOption:focus-visible {
	border-color: gray;
}
.Block__variantButton {
	flex: 1;
	align-self: stretch;
	cursor: pointer;
	background-image: url('~/src/icons/chevron-down.svg');
	background-size: 14px auto;
	background-position: 44px 2px;
	background-repeat: no-repeat;
	padding: 0;
	border-radius: 5px;
}
.Block__variantSelector {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
	flex-direction: column;
	border-radius: 5px;
	overflow: hidden;
	background: var(--secondary-gray);
}
.Block__variantSelector--is-open {
	z-index: var(--index-block-variant-selector);
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1), 0 1px 14px rgba(0, 0, 0, 0.1);
}
.Block__content {
	grid-area: content;
	display: grid;
}