import React, { Fragment, useState } from 'react';
import orderBy from 'lodash/orderBy';

import { Page } from '~/src/lib/Page';
import Resource from '~/src/lib/Resource';
import { FeedbackButton as Button, ButtonBar, Link } from '~/src/lib/Buttons';
import { InlineDiff } from '~/src/lib/InlineDiff';
import * as ActionCard from '~/src/lib/ActionCard';
import request from '~/src/util/request';
import { formatDomain, formatTimeAgo } from '~/src/util/format';

const Lead = () => (
	<Fragment>
		<p>Every night the link health detector checks all published products and attempts to find potential issues, these are shown below.</p>
		<p>Go through each, opening the product page if needed, and reject or confirm the changes.</p>
		<p>Click the product name to open the full product editor. If a product is manually edited its outstanding issues are cleared.</p>
		<p>If a detected issue seems wrong, please press the report button next to each issue header.</p>
		<p>If you notice anything that feels wrong or weird, please report it in the #dev slack channel.</p>
		<p>Need help? Ping @magnus in the #dev channel with your issue and he’ll help you as soon as possible</p>
	</Fragment>
);

const handleQueueProductHealthCheck = () => request('POST', '/v0/link-health/checks');

const Actions = ({ reload }) => (
	<Fragment>
		<Button onClick={reload}>Refresh</Button>
		<Button variant="neutral" onClick={handleQueueProductHealthCheck}>Queue product health check</Button>
	</Fragment>
);

export const LinkHealth = () => (
	<Resource endpoint="/v0/link-health/issues">
		{({ data, refresh }) => (
			<Page
				title="Link health"
				actions={<Actions reload={refresh} />}
				contentWidth="medium"
				lead={<Lead />}
			>
				<IssuesList data={data} />
			</Page>
		)}
	</Resource>
);

const IssuesList = ({ data }) => {
	if (!data.length) {
		return (
			<h2>No issues</h2>
		);
	}
	return (
		<Fragment>
			{data.map((product) => (
				<ProductIssueCard key={product.id} product={product} />
			))}
		</Fragment>
	);
};

const ProductIssueCard = ({ product }) => {
	const [isCardVisible, setIsCardVisible] = useState(true);
	const [items, setItems] = useState(
		orderBy(product.issues, ['priority'], ['desc']).map((issue) => ({ issue, isVisible: true }))
	);
	const onAction = (actionKey, issue) => {
		submitIssueAction(actionKey, issue);
		let visibleIssueCount = 0;
		setItems(items.map((item) => {
			if (item.issue.id === issue.id) {
				return {
					issue,
					isVisible: false,
				};
			}
			if (item.isVisible) {
				visibleIssueCount++;
			}
			return item;
		}));
		if (!visibleIssueCount) {
			setIsCardVisible(false);
		}
	};
	return (
		<ActionCard.ActionCard isVisible={isCardVisible}>
			<ActionCard.Header>
				<ActionCard.Heading href={`/products/${product.id}`}>{product.title}</ActionCard.Heading>
				<ActionCard.SubHeading>{product.id}</ActionCard.SubHeading>
				<ActionCard.Spread />
				<ActionCard.Source href={product.url}>{formatDomain(product.url)}</ActionCard.Source>
			</ActionCard.Header>
			{items.map(({ issue, isVisible }) => (
				<IssueSlot
					key={issue.id}
					issue={issue}
					onAction={onAction}
					isVisible={isVisible}
				/>
			))}
		</ActionCard.ActionCard>
	);
};

const IssueSlot = ({ issue, isVisible, onAction }) => {
	const actions = getActionsForIssue(issue);
	const reportIssue = () => {
		alert(`Please post in the #dev slack room tagging @magnus, quoting the ID of the issue: ${issue.id}`);
	};
	return (
		<ActionCard.Slot isVisible={isVisible}>
			<ActionCard.SlotHeader>
				<ActionCard.SlotTitle>{issue.title}</ActionCard.SlotTitle>
				<ActionCard.SlotHeaderSub>{formatTimeAgo(issue.created_at)}</ActionCard.SlotHeaderSub>
				<ActionCard.SlotHeaderSub>
					<Link variant="subtle" onClick={reportIssue}>Report</Link>
				</ActionCard.SlotHeaderSub>
				{issue.extras && issue.extras.length ? (
					issue.extras.map((extra) => (
						<ActionCard.SlotHeaderSub key={extra}>{extra}</ActionCard.SlotHeaderSub>
					))
				) : null}
			</ActionCard.SlotHeader>
			<ActionCard.SlotActions>
				<ButtonBar lozenge>
					{actions.map((action) => (
						<Button
							shape="square"
							key={action.label}
							onClick={() => onAction(action.name, issue)}
							variant={action.buttonStyle}
						>
							{action.label}
						</Button>
					))}
				</ButtonBar>
			</ActionCard.SlotActions>
			<ActionCard.SlotBody>
				{'description' in issue && <p>{issue.description}</p>}
				{renderIssueBody(issue)}
			</ActionCard.SlotBody>
		</ActionCard.Slot>
	);
}

function submitIssueAction(action, issue) {
	const endpoint = `/v0/link-health/issues/${issue.id}`;
	return request('POST', endpoint, { action });
}

function getActionsForIssue(issue) {
	if (!issue.actions) {
		return [];
	}
	return issue.actions.map((action) => ({
		name: action.action,
		label: action.label,
		buttonStyle: action.buttonStyle,
	}))
}

function renderIssueBody(issue) {
	switch (issue.meta.type) {
		case 'error':
			return (
				<p>
					Error: <span style={{ color: 'hsl(5, 99%, 55%)'}}>{issue.meta.code} {issue.meta.message}</span>
				</p>
			);
		case 'warning':
			return (
				<p>
					<span style={{ color: 'hsl(30, 99%, 55%)'}}>{issue.meta.message}</span>
				</p>
			);
		case 'diff':
			return (
				<InlineDiff oldValue={issue.meta.oldValue} newValue={issue.meta.newValue} />
			);
		case 'value_update':
			return (
				<p>
					<span style={{ textDecoration: 'line-through' }}>
						{renderValue(issue.nice ? issue.nice.oldValue : issue.meta.oldValue, issue.meta.valueType)}
					</span>
					<span style={{ marginLeft: 10 }}>
						{renderValue(issue.nice ? issue.nice.newValue : issue.meta.newValue, issue.meta.valueType)}
					</span>
				</p>
			);
		default:
			return null;
	}
}

function renderValue(value, valueType) {
	switch (valueType) {
		case 'price':
			if (value.currency === 'Unknown') {
				return `${value.amount}`;
			}
			return `${value.amount} ${value.currency}`;
		case 'bool':
			return value ? 'True' : 'False';
		default:
			return value === undefined ? 'Not set' : value;
	}
}