.Loader {
	padding: 20px var(--pad-x);
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
	font-size: 30px;
	font-weight: var(--bold);
	color: var(--gray4);
}
.Error {}
.Error__message {
	color: var(--red-dark);
}