import isNil from 'lodash/isNil';
import {
	formatDistance,
	formatDistanceStrict,
	formatDuration as dateFnsFormatDuration,
	intervalToDuration,
	format as dateFnsFormatDate,
} from 'date-fns';
import { parseDate } from './parse';

const NBSP = String.fromCharCode(160);

export function possessive(str) {
	if (str.endsWith('s')) {
		return `${str}'`;
	}
	return `${str}'s`;
}

export function pluralize(number, one, many) {
	if (typeof number === 'string') {
		return pluralize(parseInt(number, 10), one, many);
	}
	if (number === 1) {
		return one;
	}
	return many;
}

export function formatDomain(str='') {
	return str
		// Protocol
		.replace(/https?:\/\//, '')
		// Remove anything after the first slash
		.replace(/\/.+/, '')
		// www.
		.replace('www.', '')
	;
}

export function formatLink(str='') {
	if (typeof str !== 'string') {
		return str;
	}
	return str
		// Remove protocol
		.replace(/https?:\/\//, '')
		// Remove trailing slashes
		.replace(/\/+$/, '')
		// Remove www.
		.replace('www.', '')
	;
}

export function removeOrigin(url='') {
	return url
		// Remove protocol
		.replace(/https?:\/\/.+?\//, '/')
		// Remove trailing slashes
		.replace(/\/+$/, '')
	;
}

export function formatUrlPattern(pattern, data) {
	if (typeof pattern !== 'string') {
		throw new Error(`formatUrlPattern's first argument must be a pattern string (is "${typeof pattern}")`);
	}
	if (typeof data !== 'object') {
		throw new Error(`formatUrlPattern's first argument must be a pattern string (is "${typeof data}")`);
	}
	return pattern.replace(/:[a-zA-Z_]+/g, (rawKey) => {
		const key = rawKey.substring(1);
		if (!(key in data)) {
			throw new Error(`Key "${key}" not found in data`);
		}
		return data[key];
	});
}

export function formatTimeAgo(date, comparedWith=new Date()) {
	return formatDistance(parseDate(date), parseDate(comparedWith), 'YYYY-MM-DD HH:mm:ss');
}

export function formatTimeAgoStrict(date, comparedWith=new Date()) {
	return formatDistanceStrict(parseDate(date), parseDate(comparedWith), 'YYYY-MM-DD HH:mm:ss');
}

export function formatDuration(durationInMilliseconds) {
	return dateFnsFormatDuration(intervalToDuration({ start: 0, end: durationInMilliseconds }));
}

export function formatDate(date) {
	return dateFnsFormatDate(date, 'yyyy-MM-dd');
}

export const truncate = (str, maxLength=150) => {
	if (!str) {
		return '';
	}
	if (str.length > maxLength * 1.1) {
		return `${str.slice(0, maxLength)}…`
	}
};

export const noOrphan = (text) => text.replace(/\s(?=[^\s]+$)/, NBSP);

export function formatQueryString(inputs) {
	return Object
		.entries(inputs)
		.map(([key, value]) => isNil(value) ? null : `${key}=${encodeURIComponent(value)}`)
		.filter((exists) => exists)
		.join('&')
	;
}

const CURRENCY_SYMBOLS = {
	USD: '$',
	GBP: '£',
	EUR: '€',
};

export function formatPrice(price) {
	if (!price) {
		return '';
	}
	return `${CURRENCY_SYMBOLS[price.currency] || ''}${price.amount}`;
}
