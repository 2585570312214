import React, { useState, useRef } from 'react';

import { TransparentInput, TransparentSelect } from '~/src/lib/Form';

import * as css from './PriceEditor.css';

const noop = (value) => console.log('PriceEditor.onChange', value);

const CURRENCIES = [{
	label: 'GBP',
	value: 'GBP',
}, {
	label: 'EUR',
	value: 'EUR',
}, {
	label: 'USD',
	value: 'USD',
}];

export const PriceEditor = ({ defaultValue, autoFocus, onSubmit, onCancel, names, onChange=noop }) => {
	const wrapRef = useRef();
	const [value, setValue] = useState(defaultValue || { currency: '', amount: '' });
	if (!(names && names.currency && names.amount)) {
		throw new Error('PriceEditor.names must be provided ({ currency, amount })');
	}
	const handleChange = (key, input) => {
		const newValue = { ...value };
		if (key === 'amount') {
			newValue.amount = input;
		} else if (key === 'currency') {
			newValue.currency = input;
		}
		setValue(newValue);
		onChange(newValue);
	};
	return (
		<div className={css.PriceEditor} ref={wrapRef}>
			<TransparentInput
				className={css.PriceEditor__amount}
				hasFocusStyle
				name={names && names.amount}
				type="text"
				autoFocus={autoFocus}
				placeholder="Amount"
				defaultValue={value.amount}
				onSubmit={() => onSubmit(value)}
				onCancel={onCancel}
				onChange={(value) => handleChange('amount', value)}
				style={{ width: getWidthOfInput(value.amount) }}
			/>
			<TransparentSelect
				className={css.PriceEditor__currency}
				name={names && names.currency}
				defaultValue={value.currency}
				options={CURRENCIES}
				onChange={(value) => handleChange('currency', value)}
				placeholder="Currency"
			/>
		</div>
	);
};

// TODO: A better method would be to output the value
// in a dom element and shape the amount input to fit
function getWidthOfInput(amount) {
	return `${String(amount).replace('.', '').length * 0.8}em`;
}