import React from 'react';

import { Button } from '~/src/lib/Buttons';
import { TablePage } from '~/src/lib/Table';
import { useResource } from '~/src/util/useResource';
import { AutoLoader } from '~/src/lib/AutoLoader';

const HEADERS = [{
	label: 'Origin',
	key: 'origin',
	format: 'domain',
	isWide: true,
}, {
	label: 'Scans (done / total)',
	key: 'scans',
	renderItem: (item) => `${item.done_scans} / ${item.done_scans + item.remaining_scans}`
}, {
	label: 'Created',
	key: 'created_at',
	format: 'time',
}, {
	label: 'Scanned',
	key: 'finished_scanning_at',
	format: 'time',
}, {
	key: 'view',
	renderItem: (item) => (
		<Button to={`/product-imports/imports/${item.id}/products`}>Import products</Button>
	)
}];

export const ListImports = () => {
	const resource = useResource('/v0/product-importer/imports');
	return (
		<AutoLoader error={resource.error} isLoading={resource.isLoading}>
			<TablePage
				keyProp="id"
				headers={HEADERS}
				items={resource.data}
				title="Imports"
			/>
		</AutoLoader>
	);
};
