import React, { Component, Fragment, createRef } from 'react';
import classnames from 'classnames';

import notify from '~/src/util/notify';
import { Hidden } from './Fields';
import { BaseField, Input } from './core';

import * as css from './Select.css';

export const Select = ({ defaultValue, name, prefix, options, allowInsert, onChange, ...props }) => (
	<BaseField
		{...props}
		tabIndex="-1"
		noFocusStyle
		className={css.SelectField}
		prefix={prefix}
	>
		<SelectInput
			name={name}
			defaultValue={defaultValue}
			options={options}
			allowInsert={allowInsert}
			onChange={onChange}
		/>
	</BaseField>
);

export class SelectInput extends Component {
	constructor(props) {
		super(props);
		this.state = {
			index: props.options.findIndex((option) => option.value === this.props.defaultValue),
			selectedValue: this.props.defaultValue,
			isOpen: false,
			query: '',
			filteredOptionValues: props.options.map((option) => option.value),
			optionsByValue: props.options.reduce((keep, option) => {
				keep[option.value] = option;
				return keep;
			}, {}),
		};
		this.inputElement = createRef();
		this.handleOptionsClick = this.handleOptionsClick.bind(this);
		this.handleOptionsMouseDown = this.handleOptionsMouseDown.bind(this);
		this.handleOptionsMouseUp = this.handleOptionsMouseUp.bind(this);
		this.handleFieldClick = this.handleFieldClick.bind(this);
		this.handleCreateNewClick = this.handleCreateNewClick.bind(this);
		this.handleInputKeyDown = this.handleInputKeyDown.bind(this);
		this.handleInputBlur = this.handleInputBlur.bind(this);
		this.handleInputFocus = this.handleInputFocus.bind(this);
		this.handleInputClick = this.handleInputClick.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleArrowClick = this.handleArrowClick.bind(this);
	}
	getInitialSelection(currentValue) {
		const option = this.props.options.find(
			(option) => option.value === currentValue
		);
		if (option) {
			return option.value;
		}
		return '';
	}
	getPlaceholder() {
		const { selectedValue, isLoading, isOpen } = this.state;
		const option = this.getOptionByValue(selectedValue);
		if (!option) {
			return isLoading ? 'Loading...' : (isOpen ? 'Type to filter...' : 'Select...');
		}
		if (option.group) {
			return `${option.group} / ${option.label}`;
		}
		return option.label;
	}
	isOptionSelected(option) {
		return this.state.selectedValue === option.value;
	}
	isOverflowSelected() {
		return this.state.index === this.state.filteredOptionValues.length;
	}
	isInputSelected() {
		return this.state.index === -1;
	}
	doesOptionMatchSearch(option, query) {
		if (!query) {
			return true;
		}
		return (option.search || option.label.toLowerCase()).indexOf(query) !== -1;
	}
	toggleOpen() {
		if (this.state.isOpen) {
			this.setClosed();
		} else {
			this.setOpen();
		}
	}
	setOpen() {
		if (!this.state.isOpen) {
			this.setState({
				isOpen: true,
			});
		}
	}
	setClosed() {
		if (this.state.isOpen) {
			this.setState({
				isOpen: false,
			});
			this.clearSearch();
		}
	}
	setSelectionByValue(value) {
		const option = this.getOptionByValue(value);
		this.emitChange(option.value);
		this.setState({
			selectedValue: option.value,
			index: this.getIndexOfValue(option.value),
		});
	}
	setSelectionByIndex(inputIndex) {
		const index = this.wrapIndex(inputIndex);
		const value = this.getValueByIndex(index) || null;
		this.emitChange(value);
		this.setState({
			selectedValue: value,
			index,
		});
	}
	setSearch(inputQuery) {
		const query = inputQuery.toLowerCase();
		const filteredOptionValues = this.props.options
			.filter((option) => this.doesOptionMatchSearch(option, query))
			.map((option) => option.value)
		;
		this.setState({
			query,
			index: -1,
			filteredOptionValues,
		});
	}
	getOptionByValue(value) {
		return this.state.optionsByValue[value];
	}
	getIndexOfValue(value) {
		return this.state.filteredOptionValues.findIndex((checkValue) => checkValue === value);
	}
	getVisibleOptions() {
		return this.state.filteredOptionValues
			.map((value) => this.getOptionByValue(value))
			.filter((option) => option)
		;
	}
	getValueByIndex(index) {
		return this.state.filteredOptionValues[index];
	}
	wrapIndex(index) {
		// We intentionally use `length` (vs `length - 1`)
		// and `index > length` (vs `index >= length`) to
		// add one "overflow" position where "create new"
		// can appear.
		if (index < -1) {
			return this.state.filteredOptionValues.length;
		} else if (index > this.state.filteredOptionValues.length) {
			return -1;
		}
		return index;
	}
	emitChange(value) {
		if (this.props.onChange) {
			this.props.onChange(value);
		}
	}
	addOption(option) {
		this.props.options.unshift(option);
		this.setState({
			optionsByValue: Object.assign({[option.value]: option}, this.state.optionsByValue),
		});
	}
	clearSearch() {
		this.inputElement.current.value = '';
		this.inputElement.current.blur();
		this.setState({
			query: '',
			filteredOptionValues: this.props.options.map((option) => option.value),
		});
	}
	handleArrowClick(event) {
		event.preventDefault();
		event.stopPropagation();
		this.toggleOpen();
	}
	handleOptionsClick(event) {
		// This is a little hacky, but the user might click the group label
		// instead of the main option, in which case we make sure to select
		// the parent node which is the one with the data-value set on it.
		const target = event.target.hasAttribute('data-value') ? event.target : event.target.parentNode;
		if (!target.hasAttribute('data-value')) {
			return;
		}
		const value = target.getAttribute('data-value');
		event.preventDefault();
		event.stopPropagation();
		this.setSelectionByValue(value);
		this.setClosed();
	}
	handleOptionsMouseDown() {
		// If the user clicks on the options somewhat slowly,
		// with a long button press, the input blur event
		// will close the dropdown before the options click
		// is registered. So we have this switch to stop the
		// blur handler from closing the dropdown in case we
		// are in the middle of an option click.
		this.isClickingOptions = true;
	}
	handleOptionsMouseUp() {
		this.isClickingOptions = false;
	}
	handleFieldClick(event) {
		if (this.state.isOpen) {
			event.stopPropagation();
			event.preventDefault();
			this.setClosed();
		}
	}
	handleCreateNewClick(event) {
		event.preventDefault();
		event.stopPropagation();
		this.createNewItem(this.inputElement.current.value);
	}
	handleInputKeyDown(event) {
		switch (event.which) {
			case 13: // enter
				event.stopPropagation();
				event.preventDefault();
				if (this.props.allowInsert) {
					if (event.target.value) {
						if (this.isOverflowSelected()) {
							this.createNewItem(this.inputElement.current.value);
						} else if (this.isInputSelected()) {
							this.setSelectionByIndex(0);
							this.setClosed();
						} else {
							this.setClosed();
						}
					} else {
						this.setClosed();
					}
				} else {
					if (this.isOverflowSelected() || this.isInputSelected()) {
						if (this.state.filteredOptionValues.length === 1) {
							this.setSelectionByValue(this.state.filteredOptionValues[0]);
							this.setClosed();
						} else {
							this.setSelectionByIndex(0);
						}
					} else {
						this.setClosed();
					}
				}
				break;
			case 27: // escape
				this.setClosed();
				break;
			case 40: // down
				this.setSelectionByIndex(this.state.index + 1);
				break;
			case 38: // up
				this.setSelectionByIndex(this.state.index - 1);
				break;
		}
	}
	handleInputBlur() {
		if (!this.isClickingOptions) {
			this.setClosed();
		}
	}
	handleInputFocus() {
		this.setOpen();
	}
	handleInputClick(event) {
		event.preventDefault();
		event.stopPropagation();
	}
	handleInputChange(event) {
		this.setSearch(event.target.value);
	}
	async createNewItem(inputValue) {
		const { allowInsert } = this.props;
		this.setClosed();
		this.setState({ isLoading: true });
		const result = await allowInsert.onInsert(inputValue);
		if (!(result && result.value)) {
			notify.error('Failed to insert new');
		}
		this.setState({ isLoading: false });
		this.addOption(result);
		this.setSelectionByValue(result.value);
	}
	renderOptions() {
		const { prefix, allowInsert } = this.props;
		const { query } = this.state;
		const visibleOptions = this.getVisibleOptions();
		return (
			<Fragment>
				{visibleOptions.map((option) => (
					<div
						key={option.value}
						className={classnames(
							css.SelectInput__option,
							this.isOptionSelected(option) && css['SelectInput__option--is-selected']
						)}
						data-value={option.value}
						data-prefix={prefix}
					>
						{option.group && (
							<span className={css.SelectInput__group}>{option.group} / </span>
						)}
						{option.label}
					</div>
				))}
				{allowInsert && query && (
					<div
						className={classnames(
							css.SelectInput__option,
							css['SelectInput__option--create-new'],
							this.isOverflowSelected() && css['SelectInput__option--is-selected']
						)}
						onClick={this.handleCreateNewClick}
					>
						Create “{query}”
					</div>
				)}
			</Fragment>
		);
	}
	render() {
		const { name } = this.props;
		const { selectedValue, isOpen } = this.state;
		return (
			<div className={classnames(
				css.SelectInput,
				isOpen && css['SelectInput--is-open'],
				selectedValue && css['SelectInput--has-selection'],
			)}>
				<Input
					autoFocus={isOpen}
					ref={this.inputElement}
					defaultValue=""
					placeholder={this.getPlaceholder()}
					className={css.SelectInput__input}
					onKeyDown={this.handleInputKeyDown}
					onClick={this.handleInputClick}
					onBlur={this.handleInputBlur}
					onFocus={this.handleInputFocus}
					onChange={this.handleInputChange}
				/>
				<div onClick={this.handleArrowClick} className={css.SelectInput__arrow} />
				<div
					className={classnames(css.SelectInput__options, isOpen && css['SelectInput__options--is-open'])}
					onClick={this.handleOptionsClick}
					onMouseDown={this.handleOptionsMouseDown}
					onMouseUp={this.handleOptionsMouseUp}
				>
					{this.renderOptions()}
				</div>
				<Hidden name={name} value={selectedValue} />
			</div>
		);
	}
}
