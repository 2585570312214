.KeyVals {
	font-size: 16px;
	line-height: 25px;
}
.KeyVals__item {
	margin: 0 0 15px;
	background: var(--white);
	display: flex;
	padding: 0;
	flex-wrap: wrap;
	border-radius: 5px;
	overflow: hidden;
	box-shadow: var(--tight-shadow);
}
.KeyVals__item:last-child {
	margin-bottom: 0;
}
.KeyVals__key,
.KeyVals__value {
	margin: 0;
	word-break: break-all;
	padding: 8px 12px;
	box-sizing: border-box;
}
.KeyVals__key {
	font-weight: var(--regular);
	flex: 1 0 30%;
	min-width: 120px;
}
.KeyVals__key span,
.KeyVals__value span {
	display: inline-block;
	font-weight: var(--regular);
	font-size: 14px;
	line-height: 15px;
	color: var(--gray4);
}
.KeyVals__value {
	font-weight: var(--semibold);
	flex: 1 0 70%;
	min-width: 250px;
	font-size: 16px;
}
.KeyVals__list {}
.KeyVals__list .KeyVals__list {
	margin-bottom: 10px;
	line-height: 20px;
}
.KeyVals__list--unordered {
	list-style-type: disc;
}
.KeyVals__list .KeyVals__list {
	margin-left: 0.75em;
}
.KeyVals__listItem {}
.KeyVals__raw {
	font-size: 12px;
	line-height: 14px;
}