import React from 'react';

import { getTagIcon } from '~/src/util/getTagIcon';

import * as css from './CertificationIcon.css';

export const CertificationIcon = ({ name }) => {
	// TODO: Use ID to load icon
	return (
		<div className={css.Cert}>
			<img className={css.Cert__img} src={getTagIcon(null)} alt={name} />
			<span className={css.Cert__label}>{name}</span>
		</div>
	);
};
