.ExternalLink {
	text-decoration: underline;
	cursor: pointer;
}
.ExternalLink:after {
	content: '';
	display: inline-block;
	width: 0.8em;
	height: 0.8em;
	background-image: url('~/src/icons/external-link.svg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 100% auto;
	vertical-align: middle;
	margin-left: 0.25em;
	margin-right: 0.25em;
	margin-top: -0.2em;
}

.OverflowFade {
	overflow: hidden;
	display: inline-block;
	max-height: 1.3em;
	user-select: all;
	position: relative;
}
.OverflowFade:after {
	content: '';
	height: 100%;
	position: absolute;
	right: 0;
	top: 0;
	width: 2em;
	background: linear-gradient(90deg, transparent, var(--background-color));
}

.SectionTitle {
	margin-top: var(--section-spacing);
	margin-bottom: 20px;
	font-size: 26px;
	vertical-align: middle;
}
.SectionTitle:first-child {
	margin-top: 0;
}

.SubSectionTitle {
	margin-top: 30px;
	margin-bottom: 20px;
	font-size: 22px;
	vertical-align: middle;
}
.SubSectionTitle:first-child {
	margin-top: 0;
}

.Label {
	font-size: 14px;
	line-height: 22px;
	font-weight: 600;
	display: flex;
	align-items: baseline;
	justify-content: flex-start;
	white-space: nowrap;
}
.Label__subLabel {
	margin-left: 0.5em;
	font-weight: 500;
}

.Red {
	color: var(--red-dark);
}
.Red--bold {
	font-weight: var(--bold);
}

.Line {
	line-height: 35px;
	display: flex;
}
.Line--large {
	font-size: 30px;
}
.Line--medium {
	font-size: 26px;
}
.Line--fade {
	color: var(--gray3);
}
.Line > input {
	height: 35px;
}

.LabelledText {
	display: flex;
	flex-direction: column;
	row-gap: 10px;
	text-align: center;
}
.LabelledText--tight {
	row-gap: 0;
}
.LabelledText__label {
	font-size: 14px;
	line-height: 12px;
	font-weight: 600;
	white-space: nowrap;
}
.LabelledText p {
	font-size: var(--editor-normal-text);
}

.LabelledBlock {
	display: flex;
	flex-direction: column;
	row-gap: 10px;
}
.LabelledBlock__label {
	font-size: 14px;
	line-height: 12px;
	font-weight: 600;
	white-space: nowrap;
}
.LabelledBlock p {
	font-size: var(--editor-normal-text);
}

.SmallText {
	font-size: 14px;
	font-weight: var(--semibold);
}
