.ImageEditor {
	width: 230px;
	display: flex;
	flex-direction: column;
	row-gap: 5px;
}
.ImageEditor__preview {
	position: relative;
	width: 230px;
	height: 200px;
	background: var(--white);
	box-shadow: var(--wide-shadow), var(--tight-shadow);
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.ImageEditor__preview img {
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;
}
.ImageEditor__size {
	position: absolute;
	bottom: 0;
	right: 0;
	font-size: 14px;
	background: rgba(255, 255, 255, 0.9);
	padding: 0 5px;
	border-radius: 4px 0 0 0;
}
.ImageEditor__size--too-small {
	background: var(--red);
}
.ImageEditor__input {
	overflow: hidden;
	text-overflow: ellipsis;
}