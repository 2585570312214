import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';

import { submitLoginToken, submitLoginEmail, waitForAccessToken } from '~/src/util/auth';
import { rememberEmail, getRememberedEmail } from '~/src/util/login';
import { Form, EmailField } from '~/src/lib/Form';
import { Button } from '~/src/lib/Buttons';

import * as css from './LoginPage.css';

class LoginPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			email: getRememberedEmail() || '',
		};
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	async handleSubmit(data) {
		this.startLoading();
		rememberEmail(data.email);
		if (await submitLoginEmail(data.email)) {
			await waitForAccessToken();
			location.reload();
		} else {
			this.handleError();
		}
	}
	componentDidMount() {
		this.checkForSigninToken();
	}
	componentWillUnmount() {
		clearTimeout(this.checkTimeout);
	}
	startLoading() {
		this.setState({
			isLoading: true,
		});
	}
	stopLoading() {
		this.setState({
			isLoading: false,
		});
	}
	handleError() {
		this.stopLoading();
	}
	// Check if signin token is passed in, if so
	// submit it to the api to get access token
	async checkForSigninToken() {
		const { location, history } = this.props;
		const params = new URLSearchParams(location.search);
		const signinToken = params.get('signin-token');
		if (!signinToken) {
			return;
		}
		this.startLoading();
		history.replace({ search: '' });
		const didLogIn = await submitLoginToken(signinToken);
		if (!didLogIn) {
			this.handleError();
		}
	}
	render() {
		const { isLoading, email } = this.state;
		return (
			<div className={classnames(css.LoginPage, isLoading && css.isLoading)}>
				<div className={css.content}>
					<Form onSubmit={this.handleSubmit} className={css.form}>
						<EmailField name="email" autoFocus noValidate isSimple defaultValue={email || ''} />
						<Button type="submit">Login</Button>
					</Form>
					<div className={css.loading}>
						<div className={css.loader} />
						<p>Please check your email inbox</p>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(LoginPage);
