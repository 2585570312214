import React from 'react';
import { Switch, Route } from 'react-router-dom';

import request from '~/src/util/request';
import { Page } from '~/src/lib/Page';
import { useResource } from '~/src/util/useResource';
import { Button, ButtonBar, SlimIconButton } from '~/src/lib/Buttons';
import { ProductListItem } from '~/src/lib/ProductList';
import { ProtoEditor, useEditor, getEditorActions } from '~/src/lib/ProtoEditor';

const UP = Symbol('up');
const DOWN = Symbol('down');

export const ListTrendingProducts = () => {
	const { isLoading, error, data, refresh } = useResource('/v0/trending-products?include=all');
	console.log(data);
	const handleDelete = async (product_id) => {
		const response = await request('DELETE', `/v0/trending-products/${product_id}`);
		console.log(response);
		if (response.ok) {
			refresh();
		}
	};
	const products = data?.products;
	const handleReorder = async (item, direction) => {
		console.log('move', item.order, direction, item);
		const order = getUpdateOrder(item, products, direction);
		console.log('new order', order);
		await request('PUT', `/v0/trending-products/${item.product_id}`, { order });
		refresh();
	};
	return (
		<Page
			title="Trending products"
			contentWidth="medium"
			isLoading={isLoading}
			error={error}
			actions={<Button to="/trending-products/new">Add new</Button>}
		>
			{products && (
				<div>
					{products.map((product) => (
						<ProductListItem
							key={product.product_id}
							product={product}
							actions={
								<ButtonBar>
									<SlimIconButton icon="up" onClick={() => handleReorder(product, UP)}></SlimIconButton>
									<SlimIconButton icon="down" onClick={() => handleReorder(product, DOWN)}></SlimIconButton>
									<SlimIconButton icon="trash" onClick={() => handleDelete(product.product_id)}></SlimIconButton>
								</ButtonBar>
							}
						/>
					))}
				</div>
			)}
		</Page>
	);
};

const EDITOR_FIELDS = [{
	name: 'product_id',
	label: 'Product ID',
	type: 'text',
}];

export const AddTrendingProduct = () => {
	const editor = useEditor({
		endpoint: '/v0/trending-products',
		fields: EDITOR_FIELDS,
		isNew: true,
	});
	return (
		<Page
			title={'Add trending product'}
			actions={getEditorActions(editor)}
		>
			<ProtoEditor editor={editor} />
		</Page>
	);
};


function getUpdateOrder(item, list, direction) {
	// The order is a float, so we find the next two items in the given direction,
	// get their orders, and return the in-between spot.
	const index = list.indexOf(item);
	if (direction === UP) {
		const before = list[index - 1];
		const beforeBefore = list[index - 2];
		return (before.order + (beforeBefore?.order || 0)) / 2;
	} else if (direction === DOWN) {
		const after = list[index + 1];
		const afterAfter = list[index + 2];
		if (afterAfter) {
			return (after.order + afterAfter.order) / 2;
		}
		return after.order + 1;
	}
}

export const TrendingProducts = () => {
	return (
		<Switch>
			<Route path="/trending-products/new" component={AddTrendingProduct} />
			<Route path="/trending-products" component={ListTrendingProducts} />
		</Switch>
	);
};
