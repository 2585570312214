import React from 'react';
import classnames from 'classnames';

import * as css from './Layout.css';

export const Row = ({ children, valign='center' }) => (
	<div className={classnames(css.Row, css[`Row--valign-${valign}`])}>
		{children}
	</div>
);

export const Column = ({ children, align='left', gap=null }) => (
	<div
		style={{ rowGap: gap }}
		className={classnames(css.Column, css[`Column--align-${align}`])}
	>
		{children}
	</div>
);

export const Item = ({ children, size='1' }) => (
	<div className={css.Item} style={{ flex: size }}>
		{children}
	</div>
);

export const TitleBar = ({ children }) => (
	<div className={css.TitleBar}>
		{children}
	</div>
);

export const Spread = () => (
	<div className={css.Spread} />
);
