import React, { Fragment } from 'react';

import request from '~/src/util/request';
import { useResource } from '~/src/util/useResource';
import { Field, Hidden } from '~/src/lib/Form';
import Editor from '~/src/lib/Editor';
import { TablePage } from '~/src/lib/Table';
import { Button, ButtonBar } from '~/src/lib/Buttons';

const HEADERS = [{
	label: 'ID',
	key: 'id',
}, {
	label: 'Phrase',
	key: 'phrase',
	isWide: true
}, {
	key: 'edit',
	renderItem: (item, aux) => (
		<ButtonBar>
			<Button onClick={() => aux.moveItem(item)}>Make product term</Button>
			<Button variant="neutral" to={`/dictionaries/multiwords/${item.id}`}>Edit</Button>
		</ButtonBar>
	)
}];

const Actions = () => (
	<Button to="/dictionaries/multiwords/new">Create new</Button>
);

export const MultiwordDictionary = () => {
	const { isLoading, error, data, refresh } = useResource('/v0/dictionaries/multiwords');
	console.log({ data });
	const moveItem = async (item) => {
		await request('POST', '/v0/knowledge/product-terms/import-multiword', item);
		refresh();
	};
	return (
		<TablePage
			keyProp="id"
			headers={HEADERS}
			headerItemAux={{ moveItem }}
			items={data}
			isLoading={isLoading}
			error={error}
			title="Multiword Dictionary"
			actions={<Actions />}
		/>
	);
};

export const CreateMultiword = () => (
	<Editor
		title="Add multiword"
		submitEndpoint="/v0/dictionaries/multiwords"
		method="POST"
		onSuccess="/dictionaries/multiwords"
		newItem
	>
		{(item) => <SharedFields item={item} />}
	</Editor>
);

export const EditMultiword = ({ match }) => (
	<Editor
		title="Edit multiword"
		submitEndpoint={`/v0/dictionaries/multiwords/${match.params.id}`}
		method="PUT"
		onSuccess="/dictionaries/multiwords"
		loadEndpoint={`/v0/dictionaries/multiwords/${match.params.id}`}
		allowDelete
		onDelete="/dictionaries/multiwords"
	>
		{(item) => (
			<Fragment>
				<Hidden name="id" value={item.id} />
				<SharedFields item={item} />
			</Fragment>
		)}
	</Editor>
);

const SharedFields = ({ item }) => (
	<Fragment>
		<Field autoFocus label="Phrase" name="phrase" defaultValue={item.phrase} />
	</Fragment>
);
