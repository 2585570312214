import useSWR, { useSWRConfig } from 'swr';
import request from '~/src/util/request';
import { reportError } from '~/src/util/reportError';
import { useHistory } from 'react-router-dom';

// TODO: Maybe Use a streamlined version of request
// that doesn't send out notifications with errors,
// since the retrying done by SWR will cause multiple notifications.

async function fetcher(endpoint) {
	const result = await request('GET', endpoint);
	if (!result) {
		throw reportError(new Error('Request failed'), { endpoint });
	}
	if (result.status !== 'success') {
		const code = result.statusCode || result.code;
		const codeSlug = code ? ` with code ${code}` : '';
		throw reportError(
			new Error(`Request failed${codeSlug}`),
			{ endpoint }
		);
	}
	if (result.meta) {
		result.data.meta = result.meta;
	}
	return result.data;
}

export function useResource(url, options=null) {
	const { error, data, mutate } = useSWR(url, fetcher, { revalidateOnFocus: false, ...options });
	return {
		isLoading: !error && !data,
		error: error || null,
		data,
		refresh: () => mutate(),
		mutate,
	};
}

export function usePaginatedResource(url) {
	const history = useHistory();
	const searchParams = new URLSearchParams(history.location.search);
	const result = useResource(getPaginatedEndpoint(url, searchParams));
	// TODO: Ideally the API would return this
	if (result.data) {
		const lastItem = result.data[result.data.length - 1];
		if (!lastItem) {
			return result;
		}
		if (!('id' in lastItem)) {
			throw new Error('Last item does not have an ID');
		}
		// TODO: We should really use `before` instead of `after`
		// as we're going back in time, not forward
		result.nextLink = `?after=${lastItem.id}`;
	}
	return result;
}

function getPaginatedEndpoint(endpoint, searchParams) {
	if (searchParams.has('after')) {
		return `${endpoint}?after=${searchParams.get('after')}`;
	}
	return endpoint;
}

// To reload a resource, call refresh with the url.
// Pass in data to the refresh call to immediately
// set the stored data to said values, to allow for
// snappier interfaces.
export function useResourceRefresher(url) {
	const { mutate } = useSWRConfig();
	const refresh = (data) => data ? mutate(url) : mutate(url, data);
	return refresh;
}
