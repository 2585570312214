import React from 'react';
import classnames from 'classnames';

import { TransparentInput } from '~/src/lib/Form';

import * as css from './LineEditor.css';

const noop = (value) => console.log('TextEditor.onChange', value);

export const LineEditor = ({ onChange=noop, size='normal', hasFocusStyle=true, name, value, defaultValue }) => (
	<div className={classnames(css.LineEditor, css[size])}>
		<TransparentInput
			className={css.LineEditor__input}
			hasFocusStyle={hasFocusStyle}
			type="text"
			onChange={onChange}
			name={name}
			value={value}
			defaultValue={defaultValue}
		/>
	</div>
);