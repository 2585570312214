.ListItem {
	display: grid;
	grid-template-columns: auto 1fr auto;
	grid-template-rows: 1fr 1fr;
	grid-template-areas:
		'thumb title stats actions'
		'thumb metas stats actions'
	;
	grid-column-gap: 10px;
	font-size: 18px;
	min-height: 20px;
	line-height: 25px;
	background: var(--white);
	padding: 15px 15px;
	border-radius: 7px;
	box-shadow: var(--wide-shadow);
}
.ListItem + .ListItem {
	margin-top: 10px;
}
.ListItem__thumb {
	grid-area: thumb;
	width: 65px;
	height: 65px;
	background: var(--off-white);
	text-align: left;
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
	border-radius: 3px;
	overflow: hidden;
	grid-column: 1;
}
.ListItem__thumb img {
	width: 100%;
	height: 100%;
	object-fit: contain;
	overflow: hidden;
}
.ListItem__titles {
	grid-area: title;
	display: flex;
	grid-gap: 10px;
	align-items: baseline;
}
.ListItem__title {
	font-weight: var(--bold);
	font-size: inherit;
	margin: 0;
	padding: 0;
	margin-top: 5px;
	line-height: 1em;
	line-height: 20px;
}
.ListItem__tags {
	display: inline-block;
}
.ListItem__link {
	font: inherit;
	color: inherit;
}
.ListItem__metas {
	grid-area: metas;
	display: flex;
	grid-gap: 10px;
	align-items: baseline;
}
.ListItem__stats {
	grid-area: stats;
	display: flex;
	grid-gap: 10px;
	align-items: center;
}
.ListItem__actions {
	grid-area: actions;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-left: 10px;
}

.Tag {
	font-size: 12px;
	font-weight: var(--bold);
	line-height: 15px;
	padding: 2px 8px 1px;
	border-radius: 2px;
}
.Tag[data-color="gray"] {
	background-color: var(--gray1);
}
.Tag[data-color="yellow"] {
	background-color: var(--yellow);
}
.Meta {
	font-size: 14px;
	line-height: 20px;
	font-weight: var(--semibold);
	margin-top: 5px;
}
.Meta[data-color="black"] {
	color: var(--black);
}
.Meta[data-color="gray"] {
	color: var(--gray4);
}
.Stat {
	font-size: 16px;
	line-height: 25px;
}
.Stat[data-color="black"] {
	color: var(--black);
}
.Stat[data-color="red"] {
	color: var(--red-dark);
}