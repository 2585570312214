import React, { useState } from 'react';
import JSON5 from 'json5';

import { BaseField } from './core';

import * as css from './JSONField.css';

const noop = () => {};

const asString = (value) => {
	if (typeof value === 'string') {
		return value;
	}
	return JSON.stringify(value, null, '\t');
}

export const JSONField = ({ label, subLabel, name, defaultValue, disabled, onChange=noop, ...props }) => {
	const [value, updateValue] = useState(asString(defaultValue));
	const [isInvalid, setIsInvalid] = useState(false);
	const handleChange = (event) => {
		updateValue(event.target.value);
		setIsInvalid(false);
		try {
			onChange(JSON5.parse(event.target.value));
		} catch (error) {
			setIsInvalid(true);
		}
	};
	return (
		<BaseField
			{...props}
			label={label}
			subLabel={subLabel}
			disabled={disabled}
			isInvalid={isInvalid}
		>
			<div className={css.expander}>{value}</div>
			<textarea
				className={css.input}
				name={name}
				type="json"
				disabled={disabled}
				onChange={handleChange}
				defaultValue={value}
			/>
		</BaseField>
	);
};
