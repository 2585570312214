import notify from '~/src/util/notify';
import { signout, getAccessToken } from '~/src/util/auth';
import { getHost } from '~/src/util/env';
import { ACCESS_EXPIRED_ERROR_CODE } from '~/src/constants';

const DEFAULT_OPTIONS = {
	notify: true,
};

async function request(method, endpoint, data, config=DEFAULT_OPTIONS) {
	if (!endpoint) {
		throw new Error('Request is missing endpoint (method, endpoint, data)');
	}
	try {
		const accessToken = getAccessToken();
		const options = {
			method: method,
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
			},
		};
		if (accessToken) {
			options.headers['Authorization'] = `Bearer ${accessToken}`;
		}
		if (typeof data !== undefined) {
			options.body = JSON.stringify(data);
		}
		const host = getHost();
		const response = await fetch(`${host}${endpoint}`, options);
		if (response.status === 204) {
			return {
				ok: true,
				status: 'success',
				statusCode: 204,
				data: {},
			};
		}
		const json = await response.json();
		if (json.status === 'error') {
			json.ok = false;
			if (json.code === ACCESS_EXPIRED_ERROR_CODE) {
				config.notify && notify.error({
					statusCode: json.statusCode,
					code: json.code,
					message: 'Your authentication token has expired, please sign in again',
					id: endpoint,
					button: {
						label: 'Sign out',
						onClick: () => {
							signout();
						}
					}
				});
			} else {
				config.notify && notify.error({
					message: json.message,
					statusCode: json.statusCode,
					code: json.code,
					id: endpoint,
				});
			}
		} else {
			json.ok = true;
		}
		if (json && json.data && json.data.message) {
			config.notify && notify({
				message: json.data.message,
				id: json.data.id || endpoint,
				button: json.data.action,
				duration: json.data.duration,
			});
		}
		return json;
	} catch (error) {
		config.notify && notify.error({
			message: error.message,
			statusCode: error.statusCode,
			code: error.code,
			id: endpoint,
		});
		return {
			status: 'error',
			statusCode: 0,
			message: error.message,
			id: endpoint,
		};
	}
}

export default request;