.MultiInsertEditor {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	flex: 1;
	grid-gap: 10px;
}
.MultiInsertEditor__option {
	display: inline-block;
	padding: 5px 5px 5px 10px;
	font-size: 16px;
	font-weight: var(--semibold);
	line-height: 20px;
	height: 30px;
	box-sizing: border-box;
	border-radius: 8px;
	background: var(--green-light);
	cursor: pointer;
	user-select: none;
}
.MultiInsertEditor__option:hover {
	background: var(--green-lighter);
}
.MultiInsertEditor__option--is-selected,
.MultiInsertEditor__option--is-selected:hover {
	background: var(--green-light);
}
.MultiInsertEditor__option:after {
	content: '';
	display: inline-block;
	width: 1em;
	height: 1em;
	background-image: url('~/src/icons/check.svg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 100% auto;
	vertical-align: middle;
	margin-left: 0.5em;
	margin-right: 0.25em;
	margin-top: -1px;
}
.MultiInsertEditor__option:hover:after {
	background-image: url('~/src/icons/minus.svg');
}
.MultiInsertEditor__optionCheckbox {
	display: none;
}
