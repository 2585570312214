import React, { Fragment } from 'react';
import { parseISO, format as formatTime, formatDistance } from 'date-fns';

import { TablePage } from '~/src/lib/Table';
import Resource from '~/src/lib/Resource';
import { FeedbackButton as Button, ButtonBar } from '~/src/lib/Buttons';
import { OverflowFade, Red, Label } from '~/src/lib/Text';
import request from '~/src/util/request';

const HEADERS = [{
	label: 'ID',
	key: 'id',
	renderItem: (item) => <OverflowFade>{item.id}</OverflowFade>
}, {
	label: 'Command',
	key: 'command',
	noWrap: true,
}, {
	label: 'State',
	key: 'state',
	isWide: true,
	renderItem: (item) => (
		item.state === 'ERROR' ? <Red bold>{getErrorMessage(item)}</Red> : <span>{item.state}</span>
	),
}, {
	label: 'Age',
	key: 'age',
	noWrap: true,
	renderItem: (item) => (
		<abbr
			title={formatTime(parseISO(item.created_at), 'yyyy-MM-dd HH:mm:ss')}
		>
			{formatDistance(parseISO(item.created_at), new Date(), 'YYYY-MM-DD HH:mm:ss')}
		</abbr>
	),
}, {
	label: 'Actions',
	key: 'actions',
	renderItem: getItemActions,
}];

function getErrorMessage(item) {
	return item?.result?.message || item.state;
}

async function queueUnlockItem(id) {
	return await request(
		'POST',
		`/v0/queue/${id}/unlock`,
	);
}
async function queueRetryItem(id) {
	return await request(
		'POST',
		`/v0/queue/${id}/retry`,
	)
}
async function queueClearItem(id) {
	return await request(
		'DELETE',
		`/v0/queue/${id}`,
	)
}
async function clearAllCompletedItems(onDone) {
	await request(
		'DELETE',
		'/v0/queue/completed',
	);
	await onDone();
}
async function clearAllErrorItems(onDone) {
	await request(
		'DELETE',
		'/v0/queue/errors',
	);
	await onDone();
}

function getItemActions(item, aux) {
	return (
		<ButtonBar lozenge>
			{getFirstAction(item, aux)}
			<Button onClick={getActionClickHandler(item.id, 'clear', aux.refresh)} variant="danger">Clear</Button>
		</ButtonBar>
	);
}

function getFirstAction(item, aux) {
	switch (item.state) {
		case 'LOCKED':
			return <Button onClick={getActionClickHandler(item.id, 'unlock', aux.refresh)} variant="primary">Unlock</Button>
		case 'ERROR':
			return <Button onClick={getActionClickHandler(item.id, 'retry', aux.refresh)} variant="neutral">Retry</Button>
		default:
			return null;
	}
}

function getActionClickHandler(id, action, onDone) {
	switch (action) {
		case 'unlock':
			return async () => {
				await queueUnlockItem(id);
				setTimeout(onDone, 200);
			};
		case 'retry':
			return async () => {
				await queueRetryItem(id);
				setTimeout(onDone, 200);
			};
		case 'clear':
			return async () => {
				await queueClearItem(id);
				setTimeout(onDone, 200);
			};

	}
}

const Actions = ({ refresh }) => (
	<Fragment>
		<Button variant="neutral" onClick={refresh}>Refresh</Button>
		<Button variant="yellow" onClick={() => clearAllCompletedItems(refresh)}>Clear completed</Button>
		<Button variant="danger" onClick={() => clearAllErrorItems(refresh)}>Clear errors</Button>
	</Fragment>
);


export const Queue = () => (
	<Resource endpoint="/v0/queue">
		{({ data, refresh }) => (
			<TablePage
				keyProp="id"
				headers={HEADERS}
				items={data}
				title="Queue"
				actions={<Actions refresh={refresh} />}
				headerItemAux={{ refresh }}
				renderExpander={(item) => (
					<Fragment>
						<div>
							<Label>Payload</Label>
							<pre style={{ whiteSpace: 'pre-wrap' }}>{JSON.stringify(item.payload, null, '\t')}</pre>
						</div>
						<div>
							<Label>Result</Label>
							<pre style={{ whiteSpace: 'pre-wrap' }}>{JSON.stringify(item.result, null, '\t')}</pre>
						</div>
					</Fragment>
				)}
			/>
		)}
	</Resource>
);
