import React, { useState, useEffect } from 'react';
import { format as formatDate } from 'date-fns';

import { BaseField, Input } from './core';
import { Link } from '~/src/lib/Buttons';
import { ExternalLink } from '~/src/lib/Text';
import { AutoCopy } from '~/src/lib/AutoCopy';

export const Field = ({
	label,
	children,
	name,
	type='text',
	defaultValue,
	value,
	disabled,
	prefix,
	autoFocus,
	placeholder,
	onChange,
	maxLength=Infinity,
	...props
}) => {
	const [localValue, setValue] = useState(value || defaultValue);
	const handleChange = (event) => {
		setValue(event.target.value);
		if (onChange) {
			onChange(event.target.value, event)
		}
	};
	useEffect(() => {
		if (typeof value !== 'undefined') {
			setValue(value);
		}
	}, [value]);
	const outlineColor = localValue && (localValue.length > maxLength ? 'red' : (localValue.length > maxLength - 10 ? 'orange' : null));
	return (
		<BaseField label={label} disabled={disabled} {...props} prefix={prefix}>
			{children || (
				<Input
					type={type}
					name={name}
					disabled={disabled}
					defaultValue={defaultValue}
					value={value}
					style={{ outline: outlineColor ? `1px solid ${outlineColor}` : null }}
					placeholder={placeholder}
					autoFocus={autoFocus}
					onChange={handleChange}
				/>
			)}
		</BaseField>
	);
};

export const LoadingField = ({ label }) => (
	<BaseField label={label} disabled={true}>
		<Input type="text" defaultValue="Loading..." />
	</BaseField>
);

export const EmailField = ({ label='Email', name, isSimple, ...props }) => (
	<BaseField type="email" label={label} isSimple={isSimple} {...props}>
		<Input type="email" name={name} {...props} />
	</BaseField>
);

export const DateField = ({ label='Date', name='date', value, ...props }) => (
	<Field
		label={label}
		name={name}
		type="date"
		value={value instanceof Date ? formatDate(value, 'yyyy-MM-dd') : value}
		{...props}
	/>
);

export const URLField = ({ label='URL', subLabel, name, description, defaultValue, disabled, onChange, ...props }) => {
	const [ value, setValue ] = useState(defaultValue);
	const updateInput = (event) => {
		const target = event.target;
		// Delay so that the pasted value has had time to be set
		requestAnimationFrame(() => {
			setValue(target.value);
		});
	};
	return (
		<BaseField
			type="url"
			label={label}
			subLabel={subLabel || (value ? <ExternalLink href={value}>Open</ExternalLink> : null)}
			description={description}
			disabled={disabled}
		>
			<Input
				type="url"
				name={name}
				defaultValue={defaultValue}
				disabled={disabled}
				onBlur={updateInput}
				onPaste={updateInput}
				onChange={(event) => onChange && onChange(event.target.value)}
				{...props}
			/>
		</BaseField>
	);
};

export const CopyURLField = ({ value, defaultValue, ...props }) => {
	const text = value || defaultValue;
	return (
		<URLField
			{...props}
			value={value}
			defaultValue={defaultValue}
			disabled
			subLabel={text ? (
				<AutoCopy Tag={Link} text={text} variant="prominent">
					Copy
				</AutoCopy>
			) : null}
		/>
	);
}

export const Hidden = ({ name, value, defaultValue }) => {
	if (defaultValue) {
		throw new Error('Hidden field uses value, not defaultValue');
	}
	return (
		<input type="hidden" name={name} value={value || ''} />
	);
};
