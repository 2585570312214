.Categories {
	display: grid;
	column-gap: var(--grid-column-gap);
	row-gap: var(--section-spacing);
	grid-template-rows: auto auto auto;
	grid-template-columns: 1fr 1fr;
	grid-template-areas:
		"edi edi"
		"pro pro"
		"cat cat"
	;
}
@media (min-width: 1400px) {
	.Categories {
		grid-template-rows: auto auto auto;
		grid-template-columns: 1fr 1fr;
		grid-template-areas:
			"edi edi"
			"cat pro"
			"cat pro"
		;
	}

}
.Categories__edit {
	grid-area: edi;
}
.Categories__products {
	grid-area: pro;
}
.Categories__cats {
	grid-area: cat;
}

.CatGroups {
}
.CatGroup {
	background: var(--white);
	border-radius: 7px;
	padding: 0 20px 20px;
	margin-bottom: 30px;
	box-shadow: 0 1px 15px rgba(0, 0, 0, 0.05);
}
.CatGroup__header {
	padding: 20px 0;
	border-bottom: 1px solid var(--gray1);
	display: flex;
	align-items: stretch;
}
.CatGroup__headerText {
	margin-right: auto;
}
.CatGroup__headerActions {
	margin-left: auto;
	display: flex;
	align-items: center;
}
.CatGroup__title {
	font-size: 30px;
	line-height: 40px;
	font-weight: var(--semibold);
}
.CatGroup__subtitle {
	margin-top: 5px;
	font-size: 14px;
	line-height: 20px;
	font-weight: var(--regular);
	color: var(--gray6);
}
.CatGroup__count {
	display: inline-block;
	line-height: 1.5em;
	vertical-align: middle;
	border-radius: 0.6em;
	margin-left: 0.5em;
	text-align: center;
	font-size: 12px;
	min-width: 1.5em;
	height: 1.5em;
	padding-left: 0.5em;
	padding-right: 0.5em;
	background: var(--gray2);
	box-sizing: border-box;
}
.CatGroup__groups {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}
.CatGroup__group {
	flex: 1;
	margin-top: 25px;
	min-width: 30%;
	max-width: 50%;
}
.CatGroup__groupTitle {
	font-size: 20px;
	line-height: 30px;
	font-weight: var(--semibold);
}
.CatGroup__groupItems {
	margin-top: 10px;
}
.CatGroup__groupItem {
	font-size: 16px;
	line-height: 25px;
}