import React, { Fragment } from 'react';
import { parse as parseQueryString } from 'query-string';

import { SectionTitle } from '~/src/lib/Text';
import { Link, Button } from '~/src/lib/Buttons';
import { Page } from '~/src/lib/Page';
import { ProtoEditor, useEditor, getEditorActions } from '~/src/lib/ProtoEditor';
import { ProductList } from '~/src/lib/ProductList';
import { useResource } from '~/src/util/useResource';

import * as css from './CategoriesRedux.css';

const CATEGORY_EDITOR_FIELDS = [{
	name: 'category_id',
	type: 'hidden',
}, {
	name: 'name',
	label: 'Name',
	type: 'text',
}, {
	name: 'title',
	label: 'Title',
	description: 'The publicly displayed title of the category, to include SEO keywords',
	type: 'text',
}, {
	name: 'slug',
	label: 'Slug',
	type: 'slug',
}, {
	name: 'parent_id',
	label: 'Parent category',
	type: 'category',
}, {
	name: 'description',
	label: 'Description',
	type: 'textarea',
}];

export const CategoryRedux = ({ match, location }) => {
	const categoryId = match.params.id;
	const isNew = categoryId === 'new';
	const query = parseQueryString(location.search);
	const newItem = isNew ? { parent_id: query.parent || null } : null;
	const category = useResource(isNew ? null : `/v0/categories/${categoryId}`);
	const categories = useResource(isNew ? null : `/v0/categories/${categoryId}/categories?format=tree`);
	const products = useResource(isNew ? null : `/v0/categories/${categoryId}/products`);

	const editor = useEditor({
		endpoint: `/v0/categories/${categoryId}`,
		createEndpoint: '/v0/categories',
		fields: CATEGORY_EDITOR_FIELDS,
		allowDelete: true,
		onDelete: category?.data?.parent_id ? `/categories/${category?.data?.parent_id}` : -1,
		isNew,
		newItem,
	});

	return (
		<Page
			backLink={getBackLink(category.data)}
			error={category.error || categories.error || products.error}
			title={isNew ? 'New category' : `${category.isLoading ? 'Loading...' : category?.data?.name || 'Missing category'}`}
			actions={getEditorActions(editor)}
		>
			<div className={css.Categories}>
				<section className={css.Categories__edit}>
					<SectionTitle>Edit category</SectionTitle>
					<ProtoEditor key={categoryId} editor={editor} />
				</section>
				{isNew ? null : (
					<section className={css.Categories__products}>
						<SectionTitle>Products</SectionTitle>
						{products.isLoading ? 'Loading...' : (
							products.data && products.data.length ? (
								<ProductList products={products.data} />
							) : (
								<p>No products</p>
							)
						)}
					</section>
				)}
				{isNew ? null : (
					<section className={css.Categories__cats}>
						<SectionTitle>Categories</SectionTitle>
						{categories.isLoading ? 'Loading...' : (
							<CatGroup tree={categories.data[0]} keyProp="category_id" getUrl={(id) => `/categories/${id}`} />
						)}
					</section>
				)}
			</div>
		</Page>
	);
};

function getBackLink(category) {
	if (!category) {
		return true;
	}
	if (category.parent_id) {
		return `/categories/${category.parent_id}`;
	}
	return true;
}

export const CategoriesRedux = () => {
	const categories = useResource('/v0/categories?format=tree');
	return (
		<Page
			title="Categories"
			lead={(
				<Fragment>
					<p>Three levels of categories are shown, more may exist.</p>
					<p>All category titles are interactive, click to edit & see sub-categories.</p>
				</Fragment>
			)}
			actions={<Button to="/categories/new">Create new</Button>}
		>
			{categories && categories.data ? (
				<CatGroups tree={categories.data} keyProp="category_id" />
			) : null}
		</Page>
	);
};

const CatGroups = ({ tree, keyProp }) => {
	return (
		<div className={css.CatGroups}>
			{tree.map((tree) => (
				<CatGroup key={tree[keyProp]} tree={tree} keyProp={keyProp} getUrl={(id) => `/categories/${id}`} />
			))}
		</div>
	);
};

const CatGroup = ({ tree, keyProp, getUrl, title }) => {
	return (
		<article className={css.CatGroup}>
			<header className={css.CatGroup__header}>
				<div className={css.CatGroup__headerText}>
					<h1 className={css.CatGroup__title}>
						<Link to={getUrl(tree[keyProp])}>
							{title || tree.name}
						</Link>
					</h1>
					<p className={css.CatGroup__subtitle}>{tree.total_product_count} products, {tree.product_count} direct</p>
				</div>
				<div className={css.CatGroup__headerActions}>
					<Button to={`/categories/new?parent=${tree[keyProp]}`}>New sub-category</Button>
				</div>
			</header>
			<div className={css.CatGroup__groups}>
				{tree.children.map((group) => (
					<div key={group[keyProp]} className={css.CatGroup__group}>
						<h2 className={css.CatGroup__groupTitle}>
							<Link to={getUrl(group[keyProp])}>
								{group.name}
								<span className={css.CatGroup__count}>{group.total_product_count}</span>
							</Link>
						</h2>
						<div className={css.CatGroup__groupItems}>
							{group.children.map((groupItem) => (
								<div key={groupItem[keyProp]} className={css.CatGroup__groupItem}>
									<Link to={getUrl(groupItem[keyProp])}>
										{groupItem.name}
										<span className={css.CatGroup__count}>{groupItem.total_product_count}</span>
									</Link>
								</div>
							))}
							{!group.children.length ? (
								'-'
							) : null}
						</div>
					</div>
				))}
			</div>
		</article>
	);
};
