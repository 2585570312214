import React from 'react';

import { Link } from '~/src/lib/Buttons';

import * as css from './CategoriesList.css';

export const CategoriesList = ({ categories, truncate }) => {
	if (!categories) {
		return null;
	}
	const selectedCategories = truncate ? categories.slice(0, truncate) : categories;
	return (
		<ul className={css.CategoriesList}>
			{selectedCategories.map((item) => (
				<CategoriesItem key={item.category_id} category={item} />
			))}
		</ul>
	);
};

const CategoriesItem = ({ category }) => {
	return (
		<li className={css.CategoriesItem}>
			<div className={css.CategoriesItem__titles}>
				<h2 className={css.CategoriesItem__title}>
					<Link target="_blank" to={`/categories/${category.category_id}`} className={css.CategoriesItem__link}>{category.name}</Link>
				</h2>
				<span className={css.CategoriesItem__score}>
					{category.rank}
				</span>
			</div>
			<div className={css.CategoriesItem__extra}>
				<div className={css.CategoriesItem__highlights} dangerouslySetInnerHTML={{ __html: category.highlight }}>
				</div>
			</div>
		</li>
	)
};