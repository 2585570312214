import React, { Fragment, forwardRef } from 'react';
import classnames from 'classnames';

import { Label } from '~/src/lib/Text';

import * as css from './core.css';

export const BaseField = ({
	tag, label, subLabel,
	description, children, className,
	onClick, tabIndex, htmlFor,
	disabled, prefix, hasFocus,
	isInline, isSimple, noFocusStyle,
	isInvalid, gap
}) => {
	const Component = tag ? tag : 'label';
	return (
		<Component
			className={classnames(
				css.Field,
				!noFocusStyle && css['Field--enable-focus-style'],
				disabled && css['Field--is-disabled'],
				hasFocus && css['Field--has-focus'],
				isInline && css['Field--is-inline'],
				isSimple && css['Field--is-simple'],
				isInvalid && css['Field--is-invalid'],
				className
			)}
			onClick={onClick}
			tabIndex={tabIndex}
			htmlFor={htmlFor}
		>
			{(label || description) && (
				<div className={css.Field__labels}>
					<Label className={css.Field__label} subLabel={subLabel}>{label}</Label>
					{description && outputDescription(description)}
				</div>
			)}
			<div className={css.Field__input} data-prefix={prefix} style={{ marginTop: gap }}>
				{children}
			</div>
		</Component>
	);
};

function outputDescription(description) {
	if (typeof description === 'string') {
		return (
			<p className={css.Description}>{description}</p>
		);
	}
	if (description instanceof Array) {
		return (
			<Fragment>
				{description.map((part) => <p key={part} className={css.Description}>{part}</p>)}
			</Fragment>
		);
	}
	return (
		<div className={css.Description}>
			{description}
		</div>
	);
}

export const Input = forwardRef(({ type='text', className, ...props }, ref) => (
	<input className={classnames(css.Input, className)} type={type} ref={ref} {...props} />
));



export const InputRow = ({ label, children }) => (
	<div className={css.InputRow}>
		{label && (
			<Label className={css.InputRow__label}>{label}</Label>
		)}
		{children}
	</div>
);

export const Divider = ({ label }) => {
	return (
		<div className={css.Divider}>
			{label}
		</div>
	);
};