import React from 'react';

import { formatUrlPattern } from '~/src/util/format';
import { CategoryPreview } from '~/src/lib/CategoryPreview';

import * as css from './GoldSifterQuestion.css';

export const Question = ({ question, format={} }) => {
	const href = getHref(question, format);
	const text = getText(question, format);
	const PreviewComponent = format.previewType === 'category' ? CategoryPreview : null;
	return (
		<div className={css.Question}>
			{PreviewComponent && (
				<PreviewComponent title={text} href={href} question={question} />
			)}
		</div>
	);
};

function getHref(question, format) {
	if (question.url) {
		return question.url;
	}
	if (format.urlPattern) {
		return formatUrlPattern(format.urlPattern, question);
	}
	return null;
}

function getText(question, format) {
	if ('text' in format) {
		return question[format.text];
	}
	return question.text || null;
}