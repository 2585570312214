import isEqualWith from 'lodash/isEqualWith';

import notify from '~/src/util/notify';

export const MANUAL_SOURCE = 'manual';

export const getOptionValue = (activeOption) => {
	if (activeOption instanceof Array) {
		return activeOption.map((option) => String(option.value));
	}
	if (activeOption) {
		return activeOption.value;
	}
	return null;
};

export const formatSource = (source) => {
	if (source === MANUAL_SOURCE) {
		return 'Manual';
	}
	return source;
};

export const renderSource = (option) => {
	if (typeof option === 'string') {
		return formatSource(option);
	}
	if (option) {
		if (option.wasEdited) {
			return `${formatSource(option.source)} (edited)`;
		}
		return formatSource(option.source);
	}
	return null;
};

export const areValuesDifferent = (one, other) => {
	// If a value is a number, we do a loose comparison to have "18" equal 18
	return !isEqualWith(one, other, (oneValue, otherValue) => {
		if (typeof oneValue === 'number' || typeof otherValue === 'number') {
			return oneValue == otherValue;
		}
		return undefined;
	});
}

export const mergeOldAndNewValueArrays = (oldOnes, newOnes) => {
	return newOnes.map((value) => {
		const existing = oldOnes.find((old) => String(old.value) === value);
		if (existing) {
			return existing;
		}
		return {
			source: MANUAL_SOURCE,
			value: value,
		};
	})
};

export const validateSelectedOptions = (selectedProperties) => {
	for (let [key, option] of Object.entries(selectedProperties)) {
		if (!getOptionValue(option)) {
			notify.error({ message: `Input for "${key}" cannot be blank`, duration: 2000 });
			return false;
		}
	}
	return true;
};