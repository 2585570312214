.Field {
	--initial-shadow: 0 0 3px hsl(0, 3%, 60%, 0.02), 0 0 12px hsl(0, 3%, 60%, 0.05);
	--hover-shadow: 0 0 3px hsl(0, 3%, 60%, 0.1), 0 0 16px hsl(0, 3%, 60%, 0.1);
	--field-min-height: 32px;
	--focus-color: var(--green-light);
	display: flex;
	position: relative;
	border-radius: 4px;
	flex-direction: column;
	align-items: flex-start;
	padding: var(--field-padding-y, 10px) var(--field-padding-x, 10px);
	cursor: text;
	background: var(--field-background, var(--white));
	line-height: 1.4;
	box-shadow: var(--field-shadow, var(--initial-shadow));
	border: 1px solid rgba(0, 0, 0, 0);
	transition: box-shadow 75ms ease, border-color 75ms ease;
	outline: none;
}
.Field:hover {
	box-shadow: var(--field-shadow, var(--hover-shadow));
}
.Field--has-focus,
.Field--enable-focus-style:focus,
.Field--enable-focus-style:focus-within {
	outline: none;
	border-color: var(--field-focus-color, var(--focus-color));
	box-shadow: var(--field-shadow, var(--hover-shadow));
}
.Field--is-disabled {
	background: var(--gray1);
	cursor: default;
}
.Field--is-disabled:hover {
	box-shadow: var(--field-shadow, var(--initial-shadow));
}
.Field--is-disabled:focus,
.Field--is-disabled.Field--has-focus {
	box-shadow: var(--field-shadow, var(--initial-shadow));
}

.Field--is-inline {
	padding: 0;
	border: none;
	flex-direction: column;
	background: none;
	box-shadow: none;
}
.Field--is-inline.Field--has-focus,
.Field--is-inline:focus,
.Field--is-inline:focus-within,
.Field--is-inline:hover {
	box-shadow: none;
}

.Field--is-simple {
	flex-direction: column;
	align-items: stretch;
}
.Field--is-simple .Input {
	padding-left: 0;
}

.Field--is-invalid,
.Field--is-invalid:focus,
.Field--is-invalid:focus-within {
	outline: 1px solid red;
}

.Field__labels {
	order: -2;
	display: flex;
	grid-gap: 0.5em;
	align-items: baseline;
}
.Field__input {
	align-self: stretch;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: baseline;
	flex-wrap: wrap;
	flex: 2;
	position: relative;
}
.Field__input[data-prefix]:before {
	content: attr(data-prefix);
	order: -1;
	display: inline-block;
	opacity: 0.7;
	margin-right: 6px;
}
.Field__input > * {
	flex: 1;
}

.Field__label,
.InputRow__label {
	color: var(--gray5);
	user-select: none;
}
.Field:focus-within .Field__label,
.Field:focus-within .InputRow__labelx {
	color: var(--gray6);
}

.Description {
	font-weight: 400;
	display: block;
	color: var(--gray5);
	font-size: 12px;
}

.Input {
	display: inline-block;
	font-size: inherit;
	font-family: inherit;
	line-height: inherit;
	outline: none;
	box-shadow: none;
	border: none;
	flex: 1;
	margin: 0;
	min-height: var(--field-min-height);
	background: transparent;
	padding: 0;
}
.Input:focus {
}
.Input::placeholder {
	color: var(--gray3);
}

.InputRow {
	display: flex;
	flex: 100%;
}
.InputRow__label {
	margin-right: 0.5em;
	align-items: center;
}

.Divider {
	font-weight: var(--bold);
	margin: 30px 0 10px;
}