import React from 'react';

import { Page } from '~/src/lib/Page';
import { Label } from '~/src/lib/Text';
import { Column } from '~/src/lib/Layout';
import { SharpButton } from '~/src/lib/Buttons';

export const MetricsHome = () => (
	<Page title="Metrics">
		<Column gap={10}>
			<Label>Endpoints:</Label>
			<SharpButton to="/metrics/runtime/endpoints/usage-by-date">Endpoint usage</SharpButton>
			{/*<SharpButton to="/metrics/runtime/endpoints/duration">Endpoint durations</SharpButton>*/}
			<Label>Commands:</Label>
			<SharpButton to="/metrics/runtime/commands/usage-by-date">Commands usage</SharpButton>
			{/*<SharpButton to="/metrics/runtime/commands/duration">Commands durations</SharpButton>*/}
			<Label>Data providers:</Label>
			<SharpButton to="/metrics/runtime/data-providers/usage-by-date">Data provider usage</SharpButton>
			{/*<SharpButton to="/metrics/runtime/data-providers/duration">Data provider durations</SharpButton>*/}
		</Column>
	</Page>
);