import React, { Fragment } from 'react';

import {
	CategoryEditor,
	PriceEditor,
	TextEditor,
	LineEditor,
	MultiSelectEditor,
	MultiInsertEditor,
	BrandEditor,
	ImageEditor,
} from '~/src/lib/Editors';

import { getOptionValue } from './common';

export const ValueEditor = ({ property, value, ...props }) => {
	switch (property.type) {
		case 'price':
			return (
				<PriceEditor
					{...props}
					hasFocusStyle
					defaultValue={getOptionValue(value)}
					names={{
						currency: 'currency',
						amount: 'amount',
					}}
				/>
			);
		case 'category':
			return (
				<CategoryEditor
					{...props}
					defaultValue={getOptionValue(value)}
					name={property.name}
				/>
			);
		case 'tags':
			return (
				<MultiSelectEditor
					{...props}
					endpoint="/v0/tags"
					getValue={(tag) => String(tag.tag_id)}
					defaultValue={getOptionValue(value)}
					name={property.name}
				/>
			);
		case 'certifications':
			return (
				<MultiSelectEditor
					{...props}
					endpoint="/v0/certifications"
					getValue={(certification) => String(certification.certification_id)}
					defaultValue={getOptionValue(value)}
					name={property.name}
				/>
			);
		case 'gender':
			return (
				<MultiSelectEditor
					{...props}
					endpoint="/v0/knowledge/genders"
					defaultValue={getOptionValue(value)}
					name={property.name}
				/>
			);
		case 'age':
			return (
				<MultiSelectEditor
					{...props}
					endpoint="/v0/knowledge/age-groups"
					defaultValue={getOptionValue(value)}
					name={property.name}
				/>
			);
		case 'niche':
			return (
				<MultiSelectEditor
					{...props}
					endpoint="/v0/knowledge/niches"
					defaultValue={getOptionValue(value)}
					name={property.name}
				/>
			);
		case 'text':
			return (
				<TextEditor
					{...props}
					name={property.name}
					defaultValue={getOptionValue(value)}
				/>
			);
		case 'line':
			return (
				<LineEditor
					{...props}
					placeholder={`Type ${property.name}`}
					name={property.name}
					defaultValue={getOptionValue(value)}
				/>
			);
		case 'colors':
		case 'flavor':
		case 'materials':
		case 'package-size':
		case 'style':
		case 'synonyms':
			return (
				<MultiInsertEditor
					{...props}
					name={property.name}
					defaultValue={value}
					placeholder={`Add ${property.type}`}
				/>
			);
		case 'image':
			return (
				<ImageEditor
					{...props}
					name={property.name}
					defaultValue={getOptionValue(value)}
				/>
			);
		case 'brand':
			return (
				<BrandEditor
					{...props}
					defaultValue={getOptionValue(value)}
					name={property.name}
				/>
			);
		default:
			return (
				<Fragment>
					<p style={{ color: 'red' }}>
						Editor for {property.type} is not implemented
					</p>
				</Fragment>
			);
	}
};