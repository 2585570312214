import React from 'react';

import { TablePage } from '~/src/lib/Table';
import { useResource } from '~/src/util/useResource';
import { Button } from '~/src/lib/Buttons';
import { AutoLoader } from '~/src/lib/AutoLoader';

const HEADERS = [{
	label: 'URL',
	key: 'url',
	isWide: true
}, {
	key: 'view',
	renderItem: (item) => (
		<Button to={`/product-imports/imports/${item.import_run_id}/products/${item.page_scan_id}`}>View</Button>
	)
}];

export const ListRecentProducts = ({ match }) => {
	const state = match.params.page;
	const products = useResource(`/v0/product-importer/recent-products/${state}`);
	return (
		<AutoLoader error={products.error} isLoading={products.isLoading}>
			{products.data && (
				<TablePage
					keyProp="page_scan_id"
					headers={HEADERS}
					items={products.data}
					title="Recent products"
				/>
			)}
		</AutoLoader>
	)
};