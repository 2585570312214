import React, { useState } from 'react';
import classnames from 'classnames';

import { BaseField } from './core';

import * as css from './TextArea.css';

export const TextArea = ({ label, subLabel, name, defaultValue, disabled, onChange, className, size, ...props }) => {
	const [value, updateValue] = useState(defaultValue);
	const handleChange = (event) => {
		updateValue(event.target.value);
		if (onChange) {
			onChange(event.target.value);
		}
	};
	return (
		<BaseField
			label={label}
			subLabel={subLabel}
			disabled={disabled}
			className={classnames(className, css[`size--${size}`])}
			{...props}
		>
			<div className={css.expander}>{value}</div>
			<textarea
				className={css.input}
				name={name}
				disabled={disabled}
				onChange={handleChange}
				defaultValue={defaultValue}
			/>
		</BaseField>
	);
};
