import React, { Component, useRef } from 'react';

import { BaseField } from './core';
import { Tags, Tag, TagsInput } from './Tags';

const getValue = (value) => value;

export class MultiInput extends Component {
	constructor(props) {
		super(props);
		this.state = {
			items: (props.items || []).map(props.getValue || getValue),
		};
		this.inputID = props.inputID || `MultiInput-input-${Math.random()}`;
		this.handleItemClick = this.handleItemClick.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
	}
	handleItemClick(event) {
		// When an item is clicked, we remove it
		this.removeItem(event.target.value);
	}
	addItem(value) {
		// Don't add blank items
		if (!value) {
			return;
		}
		// TODO: Don't add duplicates
		this.setState((oldState) => {
			return {
				items: [...oldState.items, value],
			};
		});
	}
	removeItem(value) {
		this.setState((oldState) => {
			return {
				items: oldState.items.filter((item) => item !== value),
			};
		});
	}
	removeLast() {
		this.setState((oldState) => {
			oldState.items.pop();
			return {
				items: oldState.items,
			};
		});
	}
	handleKeyDown() {
		if (event.target.value) {
			if (event.which === 13 || event.which === 9) { // enter, tab
				event.preventDefault();
				this.addItem(event.target.value);
				event.target.value = '';
			}
		} else {
			if (event.which === 8) { // backspace
				event.preventDefault();
				this.removeLast();
			}
		}
	}
	render() {
		const { name, placeholder } = this.props;
		const { items } = this.state;
		return (
			<Tags>
				{items.map((value) => (
					<Tag
						key={value}
						name={`${name}[]`}
						value={value}
						label={value}
						isSelected={true}
						onChange={this.handleItemClick}
					/>
				))}
				<TagsInput id={this.inputID} placeholder={placeholder} onKeyDown={this.handleKeyDown} />
			</Tags>
		);
	}
}

export const MultiInputField = ({ name, items, getValue, placeholder, ...props }) => {
	const inputID = useRef(`MultiInput-input-${Math.random()}`);
	return (
		<BaseField {...props} tabIndex="0" htmlFor={inputID}>
			<MultiInput
				items={items}
				name={name}
				inputID={inputID}
				getValue={getValue}
				placeholder={placeholder}
			/>
		</BaseField>
	);
}