import React from 'react';

import { Page } from '~/src/lib/Page';
import { AutoLoader } from '~/src/lib/AutoLoader';
import { Dashboard } from '~/src/lib/Dashboard';
import { useResource } from '~/src/util/useResource';

const DashboardPage = () => {
	const { error, isLoading, data } = useResource('/v0/dashboard');
	return (
		<Page title="Dashboard" backLink={false}>
			<AutoLoader error={error} isLoading={isLoading}>
				<Dashboard data={data && data.counts} />
			</AutoLoader>
		</Page>
	);
};

export default DashboardPage;
