import React, { useState } from 'react';
import classnames from 'classnames';

import * as css from './TextEditor.css';

const noop = (value) => console.log('TextEditor.onChange', value);

export const TextEditor = ({ defaultValue='', onChange=noop, name, size='normal' }) => {
	const [value, setValue] = useState(defaultValue);
	const handleChange = (event) => {
		const newValue = event.target.value;
		setValue(newValue);
		onChange(newValue);
	};
	return (
		<div className={classnames(css.TextEditor, css[`TextEditor--size-${size}`])}>
			<div className={css.TextEditor__fill} dangerouslySetInnerHTML={{ __html: value }} />
			<textarea
				onChange={handleChange}
				className={css.TextEditor__input}
				defaultValue={value}
				name={name}
			/>
		</div>
	);
};
