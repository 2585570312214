.Header {
	box-sizing: border-box;
	width: 100%;
	align-items: middle;
	margin: 0 0 20px;
	padding: 10px var(--pad-x) 10px;
	display: grid;
	grid-template-columns: auto 1fr;
	grid-template-rows: auto auto;
	grid-template-areas:
	'back extra'
	'title actions'
	'subtitle aux'
	;
	grid-gap: 5px 20px;
}
.Header--show-lead {
	grid-template-rows: auto auto auto;
	grid-template-areas:
	'back extra'
	'title actions'
	'lead lead'
	;
}
.Header--is-sticky {
	position: sticky;
	top: 0;
	z-index: var(--index-header);
	background-color: var(--background-color);
	padding-bottom: 20px;
}
.Header--is-stuck {
	box-shadow: 0 12px 14px -14px rgba(0, 0, 0, 0.1);
}
.Header--is-short {
	padding: 30px var(--pad-x) 20px;
}
.topRow {
	grid-area: back;
}
.extra {
	grid-area: extra;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
}
.titleBar {
	grid-area: title;
	display: flex;
	grid-gap: 20px;
	align-self: center;
}
.title {
	font-size: 30px;
	line-height: 1em;
	font-weight: var(--bold);
}
.subtitle {
	grid-area: subtitle;
	font-size: 14px;
	line-height: 1em;
	margin-top: 5px;
	vertical-align: baseline;
	font-weight: var(--regular);
}
.Header--is-stuck .title,
.Header--is-short .title {
	font-size: 30px;
	line-height: 35px;
}
.lead {
	grid-area: lead;
	width: 100%;
}
.lead p {

}

.actions {
	grid-area: actions;
}