import { useEffect } from 'react';

export const KEYS = {
	LEFT: 37,
	UP: 38,
	RIGHT: 39,
	DOWN: 40,
	ONE: 49,
	TWO: 50,
	THREE: 51,
	FOUR: 52,
	FIVE: 53,
	SIX: 54,
	SEVEN: 55,
	EIGHT: 56,
	NINE: 57,
	ZERO: 48,
	ENTER: 13,
	ESCAPE: 27,
};

const BOUND_KEYS = new Set(Object.values(KEYS));

export const useKeyboard = (handler, dependencies=[]) => {
	useEffect(() => {
		const handleKeydown = (event) => {
			// Don't do anything if a meta key is pressed
			const specialKeyPressed = event.altKey || event.ctrlKey || event.metaKey || event.shiftKey;
			if (specialKeyPressed) {
				return;
			}
			if (event.target.nodeName === 'INPUT' || event.target.nodeName === 'TEXTAREA') {
				return;
			}
			const key = event.which;
			if (BOUND_KEYS.has(key)) {
				event.preventDefault();
			}
			const isNumber = event.which >= KEYS.ZERO && event.which <= KEYS.NINE;
			const isArrow = event.which >= KEYS.LEFT && event.which <= KEYS.DOWN;
			const isEnter = event.which === KEYS.ENTER;
			const isEscape = event.which === KEYS.ESCAPE;
			return handler({
				key,
				isArrow,
				isNumber,
				number: isNumber ? event.which - 48 : null,
				isEnter,
				isEscape,
			});
		};
		window.addEventListener('keydown', handleKeydown);
		return () => {
			window.removeEventListener('keydown', handleKeydown);
		};
	}, [handler, ...dependencies]);
};