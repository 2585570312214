import { useEffect } from 'react';

import notify from '~/src/util/notify';
import { reportError } from '~/src/util/reportError';

const TIME_TO_UNDO = 3000;

/*
We don't so much undo things but just defer their doing until later,
so that we have time to cancel them.

All items are cancelled if Z is pressed.

addToUndoQueue(message, fn)

When an item is undone, the message will be shown.
The action is only triggered after TIME_TO_UNDO ms.

The `<OnUndo handler={fn} />` component can be used to call a method
after the undo queue is cleared.
*/

const queue = [];
const undoHandlers = [];

let isBound = false;

setInterval(checkQueue, 500);

window.addEventListener('beforeunload', handleBeforeUnload);

function handleBeforeUnload(event) {
	const hasItemsInQueue = queue.length > 0;
	if (!hasItemsInQueue) {
		delete event['returnValue'];
		return true;
	}
	notify({
		message: 'Submitting pending items...',
		duration: 2000
	});
	// Submit all pending items
	purgeQueue();
	// Prompt user to hang tight
	event.preventDefault();
	// Chrome requires returnValue
	event.returnValue = '';
}

function bindUnbind() {
	if (queue.length) {
		if (isBound) {
			return;
		}
		document.body.addEventListener('keydown', handleUndoPress, true);
		isBound = true;
	} else {
		if (!isBound) {
			return;
		}
		document.body.removeEventListener('keydown', handleUndoPress, true);
		isBound = false;
	}
}

export function addToUndoQueue(message, action) {
	if (typeof message !== 'string') {
		throw new Error('addToUndoQueue requires message as first parameter');
	}
	queue.push({
		message,
		timeout: Date.now() + TIME_TO_UNDO,
		action,
	});
	bindUnbind();
}

function getLatestItem() {
	return queue.pop();
}

async function checkQueue() {
	if (!queue.length || queue[0].timeout > Date.now()) {
		return;
	}
	const item = getLatestItem();
	try {
		const result = await item.action();
		console.info('Queue success', result);
		// notify(result);
	} catch (error) {
		console.error(error);
		notify.error(`Undo queue action failed: "${error.message}". See more details in console`);
	}
	bindUnbind();
}

export function purgeQueue({ shouldNotify=true } = {}) {
	// No while to mitigate infinity
	const items = queue.splice(0, queue.length);
	// Using then/catch instead of await to ensure
	// this function runs synchronously, allowing
	// us to submit pending items before unload.
	return Promise.all(
		items.map((item) => item.action())
	).then((results) => {
		if (shouldNotify) {
			notify({
				message: `Submitted ${results.length} items`,
				duration: 1000
			});
		}
	}).catch((errors) => {
		reportError(new Error(`Undo queue purge queue errors: ${errors.length}`), { errors });
	});
}

function undoAllItems() {
	while (queue.length) {
		const item = getLatestItem();
		notify({
			message: `Undo: ${item.message}`,
			duration: 1000
		});
	}
	undoHandlers.forEach((trigger) => trigger());
}

function handleUndoPress(event) {
	if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
		return;
	}
	if (event.which === 90) {
		undoAllItems();
	}
}

export const OnUndo = ({ handler }) => {
	useEffect(() => {
		undoHandlers.push(handler)
		return () => {
			undoHandlers.splice(undoHandlers.indexOf(handler), 1);
		};
	});
	return null;
};