import React from 'react';

import { Link } from '~/src/lib/Buttons';
import iconOutOfStock from '~/src/icons/out-of-stock.svg';
import iconInStock from '~/src/icons/in-stock.svg';
import iconPublished from '~/src/icons/published.svg';
import iconUnpublished from '~/src/icons/unpublished.svg';

import * as css from './MatchList.css';

const splitAtIndex = (index, list) => {
	if (index === -1) {
		return [list, []];
	}
	return [
		list.slice(0, index),
		list.slice(index),
	];
}

export const MatchList = ({ products, matches }) => {
	if (!matches) {
		return null;
	}
	const indexOfFirstFiltered = matches.findIndex((match) => match.filters && match.filters.length);
	const [goodMatches, filteredMatches] = splitAtIndex(indexOfFirstFiltered, matches);
	const [goodProducts, filteredProducts] = splitAtIndex(indexOfFirstFiltered, products);
	return (
		<ul className={css.MatchList}>
			{goodMatches.map((match, index) => (
				<MatchItem key={match.product_id} match={match} product={goodProducts[index]} />
			))}
			{filteredMatches.length ? (
				<div className={css.MatchList__cutoff}>
					Results below are not shown to user
				</div>
			) : null}
			{filteredMatches.map((match, index) => (
				<MatchItem key={match.product_id} match={match} product={filteredProducts[index]} />
			))}
		</ul>
	);
};

const MatchItem = ({ match, product }) => {
	if (!(match && product && match.product_id === product.product_id)) {
		return (
			<div>Match and product don’t match ({match && match.product_id} !== {product && product.product_id})</div>
		);
	}
	return (
		<li className={css.MatchItem}>
			<div className={css.MatchItem__media}>
				{product.thumbnail && (<img src={product.thumbnail} alt="" />)}
			</div>
			<div className={css.MatchItem__titles}>
				<h2 className={css.MatchItem__title}>
					<Link target="_blank" to={`/products/${product.product_id}`} className={css.MatchItem__link}>{product.title}</Link>
				</h2>
			</div>
			<div className={css.MatchItem__meta}>
				<Link target="_blank" to={`/products/${product.product_id}`} className={css.MatchItem__id}>{product.product_id}</Link>
				{product.rank && (
					<span className={css.MatchItem__rank}>Rank: {product.rank}</span>
				)}
			</div>
			<div className={css.MatchItem__info}>
				<img src={product.is_in_stock ? iconInStock : iconOutOfStock} alt={product.is_in_stock ? 'In stock' : 'Out of stock'} />
				<img src={product.is_published ? iconPublished : iconUnpublished} alt={product.is_published ? 'Published' : 'Not published'} />
			</div>
			<div className={css.MatchItem__extra}>
				<div className={css.MatchItem__scores}>
					<span className={css.MatchItem__extraTitle}>Scores: </span>
					{Object.entries(match.scores).map(([scoreKey, scoreValue]) => (
						<span className={css.MatchItem__scoreItem} key={scoreKey}>{scoreKey}: <strong>{scoreValue}</strong></span>
					))}
				</div>
				{match.filters && match.filters.length ? (
					<div className={css.MatchItem__filters}>
						<span className={css.MatchItem__extraTitle}>Filters: </span>
						{match.filters.join(', ')}
					</div>
				) : null}
			</div>
		</li>
	)
};