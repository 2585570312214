import React, { useState, useEffect } from 'react';
import classnames from 'classnames';

import * as css from './Bubbler.css';

/*
The Bubbler will show a bubble whenever the message is set to a new string.
```
const [message, setMessage] = useState(null);
return (
	<Fragment>
		<button onClick={() => setMessage('Message!')}>Show message</button>
		<Bubbler message={message} />
	</Fragment>
);
```
*/
export const Bubbler = ({ message='', duration=1000 }) => {
	const [cachedMessage, setCachedMessage] = useState(message);
	const [isVisible, setVisibility] = useState(false);
	// Cache the message to stay on screen during hiding phase
	useEffect(() => {
		if (message) {
			setCachedMessage(message);
		}
	}, [message]);
	useEffect(() => {
		const shouldBeVisible = !!message;
		setVisibility(shouldBeVisible);
		// Hide it after duration
		if (shouldBeVisible) {
			const timeout = setTimeout(() => {
				setVisibility(false);
			}, duration);
			return () => clearTimeout(timeout);
		}
	}, [message, duration]);
	return (
		<Bubble
			message={cachedMessage}
			isVisible={isVisible}
		/>
	);
};

const Bubble = ({ message='', isVisible }) => {
	return (
		<div className={classnames(css.Bubble, isVisible && css['Bubble--is-visible'])}>
			{message}
		</div>
	);
};