import React, { useState, Fragment } from 'react';

import { Bubbler } from '~/src/lib/Bubbler';

export const AutoCopy = ({ Tag, className, children, text, ...props }) => {
	const [ message, setMessage ] = useState(null);
	const handleClick = async () => {
		await navigator.clipboard.writeText(text);
		setMessage('Copied!');
	};
	return (
		<Fragment>
			<Tag className={className} onClick={handleClick} {...props}>
				{children}
			</Tag>
			<Bubbler message={message} />
		</Fragment>
	);
};
