import React, { Component, Fragment, createRef } from 'react';
import classnames from 'classnames';
import setAtPath from 'lodash/set';
import getAtPath from 'lodash/get';
import isNil from 'lodash/isNil';

import { LoadingSpinner } from '~/src/lib/LoadingSpinner';

import * as css from './Form.css';

const addAtPath = (root, path, value) => {
	const list = getAtPath(root, path) || [];
	list.push(value);
	setAtPath(root, path, list);
}

const getValue = (inputElement) => {
	const type = inputElement.getAttribute('type');
	if (type === 'checkbox') {
		if (inputElement.checked) {
			if (inputElement.value) {
				return inputElement.value;
			}
			return true;
		}
		return false;
	}
	return inputElement.value;
}

const parseValue = (value, type) => {
	if (!type) {
		return value;
	}
	if (type === 'json') {
		return JSON.parse(value || '{}') || {};
	}
	return value;
};

export const getFormData = (formElement) => {
	return Array.from(formElement.elements).reduce((values, item) => {
			const name = item.getAttribute('name');
			const type = item.getAttribute('type');
			const value = parseValue(getValue(item), type);
			if (isNil(name) || isNil(value)) {
				return values;
			}
			const isArray = name.endsWith('[]');
			const isMap = name.indexOf('[') !== -1 || name.indexOf('.') !== -1;
			if (isArray) {
				const actualName = name.replace(/\[\]$/, '');
				// We only want to include truthy values
				if (value) {
					addAtPath(values, actualName, value);
				}
			} else if (isMap) {
				setAtPath(values, name, value);
			} else {
				// If it's a map and the property already exists, extend it
				if (typeof value === 'object' && name in values) {
					Object.assign(values[name], value);
				} else {
					// Otherwise, set the value directly
					values[name] = value;
				}
			}
			return values;
		}, {})
	;
};

export class Form extends Component {
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.formRef = createRef();
	}
	getFormData() {
		return getFormData(this.formRef.current);
	}
	handleSubmit(event) {
		event.preventDefault();
		if (this.props.onSubmit) {
			this.props.onSubmit(this.getFormData());
		}
	}
	render() {
		const { children, className, isLoading } = this.props;
		return (
			<Fragment>
				<form ref={this.formRef} className={classnames(css.Form, className)} onSubmit={this.handleSubmit}>
					<div className={classnames(css.content, isLoading && css['content--isLoading'])}>
						{children}
					</div>
					{isLoading && <LoadingSpinner delay={150} className={css.loader} />}
				</form>
			</Fragment>
		);
	}
}

export const FormFade = ({ children }) => (
	<div className={css.fade}>{children}</div>
);