import React from 'react';
import classnames from 'classnames';

import * as css from './LoadingSpinner.css';

export const LoadingSpinner = ({ delay=500, className }) => (
	<div className={classnames(css.LoadingSpinner, className)} style={{ animationDelay: `${delay}ms` }}>
		<div className={css.LoadingSpinner__inner} />
	</div>
);
