import React from 'react';
import { diffWords } from 'diff';
import classnames from 'classnames';

import * as css from './InlineDiff.css';

export const InlineDiff = ({ oldValue, newValue }) => {
	const diff = diffWords(oldValue, newValue);
	return (
		<span className={css.InlineDiff}>
			{diff.map((part, index) => (
				<span
					key={index}
					className={classnames(
						css.InlineDiff__part,
						part.added && css['InlineDiff__part--added'],
						part.removed && css['InlineDiff__part--removed']
					)}
				>
					{part.value}
				</span>
			))}
		</span>
	);
};