import React from 'react';

import { niceURL } from '~/src/util/nice';
import request from '~/src/util/request';
import { useResource } from '~/src/util/useResource';
import notify from '~/src/util/notify';
import { TablePage } from '~/src/lib/Table';
import { ButtonBar, Button } from '~/src/lib/Buttons';

function createScrapeCall(item) {
	return async () => {
		const id = Math.random();
		notify({
			id,
			message: `Scrape started: "${item.title}"`,
		});
		const json = await request('POST', '/v0/scrapes', item);
		if (json.status === 'success') {
			notify({
				id,
				message: `Scrape completed: "${item.title}"`,
				button: {
					label: 'View result',
					to: `/scrape-results/${json.data.scrape_id}`,
				},
			});
		}
	}
}

const HEADERS = [{
	label: 'ID',
	key: 'id',
}, {
	label: 'Title',
	key: 'title',
}, {
	label: 'url',
	key: 'url',
	renderItem: (item) => (
		<a href={item.url} rel="noopener noreferrer" target="_blank">{niceURL(item.url)}</a>
	)
}, {
	key: 'actions',
	renderItem: (item, aux) => (
		<ButtonBar noSpread>
			<Button onClick={createScrapeCall(item)}>Scrape</Button>
			<Button variant="neutral" onClick={() => aux.archiveItem(item)}>Archive</Button>
		</ButtonBar>
	)
}];

const Recommendations = () => {
	const { isLoading, error, data, refresh } = useResource('/v0/user-feedback/recommendations');
	const archiveItem = async (item) => {
		const response = await request('POST', '/v0/user-feedback/need-recommendations/archived', { id: item.id });
		if (response.ok) {
			refresh();
		}
	};
	return (
		<TablePage
			keyProp="id"
			headers={HEADERS}
			items={data}
			headerItemAux={{ archiveItem }}
			title="Recommendations"
			isLoading={isLoading}
			error={error}
		/>
	);
};

export default Recommendations;