.BaseButton {
	position: relative;
	display: inline-block;
	cursor: pointer;
	user-select: none;
	background-color: transparent;
	padding: 0;
	margin: 0;
	border: none;
	text-decoration: none;
	vertical-align: text-bottom;
	box-sizing: border-box;
	font-family: inherit;
}
.BaseButton:focus {
	outline: none;
}
.BaseButton[disabled] {
	opacity: 0.3;
	pointer-events: none;
}
/* This is intentionally .Button, not .BaseButton, so
that links don't get affected by it, but it's here so that
BaseButton--is-loading's animation is defined after and takes precedence */
.Button:focus {
	animation: button-click 150ms ease;
}
@keyframes button-click {
	0% {
		transform: scale(0.95);
	}
}
.BaseButton--is-loading,
.BaseButton--is-loading:focus {
	filter: saturate(0.3) brightness(1.2);
	animation: loading-fade 2s infinite;
	pointer-events: none;
	background-image: url('~/src/icons/loading-dots.svg');
	background-size: 24px auto;
	background-position: center center;
	background-repeat: no-repeat;
}
@keyframes loading-fade {
	0%,
	100% {
		opacity: 0.9;
	}
	50% {
		opacity: 0.7;
	}
}
.BaseButton > * {
	transition: opacity 150ms ease;
}
.BaseButton--is-loading > * {
	opacity: 0;
}
.BaseButton[data-popover]:before {
	content: attr(data-popover);
	position: absolute;
	top: 100%;
	left: 50%;
	margin-top: 5px;
	transform: translateX(-50%);
	background: var(--black);
	color: var(--white);
	font-size: 12px;
	border-radius: 5px;
	padding: 5px 8px;
	white-space: nowrap;
	animation: popover-intro 300ms ease backwards;
	pointer-events: none;
}
@keyframes popover-intro {
	0% {
		opacity: 0;
		transform: translateX(-50%) translateY(-10px);
	}
}
.Button {
	border-radius: var(--radius);
	padding: 10px 20px;
	transition: box-shadow 75ms ease, border-color 75ms ease, opacity 75ms ease;
	box-shadow: 0 0 3px hsl(0, 3%, 60%, 0.02), 0 0 12px hsl(0, 3%, 60%, 0.1);
	font-weight: var(--semibold);
	letter-spacing: 0.02em;
	font-size: 13px;
	line-height: 15px;
	text-align: center;
	white-space: nowrap;
	vertical-align: bottom;
}
.Button--round {
	--radius: 100px;
	padding: 10px 20px;
}
.Button--square {
	--radius: 4px;
	padding: 8px 17px;
}
.Button--size-large {
	padding: 20px 40px;
	font-size: 18px;
}
.Button--size-slim {
	padding: 6px 16px;
}
.Button--has-icon {
	padding-left: 15px;
}
.Button--has-icon::before {
	content: '';
	display: inline-block;
	width: 16px;
	height: 16px;
	vertical-align: bottom;
	margin-right: 8px;
}

.Button:hover {
	box-shadow: 0 0 3px hsl(0, 3%, 60%, 0.1), 0 0 12px hsl(0, 3%, 60%, 0.2);
}
.Button--primary {
	background-color: var(--green);
	color: var(--white);
}
.Button--primary:hover {
	background-color: var(--green-dark);
}
.Button--secondary {
	background-color: var(--gray2);
	color: var(--black);
	box-shadow: none;
}
.Button--secondary:hover {
	box-shadow: 0 0 12px hsl(0, 3%, 60%, 0.1);
}
.Button--neutral {
	background-color: var(--blue);
	color: var(--white);
}

.Button--gray {
	background-color: var(--secondary-gray);
	color: black;
}
.Button--light-red {
	background-color: var(--red-light);
	color: black;
}
.Button--light-blue {
	background-color: var(--blue-light);
	color: black;
}
.Button--light-yellow {
	background-color: var(--yellow-light);
	color: black;
}
.Button--light-green {
	background-color: var(--green-light);
	color: black;
}
.Button--red {
	background-color: var(--red);
	color: white;
}
.Button--blue {
	background-color: var(--blue);
	color: white;
}
.Button--yellow {
	background-color: var(--yellow);
	color: black;
}
.Button--green {
	background-color: var(--green);
	color: white;
}

.Button--danger {
	background-color: var(--red);
	color: white;
}
.Button--danger:hover {
	background-color: var(--red-dark);
}
.Button--bare {
	background-color: transparent;
	color: inherit;
	box-shadow: none;
	padding-left: 10px;
	padding-right: 10px;
	opacity: 0.8;
}
.Button--bare:hover {
	box-shadow: none;
	opacity: 1;
}

.ButtonBar {
	flex: 1;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	position: relative;
}
.ButtonBar--no-spread {
	flex: initial;
}
.ButtonBar--default {
	column-gap: 10px;
}
.ButtonBar--lozenge .Button {
	border-radius: 0px;
	margin: 0;
}
.ButtonBar--lozenge .Button--round:not(:first-child) {
	padding-left: 15px;
}
.ButtonBar--lozenge .Button--round:not(:last-child) {
	padding-right: 15px;
}
.ButtonBar .Button--square {
	/* padding-left: 10px;
	padding-right: 10px; */
}
.ButtonBar--lozenge .Button:first-child {
	border-top-left-radius: var(--radius);
	border-bottom-left-radius: var(--radius);
}
.ButtonBar--lozenge .Button:last-child {
	border-top-right-radius: var(--radius);
	border-bottom-right-radius: var(--radius);
}
.ButtonBar--center {
	justify-content: center;
	flex: 1;
	column-gap: 10px;
}

.ButtonList {

}
.ButtonList h2 {
	margin-bottom: 0.5em;
}
.ButtonList + .ButtonList {
	margin-top: 20px;
}
.ButtonList .Button {
	display: block;
}
.ButtonList .Button + .Button {
	margin: 10px 0 0;
}

.Link {
	font-size: inherit;
	font-family: inherit;
	font-weight: inherit;
	line-height: inherit;
	vertical-align: baseline;
}
.Link--larger-hit-area {
	padding: 0.5em;
	margin: -0.5em;
}
.Link--default {
	color: inherit;
}
.Link--default:hover {
	text-decoration: underline;
}
.Link--prominent {
	color: var(--link-color);
	text-decoration: underline;
}
.Link--subtle {
	color: inherit;
}
.Link--subtle:hover {
	text-decoration: underline;
}
.Link--danger {
	color: var(--red-dark);
	text-decoration: underline;
}
.Link--danger:hover {
	color: var(--red-darker);
}
.Link--external:after {
	content: '';
	width: 1em;
	height: 1em;
	display: inline-block;
	background-image: url('~/src/icons/external-link.svg');
	background-repeat: no-repeat;
	background-size: 0.8em auto;
	vertical-align: middle;
	margin-left: 0.5em;
}
.Link--external:hover {
	text-decoration: underline;
}
.Button + .Link {
	margin-left: calc(20px - 0.5em);
}
.ButtonBar .Link {
	font-size: 14px;
}

.SharpButton {
	border-radius: 6px;
	min-height: 35px;
	padding: 10px 15px;
	font-weight: var(--semibold);
	font-size: 13px;
	line-height: 15px;
}
.SharpButton--default {
	background-color: var(--blue);
	color: var(--white);
}
.SharpButton::after {
	content: '';
	display: inline-block;
	width: 12px;
	height: 15px;
	background-image: url('~/src/icons/wings-right.svg');
	background-size: 12px auto;
	background-position: center center;
	background-repeat: no-repeat;
	vertical-align: bottom;
	margin-left: 12px;
}

.HelpButton {
	box-sizing: border-box;
	width: 35px;
	height: 35px;
	align-self: center;
	border-radius: 50%;
	box-shadow: 0 0 1px rgba(0,0,0,0.1);
	background: var(--gray1);
	border: 1px solid var(--gray2);
	background-image: url('~/src/icons/help.svg');
	background-size: 16px;
	background-position: center center;
	background-repeat: no-repeat;
}

.SmallButton {
	border-radius: 5px;
	line-height: 14px;
	padding: 5px 12px;
	font-size: 13px;
	font-weight: var(--semibold);
	transition: background-color 75ms ease;
	white-space: nowrap;
}
.SmallButton--default {
	background-color: var(--gray1);
	color: var(--black);
	box-shadow: var(--shadow-clickable);
}
.SmallButton--default:hover {
	background-color: var(--gray2);
	color: var(--black);
}
.SmallButton--back:hover {
	background-color: var(--gray2);
	color: var(--black);
}
.SmallButton--back {
	background-color: transparent;
	color: inherit;
	padding-left: 10px;
	margin-left: -10px;
}
.SmallButton--back::before {
	content: '';
	display: inline-block;
	width: 10px;
	height: 11px;
	background-image: url('~/src/icons/chevron-left-black.svg');
	background-size: 6px auto;
	background-position: center center;
	background-repeat: no-repeat;
	vertical-align: baseline;
	margin-right: 6px;
}

.SlimIconButton {
	border-radius: 4px;
	line-height: 20px;
	padding: 5px 10px;
	min-height: 30px;
	box-sizing: border-box;
	font-size: 13px;
	font-weight: var(--semibold);
	transition: background-color 75ms ease;
	display: inline-flex;
	align-items: center;
	column-gap: 10px;
	box-shadow: var(--shadow-clickable);
	white-space: nowrap;
}
.SlimIconButton--default {
	background-color: var(--secondary-gray);
	color: var(--black);
}
.SlimIconButton--default:hover {
	background-color: var(--gray2);
	color: var(--black);
}
.SlimIconButton--yellow {
	background-color: var(--yellow);
	color: var(--black);
}
.SlimIconButton--yellow:hover {
	background-color: var(--yellow-dark);
	color: var(--black);
}
.SlimIconButton--blue {
	background-color: var(--blue);
	color: var(--white);
}
.SlimIconButton--blue:hover {
	background-color: var(--blue-darker);
	color: var(--white);
}
.SlimIconButton--no-text {
	column-gap: 0;
}
.SlimIconButton--is-selected {
	box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.1), inset 0 0 3px 1px rgba(0, 0, 0, 0.05);
}
.SlimIconButton:focus-visible {
	outline: 1px solid gray;
}
.SlimIconButton::before {
	content: '';
	display: inline-block;
	width: 16px;
	height: 16px;
	background-size: 16px;
	background-position: center center;
	background-repeat: no-repeat;
}

.Icon--edit::before {
	background-image: url('~/src/icons/edit.svg');
}
.Icon--pick::before {
	background-image: url('~/src/icons/pick.svg');
}
.Icon--trash::before {
	background-image: url('~/src/icons/trash.svg');
}
.Icon--cancel::before {
	background-image: url('~/src/icons/cancel.svg');
}
.Icon--search::before {
	background-image: url('~/src/icons/search.svg');
}
.Icon--refresh::before {
	background-image: url('~/src/icons/refresh.svg');
}
.Icon--stats::before {
	background-image: url('~/src/icons/stats.svg');
}
.Icon--activity::before {
	background-image: url('~/src/icons/activity.svg');
}
.Icon--down::before {
	background-image: url('~/src/icons/chevron-down.svg');
}
.Icon--up::before {
	background-image: url('~/src/icons/chevron-down.svg');
	transform: scaleY(-1);
}
.Icon--add::before {
	background-image: url('~/src/icons/plus.svg');
}
.Icon--move::before {
	background-image: url('~/src/icons/move.svg');
}
.Icon--filter::before {
	background-image: url('~/src/icons/filter.svg');
}
.Icon--filter.Icon--is-selected::before {
	background-image: url('~/src/icons/filter-selected.svg');
}
