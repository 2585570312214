.Row {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	grid-gap: 10px;
}
.Row--valign-center {
	align-items: center;
}
.Row--valign-top {
	align-items: flex-start;
}
.Row--valign-baseline {
	align-items: baseline;
}

.Column {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	row-gap: 20px;
}
.Column--align-center {
	align-items: center;
}
.Column--align-left {
	align-items: flex-start;
}
.Column--align-stretch {
	align-items: stretch;
}

.Item {
}

.TitleBar {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	column-gap: 40px;
	margin-top: 60px;
	margin-bottom: 30px;
}
.TitleBar > * {
	margin-top: 0;
	margin-bottom: 0;
}

.Spread {
	flex: 1;
}