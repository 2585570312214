import React from 'react';

import { TransparentInput } from '~/src/lib/Form';

import * as css from './DateEditor.css';

const noop = (value) => console.log('TextEditor.onChange', value);

export const DateEditor = ({ onChange=noop, hasFocusStyle=true, name, value }) => {
	const handleClear = (event) => {
		event.preventDefault();
		onChange(null);
	};
	return (
		<div className={css.DateEditor}>
			<TransparentInput
				className={css.DateEditor__input}
				hasFocusStyle={hasFocusStyle}
				type="date"
				onChange={onChange}
				name={name}
				value={value || ''}
			/>
			<button onClick={handleClear} className={css.DateEditor__clear}>Clear date</button>
		</div>
	);
};