.FilterBar {
	display: flex;
	grid-gap: 30px;
	margin-bottom: 40px;
}
.FilterBar > * {
	flex: 1;
}
.FilterBar__select {
	background: var(--gray1);
	padding: 10px;
	border-radius: 5px;
}


.MatchGrid {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	grid-gap: 20px;
}
.MatchPreview {
	display: grid;
	grid-template-rows: auto auto;
	grid-template-columns: 1fr auto auto;
	grid-template-areas:
		'query answer'
		'result answer'
	;
	grid-gap: 20px;
	background: var(--white);
	box-shadow: var(--card-shadow);
	border-radius: 7px;
	padding: 20px;
}
.MatchPreview__row {
	display: grid;
	grid-template-columns: 60px 1fr;
	align-items: baseline;
	align-items: center;
}
.MatchPreview__row:hover {
	text-decoration: none;
}
.MatchPreview__row--query {
	grid-area: query;
}
.MatchPreview__row--result {
	grid-area: result;
}
.MatchPreview__label {
	font-size: 14px;
	line-height: 15px;
	font-weight: var(--bold);
	color: var(--gray4);
}
.MatchPreview__text {
	font-size: 20px;
	line-height: 25px;
	font-weight: var(--semibold);
	color: var(--black);
	display: flex;
	align-items: center;
	display: inline-block;
	vertical-align: top;
}
.MatchPreview__row:hover .MatchPreview__text {
	text-decoration: underline;
}
.MatchPreview__answer {
	grid-area: answer;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.MatchPreview__answerAction {
	font-size: 24px;
	font-weight: var(--bold);
}
.MatchPreview__answerReason {
	grid-area: answer;
	display: flex;
	align-items: center;
	font-size: 20px;
	font-weight: var(--bold);
}
