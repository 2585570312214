import React, { Fragment } from 'react';
import { parseISO, formatDistance } from 'date-fns';

import { usePaginatedResource, useResourceRefresher } from '~/src/util/useResource';
import { TablePage } from '~/src/lib/Table';
import { AutoLoader } from '~/src/lib/AutoLoader';
import { Button } from '~/src/lib/Buttons';
import { Paginate } from '~/src/lib/Paginate';

const ENDPOINT = '/v0/querylog';

const HEADERS = [{
	label: 'ID',
	key: 'id',
}, {
	label: 'Query',
	key: 'query',
	isWide: true,
	renderItem: (item) => item.heading || item.title,
}, {
	label: 'Result',
	key: 'result_name',
	renderItem: (item) => item.product_name || item.service_type,
}, {
	label: 'Age',
	key: 'age',
	renderItem: (item) => formatDistance(parseISO(item.created_at), new Date(), 'YYYY-MM-DD HH:mm:ss'),
}, {
	label: 'Actions',
	key: 'actions',
	renderItem: (item) => <Button to={`/querylog/${item.id}`}>View</Button>,
}];

const Actions = ({ refresh }) => (
	<Fragment>
		<Button variant="neutral" onClick={refresh}>Refresh</Button>
	</Fragment>
);


export const Querylog = () => {
	const { error, isLoading, data, nextLink } = usePaginatedResource(ENDPOINT);
	const refresh = useResourceRefresher(ENDPOINT);
	return (
		<AutoLoader error={error} isLoading={isLoading}>
			<TablePage
				keyProp="id"
				headers={HEADERS}
				items={data}
				title="Querylog"
				actions={<Actions refresh={refresh} />}
			/>
			{data && nextLink && (<Paginate nextLink={nextLink} />)}
		</AutoLoader>
	);
};
